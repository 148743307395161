<app-simple-crud
    [modalTitle]="modalTitle"
    [gridTitle]="'trace.title' | translate"
    [addButtonTitle]="'trace.add-device' | translate"
    [entityType]="entityType"
    [entityTypeModels]="entityTypeModels"
    [deleteButtonTooltip]="'trace.delete-device' | translate"
    [deleteWarningMessage]="'trace.delete-device-warning' | translate"
    [selectedItem]="selectedTrace"
    [loadingModal]="loadingModal"
    [(showDeletePopup)]="popups.confirmDelete"
    [showCreateOrEditPopup]="popups.createOrEdit"
    (showCreateOrEditPopupChange)="closeModal()"
    [addButtonNewItem]="false"
    (addNewItem)="openModalAddTrace()" >
   
    <app-trace-list grid
        [loading]="loading"
        [traces]="dataSource"
        (editItem)="openModalEditTrace($event)"
        (deleteItem)="openPromptDeleteTrace($event)"
    >
    </app-trace-list>
    <div create-edit-modal>
        <app-trace-modal
        *ngIf="selectedTrace"
        [selectedTrace]="selectedTrace"
        (cancel)="closeModal()"
        >
        </app-trace-modal>
    </div>
</app-simple-crud>