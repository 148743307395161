import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { Fiber } from './fiber.model';
import { ICommonEntity } from './interface/iCommonEntity';
import { Service } from './service.model';

export class ServiceTrace {
  get commonID(): any {
    return this.serviceTraceID;
  }
  get entityName(): any {
    return Cons._SERVICETRACE;
  }
  serviceTraceID: number;
  serviceID: Service;
  fiberID: Fiber;

  @Type(()=> Service)
  service: Service;
  
}
