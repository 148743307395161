<app-simple-crud
[gridTitle]="'splitter.title' | translate"
[modalTitle]="modalTitle"
[addButtonTitle]="'splitter.add-element' | translate"
[deleteButtonTooltip]="'splitter.delete-element' | translate"
[deleteWarningMessage]="'splitter.delete-element-warning' | translate"
[selectedItem]="selectedSplitterType"
[loadingModal]="loadingModal"
[(showDeletePopup)]="popups.confirmDelete"
[showCreateOrEditPopup]="this.popups.createOrEdit"
(showCreateOrEditPopupChange)="closeModal()"
(addNewItem)="openModalAddSplitterType()"
(deleteItem)="deleteSplitterType($event)" 
>

    <app-splitter-type-list grid
    [loading]="loading"
    [SplitterTypes]="dataSource"
    (editItem)="openModalEdit($event)"
    (deleteItem)="deleteSplitterType($event)">
    </app-splitter-type-list>

    <div create-edit-modal>
        <app-splitter-type-modal
        *ngIf="selectedSplitterType"
        [selectedSplitterType]="selectedSplitterType"
        (cancel)="closeModal()"
        (save)="saveSplitterType($event)"
        >
        </app-splitter-type-modal>
    </div>
</app-simple-crud>