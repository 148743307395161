import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { Equipment } from '@app/@shared/model/equipment.model';
import { EquipmentService } from '@app/@shared/services/equipment.service';
import { EquipmentType } from '@app/@shared/model/equipmentType.model';

@Component({
  templateUrl: './equipment-home.component.html',
  styleUrls: ['./equipment-home.component.scss'],
})
export class EquipmentHomeComponent implements OnInit {
  equipments: Equipment[] = [];
  selected?: Equipment;
  equipmentTypes : EquipmentType[] = [];

  modalTitle: string;

  loading: boolean = false;
  loadingModal: boolean = false;

  
  popups = {
    confirmDelete: false,
    createOrEdit: false,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private equipmentService: EquipmentService,
    private platformService: PlatformService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ olts }) => this.equipments = olts);
  }

  async openModalAdd() {
    this.modalTitle = this.translateService.instant('olt.add');
    this.loadingModal = true;

    try {
      //await this.loadOltCreationData();
    } catch (ex) {
      console.error(ex);
      return;
    } finally {
      this.loadingModal = false;
    }

    const platformID: number = this.platformService.platformID;
    this.selected = plainToClass(Equipment, { platformID });
    this.popups.createOrEdit = true;
  }

  async openModalEdit(olt: Equipment) {
    this.modalTitle = this.translateService.instant('olt.edit');
    this.loadingModal = true;

    let selectedEquipment;

    try {
      const response = await Promise.all([
        firstValueFrom(this.equipmentService.getByID(olt.equipmentID))
      ]);

      selectedEquipment = response[0];
    } catch (ex) {
      console.error(ex);
      return;
    } finally {
      this.loadingModal = false;
    }

    this.selected = selectedEquipment;
    this.popups.createOrEdit = true;
  }

  openPromptDelete(olt: Equipment) {
    this.selected = olt;
    this.popups.confirmDelete = true;
  }

  async deleteOLT(olt: Equipment) {
    this.popups.confirmDelete = false;

    try { 
      await firstValueFrom(this.equipmentService.delete(olt.equipmentID));
      await this.reload();
    } catch {
      const errorMessage = this.translateService.instant('olt.delete-unexpected-error');
      notify(errorMessage, 'error', 4500);
    } finally {
      this.selected = undefined;
    }
  }

  async saveOLT(olt: Equipment) {
    this.loadingModal = true;

    try {
        if (olt.equipmentID) { 
          await firstValueFrom(this.equipmentService.edit(olt));
        } else {
          await firstValueFrom(this.equipmentService.save(olt));
        }
        await this.reload();
    } catch(ex) {
      return;
    } finally {
      this.loadingModal = false;
      this.popups.createOrEdit = false;
      this.selected = undefined;
    }
  }

  closeModal() {
    this.popups.createOrEdit = false;
    this.selected = undefined;
  }


  

  async loadModels() {
    if (!this.equipmentTypes.length) {
      this.equipmentTypes = await firstValueFrom(this.equipmentService.getModels());
    }
  }

  async reload() {
    this.loadingModal = true;

    try {
      this.equipments = await firstValueFrom(this.equipmentService.getWithFilter());
    } catch(ex) {

    } finally {
      this.loadingModal = false;
    }
  }
}
