import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cons } from '@app/@shared/cons/cons';
import { MainService } from '@app/@shared/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { DxPopoverComponent } from 'devextreme-angular';

@Component({
  selector: 'app-user-help',
  templateUrl: './user-help.component.html',
  styleUrls: ['./user-help.component.scss'],
})
export class UserHelpComponent implements OnInit {
  hideMessageOnLoad = false;
  prerender = false;
  steps = [];
  width = 600;

  constructor(private trans: TranslateService, public mainService : MainService, private activatedRoute: ActivatedRoute) {
    if (this.mainService.isMobile){
      this.width = window.screen.width - 20;
    }
  }

  @Input() formPath : string;
  @Output() onHelpClosed = new EventEmitter<any>();

  @ViewChild('popover') popover: DxPopoverComponent;

  ngOnInit() {
    const tokenFromActivatedRoute: string = this.activatedRoute.snapshot.queryParamMap.get('token');
    if(tokenFromActivatedRoute){
      this.hideMessageOnLoad = true;
      return;
    }
    
    let pref = this.mainService.getPreference(Cons.PREFERENCES._HIDE_HELP_ON_INIT, false)?.value;

    if (pref != undefined) {
      this.hideMessageOnLoad = pref;
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!simpleChanges['formPath']) {
      return;
    }

    switch(this.formPath){
      case "/map-viewer": this.loadMapViewerSteps(); break;
      case "/survey": this.loadSurveySteps(); break;
    }
  }

  loadMapViewerSteps() {
    this.prerender = true;
    this.steps = [
      this.createStep(
        this.trans.instant('user-help.map-viewer-welcome-message'),
        'top',
        'bottom',
        '#help-button',
        this.trans.instant('user-help.intro-message'),
        this.trans.instant('user-help.intro-message-2'),
        true
      ),
      this.createStep(
        this.trans.instant('forms.map-viewer.search-panel'),
        'left',
        'right',
        '#search-accordion',
        this.trans.instant('user-help.search-panel'),
        undefined,
        undefined,
        'search-panel.gif',
        '270px'
      ),
      this.createStep(
        this.trans.instant('forms.map-viewer.search-panel'),
        'left',
        'right',
        '.drawer-collapser',
        this.trans.instant('user-help.search-panel-collapse'),
        undefined,
        undefined,
        'search-panel-collapse.gif',
        '100%'
      ),
      this.createStep(
        this.trans.instant('user-help.polygon-draw.title'),
        'bottom',
        'top',
        '#polygon-draw-button',
        this.trans.instant('user-help.polygon-draw.message'),
        this.trans.instant('user-help.polygon-draw.message-2'),
        undefined,
        'draw-polygon.gif',
        '100%'
      ),
      this.createStep(
        this.trans.instant('user-help.search-area.title'),
        'bottom',
        'top',
        '#search-area-button',
        this.trans.instant('user-help.search-area.message'),
        undefined,
        undefined,
        'search-area.gif',
        '100%'
      ),
      this.createStep(
        this.trans.instant('user-help.help-button.title'),
        'top',
        'bottom',
        '#help-button',
        this.trans.instant('user-help.help-button.message')
      ),
    ];

    setTimeout(() => {
      this.prerender = false;
      this.steps[0].visible = true;
    }, 800);
  }

  loadSurveySteps() {
    this.prerender = true;
    this.steps = [
      this.createStep(
        this.trans.instant('user-help.survey-welcome-message'),
        (!this.mainService.isMobile ? 'top' : "center"),
        (!this.mainService.isMobile ? 'bottom' : "center"),
        '#help-button',
        this.trans.instant('user-help.intro-message'),
        this.trans.instant('user-help.intro-message-2'),
        true
      ),
      this.createStep(
        this.trans.instant('survey.map.load-plots'),
        'center',
        'center',
        'dx-drawer',
        this.trans.instant('user-help.load-plots'),
        this.trans.instant('user-help.load-plots-2'),
        undefined,
        'finger-touch.gif',
        '270px'
      ),
      this.createStep(
        this.trans.instant('survey.map.load-plots'),
        'center',
        'center',
        'dx-drawer',
        this.trans.instant('user-help.load-plots-3'),
        this.trans.instant('user-help.load-plots-4'),
        undefined,
        'zoom-management.gif',
        'unset'
      )
      
    ];

    setTimeout(() => {
      this.prerender = false;
      this.steps[0].visible = true;
    }, 800);
  }

  createStep(title, my, at, of, message_1, message_2?, visible?, imageSrc?, width?) {
    if (visible == undefined) {
      visible = false;
    }
    if (width == undefined) {
      width = 500;
    }
    return {
      title: title,
      visible: visible,
      my: my,
      at: at,
      of: of,
      message_1: message_1,
      message_2: message_2,
      image: imageSrc,
      width: width,
    };
  }

  skip() {
    //checkbox status
    this.continue(null);
  }

  continue(currentStep) {
    if (currentStep == null) {
      this.steps.forEach((x) => (x.visible = false));
      this.onHelpClosed.emit(false);

      if(this.hideMessageOnLoad){
        this.mainService.setPreference(Cons.PREFERENCES._HIDE_HELP_ON_INIT, true);
      }
      return;
    }

    if (currentStep >= 0) {
      this.steps[currentStep].visible = false;
    }

    if (currentStep + 1 == this.steps.length) {
      //Start over
      currentStep = -1;
    }

    let next = currentStep + 1;

    if (this.steps[next]) {
      this.steps[next].visible = true;
    }
  }

  imgLoadComplete($event) {
    if ($event.target.complete) {
      (<any>this.popover.instance).refreshPosition();
      this.popover.instance.repaint();
    }
  }
}
