import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { MainService } from '../services/main.service';
import { EMPTY } from 'rxjs';
import apis from '../../../environments/api-selector.json';
import extractDomain from 'extract-domain';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private credetentialsService: CredentialsService,
    private main: MainService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hasToken = this.credetentialsService.hasToken();
    const tokenIsExpired = this.credetentialsService.tokenIsExpired();
    const urls = [];


    for(var key in apis.local) { 
      let domain = extractDomain(apis.local[key]);
      if(!urls.includes(domain)){
        urls.push(domain);
      }
    }
    for(var key in apis.develop) { 
      let domain = extractDomain(apis.develop[key]);
      if(!urls.includes(domain)){
        urls.push(domain);
      }
    }
    for(var key in apis.qa) { 
      let domain = extractDomain(apis.qa[key]);
      if(!urls.includes(domain)){
        urls.push(domain);
      }
    }
    for(var key in apis.claro_production) { 
      let domain = extractDomain(apis.claro_production[key]);
      if(!urls.includes(domain)){
        urls.push(domain);
      }
    }
    for(var key in apis.external) { 
      let domain = extractDomain(apis.external[key]);
      if(!urls.includes(domain)){
        urls.push(domain);
      }
    }
    
    if (!urls.some(x => req.url.toLowerCase().includes(x))) {
      return next.handle(req);
    }

    const whitelistedEndpoints = [
      'GetTokenLogout'
    ];

    const isWhitelisted = whitelistedEndpoints.some((whitelistedUrl: string) => {
      const splittedUrl = req.url.split('/');
      const lastElement = splittedUrl[splittedUrl.length - 1].split('?')[0];

      return lastElement.toLowerCase() === whitelistedUrl.toLowerCase();
    });

    if (isWhitelisted) {
      return next.handle(req);
    }

    if (hasToken && tokenIsExpired) {
      this.authenticationService.openExpiredTokenModal();
      return EMPTY;
    }

    if (req.headers.get('ignore-auth')) {
      const headers = req.clone({
        headers: req.headers.delete('ignore-auth'),
      });
      return next.handle(headers);
    }

    if (hasToken && !req.headers.get('Authorization')) {
      const token = this.main.token;

      const headers = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
  
      return next.handle(headers);
    }
      
    return next.handle(req);
  }
}
