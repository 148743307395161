import { Injectable } from "@angular/core";
import routes from './shell/routes.json';
import { TranslateService } from "@ngx-translate/core";
import { PlatformService } from "./auth/platform.service";
import { WindowService } from "./@shared/services/cordova/window.service";
import { Router } from "@angular/router";


export interface RouteItem {
    name: string;
    icon: string;
    path: string;
    userHelp: boolean;
    showHelpOnInit: boolean;
    background: string;
    children: any;
    disabled: boolean;
  }

  
interface RouteNode {
    path: string;
    userHelp?: boolean;
    showHelpOnInit?: boolean;
    children?: RouteNode[];
    disabled?: boolean;
  }
  

@Injectable({
    providedIn: 'root',
  })
export class AppRoutingService {

    fillerNav: any = [];
    userHelpModules: any = [];

    constructor(private trans: TranslateService,
        private router: Router, 
        private platformService: PlatformService,
        private windowService: WindowService) {
        routes.forEach((r: RouteItem) => {
            if (!r.disabled) {
                this.fillerNav.push({
                    name: this.trans.instant(r.name),
                    icon: r.icon,
                    path: r.path,
                    children: r.children?.filter(x => !x.disabled),
                    id: r.name.replace(/ /g, '_'),
                });
            }
        });
    }

    getActiveRoutes(): any[] {
        return this.fillerNav;
    }

    getUserHelpModules(): any[] {
        const flattenTree = (tree: RouteNode[]): RouteNode[] => {
            return tree.flatMap((node: RouteNode) => {
                const flattenedNode: RouteNode = { ...node };
                delete flattenedNode.children;

                if (node.children && node.children.length > 0) {
                    const flattenedChildren: RouteNode[] = flattenTree(node.children);
                    return [flattenedNode, ...flattenedChildren];
                }
                return [flattenedNode];
            });
        };

        const flattenedRoutes: RouteNode[] = flattenTree(routes);

        this.userHelpModules = flattenedRoutes
            .filter((x) => x.userHelp && !x.disabled)
            .map((x) => ({
                url: x.path,
                visible: false,
                showOnInit: x.showHelpOnInit,
            }));

        return this.userHelpModules;
    }

    generateUrl(path: string) {
        return this.platformService.generateActivePlatformUrl(path);
    }

    navigateTo(path: string, openNewTab?: boolean) : boolean {
        if (!path || path == '') {
          return false;
        }
    
        /*
        if (this.inventoryService.pathBelongsToInventory(path)) {
          path += '?token=' + this.inventoryService.userInventory;
    
          if (!openNewTab) {
            this.windowService.redirectTo({ path, newTab: false, external: true });
            return;
          }
        }
        */
        if (openNewTab) {
          this.windowService.redirectTo({ path, newTab: true, external: false });
        } else {
          let arrayPath = [this.platformService.generateActivePlatformUrl(path)];
          this.router.navigate(arrayPath);
        }

        return true;
    }
}
