import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '@env/environment';
import { Logger, UntilDestroy } from '@shared';
import { AuthenticationService, LoginContext } from '../authentication.service';
import { CredentialsService } from '../credentials.service';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { InventoryService } from '@app/@shared/services/inventory.service';
import { PlatformService } from '../platform.service';
import { Platform } from '@app/@shared/model/platform.model';
import notify from 'devextreme/ui/notify';
import { Cons } from '@app/@shared/cons/cons';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  isLoading = false;

  model: LoginContext = {
    user: '',
    password: '',
    remember: false,
  };
  
  isLocal = false;
  redirectTo = '';

  constructor(
    private authenticationService: AuthenticationService,
    private credentials: CredentialsService,
    private inventoryService: InventoryService,
    private platformService: PlatformService,
    private router: Router,
    private trans: TranslateService,
    private activatedRoute : ActivatedRoute
  ) {
    this.isLocal = environment.local;
    this.redirectTo = decodeURIComponent(this.activatedRoute.snapshot.queryParams['redirect']);
  }

  ngOnInit() {}

  async login(user?: LoginContext) {
    this.isLoading = true;
    if (!user) {
      user = this.model;
    }

    let token: string;
    try {
      //this.inventoryService.login(user);
      token = await firstValueFrom(this.authenticationService.login(user));
    } catch (error) {
      this.error = '';
      if (error.status === 404) {
        this.error = this.trans.instant('forms.login.invalid-username');
      } else if (error.status === 401) {
        this.error = this.trans.instant('forms.login.invalid-password');
      }
      
      log.debug(`Login error: ${this.error || error?.statusText}`);
    } finally {
      this.isLoading = false;
    }

    if (!token) {
      return;
    }

    this.credentials.setCredentials(token);
    log.debug(`${user.user} successfully logged in`);

    const platform: Platform = this.platformService.getFirstValidPlatform();

    if (!platform) {
      notify(this.trans.instant('platform.no-permissions'), 'error', 4500);
      return;
    }

    const platformURL = this.platformService.createPlatformUrl(platform);
    this.router.navigate([`${platformURL}/home`]);
    /*
    if(!this.redirectTo || this.redirectTo == ""){
      this.router.navigate([`${platformURL}/home`]);
    }
    else{
      this.router.navigate([`${this.redirectTo}`]);
    }*/
  }

  quickAccess() {
    let user: LoginContext = {
      user: 'nmancini@koppengroupltd.com',
      password: 'France3014',
    };
    this.login(user);
  }

}
