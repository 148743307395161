import { IOlMapFeature } from "../../interface/iOlMapFeature";

export class olMapPolygon extends IOlMapFeature {
  color: string;
  width: number;
  background: string;
  centerExtent: any;

  toMapFeature(m: any, type : string, color : string, background?: string): olMapPolygon {
    let feature = new olMapPolygon();

    feature.entity = m;
    feature.id = m.entityName + '_' + m.commonID;
    feature.name = m.name;
    feature.type = type;
    feature.locationData = m.locationData;
    feature.color = color;
    feature.width = 1;
    feature.background = background;

    return feature;
  }
}
