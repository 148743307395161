import { Platform } from './platform.model';
import { UserRole } from './userRole.model';

export class UserProfile {
  userID: number;
  platformID: number;
  cultureID : string;
  name: string;
  email: string;
  active: boolean;
  startDate: Date;
  password: string;

  platforms: any [];
  roles: any [];
  resources : any;

  userPlatform: {
    userPlatformAttributes: string;
    userID: number;
    platformID: number;
    active: boolean;
  }[];
  userRole: {
    roleID: number;
    name: string;
    startDate: Date;
    endDate: Date;
    __new: boolean;
    __updated: boolean;
    __deleted: boolean;
  }[];
  }



