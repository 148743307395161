import { Injectable } from '@angular/core';

import { MainService } from './main.service';
import { PlatformService } from '@app/auth/platform.service';
import { Cons } from '../cons/cons';
import { Observable, catchError, map, throwError } from 'rxjs';
import { FileService } from './aux-services/file.service';
import { UserFile } from '../model/userFile.model';

@Injectable({ providedIn: 'root' })
export class OverlappedRingsService {

  constructor(
    private fileService: FileService,
    private mainService: MainService,
    private platformService: PlatformService,
  ) {}

  downloadReport(): Observable<UserFile> {
    const platformID = this.platformService.platformID;

    return this.fileService.getWithFilter({
      platformID,
      entityType: 'SERVICE',
      name: 'OverlappedServices'
    }).pipe(
      map(x => (x || []).sort((a, b) => a.fileID > b.fileID ? -1 : 1)[0])
    )
  }

  generateReport() {
    this.mainService.chooseAPI(Cons._FILE);
    const platformID = this.platformService.platformID;

    return this.mainService.post(Cons._FILE, 'OverlappedServices/' + platformID, {}).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
