import { Component, OnInit, ViewChild } from '@angular/core';
import { FileService } from '@app/@shared/services/aux-services/file.service';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from '@app/auth/platform.service';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { ResponseAPI } from '@app/@shared/model/responseApi.model';
import { CredentialsService } from '@app/auth';
import * as DevExpress from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentInterface } from '../../../../../../model/interface/comment-interface';

@Component({
  selector: 'app-odf-comment-section',
  templateUrl: './odf-comment-section.component.html',
  styleUrls: ['./odf-comment-section.component.scss']
})
export class OdfCommentSectionComponent implements OnInit {

  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;

  constructor(
    private fileService: FileService,
    private infrastructureService: InfrastructureService,
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private credentialsService: CredentialsService,
    private trans: TranslateService,
  ) { } 

  comments: CommentInterface[] = [];
  comment: string = '';
  searchTerm: string = '';
  filteredCommentsList: CommentInterface[] = [];
  infrastructureID: number | null = null;
  platformID: number | null = null;
  file: File | null = null;
  filePathVariable: string;
  isSaving: boolean = false;
  currentUserID: number | null = null;

  ngOnInit(): void {
    this.getInfrastructureID();
    this.getPlatformID();
    this.getCurrentUserID();
  }

  getInfrastructureID() {
    this.activatedRoute.params.subscribe(params => {
      const id = params['infrastructureID']; // Ajusta según el nombre del parámetro en la ruta
      if (id) {
        this.infrastructureService.getByID(id)
          .subscribe(
            (infrastructure: Infrastructure) => {
              this.infrastructureID = infrastructure.infrastructureID;
              this.loadComments();
            },
            (error) => {
              console.error('Error al obtener el Infrastructure ID:', error);
            }
          );
      }
    });
  }

  getPlatformID(): number {
    this.platformID = this.platformService.platformID;
    return this.platformID;
  }

  getCurrentUserID() {
    const currentUserIDstring = this.credentialsService.decodeToken()?.['userID'];
    this.currentUserID = currentUserIDstring ? parseInt(currentUserIDstring, 10) : undefined;
    return this.currentUserID;
  }

  async loadComments() {
    if (this.infrastructureID === null) {
      console.log('No se ha cargado el ID de infraestructura.');
      return;
    }
  
    try {
      const comments = await this.fetchComments();
      const commentsWithFileLinks = await this.processComments(comments);
      this.updateCommentsState(commentsWithFileLinks);
    } catch (error) {
      console.error('Error al obtener comentarios:', error);
    }
  }
  
  private fetchComments(): Promise<any[]> {
    return this.fileService.getCommentByEntityID(this.infrastructureID).toPromise();
  }
  
  private async processComments(comments: any[]): Promise<CommentInterface[]> {
    return Promise.all(
      comments.map(async (item: any) => {
        const fileLinkData = item.fileID ? await this.getFileLink(item.fileID) : null;
        return {
          ...item,
          initials: this.getInitials(item.user as string),
          content: String(item.description),
          name: item.user,
          creationDate: new Date(item.creationDate),
          fileLink: fileLinkData ? fileLinkData.filePath : null,
          fileName: fileLinkData ? fileLinkData.fileName : null,
          eventID: item.eventID
        } as CommentInterface;
      })
    );
  }
  
  private updateCommentsState(commentsWithFileLinks: CommentInterface[]) {
    this.comments = commentsWithFileLinks;
    this.comments.sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
    this.filteredCommentsList = [...this.comments];
  }

  async getFileLink(fileID: number): Promise<{ filePath: string, fileName: string }> {
    try {
      const res: any = await this.fileService.getByID(fileID).toPromise();
      return { filePath: res.filePath, fileName: res.name };
    } catch (error) {
      console.error('Error fetching file:', error);
      throw new Error(error?.message);
    }
  }

  filterComments() {
    this.filteredCommentsList = this.comments.filter(comment =>
      comment.content.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  get filteredComments() {
    return this.filteredCommentsList;
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    const initials = names.map(n => n.charAt(0).toUpperCase()).join('');
    return initials;
  }

  formatTime(time: Date): string {
    const adjustedTime = new Date(time.getTime() - (3 * 60 * 60 * 1000));

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(adjustedTime);
  }

  onFileSelected(event: any) {
    const file = event.value[0]; 
    if (file) {
      const fileName = file.name;
      const isValid = this.validateFileExtension(fileName);

      if (isValid) {
        this.file = file;
      } else {
        this.resetForm();
        alert('El archivo no es válido debido a la doble extensión.');
      }
    }
  }

  validateFileExtension(fileName: string): boolean {
    const doubleExtensionPattern = /(\.[a-zA-Z0-9]+)\.[a-zA-Z0-9]+$/;
    return !doubleExtensionPattern.test(fileName);
  }


  saveComment() {
    if (this.infrastructureID === null || this.comment.trim() === '') {
      console.error('No se ha cargado el ID de infraestructura o el comentario está vacío.');
      return;
    }

    if (this.file) {
      this.uploadFileAndComment();
    } else {
      this.uploadCommentOnly();
    }
  }

  private uploadFileAndComment() {
    this.isSaving = true;
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('elementID', this.infrastructureID?.toString() || '');
    formData.append('entityType', 'INFRASTRUCTURE');


    this.fileService.uploadFile(formData).subscribe(
      (response: ResponseAPI) => {
        if (Array.isArray(response.responseData) && response.responseData.length > 0) {
          const fileID = response.responseData[0];
          this.uploadCommentOnly(fileID);
        } else {
          this.isSaving = false;
        }
      },
      (error) => {
        this.isSaving = false;
      }
    );
  }

  private uploadCommentOnly(fileID?: number) {
    this.isSaving = true;
    this.fileService.uploadComment(this.comment, this.infrastructureID, this.platformID, fileID, this.currentUserID, "INFRASTRUCTURE").subscribe(
      () => {
        this.resetForm();
        this.loadComments();
        this.isSaving = false;
      },
      (error) => {
        this.isSaving = false;
      }
    );
  }

  removeComment(eventID: number) {

    const title = this.trans.instant("general.confirm-action");
    const message = `<i>${this.trans.instant("infrastructure-odf.delete.comment")}</i>`;

    try {
      DevExpress.custom({
        title,
        messageHtml: message,
        buttons: [
          { text: this.trans.instant('general.yes'), onClick: () => this.confirmDelete(eventID) },
          { text: this.trans.instant('general.no'), onClick: () => { } }
        ]
      }).show();
    } catch (error) {
      console.error('Error confirming delete:', error);
    }
  }

  confirmDelete(eventID) {
    this.fileService.removeComment(eventID).subscribe(
      () => {
        this.loadComments();
      },
      (error) => {
        console.error('Error al eliminar el comentario:', error);
      }
    );
  }

  /**
 * Converts plain text with line breaks into HTML.
 * @param text The plain text to convert.
 * @returns The HTML string with line breaks converted.
 */
  convertLineBreaksToHtml(text: string): string {
    if (!text) return '';
    return text.replace(/\n/g, '<br>');
  }

  resetForm() {
    this.comment = '';
    this.file = null;
    this.fileUploader.instance.reset();
  }

}
