import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EquipmentType } from '@app/@shared/model/equipmentType.model';

@Component({
  selector: 'app-equipment-type-modal',
  templateUrl: './equipment-type-modal.component.html',
  styleUrls: ['./equipment-type-modal.component.scss']
})
export class EquipmentTypeModalComponent implements OnInit {
  @Input() isPopupVisible: boolean = false;
  @Input() selectedEquipmentType: EquipmentType;
  @Input() dataSourceGrid: any[];
  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();
  elementEditableData = [
    { label: 'splitter.grid-header.name', field: 'name' },
    { label: 'splitter.grid-header.model', field: 'model' },
  ];

  isJSONValid : boolean = true;

  constructor() { 
    console.log(this.selectedEquipmentType);
  }

  ngOnInit(): void {
    console.log(this.selectedEquipmentType);
  }

  specSaved(spec){
    this.selectedEquipmentType.specAttributes = spec;
  }
  

  saveElementType() {
    if(!this.isJSONValid){
      return;
    }  
    this.save.emit(this.selectedEquipmentType);
  }
}
