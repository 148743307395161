export class ElementType {
  elementTypeID: number;
  platformID: number;
  name: string;
  reference: string;
  groupID: string;
  filePath: string;
  specAttributes : any|SpecAttributes;
}
export interface SpecAttributes {
  showConnection: boolean;
  showSplitter:   boolean;
  scale:          number;
}