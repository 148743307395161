import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { environment } from '@env/environment';
import { classToPlain } from 'class-transformer';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { FileSaverService } from '../file-saver.service';
import apiSelector from '../../../environments/api-selector.json';

import { LocationData } from '../model/aux-models/locationData';
import { Preference } from '../model/preference.model';
import { PlatformService } from '@app/auth/platform.service';
import { Cons } from '../cons/cons';
import { IQueryViewConfiguration } from '../model/interface/iQuery-view-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private showElementSubject = new BehaviorSubject<boolean>(false);
  showElement$ = this.showElementSubject.asObservable();


  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private platformService: PlatformService,
    private _FileSaverService: FileSaverService,
  ) {
    //this._token = this.credentialsService.credentials.token;
    //this.API_URL = this.credentialsService.API_URL;
    this.setDefaultAPI();
    if(environment.dev){
      console.log('Default API: ' + this.API_URL);
    }
  }

  private clearSearchesPerformedSource = new Subject<void>();
  clearSearchesPerformed$ = this.clearSearchesPerformedSource.asObservable();

  private _token: string = '';
  public API_URL: string = '';

  set token(token: string) {
    this._token = token;
  }

  get token() {
    if (
      this.credentialsService &&
      this.credentialsService.credentials &&
      this.credentialsService.credentials.token
    ) {
      this._token = this.credentialsService.credentials.token;
    }
    return this._token;
  }

  get isMobile(): boolean {
    if (
      navigator &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }


  get activeUser() {
    return this.credentialsService.credentials;
  }

  triggerClearSearchesPerformed() {
    this.clearSearchesPerformedSource.next();
  }
  updateShowElementState(show: boolean) {
    this.showElementSubject.next(show);
  }

  getPreference(module: string, defaultValue?: any): Preference {
    if (this.credentialsService && this.credentialsService.preferences) {
      let index = this.credentialsService.preferences.findIndex(x => x.module == module);
      if (index > -1) {
        return this.credentialsService.preferences[index];
      }
    }

    if (defaultValue) {
      return { module: module, value: defaultValue };
    }

    return undefined;
  }

  setPreference(module: string, value: any) {
    let preference: Preference;
    let preferences = this.credentialsService && this.credentialsService.preferences;

    if (preferences) {
      let index = preferences.findIndex(x => x.module == module);
      if (index > -1) {
        preference = preferences[index];
        //Remove the preference from the list
        preferences.splice(index, 1)
      }
    }
    else {
      preferences = [];
    }

    if (preference == undefined) {
      preference = new Preference();
      preference.module = module;
      preference.value = value;
    }

    //Add preference to list
    preferences.push(preference);
    this.credentialsService.setPreferences(preferences, true);
  }

  // ********* SERVER CALLS **********//
  getAPIEnvironment(){
    return environment.api;
  }
  setDefaultAPI() {
    this.API_URL = apiSelector[environment.api]['default'];
  }

  getAPIByName(api: string) {

    let apiName = apiSelector['local'][api];

    this.API_URL = apiSelector[environment.api][api];

    return apiName;
  }

  chooseAPI(api: string) {
    this.API_URL = apiSelector['local'][api];
    this.API_URL = apiSelector[environment.api][api];

    if(environment.dev){
      console.log('API updated: ' + this.API_URL);
    }
  }

  get(controller: string, action: string, addtoken?: boolean): Observable<any> {
    // Replace by proper authentication call
    let token = undefined;
    if (addtoken) {
      token = this.token;
    }

    return this.http
      .get(this.API_URL + controller + '/' + action, {
        headers: this.createAuthHeaders(undefined, token),
      })
      .pipe(
        map((response: any) => {
          //this.store.dispatch(actionLoadingEnd());
          return <any>response;
        })
      );
  }

  post(controller: string, action?: string, body?: any): Observable<any> {
    // Replace by proper authentication call
    if (!body) {
      body = '';
    }

    const entityID = action ? '/' + action : '';
    const url = this.API_URL + controller + entityID;

    let obs = this.http
      .post(url, JSON.stringify(body), {
        headers: this.createAuthHeaders(),
      })
      .pipe(
        map((res: any) => {
          try {
            return <any>res;
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );

    return obs;
  }

  put(controller: string, action?: string, body?: any): Observable<any> {
    // Replace by proper authentication call
    if (!body) {
      body = '';
    }

    const entityID = action ? '/' + action : '';
    const url = this.API_URL + controller + entityID;

    let obs = this.http
      .put(url, JSON.stringify(body), {
        headers: this.createAuthHeaders(),
        withCredentials: false,
      })
      .pipe(
        map((res: any) => {
          try {
            return <any>res;
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );

    return obs;
  }

  getWithFilter(controller: string, entity?: any, spatial?: boolean, limit?: number, offset?: number, clause?: string): Observable<any> {
    // Replace by proper authentication call

    if (!entity) {
      entity = { platformID: this.platformService.platformID };
    } else {
      entity.platformID = this.platformService.platformID;
    }

    let body = entity;

    let method = '/GetWithFilter';
    if (spatial) {
      method = '/QueryView';
      body = { entity: entity, clause: clause };
    } else if (clause) {
      method = method + "?clause=" + clause;
    }

    if ((limit || offset) && spatial) {
      method = method + "?";
    }
    else if ((limit || offset) && !spatial && clause) {
      method = method + "&";
    }
    else if ((limit || offset) && !spatial && !clause) {
      method = method + "?";
    }

    if (limit && offset) {
      method = method + "limit=" + limit + "&offset=" + offset;
    }
    else if (limit) {
      method = method + "limit=" + limit;
    }
    else if (offset) {
      method = method + "offset=" + offset;
    }


    let obs = this.http
      .post(this.API_URL + controller + method, JSON.stringify(body), {
        headers: this.createAuthHeaders(),
      })
      .pipe(
        map((res: any) => {
          try {
            return <any>res;
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );

    return obs;
  }

  queryView(controller: string, entity: any, queryViewConfiguration: IQueryViewConfiguration = {}, isWithServiceID: boolean = false): Observable<any> {
    const {
      xyCoordinates,
      locationCriteria,
      limit,
      offset,
      extraParams
    } = queryViewConfiguration;

    let token = undefined;
    // if (isWithServiceID) {
    //   token = this.token;
    // }

    // Replace by proper authentication call
    if (xyCoordinates) {
      entity.locationData = new LocationData('', {}, 0);

      entity.locationData.inputSrID = 4326;
      entity.locationData.outputSrID = 4326;
      entity.locationData.setCoordinates(xyCoordinates);
    }

    let body = {
      entity: entity,
      orderBy: '',
      locationCriteria: locationCriteria || 'INTERSECTS',
      withServiceID: isWithServiceID
    };

    if (extraParams) {
      Object.entries(extraParams).forEach(([key, value]) => body[key] = value);
    }

    let method = '/QueryView';
    if (limit && offset) {
      method = method + "?limit=" + limit + "&offset=" + offset;
    }
    else if (limit) {
      method = method + "?limit=" + limit;
    }
    else if (offset) {
      method = method + "?offset=" + offset;
    }

    let obs = this.http
      .post(this.API_URL + controller + method, JSON.stringify(body), {
        headers: this.createAuthHeaders(),
      })
      .pipe(
        map((res: any) => {
          try {
            return <any>res;
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );

    return obs;
  }

  getByID(controller: string, id: any): Observable<any> {
    // Replace by proper authentication call

    const url = `${this.API_URL}${controller}/${this.platformService.platformID}/${id}`;
    return this.http
      .get(url, {
        headers: this.createAuthHeaders(),
      })
      .pipe(
        map((response: any) => {
          //this.store.dispatch(actionLoadingEnd());
          return <any>response.responseData;
        })
      );
  }

  delete(controller: string, id: number): Observable<any> {
    const url = `${this.API_URL}${controller}/${this.platformService.platformID}/${id}`;
    return this.http.delete(url, { headers: this.createAuthHeaders() })
  }

  postFile(file: File, formData: FormData = new FormData(), path?: string): Observable<any> {
    if (!formData) {
      formData.append('entity', file, file.name);
    }

    if (!path) {
      path = 'File';
    }

    let obs = this.http
      .post(this.API_URL + path, formData, {
        headers: this.createAuthHeaders(true),
      })
      .pipe(
        map((res: any) => {
          try {
            return <any>res;
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );

    return obs;
   
  }

  getFromUrl(url: string, token?: string) {
    return this.http.get(url, {
      headers: this.createAuthHeaders(null, token),
    }).pipe(
      map((response: any) => {
        //this.store.dispatch(actionLoadingEnd());
        return <any>response;
      })
    );
  }

  _loading: number = 0;
  getFile(
    controller: string,
    action: string,
    payload: any,
    context?: any,
    callback?: any
  ) {
    // Xhr creates new context so we need to create reference to this
    var self = this;

    return new Promise(function (resolve, reject) {
      // Create the Xhr request object
      let xhr = new XMLHttpRequest();
      xhr.open('POST', self.API_URL + controller + '/' + action, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + self.token);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.responseType = 'blob';

      // Xhr callback when we get a result back
      // We are not using arrow function because we need the 'this' context
      var t = self;
      xhr.onreadystatechange = function () {
        // If we get an HTTP status OK (200), save the file using fileSaver
        if (xhr.readyState === 4 && xhr.status === 200) {
          var contentType: any = 'application/octet-stream';
          var filename: any = 'archivo.pdf';
          try {
      
            filename = xhr.getResponseHeader('Content-Disposition');
            var patt = new RegExp('filename=(.*); filename');
            filename = patt.exec(filename)[1].replace('"', '').replace('"', '');
          } catch (e) { }
          try {
            contentType = xhr.getResponseHeader('Content-Type');
          } catch (e) { }
          var blob = new Blob([this.response], { type: contentType });
          t._FileSaverService.save(blob, filename);
          t._loading--;
          resolve(filename);
          if (callback) {
            callback(context);
          }
        } else {
          if (this.response) {
            var myReader = new FileReader();

            //start the reading process.
            myReader.readAsText(this.response);
            reject();
          }
          t._loading--;
        }
      };

      // Start the Ajax request
      xhr.send(payload ? JSON.stringify(classToPlain(payload)) : payload);
    });
  }

  createAuthHeaders(formData?: boolean, token?: string): HttpHeaders {
    var headers = new HttpHeaders();

    if (formData != undefined && formData) {
      headers = headers.set('Content-Disposition', 'multipart/form-data');
    } else {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    headers = headers.set('Accept', 'application/json');

    // if (this.credentialsService.hasToken()) {
    // headers = headers.set('audUser', this.credentialsService.decodeToken()?.['userID']);
    // }

    return headers;
  }

  // ********* END SERVER CALLS **********//
}

export class Preferences {
  private _activeUser: string;
  get activeUser() {
    let user = sessionStorage.getItem('activeUser');
    if (user == null) {
      return '';
    } else {
      let jsonUser = JSON.parse(user);
      return jsonUser.cuit;
    }
  }
  set activeUser(value) {
    sessionStorage.setItem('activeUser', JSON.stringify({ cuit: value }));
  }
}

export class Utilities {
  public static plainArray(array: any) {
    let a = [];
    if (!(array instanceof Array)) {
      return array;
    }
    array.forEach((x: any) => {
      if (x instanceof Array) {
        a.push(...this.plainArray(x));
      }
      else {
        a.push(x);
      }
    });

    return a;
  }

  static getNewGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
