import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { Service, ServiceAttributes } from '@app/@shared/model/service.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';
import { PlatformService } from '@app/auth/platform.service';
import { ITraceabilityDiagram } from '@app/@shared/model/interface/iTraceability-diagram';
import { Container } from '@app/@shared/model/container.model';
import { Trace } from '@app/@shared/model/trace.model';
import { Device } from '@app/@shared/model/device.model';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { plainToInstance } from 'class-transformer';

@Injectable()
export class ServiceService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) {}

  getWithFilter(entity?: any, limit? : number, offset? : number): Observable<any> {
    this.main.chooseAPI(Cons._SERVICE);
    console.log(Cons._SERVICE);
    return this.main.getWithFilter('Service', entity, false, limit, offset).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.serviceID;
            x.entityName = Cons._SERVICE;
            
            return <Service>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
         
        }
      })
    );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._SERVICE);
    return this.main.getByID(Cons._SERVICE, id).pipe(
      map((res) => {
        res.commonID = res.serviceID;
        res.entityName = Cons._SERVICE;
        res.serviceAttributes = this.hydrateServiceAttributes(res.serviceAttributes);
        return res;
      }),
      catchError((error) => throwError(() => error))
    );
  }

  getTraceabilityDiagramData(fiberID: number, serviceID: number, infrastructureID: number): Observable<ITraceabilityDiagram> {
    this.main.chooseAPI(Cons._SERVICE);
    const platformID = this.platformService.platformID;
    const url = `Traceability/${platformID}/${infrastructureID}/${serviceID}/${fiberID}`;

    return this.main.get(Cons._SERVICE, url).pipe(
      map((res) => {
        res.commonID = res.serviceID;
        res.entityName = Cons._SERVICE;
        return res.responseData;
      }),
      catchError((error) => throwError(() => error))
    );
  }

  hydrateServiceAttributes(serviceAttributes: ServiceAttributes): ServiceAttributes {
    const hydrateArray = <T>(array: any[], classType: new () => T): T[] => (array || []).map((item: any) => plainToInstance(classType, item));

    const container = hydrateArray(serviceAttributes.container, Container);
    const device = hydrateArray(serviceAttributes.device, Device);
    const trace = hydrateArray(serviceAttributes.trace, Trace);
    const infrastructure = hydrateArray(serviceAttributes.infrastructure, Infrastructure);

    return {
      container,
      device,
      trace,
      infrastructure,
    }
  }
}
