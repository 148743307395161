<!-- <dx-accordion 
  [dataSource]="[{ name: 'Headends disponibles', data: headendData }]"
  [collapsible]="true"
  [multiple]="false"
  [animationDuration]="300"
  id="accordion-element-container"
  style="background-color: #f8f8f8"
>
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.name }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <div class="grid-container">
      <dx-data-grid
        id="gridContainer" 
        style="max-height:50vh;"
        [dataSource]="item.data"
        [remoteOperations]="false"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [showColumnLines]="false"
        [showBorders]="false"
        [columnAutoWidth]="true"
      >
        <dxo-search-panel
          [visible]="true"
          [highlightCaseSensitive]="true"
        ></dxo-search-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxi-column
          dataField="name"
          caption="Nombres de los headends"
          dataType="string"
        ></dxi-column>
        <dxi-column
          dataField="location"
          caption="Localización"
          dataType="string"
          cellTemplate="locationTemplate"
        >
          <div *dxTemplate="let cellInfo of 'locationTemplate'">
            <div>
              {{ cellInfo.value }}
            </div>
          </div>
        </dxi-column>
        <dxo-export [enabled]="true"></dxo-export>
      </dx-data-grid>
    </div>
  </div>
</dx-accordion> -->
