import { Component, OnInit } from "@angular/core";
import { AppRoutingService } from "@app/app-routing.service";
import { PlatformService } from "@app/auth/platform.service";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss'],
})
export class MainScreenComponent implements OnInit {


  constructor(
    private appRoutingService : AppRoutingService,
    private trans : TranslateService,
    private platformService : PlatformService
  ) {
    this.platformID = this.platformService.activePlatform.platformID;
  }


  platformID;
  ngOnInit(): void {
    
  }

  
}
