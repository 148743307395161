<app-simple-crud
    [gridTitle]="'devices.title' | translate"
    [modalTitle]="modalTitle"
    [entityType]="entityType"
    [entityTypeModels]="entityTypeModels"
    [addButtonTitle]="'devices.add-device' | translate"
    [deleteButtonTooltip]="'devices.delete-device' | translate"
    [deleteWarningMessage]="'devices.delete-device-warning' | translate"
    [selectedItem]="selectedDevice"
    [loadingModal]="loadingModal"
    [(showDeletePopup)]="popups.confirmDelete"
    [showCreateOrEditPopup]="popups.createOrEdit"
    (showCreateOrEditPopupChange)="closeModal()"
    (addNewItem)="openModalAddDevice()"
    (deleteItem)="deleteDevice($event)"
>
    <app-device-list grid
        [loading]="loading"
        [devices]="devices"
        (editItem)="openModalEditDevice($event)"
        (deleteItem)="openPromptDeleteDevice($event)"
    >
    </app-device-list>

    <div create-edit-modal>
      <app-create-edit-device-modal *ngIf="selectedDevice"
        [selectedDevice]="selectedDevice"
        (cancel)="closeModal()"
        (save)="saveDevice($event)"
      ></app-create-edit-device-modal>
    </div>
</app-simple-crud>
