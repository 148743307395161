<div class="card-container">
  <div class="primary-title">Average Device Occupation</div>

  <div class="secondary-title">Allocated</div>
  <dx-linear-gauge id="c4" [value]="60">
    <dxo-value-indicator type="rangebar" color="#ffa500"> </dxo-value-indicator>
    <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="25">
      <dxo-label [customizeText]="customizeText"></dxo-label>
    </dxo-scale>
  </dx-linear-gauge>

  <div class="secondary-title no-padding">Active</div>
  <dx-linear-gauge  [value]="80">
    <dxo-value-indicator type="rangebar" color="#ff5500"> </dxo-value-indicator>
    <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="25">
      <dxo-label [customizeText]="customizeText"></dxo-label>
    </dxo-scale>
  </dx-linear-gauge>

  <div fxLayout="row" fxLayoutAlign="center center">
    <dx-button 
      style="margin-right: 10px;"
      icon="fas fa-search"
      [text]="'View Details'"
      (click)="exploreDeviceUsage()">
    </dx-button>
</div>
</div>
