import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { Platform } from './platform.model';
import { Device } from './device.model';
import { SplitterConnection } from './splitterConnection.model';
import { SplitterType } from './splitterType.model';

export class Splitter {
  splitterID: number;
  platformID: number;
  splitterTypeID: number;
  deviceID: number;
  model: string;
  name: string;
  description: string;
  splitterAttributes: any;

  @Type(()=> Device)
  device?: Device;
  @Type(()=> Platform)
  platform?: Platform;
  @Type(()=> SplitterType)
  splitterType?: SplitterType;

  splitterConnection?: SplitterConnection[];

  __new?: boolean;
  __modified?: boolean;
  __deleted?: boolean;

  get commonID(){
    return this.splitterID;
  }
  get entityName() {
    return Cons._SPLITTER;
  }
}
