import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IRedirectSettings } from '@app/@shared/model/interface/iRedirect-settings';

declare var cordova: any;

@Injectable({ providedIn: 'root' })
export class WindowService {

  constructor(
    private router: Router,
  ) {}

  redirectTo({ path, newTab, external, target = '_self', options = 'location=no' }: IRedirectSettings) {
    const notUsingCordova = typeof window['cordova'] === 'undefined';

    if (notUsingCordova) {
      if (newTab) {
        window.open(path, '_blank');
      } else {
        window.location.href = path;
      }
      return;
    }

    if (external) {
      cordova.InAppBrowser.open(path, target, options);
    } else {
      this.router.navigate([path]);
    }
  }
}
