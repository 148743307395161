export class Platform {
  platformID: number;
  name: string;
  active: boolean;

  iterativePlatformID?: number;
  iterativePlatform?: any;

  userPlatformAttributes : any;
}
