import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor(private http: HttpClient) {}

  
  
}

declare global {
  interface String {
    upperFirstLetter(prefix: string): string;
  }
}

String.prototype.upperFirstLetter = function (word: string) {
  let charWord = word.trim().split('');
  for (let i = 0; i < charWord.length; i++) {
    if (charWord[i] != '' && i == 0) {
      charWord[i] = charWord[i].toUpperCase();
    } else if (charWord[i] == ' ') {
      charWord[i + 1] = charWord[i + 1].toUpperCase();
    } else {
      charWord[i] = charWord[i].toLowerCase();
    }
  }

  return charWord.join('');
};
