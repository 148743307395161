import { Injectable } from '@angular/core';
import { CoordinatesServices } from './aux-services/coordinates-service';
import { MainService } from './main.service';
import { Cons } from '../cons/cons';
import { Observable, catchError, map, throwError } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { PlatformService } from '@app/auth/platform.service';
import { EquipmentType } from '../model/equipmentType.model';

@Injectable({
  providedIn: 'root'
})
export class EquipamentTypeService {



  constructor(
    private coordinatesServices: CoordinatesServices,
    private main: MainService,
    private platformService: PlatformService,
  ) { }

  getEquipmentsTypeWithFilter(entity?: any): Observable<any> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    
    return this.main.getWithFilter(Cons._EQUIPMENTtYPE, entity).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  getEquipmentTypeById(id: number): Observable<EquipmentType> {
    this.main.chooseAPI(Cons._SERVICECONFIG);

    return this.main.getByID(Cons._EQUIPMENTtYPE, id).pipe(
      map((res) => {
        try {
          res.commonID = res.containerID;
          res.entityName = Cons._EQUIPMENTtYPE;
          return plainToInstance(EquipmentType, res);
        } catch (ex) {
          return (<any>res)._body.toString();
        }
      })
    );
  }
  update(elementType: EquipmentType): any {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    //TODO: evaluar como quitar la variable busqueda
    const busqueda =`${this.platformService.platformID }/${elementType.elementTypeID}`;
    return this.main.put(Cons._EQUIPMENTtYPE, busqueda,elementType).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  delete(elementTypeID: number): Observable<unknown> {
    this.main.chooseAPI(Cons._SERVICECONFIG);

    return this.main.delete(Cons._EQUIPMENTtYPE, elementTypeID).pipe(
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }
  create(elementType: EquipmentType): Observable<any> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    return this.main.post(Cons._EQUIPMENTtYPE, undefined, elementType).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
