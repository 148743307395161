<div fxLayout="column" fxLayoutAlign="space-between none" class="h-100">
  <div fxLayout="row nowrap">
    <div class="me-2" fxLayout="column nowrap" fxLayoutAlign="space-between none" fxFlex="50">

      <dx-select-box
      style="margin-bottom: 20px"
        height="45px"
        labelMode="floating"
        displayExpr="name"
        valueExpr="elementTypeID"
        [value]="device.elementTypeID" 
        [dataSource]="elementTypeDataSource"
        [deferRendering]="false"
        [placeholder]="labels.model"
        [searchEnabled]="true"
        [searchExpr]="'name'" 
        [searchMode]="'contains'"
        [searchTimeout]="200" 
        (onSelectionChanged)="elementTypeChange($event)"
      >
      </dx-select-box>
        
      <dx-select-box
       style="margin-bottom: 20px"
        height="45px"
        labelMode="floating"
        displayExpr="name" 
        valueExpr="containerID"
        [value]="device.containerID"
        [dataSource]="containerDataSource"
        [deferRendering]="false"
        [placeholder]="labels.container"
        [searchEnabled]="true"
        [searchExpr]="'name'" 
        [searchMode]="'contains'"
        [searchTimeout]="200" 
        (onSelectionChanged)="containerChange($event)"
      ></dx-select-box>

      <dx-text-box
        style="margin-bottom: 20px"
        height="45px"
        [value]="device.name"
        [valueChangeEvent]="'keyup'"
        (onValueChanged)="nameChange($event)"
        labelMode="floating"
        [label]="labels.name"
      >
      </dx-text-box>

 
        <div *ngIf="showElement && !newImage">
        <img class="device-image" [src]="image">
        </div>

        <img class="device-image"  *ngIf="newImage" [src]="newImage" alt="">
        <div class="container-new-image">
          <p class="paragraph-new-image">{{'add.new.image' | translate}}</p>
          <button class="upload-file"  onclick="document.getElementById('getFile').click()">{{'upload.image' | translate}}</button>
          <input  class="new-image" accept="image/jpeg, image/png" (change)="handleFileInput($event)" type='file' id="getFile" style="display:none">
          
        </div>

      
      <dx-text-box
      style="margin-bottom: 20px"
        height="45px"
        [(value)]="device.description"
        labelMode="floating"
        [label]="'devices.add-edit.description' | translate"
      >
      </dx-text-box>

      <dx-text-box
      style="margin-bottom: 20px"
        height="45px"
        [(value)]="device.nfcTag"
        labelMode="floating"
        [label]="'devices.add-edit.nfc-tag' | translate"
      >
      </dx-text-box>
    </div>

    <div fxLayout="column nowrap" fxFlex="50">
      <address-form
        [required]="true"
        [location]="device?.locationData"
        (fullAddressChange)="fullAddressChange($event)"
        (onAddressChanged)="addressChanged($event)"
      >
      </address-form>
    </div>
  </div>

  <div class="me-2 mt-4">
    <dx-tab-panel>
      <dxi-item [title]="'devices.add-edit.ports' | translate" *ngIf="ports.length">
        <dx-scroll-view>
          <div fxLayout="column" class="data-container">
            <app-device-port-list
              [ports]="ports"
            >
            </app-device-port-list>
          </div>
        </dx-scroll-view>
      </dxi-item>

      <dxi-item [title]="'devices.add-edit.splitters' | translate">
        <dx-scroll-view>
          <div class="data-container">
            <app-device-splitter-list
              [splitters]="splitters"
              [splitterTypes]="splitterTypes"
              (addSplitter)="handleAddSplitter($event)"
              (editSplitter)="handleEditSplitter($event)"
              (deleteSplitter)="handleDeleteSplitter($event)"
            >
            </app-device-splitter-list>
          </div>
        </dx-scroll-view>
      </dxi-item>
    </dx-tab-panel>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="me-2 mt-4">
    <dx-button
      [text]="'general.cancel' | translate"
      (click)="cancel.emit()"
    >
    </dx-button>
    <dx-button
      [disabled]="disableSave"
      class="ms-3 main-background-theme"
      [text]="'general.save' | translate"
      (click)="saveDevice()"
    >
    </dx-button>
  </div>
</div>
