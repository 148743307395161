import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-crud',
  templateUrl: './simple-crud.component.html',
  styleUrls: ['./simple-crud.component.scss'],
})
export class SimpleCrudComponent {

  @Input() gridTitle: string;
  @Input() modalTitle: string;
  @Input() loadingModal: boolean;
  @Input() addButtonTitle: string;
  @Input() deleteButtonTooltip: string;
  @Input() deleteWarningMessage: string;

  @Input() entityType: string;
  @Input() entityTypeModels : any[];

  @Input() selectedItem: any;

  @Output() addNewItem = new EventEmitter<void>();
  @Output() updateItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  @Output() onSearchPerformed = new EventEmitter<any>();

  @Input() showCreateOrEditPopup: boolean;
  @Output() showCreateOrEditPopupChange = new EventEmitter<boolean>();

  @Input() addButtonNewItem: boolean =true;

  @Input() showDeletePopup: boolean;
  @Output() showDeletePopupChange = new EventEmitter<boolean>();

  constructor() {}


  search(filter : any){
    this.onSearchPerformed.emit(filter);
  }
}
