import { IOlMapFeature } from "../../interface/iOlMapFeature";

export class olMapLineString extends IOlMapFeature {
  color: string;
  width: number;


  toMapFeature(m: any, type: string, color: string, background?: string): olMapLineString {
    let feature = new olMapLineString();

    feature.entity = m;
    feature.id = m.entityName + '_' + m.commonID;
    feature.name = m.name;
    feature.type = type;
    feature.color = color;
    feature.width = 2;
    feature.locationData = m.locationData;

    return feature;
  }
}
