import { Injectable } from '@angular/core';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class FilesystemService {

  constructor() {}

  getFileEntry(imgUri) {
    return new Promise<any>((resolve, reject) => window.resolveLocalFileSystemURL(imgUri, resolve, reject));
  }

  transformFileEntryToBlob(fileEntry) {
    return new Promise<Blob>((resolve, reject) => {
      fileEntry.file((file) => {
        var reader = new FileReader();
  
        reader.onloadend = () => {
            const blob = new Blob([new Uint8Array(<any>(reader).result)], { type: "image/jpeg" });
            resolve(blob);
        };
  
        reader.readAsArrayBuffer(file);
  
    }, reject)});
  }
}
