import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ITraceabilityDiagram } from '@app/@shared/model/interface/iTraceability-diagram';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { firstValueFrom } from 'rxjs';
import { ServiceService } from '@app/@shared/services/map-elements-services/service.service';
import { CommunicationService } from '@app/@shared/services/map-elements-services/communication.service';

@Component({
  selector: 'service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss'],
})
export class ServiceDetailComponent {

  traceabilityDiagramData: ITraceabilityDiagram;
  @Output() switchChanged = new EventEmitter<boolean>();
  @Input() textBoxValue: string;
  @Input() dataFromSearchPanel: any;
  @Input() showDistance: boolean = true;

  _entity: any;
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;

    this.loadEntity();
  }

  constructor(
    private infraService: InfrastructureService,
    private serviceService: ServiceService,
    private communicationService: CommunicationService
  ) { }

  loadEntity() {
    this.loadTraceabilityDiagram();
  }

  loadTraceabilityDiagram = async () => {
    let infraLength = this.entity.serviceAttributes.infrastructure.length;
    if (infraLength > 0) {
      let indexInfra = (infraLength > 1) ? 1 : 0;
      let infraID = this.entity.serviceAttributes.infrastructure[0].infrastructureID;
      const odfData = await firstValueFrom(this.infraService.getODF(infraID));
      console.log("datos infra odf-->", odfData);
      if (odfData.length > 0) {
        //const filterService = odfData[0].children.find(x => x.serviceID != 0);
        //const filterService = odfData.find(item => item.children.find(nestedItem => nestedItem.serviceID != 0))?.[0];
        let filterService;
        odfData.find(item => {
          if (item.children.length > 0) {
            for (const child of item?.children) {
              if (child.serviceID == this.entity.serviceID) {
                filterService = child;
                break;
              }
            }
          }
        });

        console.log("data from search panel-->", this.dataFromSearchPanel);

        if (filterService) {
          let data = await firstValueFrom(this.serviceService.getTraceabilityDiagramData(filterService.fiberID, filterService.serviceID, infraID));
          console.log("datos traceability-->", data);
          this.communicationService.callFunctionWithParam(data);
        }
      }
    }
  };

  onSwitchChangedInDiagram(newValue: boolean) {
    this.switchChanged.emit(newValue);
  }
}
