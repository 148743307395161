import { Cons } from '../cons/cons';
import { Platform } from './platform.model';
import { ICommonEntity } from './interface/iCommonEntity';
import { ServiceTrace } from './serviceTrace.model';
import { Trace } from './trace.model';

export class Fiber {
  name: string;
  serviceName: string;
  fiberID: number;
  fiberOrder: number;
  platformID: number;
  traceID: Trace;
  fiberAttributes: any;

  serviceTrace: ServiceTrace[];

  get commonID(): any {
    return this.fiberID;  
  }

  get entityName(): any {
    return Cons._FIBER;
  }
}
