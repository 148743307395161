import { Component, OnInit } from '@angular/core';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { UserProfile } from '@app/@shared/model/userProfile.model';
import { UserProfileService } from '@app/@shared/services/user-profile.service';
import { Cons } from '@app/@shared/cons/cons';
import { error } from 'console';
import { confirm } from 'devextreme/ui/dialog';
import { CredentialsService } from '@app/auth';
import { Router } from '@angular/router';
import { Platform } from '@app/@shared/model/platform.model';


@Component({
  selector: 'app-users-home',
  templateUrl: './users-home.component.html',
  styleUrls: ['./users-home.component.scss'],
})

export class UsersHomeComponent implements OnInit {

  dataSource: any[];
  selectedEntity: UserProfile;
  isPopupVisible: boolean = false;

  isVisible = false;
  type = '';
  message = '';
  isNewUser = false;
  

  regionalDS = Cons.REGIONALDATA.getRegionalDatasource();

  constructor(private userService: UserProfileService, private platformService: PlatformService, private translateService: TranslateService, private credentialService: CredentialsService, private router: Router,
  ) {

  }

  ngOnInit(): void {
    const platform: Platform = this.platformService.getFirstValidPlatform();

    const roleID = this.getCurrentRoleID();
    if (roleID !== 1) {
      const platformURL = this.platformService.createPlatformUrl(platform);
      this.router.navigate([`${platformURL}/home`]);    } else {
      this.search();
    }
  }

  search(filter?: any) {
    if (!filter) {
      filter = {};
    }
    this.userService.getWithFilter(filter, 1000, 0).subscribe((res) => {
      this.dataSource = res;
    });
  }


  openPopup(e: any) {
    this.isNewUser = false;
    this.userService.getByID(e.userID).subscribe((res) => {
      if (res) {
        this.selectedEntity = res;
        this.isPopupVisible = true;
      }
    });
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  save(e: UserProfile) {
    this.userService.update(e).subscribe(
      (res) => {
        if (res) {
          let index = this.dataSource.findIndex(x => x.userID == res.userID);
          if (index > -1) {
            this.dataSource[index] = res;
          }
          this.closePopup();
          this.type = 'success';
          this.message = this.translateService.instant('general.saved-confirmation');
          this.isVisible = true;
        }
      },
      (error) => {
        this.type = 'error';
        this.message = error;
        this.isVisible = true;
      });

  }

  resetPassword(e: UserProfile) {
    this.isNewUser = false;
    let res: any = confirm("Confirm password reset?", "Warning");

    res.done((dialogResult) => {
      if (dialogResult) {
        this.userService.resetPassword(e).subscribe(
          (res) => {
            if (res) {
              this.type = 'success';
              this.message = this.translateService.instant('users.password-reset-confirmation');
              this.isVisible = true;
            }
          },
          (error) => {
            this.type = 'error';
            this.message = error;
            this.isVisible = true;
          }
        );
      }
    });
  }

  createUser(e: UserProfile) {
    this.userService.create(e).subscribe(
      (res) => {
        if (res) {
          let index = this.dataSource.findIndex(x => x.userID == res.userID);
          if (index > -1) {
            this.dataSource[index] = res;
          }
          this.closePopup();
          this.type = 'success';
          this.message = this.translateService.instant('general.saved-confirmation');
          this.isVisible = true;
        }
      },
      (error) => {
        this.type = 'error';
        this.message = error;
        this.isVisible = true;
      });

  }

  addUser() {
    this.isNewUser = true;
    this.selectedEntity = {
      userID: 0,
      platformID: 0,
      cultureID: '',
      name: '',
      email: '',
      active: false,
      startDate: new Date(),
      platforms: [], 
      roles: [], 
      resources: [], 
      password: '',
      userPlatform: [ 
        {
          userPlatformAttributes: '',
          userID: 0,
          platformID: 0,
          active: false
        }
      ],
      userRole: [
        {
          roleID: 0,
          name: '',
          startDate: new Date(),
          endDate: new Date(),
          __new: false,
          __updated: false,
          __deleted: false
        }
      ]
    };
    this.isPopupVisible = true;
  }

  getCurrentRoleID() {
    const rolesArray = this.credentialService.decodeToken()?.['roles'];
    if (Array.isArray(rolesArray) && rolesArray.length > 0) {
        const firstRole = rolesArray[0];
        const roleID = firstRole?.roleID;
        return roleID;
    }

    return undefined;
}


}