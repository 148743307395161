<div>
  <div class="d-flex justify-content-end align-items-center mb-3">
    <dx-button
      class="button main-background-theme"
      [text]="'general.add' | translate"
      (click)="createSplitterRow()"
      [disabled]="disableAddSplitterButton"
    >
    </dx-button>
  </div>

  <dx-data-grid
    [dataSource]="displayedSplitters"
    [showBorders]="true"
    [showRowLines]="true"
  >
    <dxi-column [caption]="'devices.add-edit.splitter-grid.type' | translate" cellTemplate="splitterTypeTemplate"></dxi-column>
    <dxi-column [caption]="'devices.add-edit.splitter-grid.name' | translate" cellTemplate="splitterNameTemplate"></dxi-column>
    <dxi-column id="cell-template-actions" width="60" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>

    <div *dxTemplate="let d of 'splitterTypeTemplate'">
      <div *ngIf="!d?.data?.editing"> {{ getSplitterTypeName(d?.data?.splitterTypeID) }}</div>

      <div *ngIf="d?.data?.editing">
        <dx-select-box
          height="45px"
          labelMode="floating"
          displayExpr="name"
          valueExpr="splitterTypeID"
          [value]="d?.data?.splitterTypeID"
          [items]="splitterTypes"
          [placeholder]="'devices.add-edit.model' | translate"
          [searchEnabled]="true"
          [searchExpr]="'name'" 
          [searchMode]="'contains'"
          [searchTimeout]="200" 
          (onSelectionChanged)="splitterTypeChange(d?.data, $event)"
        >
        </dx-select-box>
      </div>
    </div>

    <div *dxTemplate="let d of 'splitterNameTemplate'">
      <div *ngIf="!d?.data?.editing"> {{ d?.data?.name }}</div>

      <div *ngIf="d?.data?.editing">
        <dx-text-box
          height="45px"
          [valueChangeEvent]="'keyup'"
          [(value)]="d.data.newName"
          labelMode="floating"
          [label]="'devices.add-edit.splitter-grid.name' | translate"
        >
        </dx-text-box>
      </div>
    </div>

    <div *dxTemplate="let d of 'actionsCellTemplate'" class="h-100 w-100">
      <div class="d-flex justify-content-around align-items-center h-100">
        <i 
          *ngIf="d?.data?.editing"
          (click)="saveSplitter(d.data)"
          class="fas fa-check clickable mt-3"
          [ngClass]="{ 'disabled': !d.data.newName || !d.data.newSplitterType }"
          placement="bottom" [ngbTooltip]="'devices.add-edit.splitter-grid.save' | translate" container="body"
        ></i>
        <i 
          *ngIf="d?.data?.editing"
          (click)="cancelSplitterChanges(d?.data)"
          class="fas fa-ban clickable mt-3"
          placement="bottom" [ngbTooltip]="'devices.add-edit.splitter-grid.cancel' | translate" container="body"
        ></i>
        <i 
          *ngIf="!d?.data?.editing"
          (click)="editSplitterRow(d?.data)"
          class="fas fa-pen clickable"
          placement="bottom" [ngbTooltip]="'devices.add-edit.splitter-grid.edit' | translate" container="body"
        ></i>
        <i
          *ngIf="!d?.data?.editing"
          (click)="deleteSplitterRow(d?.data)"
          class="fa fa-trash clickable"
          placement="bottom" [ngbTooltip]="'devices.add-edit.splitter-grid.delete' | translate" container="body"
        ></i>
      </div>
    </div>
  </dx-data-grid>
</div>
