<dx-accordion #accordion_element [dataSource]="ds_accordion_element" [collapsible]="true" [multiple]="false"
  [animationDuration]="300" [selectedItems]="accordion_element_selected" id="accordion-element-container"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.containerAttributes.mslink)" [disabled]="true"
          [label]="'general.bentley.mslink' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.elementType.name)" [disabled]="true" labelMode="floating"
          [label]="'general.model' | translate">
        </dx-text-box>
      </div>
    </div>
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.name)" [disabled]="true" labelMode="floating"
          [label]="'general.name' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.description)" [disabled]="true" labelMode="floating"
          [label]="'general.description' | translate">
        </dx-text-box>
      </div>
    </div>
    <!--div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="100" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="item.data.containerAttributes.address"
          [disabled]="true"
          labelMode="floating"
          [label]="'general.address' | translate"
        >
        </dx-text-box>
      </div>
    </div-->

    <!--BENTLEY-->
    <div fxLayout="row wrap" class="row-clearance">

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.containerAttributes.xfmID)" [disabled]="true"
          [label]="'general.bentley.xfmid' | translate">
        </dx-text-box>


      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.containerAttributes.poleno)" [disabled]="true"
          labelMode="floating" [label]="'general.bentley.poleno' | translate">
        </dx-text-box>
      </div>
    </div>

    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.containerAttributes.owner)" [disabled]="true"
          labelMode="floating" [label]="'general.bentley.owner' | translate">
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.containerAttributes.jobDesc)" [disabled]="true"
          labelMode="floating" [label]="'general.bentley.jobDesc' | translate">
        </dx-text-box>
      </div>
    </div>


    <div fxLayout="row wrap" class="row-clearance">

      <div fxFlex="100" class="form-field custom-disabled-text-box">
        <dx-text-box [value]="getValueOrDefault(item.data.containerAttributes.jobName)" [disabled]="true"
          labelMode="floating" [label]="'general.bentley.jobName' | translate">
        </dx-text-box>
      </div>
    </div>

    <div *ngFor="let group of getGroupedFields()">
      <div fxLayout="row wrap" class="row-clearance">
        <div *ngFor="let field of group" fxFlex="50" class="form-field custom-disabled-text-box">
          <dx-text-box [(value)]="field.value" [disabled]="true" labelMode="floating" [label]="field.key | translate">
          </dx-text-box>
        </div>
      </div>
    </div>
    <!---------------------------------->

    <div fxLayout="row" fxLayoutAlign="end center" class="row-clearance" *ngIf="traceData && traceData.length > 0">
      <div style="margin-right: 1vh;">
        <dx-button class="device-view" icon="far fa-eye" [text]="'general.inspect' | translate"
          [hint]="'general.inspect' | translate" (click)="openComponentInNewTab()">
        </dx-button>
      </div>
    </div>



  </div>
</dx-accordion>

<br />
<dx-accordion *ngIf="entity && entity.elementType?.specAttributes?.showDevice" [dataSource]="ds_accordion_device"
  [collapsible]="true" [multiple]="false" [animationDuration]="300" [selectedItems]="ds_accordion_device_selected"
  id="accordion-element-container" style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-data-grid id="gridContainer" [dataSource]="item.data" [remoteOperations]="false" [allowColumnReordering]="true"
      [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true"
      [noDataText]="'comp.container-detail.no-devices' | translate" (onRowClick)="selectItem($event)"
      (onExporting)="onExporting($event)">
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxi-column dataField="deviceID" width="80" caption="ID"> </dxi-column>
      <dxi-column dataField="name" dataType="string" caption="Name">
      </dxi-column>
      <dxi-column dataField="description" dataType="string" caption="Description"></dxi-column>
      <dxo-export [enabled]="true"></dxo-export>
    </dx-data-grid>
    <br>
    <div style="text-align: right;">
      <dx-button class="device-view me-2" icon="fas fa-file-download" [text]="'general.download' | translate"
        [hint]="'general.download' | translate" (onClick)="selectItem(null, item.data)">
      </dx-button>
    </div>
  </div>
</dx-accordion>

<div class="project-detail">
  <project-detail [projectDataDetail]="projectDataDetail"></project-detail>
</div>

<br />
<device-detail [entity]="detail" [isDetailView]="true" [connectionsAreSelectable]="true" *ngIf="detail"
  (onDetailClosed)="detail = undefined" (collapseDetail)="ds_accordion_device_selected = []"
  (connectionSelected)="connectionSelected.emit($event)"></device-detail>
<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="showLoading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<br />
<div class="asociatedTraceData" *ngIf="traceData && traceData.length > 0" style="background-color: #f8f8f8">
  <dx-accordion [collapsible]="true">
    <div *dxTemplate="let item of 'title'">
      <h1 class="main-secondary-text">
        Asociated Traces
      </h1>
    </div>
    <dxi-item>
      <dx-data-grid [dataSource]="traceData" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true"
        style="max-height:50vh;" (onRowClick)="selectItem($event)"
        (onExporting)="exportDuctDataWithAsociatedTraceData($event, traceData)">
        <dxi-column dataField="msLink" caption="MsLink"></dxi-column>
        <dxi-column dataField="name" caption="Name"></dxi-column>
        <dxi-column dataField="description" caption="Description"></dxi-column>
        <dxo-export [enabled]="true"></dxo-export>
      </dx-data-grid>
    </dxi-item>
  </dx-accordion>
</div>

<div *ngIf="traceDetail">
  <trace-detail [entity]="traceDetail"></trace-detail>
</div>

<dx-load-panel [visible]="showLoading" [shadingColor]="'rgba(0,0,0,0.4)'" [closeOnOutsideClick]="false"
  [showIndicator]="true"></dx-load-panel>

<dx-load-panel [visible]="isLoading" [shadingColor]="'rgba(0,0,0,0.4)'" [closeOnOutsideClick]="false"
  [showIndicator]="true" [message]="('comp.container-detail.processing-download') | translate">
</dx-load-panel>

