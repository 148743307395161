import { geojsonToWKT } from "@terraformer/wkt"

export class LocationData {
  inputSrID: number;
  outputSrID: number;
  format: string;
  coordinates: any;

  setCoordinates(xyCoordinates) {
    this.coordinates = { values: xyCoordinates };
  }


  constructor (format : string, coordinates : any, inputSrID : number, outputSrID? : number){
    this.inputSrID = inputSrID;
    this.outputSrID = outputSrID;
    this.format = format;

    if(!outputSrID){
      this.outputSrID = inputSrID;
    }
    
    if(coordinates && typeof coordinates == 'string'){
      if(coordinates.toUpperCase().includes("POINT")){
        const geojsonPoint = {
          "type": "Point",
          "coordinates": coordinates
        }
        
        if(format != ""){
          this.coordinates = { geographyType: "Point", values: coordinates };
        }
      }
      if(coordinates.toUpperCase().includes("LINESTRING")){
        if(format != ""){
          this.coordinates = { geographyType: "LineString", values: coordinates };
        }
      }
    }
  }
}
