import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IStep } from '@app/@shared/model/interface/iStep.model';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})

export class StepperComponent implements OnInit, OnChanges {
  @Input() steps: IStep[] = [];
  @Input() vertical: boolean = false;
  @Input() currentStep: number;

  @Input() hideCurrentStepIndicator: boolean = false;

  currentStepObject: IStep;
  height: number = 450;

  ngOnInit(): void {
    this.currentStepObject = this.steps.find(x => x.id === (this.currentStep || 1));
    this.calculateTotalHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const stepsLength = this.steps.length;

    if (!!stepsLength && changes['currentStep']) {
      if (this.currentStep > stepsLength) {
        this.currentStepObject = this.steps[stepsLength - 1];
      } else {
        this.currentStepObject = this.steps.find(x => x.id === (this.currentStep || 1));
      }

      this.calculateTotalHeight();
    }

  }

  
  calculateTotalHeight() {
    if (!this.vertical) {
      return;
    }

    const stepsLength = this.steps.length;
    this.height = Math.max(450, stepsLength * 50);
  }
}
