import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from '@app/@shared/model/userProfile.model';
import { Cons } from '@app/@shared/cons/cons';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'users-single-modal',
  templateUrl: './users-single-modal.component.html',
  styleUrls: ['./users-single-modal.component.scss'],
})



export class UsersSingleModalComponent implements OnInit, OnChanges {

  _entity: UserProfile;

  rolesDataSource: any[] =
    [
      { "id": 2, "name": "Usuario" },
    ];

  @Input() isNewUser: boolean;

  @Input("entity")
  get entity() {
    return this._entity;
  }
  set entity(value) {
    this._entity = value;
    if (!this._entity.platforms) {
      this._entity.platforms = [];
    }
    this.platformDS = this._entity.platforms.filter(x => !x.__deleted)

    if (!this._entity.roles) {
      this._entity.roles = [];
    }

    if (this.isNewUser) {
      this.rolesDS = this.rolesDataSource;
    } else {
      this.rolesDS = this._entity.roles.filter(x => !x.__deleted);
    }
  }

  @Output() onSaved = new EventEmitter<any>();
  @Output() onCreateUser = new EventEmitter<any>();
  @Output() onCanceled = new EventEmitter<any>();

  regionalDS = Cons.REGIONALDATA.getRegionalDatasource();
  @ViewChild("rolesGrid") rolesGrid: DxDataGridComponent;
  @ViewChild("platformsGrid") platformsGrid: DxDataGridComponent;

  platformDS = [];
  rolesDS = [];

  platformID: number;
  platformName: any[];

  constructor(private platformService: PlatformService, private translateService: TranslateService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['isNewUser']) {
      this.getPlatformID();

    }

    if(this.isNewUser == true){
      this.asingValuesToEntity();  
    }
  }



  save() {
    if (this.isNewUser == true) {

      this.onCreateUser.emit(this.entity);
    } else {
      this.onSaved.emit(this.entity);

    }
  }

  cancel() {
    this.onCanceled.emit();
  }


  rowInserted($event) {
    $event.data.__new = true;
    this.entity.roles.push($event.data);
  }
  rowUpdated($event) {
    $event.data.__updated = true;
    if (this.platformsGrid) {
      this.platformsGrid.instance.refresh();
    }
    if (this.rolesGrid) {
      this.rolesGrid.instance.refresh();
    }
  }

  rowInsertedPlatform($event){
    $event.data.__new = true;
    this.entity.platforms.push($event.data);
  }

  onRowRemoving(e) {
    let index = e.component.getRowIndexByKey(e.key);
    let rowEl = e.component.getRowElement(index);

    rowEl[0].classList.add("rowToDelete");

    let res: any = confirm("Do you really want to remove the element: " + e.data.name, "Warning");

    e.cancel = new Promise((resolve, reject) => {
      res.done((dialogResult) => {
        if (dialogResult) {
          e.data.__deleted = true;
          if (this.platformsGrid) {
            this.platformsGrid.instance.refresh();
          }
          if (this.rolesGrid) {
            this.rolesGrid.instance.refresh();
          }
        }
        resolve(!dialogResult)
      });

    });
  }

  onRowPrepared(e) {
    if (e.rowType == 'data' && e.data.__new) {
      e.rowElement.style.backgroundColor = '#bffb8f';
    }
    if (e.rowType == 'data' && e.data.__updated) {
      e.rowElement.style.backgroundColor = '#ffd972';
    }
    if (e.rowType == 'data' && e.data.__deleted) {
      e.rowElement.style.backgroundColor = '#ff7772';
    }
  }
  onEditorPreparing(e: any) {
    if (e.parentType === "dataRow" && e.dataField === "name" && e.editorName === "dxLookup") {
      e.editorOptions.value = e.row.data.id; 
    }
  }


  roleCellTemplate(cellElement, cellInfo) {
    const rowData = cellInfo.row.data;
    const lookupText = rowData.name;
    cellElement.innerText = lookupText;

    
    return cellElement;
  }

  startDate(cellElement) {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    cellElement.innerText = formattedDate;
    return cellElement;
  }
  
  endDate(cellElement) {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setFullYear(currentDate.getFullYear() + 1);
    const day = String(futureDate.getDate()).padStart(2, '0');
    const month = String(futureDate.getMonth() + 1).padStart(2, '0');
    const year = futureDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    cellElement.innerText = formattedDate;
    return cellElement;
  }

  idCellTemplate(cellElement, cellInfo) {
    const id = cellInfo.data.name;
    cellElement.innerText = id !== undefined && id !== null ? id : '';
    return cellElement;
  }

  platformCellTemplate(cellElement, cellInfo) {
    const name = cellInfo.displayValue;  
    cellElement.innerText = name;
    return cellElement;
  }

  activeCellTemplate(cellElement){
    cellElement.innerText = true;
    return cellElement;
  }

  getPlatformData() {
    this.platformName = this.platformService.platforms.map(platform => ({

      id: platform.platformID,
      name: platform.name,
      active: true,

    }));

    console.log(this.platformName);

  }

  getPlatformID(): number {
    this.platformID = this.platformService.platformID;
    return this.platformID;
  }

  asingValuesToEntity(): void {

    const currentDate = new Date();

    const endDate = new Date();
    endDate.setFullYear(currentDate.getFullYear() + 1);

    this.entity = {
      userID: 0,
      platformID: 0, 
      cultureID: 'es-AR',
      name: '',
      email: '',
      active: true,
      startDate: new Date(), 
      password: 'Claro@2024!',
      platforms: [], 
      roles: [], 
      resources: null, 
      userPlatform: [
        {
          userPlatformAttributes: '',
          userID: 0,
          platformID: this.platformID,
          active: true
        }
      ],
      userRole: [
        {
          roleID: 2,
          name: 'Usuario',
          startDate: currentDate,
          endDate: endDate,
          __new: true,
          __updated: false,
          __deleted: false
        }
      ]
    };
  }


}