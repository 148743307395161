import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { D3HorizontalTreeData } from '@app/@shared/model/d3/horizontal-tree.model';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { CredentialsService } from '@app/auth';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InfrastructureODFResolver implements Resolve<D3HorizontalTreeData[]> {
  constructor(
    private infrastructureService: InfrastructureService,
    private credentials: CredentialsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<D3HorizontalTreeData[]> {
    const tokenFromActivatedRoute: string = route.queryParamMap.get('token');
    if(tokenFromActivatedRoute){
      //The module is being loaded from an external link (PortalGIS)
      this.credentials.setCredentials(tokenFromActivatedRoute);
      const mslink = route.params['infrastructureID'];
      return firstValueFrom(this.infrastructureService.getODFByMslink(mslink));
    }
    else {
      const infrastructureID = Number(route.params['infrastructureID']);
      return firstValueFrom(this.infrastructureService.getODF(infrastructureID));
    }
  }
}
