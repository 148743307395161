import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Connection } from '@app/@shared/model/connection.model';
import { Container } from '@app/@shared/model/container.model';
import { Device } from '@app/@shared/model/device.model';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { DeviceDetailComponent } from '../device-detail/device-detail.component';

@Component({
  selector: 'container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss'],
})
export class ContainerDetailComponent implements OnInit {

  @Output() connectionSelected = new EventEmitter<Connection>();
  @ViewChild(DeviceDetailComponent, { static: false }) deviceDetail: DeviceDetailComponent;

  ds_accordion_element = [];
  accordion_element_selected = [];
  ds_accordion_device = [];
  ds_accordion_device_selected = [];

  showLoading: boolean = false;

  _entity: Container;
  projectDataDetail: any;
  projectDataDetailUserfId2: any;
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
    this.projectDataDetail = this._entity.containerAttributes;
    const containerAttributes = this._entity.containerAttributes;

    const userFldFields = Object.keys(containerAttributes)
      .filter(key => key.startsWith('userfld') || key.startsWith('userFld'))
      .reduce((obj, key) => {
        obj[key] = containerAttributes[key];
        return obj;
      }, {});

    this.projectDataDetailUserfId2 = userFldFields;
    this.loadEntity();
  }

  detail: Device;

  constructor(
    private trans: TranslateService,
    private deviceService: DeviceService,
    private excelJSHelperService: ExcelJSHelperService
  ) { }

  ngOnInit() { }

  loadEntity() {
    //Close search panel
    this.ds_accordion_element = [];

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.ds_accordion_device = [];
    this.ds_accordion_device.push({
      title: this.trans.instant('comp.search-panel.devices'),
      data: this.entity.device,
    });

    this.ds_accordion_device_selected = this.ds_accordion_device;
  }

  selectItem($event, data = null) {

    if (!$event || ($event.length === 0 && !data)) {
      const defaultItem = data && data.length > 0 ? data[0] : null;
      if (defaultItem) {
        this.deviceService.getByID(defaultItem.deviceID).subscribe((res) => {
          if (res) {
            this.accordion_element_selected = [];
            this.detail = res;
          }
        });
      }
      $event = data;
    }

    if (Array.isArray($event)) {
      // $event.forEach((device, index) => {
      //   this.deviceService.getByID(device.deviceID).subscribe((res) => {
      //     time += 500;
      //     if (res) {
      //       setTimeout(() => {
      //         this.detail = res;
      //         this.deviceDetail.prepareToDownloadDevice(res);
      //       }, time);
      //     }
      //   });
      // });
      const promises = $event.map(device =>
        this.deviceService.getByID(device.deviceID).toPromise()
      );

      Promise.all(promises).then(results => {
        // this.deviceDetail.deviceBoxComponent.loading = true;

        this.showLoading = true;
        results.forEach((res, index) => {
          if (res) {
            const delay = (index == 0) ? 8000 : (index + 1) * 8000;
            setTimeout(() => {
              this.detail = res;
              this.deviceDetail.prepareToDownloadDevice(res);
              if (index == results.length - 1) {
                this.showLoading = false;
              }
            }, delay);
          }
        });
      }).catch(error => {
        console.error('Error al obtener dispositivos:', error);
      });
    } else {
      this.deviceService.getByID($event.data.deviceID).subscribe((res) => {
        if (res) {
          this.accordion_element_selected = [];
          this.detail = res;
        }
      });
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      this.initializeExcelHeaders(worksheet);
      this.writeExcelData(worksheet);
      this.excelJSHelperService.adjustColumnsWidth(worksheet);
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          this.entity.name + '_DEVICE.xlsx'
        );
      });
    });
    e.cancel = true;
  }

  initializeExcelHeaders = (worksheet: Worksheet) => {
    const idLabel = this.trans.instant('ID')?.toUpperCase();
    const nameLabel = this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase();
    const descriptionLabel = this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase();

    const headers = [
      idLabel,
      nameLabel,
      descriptionLabel
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers);
    this.centerTextExcel(worksheet, 1, [1, 2, 3]);

  };

  writeExcelData = (worksheet: Worksheet) => {
    const devices: Device[] = this.ds_accordion_device[0].data;

    const excelData: string[][] = devices.map((x) => {
      const id = String(x.deviceID);
      const name = x.name;
      const description = x.description;

      return [id, name, description];
    });

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
    this.centerTextExcel(worksheet, 2, [1, 2, 3], devices.length);

  }

  getValueOrDefault(value: any): string {
    const stringValue = value !== null && value !== undefined ? String(value) : '';
    return stringValue.trim() !== '' ? stringValue : this.trans.instant('general.no-data');
  }

  getProjectDataDetailUserfld2Fields(): { key: string; value: string }[] {
    return Object.entries(this.projectDataDetailUserfId2 || {}).map(([key, value]) => ({
      key: this.capitalizeFirstLetter(key),
      value: value?.toString().trim() === '' ? this.trans.instant('general.no-data') : String(value)
    }));
  }

  capitalizeFirstLetter(text: string): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  getGroupedFields() {
    const fields = this.getProjectDataDetailUserfld2Fields();
    const groupedFields = [];

    for (let i = 0; i < fields.length; i += 2) {
      groupedFields.push(fields.slice(i, i + 2));
    }

    return groupedFields;
  }

  centerTextExcel(worksheet: Worksheet, startRow: number, cells: number[], rowCount: number = 1) {
    for (let i = 0; i < rowCount; i++) {
      const row = worksheet.getRow(startRow + i);
      cells.forEach((cell) => {
        row.getCell(cell).alignment = { horizontal: 'center' };
      });
    }
  }

}