import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SplitterType } from '@app/@shared/model/splitterType.model';

@Component({
  selector: 'app-splitter-type-list',
  templateUrl: './splitter-type-list.component.html',
  styleUrls: ['./splitter-type-list.component.scss']
})
export class SplitterTypeListComponent implements OnInit {

  @Input() SplitterTypes: SplitterType[] = [];
  @Input() loading: boolean = false;

  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

}
