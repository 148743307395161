import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { LocationData } from './aux-models/locationData';
import { Platform } from './platform.model';
import { PlatformPlot } from './platform-plot.model';
import { ICommonEntity } from './interface/iCommonEntity';
import { ElementType } from './elementType.model';

export class Plot implements ICommonEntity {
  _SCALE: number;
  plotID: number;
  name : string;
  platformID: Platform;
  _locationCode: string;
  get locationCode(){
    return this._locationCode;
  }
  set locationCode(value){
    this._locationCode = value;
    this.name = value;
  }
  
  @Type(()=> LocationData)
  locationData: LocationData;
  builtUpArea: number;
  plotAttributes: any;

  platformPlot : PlatformPlot[];

  get commonID() {
    return this.plotID;
  }

  get entityName() {
    return Cons._PLOT;
  }

  elementTypeID: number;
  elementType: ElementType;
  modelType: ElementType;
}
