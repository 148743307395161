<div fxLayout="column" fxLayoutAlign="space-between none" class="h-100">
  
  <div class="mb-5 olt-data-container" fxLayout="row wrap" fxLayoutAlign="space-between none">
    <dx-text-box *ngIf="!!olt.equipmentID"
      fxFlex="48%"
      labelMode="floating"
      [label]="('olt.attr.id' | translate) + '*'"
      [disabled]="true"
      [value]="''+olt.equipmentID"
    >
    </dx-text-box>

    <dx-text-box
      fxFlex="48%"
      labelMode="floating"
      [label]="('olt.attr.name' | translate) + '*'"
      [(value)]="olt.name"
    >
    </dx-text-box>

    <dx-select-box
      fxFlex="48%"
      labelMode="floating"
      displayExpr="name"
      valueExpr="equipmentTypeID"
      [value]="olt.equipmentTypeID"
      [items]="equipmentTypes"
      [deferRendering]="false"
      [placeholder]="('olt.attr.model' | translate) + '*'"
      [searchEnabled]="true"
      [searchExpr]="'name'" 
      [searchMode]="'contains'"
      [searchTimeout]="200" 
      (onSelectionChanged)="modelSelectionChange($event)"
    >
    </dx-select-box>


    <dx-select-box
      fxFlex="48%"
      labelMode="floating"
      displayExpr="name"
      valueExpr="rackId"
      [value]="olt.equipmentAttributes?.cabinetRack?.rackId"
      [items]="mockedRacks"
      [deferRendering]="false"
      [placeholder]="('olt.attr.rack' | translate) + '*'"
      [searchEnabled]="true"
      [searchExpr]="'name'" 
      [searchMode]="'contains'"
      [searchTimeout]="200" 
      (onSelectionChanged)="rackSelectionChange($event)"
    >
    </dx-select-box>

    <dx-select-box
      fxFlex="48%"
      labelMode="floating"
      displayExpr="name"
      valueExpr="frameId"
      [value]="olt.equipmentAttributes?.frame?.frameId"
      [items]="mockedFrames"
      [deferRendering]="false"
      [placeholder]="('olt.attr.frame' | translate) + '*'"
      [searchEnabled]="true"
      [searchExpr]="'name'" 
      [searchMode]="'contains'"
      [searchTimeout]="200" 
      (onSelectionChanged)="framesSelectionChange($event)"
    >
    </dx-select-box>

    <dx-text-box
      fxFlex="48%"
      labelMode="floating"
      [label]="'olt.attr.IP' | translate"
      [value]="olt?.equipmentAttributes?.ip"
      (valueChange)="olt.equipmentAttributes.ip = $event"
    >
    </dx-text-box>
  </div>

  <h1 class="main-secondary-text">{{ 'olt.modal.ports' | translate }}</h1>

  <div class="olt-data-container" fxLayout="row wrap" fxLayoutAlign="space-between none">
    <dx-number-box
      fxFlex="30%"
      labelMode="floating"
      [label]="'olt.modal.number-of-ports' | translate"
      [(value)]="ports"
      format="###0"
      [min]="0"
      [max]="10000"
    ></dx-number-box>

    <dx-number-box
      fxFlex="30%"
      labelMode="floating"
      [label]="'olt.modal.number-of-trays' | translate"
      [(value)]="rows"
      format="###0"
      [min]="0"
      [max]="10000"
    ></dx-number-box>

    <dx-button
      fxFlex="30%"
      class="main-background-theme"
      [text]="'olt.modal.generate-ports' | translate"
      (click)="generatePorts()"
    >
    </dx-button>
  </div>
  
  <dx-tab-panel *ngIf="olt.equipmentPort?.length && olt.equipmentAttributes?.slot && displayEquipmentGraph"> 
    <dxi-item [title]="'olt.modal.frontal-view' | translate">
      <dx-scroll-view>
        <app-odf-front-view
          [equipment]="olt"
          [showTitle]="false"
        ></app-odf-front-view>
      </dx-scroll-view>
    </dxi-item>

    <dxi-item [title]="'olt.modal.grid-view' | translate">
      <dx-scroll-view>
        <div style="height: 300px;">
          <app-odf-grid-view
            [equipmentPorts]="olt.equipmentPort"
          ></app-odf-grid-view>
        </div>
      </dx-scroll-view>
    </dxi-item>
  </dx-tab-panel>

  <div fxLayout="row" fxLayoutAlign="end center" class="me-2 mt-4">
    <dx-button
      [text]="'general.cancel' | translate"
      (click)="cancel.emit()"
    >
    </dx-button>
    <dx-button
      class="ms-3 main-background-theme"
      [text]="'general.save' | translate"
      (click)="saveOLT()"
    >
    </dx-button>
  </div>
</div>
