import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { LocationData } from './aux-models/locationData';
import { ElementType } from './elementType.model';
import { Fiber } from './fiber.model';
import { ICommonEntity } from './interface/iCommonEntity';

export class Trace implements ICommonEntity{
  traceID: number;
  platformID: number;
  length?:number;
  containerID?:number;

  elementTypeID: number;
  @Type(()=> ElementType)
  elementType: ElementType;

  modelTypeID: number;
  @Type(()=> ElementType)
  modelType: ElementType;

  name: string;
  description: string;
 
  @Type(()=> LocationData)
  locationData: LocationData;
  
  sectionAttributes: any;



  fiber: Fiber[];

  get commonID() {
    return this.traceID;
  }

  get entityName() {
    return Cons._TRACE;
  }

  _SCALE: number = 0;
}
