import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  @Input() projectDataDetail: any;
  @Input() projectDataEntityName: any;

  projectData = {
    createdBy: null,
    creationDate: null,
    editedBy: null,
    editionDate: null,
    updatedVersion: null
  };

  dataSource = [this.projectData];

  constructor(private trans: TranslateService) { }

  ngOnInit(): void {
    this.mapProjectDataDetailToProjectData();
  }

  private mapProjectDataDetailToProjectData() {
    const noData = this.trans.instant('project-detail.no-data');

    if (this.projectDataDetail) {
      this.projectData.createdBy = this.projectDataDetail.createdBy?.trim() || noData;
      this.projectData.creationDate = this.projectDataDetail.createDate?.trim() || noData;
      this.projectData.editedBy = this.projectDataDetail.updatedBy?.trim() || noData;
      this.projectData.editionDate = this.projectDataDetail.updateDate?.trim() || noData;
      this.projectData.updatedVersion = this.projectDataDetail.updatedVersion?.trim() || noData;

      if (this.projectDataEntityName === 'TRACE') {
        [this.projectData.creationDate, this.projectData.editionDate] =
          [this.projectData.editionDate, this.projectData.creationDate];
      }

      this.dataSource = [this.projectData];
    }
  }
}