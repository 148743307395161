import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { DxContextMenuComponent, DxTreeViewComponent } from 'devextreme-angular';

@Component({
  selector: 'app-splitter-type-modal',
  templateUrl: './splitter-type-modal.component.html',
  styleUrls: ['./splitter-type-modal.component.scss']
})
export class SplitterTypeModalComponent implements OnInit {

  @Input() isPopupVisible: boolean = false;
  @Input() selectedSplitterType: SplitterType;
  @Input() dataSourceGrid: any[];
  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();
  elementEditableData = [
    { label: 'splitter.grid-header.name', field: 'name' },
    { label: 'splitter.grid-header.model', field: 'model' },
    { label: 'splitter.grid-header.commonId', field: 'commonID' },
  ];

  isJSONValid : boolean = true;
  
  constructor() { 
    console.log(this.selectedSplitterType);
  }

  ngOnInit(): void {
    console.log(this.selectedSplitterType);
    
  }
  specSaved(spec){
    this.selectedSplitterType.specAttributes = spec;
  }

  saveElementType() {
    if(!this.isJSONValid){
      return;
    }   
    this.save.emit(this.selectedSplitterType);
  }
}
