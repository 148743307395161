
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})

export class ConfirmationModalComponent {
  @Input() message: string;
  @Input() confirmButtonMessage: string;

  @Output() userConfirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
}
