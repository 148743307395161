<input type="text" id="searchInput" placeholder="Search service name..."  (input)="searchService()" style="visibility: hidden;" />



<div class="fiber-diagram">
    <div class="row">

      <div class="col-12">
      
        <div #svgcontainer></div>
        <button (click)="downloadD3Graph()">Exportar diagrama</button>
      </div>


    </div>
  </div>