<div style="height: 100%" id="container_olMap">
  <dx-load-panel
    class="olmapPanel"
    [wrapperAttr]="{ class: 'olmapPanel' }"
    #loadPanel1
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#container_olMap' }"
    [visible]="enableDragAndDrop && !hideOverlay && !newMarker"
    [showIndicator]="false"
    [showPane]="true"
    [shading]="true"
    [closeOnOutsideClick]="true"
    (onHidden)="hideOverlay = true"
    [message]="'forms.general.map.dragAndDropMessage' | translate"
  >
  </dx-load-panel>
  <dx-load-panel
    class="olmapPanel"
    [wrapperAttr]="{ class: 'olmapPanel' }"
    #loadPanel2
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#container_olMap' }"
    [visible]="enableDragAndDrop && !hideOverlay && newMarker"
    [showIndicator]="false"
    [showPane]="true"
    [shading]="true"
    [closeOnOutsideClick]="true"
    (onHidden)="hideOverlay = true"
    [message]="'forms.general.map.dragAndDropMessage.new' | translate"
  >
  </dx-load-panel>
  <div
    tabindex="1"
    [class]="cssClass"
    [style.height]="height"
    [id]="mapID"
  >
    <div #tooltip id="tooltip" [hidden]="true"></div>
  </div>

  <div
    *ngFor="let am of animatedMarkers"
    [title]="am.name"
    [id]="'a_' + am.id"
    style="width: 100px; height: 50px"
    class="animated-icon"
    [style.background]="
      'url(' + am.icon + ') no-repeat scroll 0% 0% transparent'
    "
    [style.backgroundSize]="'contain'"
  ></div>
</div>
