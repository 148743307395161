
<div class="homepage-container pt-4 px-5">
  <div class="d-flex justify-content-between align-items-center mb-3">
      <label class="main-secondary-text">{{ 'element.title' | translate }}</label>
  </div>

  <div class="card-panel" style="width: 50%; max-width: 500px;">
    <h5><i class="fa fa-search"></i> {{ 'general.search-panel' | translate }}</h5>
    <div class="mb-2 mt-2" fxLayout="row">
      <div [fxFlex]="100" class="form-field">
        <dx-text-box 
          labelMode="floating" 
          [showClearButton]="true"
          [label]="'general.name' | translate"  
          [(value)]="filter.name" >
        </dx-text-box>
      </div>
    </div>
    <div class="mb-2" fxLayout="row">
      <div [fxFlex]="100" class="form-field">
        <dx-select-box  labelMode="floating" [label]="'element.grid-header.groupId' | translate"  
            [deferRendering]="false" [dataSource]="groups" [(value)]="filter.groupID">
        </dx-select-box>
      </div>
    </div>
    <div style="text-align: right;">
        <dx-button
        class="ms-3 main-background-theme"
        [text]="'general.search' | translate"
        (click)="search()"
        ></dx-button>
    </div>
</div>
<dx-data-grid 
  [dataSource]="dataSource" [showBorders]="true" height="65vh" 
  [allowColumnResizing]="true"  [columnAutoWidth]="true" columnResizingMode="widget">
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <dxi-column [caption]="'element.grid-header.ID' | translate" dataField="elementTypeID" ></dxi-column>
  <dxi-column [caption]="'element.grid-header.name' | translate" dataField="name"></dxi-column>
  <dxi-column [caption]="'element.grid-header.reference' | translate" dataField="reference"></dxi-column>
  <dxi-column [caption]="'element.grid-header.groupId' | translate" dataField="groupID"></dxi-column>
  <dxi-column [caption]="'element.grid-header.filePath' | translate" dataField="filePath"></dxi-column>
  
  <dxi-column width="90" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>
  <div *dxTemplate="let d of 'actionsCellTemplate'">
    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <div fxFlex="100" style="text-align: center;">
        <dx-button 
          class="main-background-theme grid-button"
          (onClick)="openPopup(d.data)"
          icon="fas fa-pen"
          [hint]="'general.edit' | translate"
        ></dx-button>
      </div>
    </div>
  </div>

</dx-data-grid>
  
<dx-popup  [(visible)]="isPopupVisible" [closeOnOutsideClick]="false" [showCloseButton]="false" [maxWidth]="'50%'">
  <element-type-single *ngIf="selectedEntity"
    [entity]="selectedEntity"
    (onSaved)="save($event)"
    (onCanceled)="closePopup()">
  </element-type-single>
</dx-popup>

<dx-toast [(visible)]="isVisible" [type]="type" [message]="message" [displayTime]="5000" [maxWidth]="'30vw'">
</dx-toast>
  