<dx-data-grid
  [dataSource]="feasibilityHistory"
  [showBorders]="true"
  [showRowLines]="true"
  keyExpr="feasibilityStudyID"
>
  <dxi-column [caption]="'general.address' | translate" dataField="placeName" width="250"></dxi-column>
  <dxi-column [caption]="'feasibility.client-details.client-feasibility-history-list.date' | translate" cellTemplate="dateCellTemplate"></dxi-column>
  <dxi-column [caption]="'feasibility.client-details.client-feasibility-history-list.hour' | translate" cellTemplate="hourCellTemplate"></dxi-column>
  <dxi-column [caption]="'feasibility.grid.status' | translate" cellTemplate="feasibleCellTemplate"></dxi-column>
  <dxi-column [caption]="'general.description' | translate" dataField="event.description" width="300"></dxi-column>

  <div *dxTemplate="let d of 'dateCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
      <label>{{ d.data.verificationDate | date: 'dd/MM/yyyy' }}</label>
    </div>
  </div>

  <div *dxTemplate="let d of 'hourCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
      <label>{{ d.data.verificationDate | date: 'HH:mm:ss' }}</label>
    </div>
  </div>

  <div *dxTemplate="let d of 'feasibleCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
      <label>{{ d.data.feasible | translate }}</label>
    </div>
  </div>
</dx-data-grid>