<div class="homepage-container pt-4 px-5">
  <div class="d-flex flex-column flex-sm-row justify-content-between align-items-stretch align-items-sm-center mb-3">
    <label class="main-secondary-text mb-3 mb-sm-0">{{ 'feasibility.title' | translate }}</label>    
  </div>

  <div fxLayout="row" >
    <div fxFlex="70" class="card-panel" style="position: relative">
      <address-form 
        [location]="location"
        [height]="325"
        [embeddedAddress]="true"
        (onAddressChanged)="addressChanged($event)"
        >
      </address-form>
      <dx-button class="button main-light-theme add-study" 
        *ngIf="location"
        [text]="'feasibility.new-study' | translate"
        [icon]="'add'"
        (onClick)="newStudy()">
      </dx-button>
    </div>
    <div fxFlex="25" class="card-panel" *ngIf="false">
      <h5>{{ 'general.recent' | translate }}</h5>
    </div>
    <div fxFlex="30" class="card-panel">
      <h5>{{ 'feasibility.feasibility-ratio' | translate }}</h5>
      <dx-pie-chart #feasibilityRatioChart palette="Violet" paletteExtensionMode="Blend" [dataSource]="feasibilityRatio"
        (onPointClick)="pointClickHandler($event)" (onLegendClick)="legendClickHandler($event)">
        <dxi-series argumentField="state" valueField="value">
          <dxo-label [visible]="true">
            <dxo-connector [visible]="true" [width]="1"></dxo-connector>
          </dxo-label>
        </dxi-series>
        <dxo-legend verticalAlignment="center" horizontalAlignment="center" itemTextPosition="right" [rowCount]="1">
        </dxo-legend>
        <dxo-size  [height]="300"></dxo-size>
      </dx-pie-chart>
    </div>
  </div>
  
  <dx-data-grid
      #datagrid
      [dataSource]="studies"
      [showBorders]="true"
      [showRowLines]="true"
      keyExpr="clientID"
      id="gridContainer"
      height="40vh" >
    <dxo-paging [pageSize]="10"> </dxo-paging>
      
    <dxo-pager
      [visible]="true"
      [displayMode]="'full'"
      [showPageSizeSelector]="false"
      [showInfo]="false"
      [showNavigationButtons]="true" >
    </dxo-pager>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    
    <dxi-column [caption]="'feasibility.client.name' | translate" dataField="clientName" [cssClass]="'verticalAlign'" [groupIndex]="0"></dxi-column>

    <dxi-column [caption]="'feasibility.grid.id' | translate" dataField="feasibilityStudyID" width="80"  [cssClass]="'verticalAlign'"></dxi-column>
    <dxi-column [caption]="'feasibility.grid.invokerSystem' | translate" dataField="invokerSystemCode" cellTemplate="invokerCellTemplate" width="200" [cssClass]="'verticalAlign'"></dxi-column>
    <div *dxTemplate="let d of 'invokerCellTemplate'">
      <div class="d-flex justify-content-between align-items-center">
        <label>{{ d.data?.invokerSystemCode}} - {{d.data?.invokerSystem?.name }}</label>
      </div>
    </div>
    <dxi-column [caption]="'general.address' | translate" dataField="placeName" [cssClass]="'verticalAlign'"></dxi-column>
    <dxi-column [caption]="'feasibility.details.verification-date' | translate" dataField="verificationDate" dataType="date" format="dd/MM/yyyy HH:mm" width="150" [cssClass]="'verticalAlign'"></dxi-column>
    <dxi-column [caption]="'feasibility.grid.verification-code' | translate" dataField="verificationCode" [cssClass]="'verticalAlign'" width="100"></dxi-column>
    <dxi-column [caption]="'feasibility.grid.verification-result' | translate" dataField="verificationResult" cellTemplate="resultCellTemplate" [cssClass]="'verticalAlign'"></dxi-column>
    <div *dxTemplate="let d of 'resultCellTemplate'">
      <div class="d-flex justify-content-between align-items-center">
        <label>{{ d.data?.verificationResult | translate }}</label>
      </div>
    </div>
    <dxi-column [caption]="'feasibility.grid.status' | translate" dataField="state?.name" cellTemplate="statusCellTemplate" [cssClass]="'verticalAlign'" width="120"></dxi-column>
    <div *dxTemplate="let d of 'statusCellTemplate'">
      <div class="d-flex justify-content-between align-items-center">
        <label>{{ d.data?.state?.name | translate }}</label>
      </div>
    </div>
    <dxi-column dataField="allocationDate" width="150" [cssClass]="'verticalAlign'" cellTemplate="dateCellTemplate" [caption]="'feasibility.allocation.date' | translate" ></dxi-column>
    <div *dxTemplate="let d of 'dateCellTemplate'">
      <div class="d-flex justify-content-between align-items-center">
        <label *ngIf="d.data?.state?.orderBy > 1">{{  d.data?.allocationDate | date: 'dd/MM/yyyy HH:mm' }}</label>
      </div>
    </div>
    <dxi-column width="80" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>
    <div *dxTemplate="let d of 'actionsCellTemplate'">
      <div fxLayout="row" fxLayoutAlign="space-between center" >
        <div fxFlex="100" style="text-align: center;">
          <!--dx-button 
            (onClick)="positionOnMap(d.data)"
            icon="fa fa-eye"
            [hint]="'feasibility.grid.tooltip.view-details' | translate"
          ></dx-button-->
          <dx-button 
            (onClick)="viewDetails(d.data)"
            icon="fa fa-info-circle"
            [hint]="'feasibility.grid.tooltip.view-details' | translate"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

</div>


<ng-container *ngIf="popups.addOrEditClient && selectedEntity">
  <dx-popup
    [visible]="popups.addOrEditClient"
    (visibleChange)="closeClientDetailModal()"
    [closeOnOutsideClick]="false"
    [showTitle]="true"
    [dragEnabled]="false"
    [height]="'97%'"
    [maxWidth]="'50%'"
  >
    <div *dxTemplate="let data of 'content'">
      <feasibility-single-modal *ngIf="selectedEntity"
        [entity]="selectedEntity"
        (cancel)="closeClientDetailModal()"
        (onFeasibilityChecked)="checkFeasibilityAndSaveStudy($event)"
        (onFeasibilityAllocated)="allocateFeasibility($event)"
        (onClientSaved)="save($event)"
      ></feasibility-single-modal>
    </div>
  </dx-popup>
</ng-container>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#gridContainer' }"
  [visible]="loadingModal"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>
