<div class="widget-container flex-box" *ngIf="!buttonMode">
  <span>{{ label }} <i *ngIf="required" style="color:red" class="fas fa-exclamation-circle" [title]="'general.required' | translate"></i></span>
  <div
    [id]="'dropzone-external' + dyID"
    [class]="'flex-box dropzone-external ' + (required ? 'required' : '')"
    [style.height]="height"
    [ngClass]="isDropZoneActive ? ['dx-theme-accent-as-border-color', 'dropzone-active'] : ['dx-theme-border-color']"
  >
    <img [id]="'dropzone-image'" [src]="fileSource" *ngIf="fileSource" alt="" />
    <div [id]="'dropzone-text'" class="flex-box" *ngIf="textVisible">
      <span>{{ 'general.drag-drop' | translate }}</span>
    </div>
    <dx-progress-bar
      #uploadProgress
      [min]="0"
      [max]="100"
      width="30%"
      [showStatus]="false"
      [visible]="progressVisible"
      [value]="progressValue"
    ></dx-progress-bar>
  </div>
  <dx-file-uploader *ngIf="showUploader"
    #fileUploader
    [dialogTrigger]="'#dropzone-external' + dyID"
    [dropZone]="'#dropzone-external' + dyID"
    [multiple]="false"
    [allowedFileExtensions]="allowedTypes"
    uploadMode="instantly"
    [uploadUrl]="url"
    [uploadHeaders]="headers"
    [visible]="false"
    (onDropZoneEnter)="onDropZoneEnter($event)"
    (onDropZoneLeave)="onDropZoneLeave($event)"
    (onUploaded)="onUploaded($event)"
    (onProgress)="onProgress($event)"
    (onUploadStarted)="onUploadStarted()"
  ></dx-file-uploader>
</div>

<div class="widget-container flex-box" *ngIf="buttonMode">
  <dx-file-uploader
    #fileUploader
    [selectButtonText]="selectButtonText"
    [multiple]="false"
    [allowedFileExtensions]="allowedTypes"
    class="file-uploader-button-mode"
    uploadMode="instantly"
    [uploadUrl]="url"
    [uploadHeaders]="headers"
    (onUploaded)="onUploaded($event)"
    (onProgress)="onProgress($event)"
    (onUploadStarted)="onUploadStarted()"
  ></dx-file-uploader>
</div>
