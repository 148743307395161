

import { Injectable } from '@angular/core';
import { Platform } from '@app/@shared/model/platform.model';
import { Router } from '@angular/router';
import { CredentialsService } from './credentials.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  activePlatform: Platform;
  platforms: Platform[] = [];

  constructor(
    private credentialsService: CredentialsService,
    private router: Router,
  ) { }

  get platformID() {
    if (!this.activePlatform) {
      this.activePlatform = this.getActivePlatform();
    }

    return this.activePlatform.platformID;
  }

  getPlatforms(): Platform[] {
    if (!this.platforms.length) {
      this.setPlatforms();
    }

    return this.platforms;
  }

  getFirstValidPlatform(): Platform {
    if (!this.platforms.length) {
      this.setPlatforms();
    }

    return this.platforms.find(x => x.active) || this.platforms[0];
  }

  getActivePlatform(url = this.router.url, token? : string): Platform {
    if (!this.platforms.length) {
      this.setPlatforms(token);
    }

    const urlPlatformData = url.split('/')[1];
    const platformID = +urlPlatformData.split('-')[0];
    const platformName = decodeURI(urlPlatformData.split('-').slice(1).join('-') || '');
    this.activePlatform = this.platforms.find(x => x.platformID === platformID);
    return this.activePlatform;
  }

  generateActivePlatformUrl(path: string): string {
    if (!this.platforms.length) {
      this.setPlatforms();
    }

    if (!this.activePlatform) {
      this.activePlatform = this.getActivePlatform();
    }

    const platformURL = this.createPlatformUrl(this.activePlatform);
    return `${platformURL}${path.startsWith('/') ? path?.replace(platformURL + "/", "") : '/' + path?.replace(platformURL, "")}`
  }

  createPlatformUrl(platform: Platform): string {
    return `${platform.platformID}-${platform.name}`;
  }

  deletePlatforms() {
    this.platforms = [];
  }

  setActivePlatform(platform: Platform) {
    // this.activePlatform = platform;
  }

  getPlatformPreference(preferenceName : string, defaultValue? : any){
    if(this.activePlatform.userPlatformAttributes){
      let val = this.activePlatform?.userPlatformAttributes[preferenceName];
      if(val != undefined){
        return val;
      }
    }
    return defaultValue;
  }

  private setPlatforms(token? : string) {
    if ((!!this.platforms.length || !this.credentialsService.hasToken()) && !token) {
      return;
    }

    if(token){
      const decodedToken = this.credentialsService.decodeToken(token);
      const { platforms } = decodedToken;
      this.platforms = platforms;
      return;
    }

    this.platforms = this.credentialsService.decodeToken()?.['platforms'] || [];
  }
}
