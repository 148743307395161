import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Equipment } from '@app/@shared/model/equipment.model';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
})

export class EquipmentListComponent {
  @Input() loading: boolean;
  @Input() equipments: Equipment[] = [];
  @Output() editItem = new EventEmitter<Equipment>();
  @Output() deleteItem = new EventEmitter<Equipment>();
}
