import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { LoginContext } from '@app/auth/authentication.service';
import { Observable, tap } from 'rxjs';

export interface InventoryToken {
  Data: {
    Active: boolean;
    Identity: string;
    Name: string;
    Resources: [];
    Rol: any;
    Token: string;
	}
}

const key = 'inventory-user'; 

@Injectable({ providedIn: 'root' })
export class InventoryService {
  constructor(
		private httpClient: HttpClient,
		private credentialsService: CredentialsService,
  ) { }

  pathBelongsToInventory = (path: string) => path.startsWith('http://inventory.koppengis.com') || path.startsWith('https://inventory.koppengis.com');

  login({ user, password }: LoginContext) {
		// const url = 'http://inventory.koppengis.com/inventoryapi/api/login/authenticate';
		
    /*
		return this.httpClient.post<InventoryToken>(url, { UserIdentity: user, UserPassword: password }).pipe(
			tap((x) => this.credentialsService.setUserInventory(key, JSON.stringify(x.Data)))
		);
    */
   this.credentialsService.setUserInventory(key, btoa(JSON.stringify({ UserIdentity: user, UserPassword: password })));
	}

  get userInventory(): string {
    return this.credentialsService.getUserInventory(key);
  }

  removeToken() {
    return this.credentialsService.removeUserInventory(key);
  }
}
