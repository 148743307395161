import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-trace-modal-fiber-list',
  templateUrl: './trace-modal-fiber-list.component.html',
  styleUrls: ['./trace-modal-fiber-list.component.scss']
})
export class TraceModalFiberListComponent implements OnInit {
  @Input() fiber: any[];
  constructor() { }

  ngOnInit(): void {
  }

}
