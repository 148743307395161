import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { D3HorizontalTreeData } from '@app/@shared/model/d3/horizontal-tree.model';
import { Splitter } from '@app/@shared/model/splitter.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';

import { PlatformService } from '@app/auth/platform.service';

@Injectable({
  providedIn: 'root',
})
export class SplitterService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) {}

  getWithFilter(entity?: any): Observable<any> {
    this.main.chooseAPI(Cons._SPLITTER);
    entity.platformID = this.platformService.platformID;
    return this.main.getWithFilter('Splitter', entity).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.containerID;
            x.entityName = Cons._SPLITTER;
            return <Splitter>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._SPLITTER);
    return this.main.getByID('Splitter', id).pipe(
      map((res) => {
        try {
          return res.map((x) => {
            x.commonID = x.containerID;
            x.entityName = Cons._SPLITTER;
            return <Splitter>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getSplitterBox(deviceID: number): Observable<D3HorizontalTreeData[]> {
    const platformID = this.platformService.platformID;
    this.main.chooseAPI(Cons._SPLITTER);

    return this.main.get('SPLITTERBOX', `${platformID}/${deviceID}`).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
