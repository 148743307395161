import { Component, OnInit } from '@angular/core';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { SplitterTypeService } from '@app/@shared/services/splitter-type.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import notify from 'devextreme/ui/notify';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-splitter-type',
  templateUrl: './splitter-type.component.html',
  styleUrls: ['./splitter-type.component.scss']
})
export class SplitterTypeComponent implements OnInit {




  loading: boolean = false;
  dataSource: SplitterType[] ;
  dataSourceGrid: any [];
  elementEditableData: { label, field }[] = [];
  isPopupVisible: boolean = false;
  modalTitle: string;
  loadingModal: boolean;

  popups = {
    confirmDelete: false,
    createOrEdit: false,
  };
  selectedSplitterType: SplitterType;

  constructor(
    private splitterTypeService: SplitterTypeService,
    private translateService: TranslateService, 
    private platformService: PlatformService
    ){ 

    this.dataSourceGrid = [
    ]
   }
   ngOnInit(): void {
    
    this.reload();
  }
  async reload() {
    this.loadingModal=true;
    this.splitterTypeService.getElementsTypeWithFilter().subscribe(
      (value) => {
        this.dataSource = value;
        this.loadingModal=false;
      }
    );
  }

  closeModal() {
    this.popups.createOrEdit = false;
    this.selectedSplitterType = null;
  }
  openModalAddSplitterType() {
    const platformID: number = this.platformService.platformID;
    this.modalTitle = this.translateService.instant('element.add-element');
    this.selectedSplitterType = plainToClass(SplitterType, { platformID });
    this.popups.createOrEdit = true;
  }
  async deleteSplitterType(splitterType: SplitterType) {
    console.log("borrar ");
    this.popups.confirmDelete = false;

    try {
      await firstValueFrom(this.splitterTypeService.delete(splitterType.splitterTypeID));
      await this.reload();
    } catch {
      const errorMessage = this.translateService.instant('element-type.delete-error');
      notify(errorMessage, 'error', 4500);
    } finally {
      this.selectedSplitterType = undefined;
    }
    
  }
  openModalEdit(splitterType: SplitterType) {
    this.modalTitle = this.translateService.instant('splitter.edit-element');
    this.modalTitle += ` - #${splitterType.splitterTypeID}`;
    this.loadingModal = true;
    this.splitterTypeService.getSplitterTypeById(splitterType.splitterTypeID).subscribe(
      (valor) => {
        this.selectedSplitterType = valor;
        this.loadingModal = false;
        this.popups.createOrEdit = true;
      }
    )
  }
  saveSplitterType(splitterType:SplitterType){
    this.loadingModal = true;
    try {
      if (this.selectedSplitterType?.splitterTypeID) {
        this.splitterTypeService.update(splitterType).subscribe(
          (splitterType) => {
            this.modifyObjectById(splitterType);
            this.closeModal();
            this.loadingModal = false;
          }
        )
      } else {
        this.splitterTypeService.create(splitterType).subscribe(
          (elementType)=>{
            this.dataSource = [elementType, ...this.dataSource];
            this.loadingModal = false;
          }
        )
      }
    } catch(ex) {
      const errorMessage = this.translateService.instant('element-type.update-error');
      notify(errorMessage, 'error', 4500);
    } finally {
      this.loadingModal = false;
      this.popups.createOrEdit = false;
      this.selectedSplitterType = undefined;
    }

  }
  modifyObjectById(newInformation: Partial<SplitterType>) {
    const index = this.dataSource.findIndex((obj) => {return obj.splitterTypeID === newInformation.splitterTypeID});
    if (index !== -1) {
      const updatedObject = { ...this.dataSource[index], ...newInformation };
      this.dataSource[index] = updatedObject;
      // console.log('Objeto actualizado:', updatedObject);
    } else {
      // console.log('No se encontró un objeto con el ID especificado');
    }
  }
    
}
