import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { Container } from '@app/@shared/model/container.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';
import { IQueryViewConfiguration } from '@app/@shared/model/interface/iQuery-view-configuration.model';
import { StaticReportContainer, StaticReportContainerSpec } from '@app/@shared/model/aux-models/staticReportContainer.model';
import { LocationData } from '@app/@shared/model/aux-models/locationData';

@Injectable()
export class ContainerService {
  constructor(private main: MainService) {}

  getWithFilter(entity?: any, limit? : number, offset?: number, clause? : string): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);

    return this.main.getWithFilter(Cons._CONTAINER, entity, true, limit, offset, clause).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.containerID;
            x.entityName = Cons._CONTAINER;
            return <Container>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  queryView(entity: any, xyCoordinates?: any[], locationCriteria?, extraParams?: Object): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);

    const queryViewConfiguration: IQueryViewConfiguration = {
      xyCoordinates,
      locationCriteria,
      extraParams
    };
    
    return this.main.queryView(Cons._CONTAINER, entity, queryViewConfiguration)
    .pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.containerID;
            x.entityName = Cons._CONTAINER;
            return <Container>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);

    return this.main.getByID(Cons._CONTAINER, id).pipe(
      map((res) => {
        try {
          res.commonID = res.containerID;
          res.entityName = Cons._CONTAINER;
          return <Container>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByMslink(id: any): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);
    return this.main.getByID('Container/ByMslink', id).pipe(
      map((res) => {
        try {
          res.commonID = res.deviceID;
          res.entityName = Cons._DEVICE;
          return <Container>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getFixedDuct(platformID : number, name: string): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);
    return this.main.get(Cons._CONTAINER, `FixedDuct/${platformID}/${name}`).pipe(
      map((res) => {
        try {
            const container: StaticReportContainer = <StaticReportContainer>res.responseData;

            // Parse JSON string in specData to a JSON object
            container.reportAttributes.forEach((attribute: StaticReportContainerSpec) => {
              if(attribute.specData){
                attribute.specAttributes = JSON.parse(attribute.specData);
              }
              attribute.locationData = new LocationData(Cons._WKT, attribute.wktLocation, parseInt(Cons.SRIDS.S4326), parseInt(Cons.SRIDS.S4326));
              attribute.commonID = attribute.containerID;
              attribute.entityName = Cons._CONTAINER;
            });
    
            return container;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  
  update(container: Container): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);
    return this.main.put(Cons._CONTAINER, undefined, container).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
  create(container: Container): Observable<any> {
    this.main.chooseAPI(Cons._CONTAINER);
    return this.main.post(Cons._CONTAINER, undefined, container).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
