import { Component, OnInit } from '@angular/core';
import { UserFile } from '@app/@shared/model/userFile.model';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { OverlappedRingsService } from '@app/@shared/services/overlapped-rings.service';
import { AppRoutingService } from '@app/app-routing.service';
import { CredentialsService } from '@app/auth';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'overlapping-rings',
  templateUrl: './overlapping-rings.component.html',
  styleUrls: ['./overlapping-rings.component.scss']
})
export class OverlappingRingsComponent implements OnInit {
  welcomeMessage = '';
  showAlertPopupGenerateReport: boolean = false;
  loading: boolean = false;
  overlappedRingsFile: UserFile;

  
  formattedDate = '';
  pieRatio : { metric, value }[];

  constructor(
    private trans: TranslateService,
    private credentialsService: CredentialsService,
    private overlappedRingsService: OverlappedRingsService,
    private windowService: WindowService
  ) {
    let date = new Date();
    date.setDate(date.getDate() - 1);

  }

  ngOnInit(): void {
    const name = this.credentialsService.decodeToken()?.['name'];
    this.welcomeMessage = this.trans.instant('general.hello') + ' ' + name + '!';

    this.loadReport();
  }

  async generateNewReport() {
    this.showAlertPopupGenerateReport = false;
    this.loading = true;
    this.overlappedRingsService.generateReport().subscribe({
      next: (res) => {
        let date;
        if(res){
          this.loadReport();
        }
      },
      error: (err) => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  loadReport() {
    this.loading = true;
    this.overlappedRingsService.downloadReport().subscribe({
        next: (res) => {
          let date;
          if(res){
            this.overlappedRingsFile = res;
             date = this.formatDate(this.overlappedRingsFile.creationDate);

             this.pieRatio = [
              { metric: "Total services", value: res.userFileAttributes?.totalServices },
              { metric: "Affected services", value: res.userFileAttributes?.affectedServices }];
          }
          
          this.formattedDate =  this.trans.instant('general.last-update') + ': ' + (date ? date : 'no data');
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  downloadReport() {
    if (this.overlappedRingsFile?.filePath) {
      this.windowService.redirectTo({
        path: this.overlappedRingsFile?.filePath,
        newTab: false,
        external: true,
      });
    } else {
      notify(
        this.trans.instant(
          'overlapped-rings.home.btn.download-report.error-report-not-found'
        ),
        'error',
        4500
      );
    }
  }


  formatDate(date) {
    const options : Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
    };
  
    return new Intl.DateTimeFormat('es-AR', options).format(new Date(date));
  }
}

