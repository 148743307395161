import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HorizontalTreeComponent } from '@app/@shared/components/d3/horizontal-tree/horizontal-tree.component';
import { D3HorizontalTreeData } from '@app/@shared/model/d3/horizontal-tree.model';
import { Device } from '@app/@shared/model/device.model';
import { D3GraphHelperService } from '@app/@shared/services/d3/download-d3-graph.service';
import { SplitterService } from '@app/@shared/services/map-elements-services/splitter.service';

interface SplitterBox {
  fileName: string;
  graph: D3HorizontalTreeData,
  rendered: boolean,
}

@Component({
  selector: 'splitter-box',
  templateUrl: './splitter-box.component.html',
  styleUrls: ['./splitter-box.component.scss'],
})

export class SplitterBoxComponent implements OnInit {
  
  @ViewChildren(HorizontalTreeComponent) horizontalTreeComponents: QueryList<HorizontalTreeComponent>;

  /*
    This component can be rendered via URL, in that case we'll get the data from the resolvers inside the activatedRoute.
    But if this component is rendered inside HTML using the <splitter-box> selector, we need to pass this Input to supply the missing information.
  */
  @Input() device: Device;
  @Output() componentReady = new EventEmitter<void>();

  svgIcons = new Map<string, string>();

  deviceID: number;
  splitterBoxes: SplitterBox[] = [];
  fileName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private d3GraphHelperService: D3GraphHelperService,
    private splitterService: SplitterService,
  ) { }

  async ngOnInit() {
    await this.loadSVGIcons();

    this.deviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));

    if (!this.deviceID) {
      // If this component is rendered via HTML (not via url), we'll get a splitter via Input()
      this.deviceID = this.device.deviceID;
    }

    this.activatedRoute.data.subscribe(({ device, splitterBox }: { device: Device, splitterBox: D3HorizontalTreeData[] }) => {
      if (device && splitterBox) {
        this.device = device;
        this.splitterBoxes = this.generateSplitterBoxes(splitterBox);
      } else {
        // If this component is rendered via HTML (not via url), we don't get this data from the Resolver.
        this.getSplitterBox();
      }

      this.fileName = `${this.device.name} - Splitters`;
    });
  }

  getSplitterBox() {
    this.splitterService.getSplitterBox(this.deviceID).subscribe(x => this.splitterBoxes = this.generateSplitterBoxes(x));
  }

  async loadSVGIcons() {
    const iconsToImport = [
      'buffer',
      'fibra',
      'splitter_icon'
    ];

    this.svgIcons = await this.d3GraphHelperService.loadSVGIcons(iconsToImport);
  }

  generateSplitterBoxes(graphs: D3HorizontalTreeData[]) {
    const prefix: string = this.device.name;
    const suffix: string = '- Splitters';
    return graphs.map((x, i) => ({ fileName: graphs.length > 1 ? `${prefix}-${i+1}${suffix}` : `${prefix}${suffix}`, rendered: false, graph: x }));
  }

  graphReady(splitterBox: SplitterBox) {
    splitterBox.rendered = true;

    if (this.splitterBoxes.every(x => x.rendered)) {
      this.componentReady.emit();
    }
  }

  async downloadGraph() {
    const promises: Promise<void>[] = this.horizontalTreeComponents?.map(x => x?.downloadD3Graph());
    return Promise.all(promises);
  }
}
