import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-plot-saved-successfully-modal',
  templateUrl: './plot-saved-successfully-modal.component.html',
  styleUrls: ['./plot-saved-successfully-modal.component.scss'],
})
export class PlotSavedSuccessfullyModalComponent {
  @Output() close = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  constructor() {}
}
