import { Component, Input } from '@angular/core';
import { EquipmentPort } from '@app/@shared/model/equipmentPort.model';

@Component({
  selector: 'app-port-details-modal',
  templateUrl: './port-details-modal.component.html',
  styleUrls: ['./port-details-modal.component.scss'],
})

export class PortDetailsModalComponent {
  @Input() port: EquipmentPort;
}
