import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { LocationData } from './aux-models/locationData';
import { Platform } from './platform.model';
import { ElementType } from './elementType.model';
import { ICommonEntity } from './interface/iCommonEntity';
import { Equipment } from './equipment.model';

export class Infrastructure implements ICommonEntity {
  _SCALE: number = 0.24;
  infrastructureID: number;
  platformID: Platform;
  elementTypeID: number;
  locationData: LocationData;
  name: string;
  description: string;
  infrastructureAttributes: any;
  __modified: boolean;

  @Type(()=> Equipment)
  equipment?: Equipment[];

  @Type(()=> ElementType)
  elementType: ElementType;
  modelType: ElementType;

  get commonID() {
    return this.infrastructureID;
  }

  get entityName() {
    return Cons._INFRASTRUCTURE;
  }
}
