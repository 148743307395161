import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { LocationData } from './aux-models/locationData';
import { Connection } from './connection.model';
import { Container } from './container.model';
import { ElementType } from './elementType.model';
import { ICommonEntity } from './interface/iCommonEntity';
import { Splitter } from './splitter.model';

export class Device implements ICommonEntity{
  deviceID: number;
  platformID: number;
  elementTypeID: number;
  containerID: number;
  @Type(()=> LocationData)
  locationData: LocationData;
  name: string;
  description: string;
  deviceAttributes: any;
  portsDataAttributes: any;

  nfcTag: string;

  @Type(()=> ElementType)
  elementType: ElementType;
  modelType: ElementType;
  @Type(()=> Container)
  container: Container;
  @Type(() => Splitter)
  splitter: Splitter[] = [];

  connection: Connection[];

  get commonID() {
    return this.deviceID;
  }

  get entityName() {
    return Cons._DEVICE;
  }
}
