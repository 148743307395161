
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ElementType } from '@app/@shared/model/elementType.model';
import { ElementTypeService } from '../../services/element-type.service';
import { PlatformService } from '@app/auth/platform.service';

@Component({
  selector: 'common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
})

export class CommonFilterComponent {

  @Input() showEmail = false;
  @Input() showDescription = true;
  @Input() showElementType = true;
  @Input() entityType = '';
  @Input() entityTypeModels : any[];
  @Output() onSearchPerformed = new EventEmitter<any>();


  constructor(){
    
  }

  /*
  ngOnInit(){
   
  }
*/
  entity : any = { name: '', email: '', description: '', elementTypeID: 0 };

  search(){
    this.onSearchPerformed.emit(this.entity);
  }
}