<!-- <div class="homepage-container pt-4 px-5">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <label class="main-secondary-text">{{ 'equipment-type.title' | translate }}</label>
    </div>
  <dx-data-grid
  [dataSource]="dataSource"
  [showBorders]="true"
  [showRowLines]="true"
  >
  <dxo-paging [pageSize]="10"> </dxo-paging>
    
  <dxo-pager
    [visible]="true"
    [displayMode]="'full'"
    [showPageSizeSelector]="true"
    [showInfo]="false"
    [showNavigationButtons]="true"
  >
  </dxo-pager>
  
  <dxi-column [caption]="'equipment-type.grid-header.id' | translate" dataField="equipmentTypeID" ></dxi-column>
  <dxi-column [caption]="'equipment-type.grid-header.name' | translate" dataField="name"></dxi-column>
  <dxi-column [caption]="'equipment-type.grid-header.model' | translate" dataField="model"></dxi-column>
  <dxi-column [caption]="'equipment-type.grid-header.spec' | translate" dataField="spec"></dxi-column>
  
  <dxi-column width="75" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>
  
  
  <div *dxTemplate="let d of 'actionsCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <i 
        class="mx-2 fas fa-sharp fa-solid fa-pen clickable"
        placement="bottom" [ngbTooltip]= "'equipment-type.edit-equipment' | translate "container="body"
        (click)="openPopup()"
      ></i>
        <i 
          class="mx-2 fas  fa-solid fa-trash"
          placement="bottom" [ngbTooltip]="'equipment-type.delete-equipment' | translate" container="body"
        ></i>
      </div>
    </div> 
  </div>
  </dx-data-grid>
  
  <dx-popup [(visible)]="isPopupVisible" [closeOnOutsideClick]="true">
  <label class="main-secondary-text">{{ 'equipment.edit-equipment' | translate }}</label>
  <div fxLayout="column" fxLayoutAlign="space-between none">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="flex flex-wrap justify-content-between">
        <dx-text-box fxFlex="49" class="my-3" *ngFor="let equipmentEditableField of equipmentEditableData"
          height="45px"
          [(value)]="equipmentEditableField.field"
          labelMode="floating"
          [label]="equipmentEditableField.label | translate"
        >
        </dx-text-box>
      </div>
    </div>
  </div>
  <label class="main-secondary-text">SpecData</label>
  <dx-data-grid
  [dataSource]="dataSourceGrid"
  [showBorders]="true"
  [showRowLines]="true">
    <dxi-column [caption]="'equipment-type.grid-header.name' | translate" dataField="NAME"></dxi-column>
    <dxi-column [caption]="'equipment-type.grid-header.number' | translate" dataField="Number"></dxi-column>
    <dxi-column [caption]="'equipment-type.grid-header.description' | translate" dataField="Description"></dxi-column>
  </dx-data-grid>
   
  <div fxLayout="row" fxLayoutAlign="end center" class="my-4 pe-2">
    <dx-button
    [text]="'general.cancel' | translate"
    (click)="closePopup()"
  >
  </dx-button>
  <dx-button
    [text]="'general.save' | translate"
  >
  </dx-button>
  </div>  
  </dx-popup>   -->


  <app-simple-crud
    [gridTitle]="'equipment-type.title' | translate"
    [modalTitle]="modalTitle"
    [addButtonTitle]="'equipment-type.add-equipment' | translate"
    [deleteButtonTooltip]="'equipment-type.delete-equipment' | translate"
    [deleteWarningMessage]="'splitter.delete-element-warning' | translate"
    [selectedItem]="selectedEquipmentType"
    [loadingModal]="loadingModal"
    [(showDeletePopup)]="popups.confirmDelete"
    [showCreateOrEditPopup]="this.popups.createOrEdit"
    (showCreateOrEditPopupChange)="closeModal()"
    (addNewItem)="openModalAddEquipmentType()"
    (deleteItem)="deleteEquipmentType($event)" 
    >

    <app-equipment-type-list grid
    [loading]="loading"
    [EquipmentTypes]="dataSource"
    (editItem)="openModalEdit($event)"
    (deleteItem)="deleteEquipmentType($event)">
    </app-equipment-type-list>

    <div create-edit-modal>
        <app-equipment-type-modal
        *ngIf="selectedEquipmentType"
        [selectedEquipmentType]="selectedEquipmentType"
        (cancel)="closeModal()"
        (save)="saveEquipmentType($event)"
        >
        </app-equipment-type-modal>
    </div>
</app-simple-crud>