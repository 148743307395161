import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeasibilityService } from '@app/@shared/services/feasibility.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { Client } from '../../../@shared/model/client.model';
import { FeasibilityStudy } from '@app/@shared/model/feasibilityStudy.model';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { Cons } from '@app/@shared/cons/cons';
import { DxDataGridComponent, DxPieChartComponent } from 'devextreme-angular';

@Component({
  selector: 'app-feasibility',
  templateUrl: './feasibility-home.component.html',
  styleUrls: ['./feasibility-home.component.scss'],
})
export class FeasibilityHomeComponent implements OnInit {
  
  clientDetailsTitle: string;
  loading = false;
  loadingModal = false;

  popups = {
    confirmDeleteClientAction: false,
    addOrEditClient: false,
  };

  studies: FeasibilityStudy[] = [];
  feasibilityRatio : { stateID, state, value }[];
  selectedEntity: FeasibilityStudy;
  location: LocationData;
  placeName : string = '';

  @ViewChild("feasibilityRatioChart") feasibilityRatioChart : DxPieChartComponent;
  @ViewChild("datagrid") datagrid : DxDataGridComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private feasibilityService: FeasibilityService,
    private trans: TranslateService,
  ) {
    this.feasibilityRatio = [
    { stateID: Cons.STATE_CODES._FEASIBLE, state: this.trans.instant('feasibility.states.feasible'), value: 0 },
    { stateID: Cons.STATE_CODES._NOT_FEASIBLE, state: this.trans.instant('feasibility.states.not-feasible'), value: 0 },
    { stateID: Cons.STATE_CODES._RESERVED, state: this.trans.instant('feasibility.states.reseved'), value: 0 },
    { stateID: Cons.STATE_CODES._ACTIVE, state: this.trans.instant('feasibility.states.active'), value: 0 }];
  }

  ngOnInit(): void {
    this.loadStudies();
  }

  loadStudies(){
    setTimeout(()=> {
      this.loadingModal = true;
    }, 800);

    this.feasibilityService.getFeasibilityWithFilter().subscribe({
      next: (res)=> {
        this.studies = res.sort((a,b) => { return b.feasibilityStudyID - a. feasibilityStudyID; });
        this.loadFeasibilityRatio();
      },
      error: (err)=>{
        setTimeout(()=> {
          this.loadingModal = false;
        }, 800);
      },
      complete: () => {
        setTimeout(()=> {
          this.loadingModal = false;
        }, 800);
      }
    });
  }

  
  addressChanged($event){
    this.location = $event.location;
    this.placeName = $event.placeName;
  }
  
  save(client: Client){
    this.feasibilityService.saveClient(client.clientID, client).subscribe({
      next: (res)=> {
        if(res){
          this.studies.filter(x=> x.clientID == res.clientID)
          .forEach(x=> {
            x.client = res;
            (<any>x).clientName = res.name;
          });
          this.datagrid.instance.refresh();
        }
      },
      error: (err)=>{
      },
      complete: () => {
        this.selectedEntity = undefined;
      }
    });
  }


  async checkFeasibilityAndSaveStudy(feasibilityStudy: FeasibilityStudy): Promise<FeasibilityStudy> {
    try {
      let retval = await firstValueFrom(this.feasibilityService.checkFeasibility(feasibilityStudy));
      this.studies.push(retval);
      this.loadFeasibilityRatio();
      return retval;
    } catch (ex) {
        return new Promise<FeasibilityStudy>(()=> {});
    } finally {
      this.popups.addOrEditClient = false;
      this.selectedEntity = plainToClass(FeasibilityStudy, {});
    }
  }

  async allocateFeasibility(feasibilityStudy: FeasibilityStudy): Promise<FeasibilityStudy> {
    this.loadingModal = true;
    try {
      let retval = await firstValueFrom(this.feasibilityService.allocateFeasibility(feasibilityStudy));
      let index = this.studies.findIndex(x=> x.feasibilityStudyID == feasibilityStudy.feasibilityStudyID);
      if(index > -1 && retval){
        this.studies[index] = retval;
      }
      this.loadFeasibilityRatio();
      return retval;
    } catch (ex) {
        return new Promise<FeasibilityStudy>(()=> {});
    } finally {
      this.loadingModal = false;
      this.popups.addOrEditClient = false;
      this.selectedEntity = plainToClass(FeasibilityStudy, {});
    }
  }

  async updateClient(client: Client) {
    let res = await firstValueFrom(this.feasibilityService.saveClient(client.clientID, client));
  }

  async viewDetails(entity : FeasibilityStudy) {
    this.loadingModal = true;

    try {
      this.selectedEntity = await firstValueFrom(this.feasibilityService.getFeasibilityById(entity.feasibilityStudyID));
    } catch (ex) {

    } finally {
      this.loadingModal = false;
      this.openModal();
    }
  }

  newStudy(){
    this.selectedEntity = FeasibilityStudy.createInstance();
    this.selectedEntity.locationData = this.location;
    this.selectedEntity.placeName = this.placeName;
    this.openModal();
  }

  openModal(){
    this.popups.addOrEditClient = true;
  }

  positionOnMap(entity : FeasibilityStudy) {
    this.loadingModal = true;

    try {
      //this.selectedEntity = await firstValueFrom(this.feasibilityService.getFeasibilityById(entity.feasibilityStudyID));
    } catch (ex) {

    } finally {
      this.loadingModal = false;
    }
  }

  closeClientDetailModal() {
    this.popups.addOrEditClient = false
    this.selectedEntity = undefined;
  }

  // <<<<<<<<<<<< CHART >>>>>>>>>>>>>> //
   loadFeasibilityRatio(){
    if(this.studies){
      this.updateChartStateValue(Cons.STATE_CODES._FEASIBLE);
      this.updateChartStateValue(Cons.STATE_CODES._NOT_FEASIBLE);
      this.updateChartStateValue(Cons.STATE_CODES._RESERVED);
      this.updateChartStateValue(Cons.STATE_CODES._ACTIVE);

      this.feasibilityRatioChart.instance.refresh();
    }
  }

  updateChartStateValue(stateID){
    let count = this.studies.filter(x=> x.stateID == stateID)?.length;
      if(count){
        let index;
        index = this.feasibilityRatio.findIndex(x=> x.stateID == stateID);
        if(index > -1){
          this.feasibilityRatio[index].value = count;
        }
      }
  }

   pointClickHandler(e) {
    this.toggleVisibility(e.target);
  }

  legendClickHandler(e) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

    this.toggleVisibility(item);
  }

  toggleVisibility(item) {
    if (item.isVisible()) {
      item.hide();
    } else {
      item.show();
    }
  }


  
}
