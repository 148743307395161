<app-simple-crud
  [gridTitle]="'olt.title' | translate"
  [modalTitle]="modalTitle"
  [addButtonTitle]="'olt.add' | translate"
  [deleteButtonTooltip]="'olt.delete' | translate"
  [deleteWarningMessage]="'olt.delete-warning' | translate"
  [selectedItem]="selected"
  [loadingModal]="loadingModal"
  [(showDeletePopup)]="popups.confirmDelete"
  [showCreateOrEditPopup]="popups.createOrEdit"
  (showCreateOrEditPopupChange)="closeModal()"
  (addNewItem)="openModalAdd()"
  (deleteItem)="deleteOLT($event)"
>
  <app-equipment-list grid
    [loading]="loading"
    [equipments]="equipments"
    (editItem)="openModalEdit($event)"
    (deleteItem)="openPromptDelete($event)"
  >
  </app-equipment-list>

  <div create-edit-modal>
    <app-create-edit-equipment-modal *ngIf="selected && popups.createOrEdit"
      [selected]="selected"
      [equipmentTypes]="equipmentTypes"
      (cancel)="closeModal()"
      (save)="saveOLT($event)"
    ></app-create-edit-equipment-modal>
  </div>
</app-simple-crud>
