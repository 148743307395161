import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

import * as d3 from 'd3';
import { TreeLayout } from 'd3';

import { D3HorizontalTreeData, LeafData } from '@app/@shared/model/d3/horizontal-tree.model';
import { D3GraphHelperService } from '@app/@shared/services/d3/download-d3-graph.service';

@Component({
  selector: 'app-d3-horizontal-tree',
  templateUrl: './horizontal-tree.component.html',
  styleUrls: ['./horizontal-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HorizontalTreeComponent implements OnChanges, AfterViewInit {

  @ViewChild('svgContainer', {read: ElementRef, static: true}) svgContainerRef!: ElementRef<SVGElement>;

  isRendered = false;
  @Input() data: D3HorizontalTreeData;
  @Input() fileName: string;
  @Input() svgIcons: Map<string, string>;
  @Input() selectableLeafs: boolean = false;

  @Output() componentReady = new EventEmitter<void>();
  @Output() leafSelected = new EventEmitter<any>();
  height: number;

  constructor(
    private d3GraphHelperService: D3GraphHelperService,
  ) { }
  
  
  async ngOnChanges(changes: SimpleChanges) {
    if (!this.isRendered) {
      return;
    }

    if (changes['data'] || changes['svgIcons']) {
      this.createHorizontalTree(this.data);
    }
  }

  async ngAfterViewInit() {
    this.createHorizontalTree(this.data);
    this.isRendered = true;
    this.componentReady.emit();
  }

  createHorizontalTree(horizontalTreeData: D3HorizontalTreeData) {
    d3.select(this.svgContainerRef.nativeElement).selectAll('*').remove();
  
    // Ordenar los datos por el número en `descr`
    function extractNumber(descr: string) {
      const match = descr.match(/^(\d+)/);
      return match ? parseInt(match[1], 10) : Infinity;
    }
  
    function sortNodes(node) {
      if (!node.children) return;
  
      node.children.sort((a, b) => extractNumber(a.data.descr) - extractNumber(b.data.descr));
      node.children.forEach(sortNodes);
    }
  
    const root = d3.hierarchy(horizontalTreeData, (d) => d.children);
    sortNodes(root);
  
    // set the dimensions and margins of the diagram
    const margin = { top: 80, right: 90, bottom: 80, left: 100 };
    const width = window.innerWidth; 
    this.height = 800;
    const increasedHeight = horizontalTreeData.children?.length > 38;
  
    if (increasedHeight && horizontalTreeData.children?.length <= 50) {
      this.height = this.height * 2;
    }
  
    if (increasedHeight && horizontalTreeData.children?.length <= 96) {
      this.height = this.height * 3;
    } else if (horizontalTreeData.children?.length > 96) {
      this.height = this.height * 4;
    }
  
    const treemap: TreeLayout<D3HorizontalTreeData> = d3.tree<D3HorizontalTreeData>().size([this.height, 800]);
    const nodes = treemap(root);
  
    const svg = d3.select(this.svgContainerRef.nativeElement)
      .attr('width', width)
      .attr('height', this.height + margin.top + margin.bottom);
  
    const g = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  
    // adds the links between the nodes
    let pNode = undefined;
    g.selectAll('.link')
      .data(nodes.descendants().slice(1))
      .enter()
      .append('path')
      .attr('class', 'link')
      .style('stroke', (d) => d.data.color)
      .attr('d', function (d) {
        // Fix factor (some lines are closer and distance must be forced)
        if (pNode && (d.x - pNode.x) < 14 && d.y == pNode.y) {
          d.x += (14 - (d.x - pNode.x));
        }
  
        // Fix factor for children: If a fix factor was applied to the parent (depth == 1), child value must be fixed as well
        if (d.depth == 2 && d.parent.x != d.x) {
          d.x = d.parent.x;
        }
  
        pNode = { x: d.x, y: d.y };
        return 'M' + d.y + ',' + d.x
          + 'C' + (d.y + d.parent.y) / 2 + ',' + d.x
          + ' ' + (d.y + d.parent.y) / 2 + ',' + d.parent.x
          + ' ' + d.parent.y + ',' + d.parent.x;
      });
  
    // adds each node as a group
    const node = g.selectAll('.node')
      .data(nodes.descendants())
      .enter().append('g')
      .attr('class', (d) => {
        let className = d.children ? ' node--internal' : ' node--leaf';
  
        if (this.selectableLeafs) {
          className = className + ' node--leaf--clickable'
        }
  
        return 'node' + className;
      })
      .attr('transform', function (d) {
        return 'translate(' + d.y + ',' + d.x + ')';
      }
    );
  
    node
      .filter('.node--leaf--clickable')
      .on('click', (d) => {
        const selectedNode: Partial<LeafData> = d?.srcElement?.__data__?.data;
        this.leafSelected.emit(selectedNode);
      });
  
    // adds images as nodes
    node.append('image')
      .attr('href', (d) => {
  
        if (!d.data.icon) {
          return '';
        }
  
        const iconNameWithExtension = d.data.icon;
        const splittedName = iconNameWithExtension.split('.');
  
        const iconNameWithoutExtension: string = splittedName?.length === 1 ?
          splittedName[0] :
          splittedName.slice(0, -1).join('.');
  
        // We need to return a base64SVGIcon if we want to display that icon when we download the file.
        return this.svgIcons.get(iconNameWithoutExtension);
      })
      .attr('x', '-12px')
      .attr('y', '-12px')
      .attr('width', '24px')
      .attr('height', '24px');
  
    // Adds the text to the node
    node.append('text')
      .attr('class', 'descr-text')
      .attr('dy', (d) => {
        if (d.data.descr) {
          return '-0.5em';
        } else {
          return d.parent == null ? '2.5em' : '.35em';
        }
      })
      .attr('font-size', (d) => d.depth == 1 ? '12px' : '14px')
      .style('fill', 'black')
      .attr('x', (d) => d.depth == 1 ? 0 : 25)
      .attr('text-anchor', 'start')
      .text((d) => d.data.leaf || d.data.name || d.data.descr);
  };  



  // This function is executed by accessing to this component via ViewChild/ViewChildren. Don't delete.
  // Se ejecuta esta funcion mediante ViewChild/ViewChildren. No borrar.
  downloadD3Graph(): Promise<void> {
    if (!this.svgContainerRef?.nativeElement) {
      return Promise.reject(new Error('svgContainerRef not found'));
    }

    return this.d3GraphHelperService.downloadHorizontalTree({
      svg: this.svgContainerRef.nativeElement,
      fileName: this.fileName,
      extension: 'jpg', 
      height: this.height
    });
  }
}
