import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InfrastructureResolver implements Resolve<Infrastructure> {
  constructor(
    private infrastructureService: InfrastructureService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Infrastructure> {
    const tokenFromActivatedRoute: string = route.queryParamMap.get('token');
    if(tokenFromActivatedRoute){
      return Promise.resolve( new Infrastructure());
    }

    const infrastructureID = Number(route.params['infrastructureID']);
    return firstValueFrom(this.infrastructureService.getByID(infrastructureID));
  }
}
