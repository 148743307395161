<dx-tab-panel class="dx-theme-background-color mb-1" width="100%" >
    <dxi-item [title]="'feasibility.allocation.general-info' | translate">
        <div fxLayout="row" >
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.operator' | translate"></h4>
                <div>{{ entity?.operatorID}} - {{ entity?.invokerSystem?.operator?.name}}</div>
            </div> 
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.invoker-system' | translate"></h4>
                <div>{{ entity?.invokerSystemCode }} - {{ entity?.invokerSystem.name }}</div>
            </div>
            <div fxFlex="30">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.access-id' | translate"></h4>
                <div>[#]{{ entity?.feasibilityStudyID }}</div>
            </div>
        </div>
        <div fxLayout="row"  class="mt-3">
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.details.verification-date' | translate"></h4>
                <div>{{ entity?.verificationDate |  date: 'dd/MM/yyyy HH:mm' }}</div>
            </div>
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.details.verification-result' | translate"></h4>
                <div>{{ entity?.verificationResult }}</div>
            </div>
            <div fxFlex="30" *ngIf="entity?.state?.orderBy > 1">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.date' | translate"></h4>
                <div>{{ entity?.allocationDate |  date: 'dd/MM/yyyy HH:mm' }}</div>
            </div>
        </div>
    </dxi-item>
</dx-tab-panel>

<dx-tab-panel class="dx-theme-background-color mb-2" width="100%"  >
    <dxi-item [title]="'CTO'">
        <div fxLayout="row">
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.device-name' | translate"></h4>
                <div>{{ entity?.device?.name}}</div>
            </div>
            <div fxFlex="35" >
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.device-nfc-tag' | translate"></h4>
                <div>[#]{{ entity?.device?.nfcTag }}</div>
            </div>
            <div fxFlex="30">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.device-port' | translate"></h4>
                <div>{{ devicePort }}</div>
            </div>
        </div>
    </dxi-item>
</dx-tab-panel>

<dx-tab-panel class="dx-theme-background-color mb-2" width="100%" *ngIf="entity?.state?.orderBy > 1">
    <dxi-item [title]="'OLT'">
        <div fxLayout="row">
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.equipment-type' | translate"></h4>
                <div>{{ entity?.portResource?.equipmentPort?.equipment?.equipmentType?.name }}</div>
            </div>
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.equipment' | translate"></h4>
                <div>{{ entity?.portResource?.equipmentPort?.equipment?.name }}</div>
            </div>
            <div fxFlex="30">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.equipment-ip' | translate"></h4>
                <div>{{ entity?.portResource?.equipmentPort?.equipment?.equipmentAttributes?.IP }}</div>
            </div>
        </div>
        <div fxLayout="row" class="mt-3">
            <div fxFlex="35">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.equipment-port' | translate"></h4>
                <div>{{ entity?.portResource?.equipmentPort?.number }} ({{ entity?.portResource?.equipmentPort?.name }})</div>
            </div>
            <div fxFlex="65">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.resource-objectID' | translate"></h4>
                <div>{{ entity?.portResource?.equipmentPort?.equipment?.name }}:{{ entity?.portResource?.equipmentPort?.equipment?.equipmentTypeID }}-
                    {{ entity?.portResource?.equipmentPort?.slotNumber }}-{{ entity?.portResource?.equipmentPort?.number }}-{{ entity?.portResource?.number }}
                </div>
            </div>
        </div>
    </dxi-item>
</dx-tab-panel>

<dx-tab-panel class="dx-theme-background-color mb-2" width="100%" *ngIf="entity?.state?.orderBy > 1">
    <dxi-item [title]="'VLAN'">
        <div fxLayout="row">
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-opcvlan' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.opCVLAN }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-opsvlan' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.opSVLAN }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-opiptv' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.opIPTv }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-opVoice' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.opVoice }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-opMulticast' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.opMulticast }}</div>
            </div>
        </div>
        <div fxLayout="row" class="mt-2">
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-prcvlan' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.prCVLAN }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-prsvlan' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.prSVLAN }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-priptv' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.prIPTv }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-prVoice' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.prVoice }}</div>
            </div>
            <div fxFlex="20">
                <h4 class="subTitleCard" [textContent]="'feasibility.allocation.vlan-prMulticast' | translate"></h4>
                <div>{{ entity?.feasibilityStudyAttributes?.prMulticast }}</div>
            </div>
        </div>
    </dxi-item>
</dx-tab-panel>