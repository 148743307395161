import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { Device } from '@app/@shared/model/device.model';
import { ElementType } from '@app/@shared/model/elementType.model';
import { Splitter } from '@app/@shared/model/splitter.model';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import DataSource from 'devextreme/data/data_source';
import { ElementTypeService } from '@app/@shared/services/element-type.service';

@Component({
  selector: 'app-create-edit-infrastructure-modal',
  templateUrl: './create-edit-infrastructure-modal.component.html',
  styleUrls: ['./create-edit-infrastructure-modal.component.scss'],
})

export class CreateEditInfrastructureModalComponent implements OnInit {

  labels = {
    model: '',
    infrastructure: '',
    name: '',
  };

  

  infrastructureDataSource: DataSource;
  elementTypeDataSource: DataSource;
  splitters: Splitter[] = [];
  splitterTypes: SplitterType[] = [];
  infrastructure: Infrastructure;
  device: Device;
  ports: any[] = [];

  address: string;

  disableSave: boolean = true;
  @Input() infrastructureList: any[];
  @Input() selectedInfrastructure: Infrastructure;
  @Output() save = new EventEmitter<Infrastructure>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private allDatasources: DataSourceService,
    private translateService: TranslateService,
    private elementTypeService: ElementTypeService
  ) {}

  ngOnInit(): void {
    this.setMandatoryLabels();
    
    const infrastructure: Infrastructure[] = this.allDatasources.allInfrastructures;
    this.infrastructureDataSource = new DataSource({ store: infrastructure });

    this.loadElementTypes();
  }

  removeAttributes(data: any): any[] {
    return data.map(item => {
      const { equipmentPortAttributes, equipmentID, equipmentPortID, fiberID, 
              portIDToShow = item.internalPortID !== 0 ? item.internalPortID : item.fiberID, 
              ...rest } = item;
      return rest;
    });
  }

  async loadElementTypes() {
    this.elementTypeService.getElementsTypeWithFilter().subscribe(
      (valor) => {
        this.elementTypeDataSource = valor;
      }
    );
  }

  setMandatoryLabels() {
    this.labels = {
      model: this.translateService.instant('infrastructure.add-edit.model') + '*',
      infrastructure: this.translateService.instant('infrastructure.add-edit.infrastructure') + '*',
      name: this.translateService.instant('infrastructure.add-edit.name') + '*',
    };
  }

  fullAddressChange(fullAddress : any) {
    this.device.deviceAttributes.address = `${fullAddress.street} ${fullAddress.number}`;
    this.updateDisableSaveButton();
  }
  
  infrastructureChange(event: { selectedItem: Infrastructure }) {
    this.updateDisableSaveButton();
  }

  elementTypeChange(event: { selectedItem: ElementType }) {
    const selectedElementType = event.selectedItem;
    this.selectedInfrastructure.elementTypeID = selectedElementType.elementTypeID;

    this.updateDisableSaveButton();
  }

  nameChange(event: { value: string }) {
    this.selectedInfrastructure.name = event.value;
    this.updateDisableSaveButton();
  }

  updateDisableSaveButton() {
    this.disableSave = !this.selectedInfrastructure.elementTypeID || !this.selectedInfrastructure.infrastructureID || !this.selectedInfrastructure.name || !this.selectedInfrastructure.locationData;
    this.disableSave = !this.selectedInfrastructure.elementTypeID || !this.selectedInfrastructure.infrastructureID || !this.selectedInfrastructure.name;
  }

  saveInfrastructure() {
    this.selectedInfrastructure.__modified = true;
    this.save.emit(this.selectedInfrastructure);
  }
}
