import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';

import { I18nModule } from '@app/i18n';
import { AuthModule } from '@app/auth';
import { ShellComponent } from './shell.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxListModule,
  DxPopoverModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { ClientSelectorComponent } from './platform-selector/platform-selector.component';
import { UserHelpComponent } from '@app/shell/user-help/user-help.component';
import { UserOptionsComponent } from './user-options/user-options.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    NgbModule,
    AuthModule,
    I18nModule,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    DxButtonModule,
    DxAccordionModule,
    DxPopoverModule,
    DxListModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxScrollViewModule,
  ],
  declarations: [
    ShellComponent,
    ClientSelectorComponent,
    UserHelpComponent,
    UserOptionsComponent,
    LoadingScreenComponent,
  ],
  exports: [
    ClientSelectorComponent, 
    UserHelpComponent, 
    UserOptionsComponent,
    LoadingScreenComponent,
  ],
})
export class ShellModule {}
