import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderComponent } from './components/loader/loader.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DxButtonModule, DxLoadPanelModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { SimpleCrudComponent } from './components/simple-crud/simple-crud.component';
import { HorizontalTreeComponent } from './components/d3/horizontal-tree/horizontal-tree.component';
import { CircularHierarchyTreeComponent } from './components/d3/circular-hierarchy-tree/circular-hierarchy-tree.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { CommonFilterModule } from './components/common-filter/common-filter.module';

const components = [
  LoaderComponent, 
  ConfirmationModalComponent,
  SimpleCrudComponent,
  StepperComponent,
  HorizontalTreeComponent,
  CircularHierarchyTreeComponent,
];

@NgModule({
  imports: [TranslateModule, CommonModule, FlexLayoutModule, DxButtonModule, DxPopupModule, DxLoadPanelModule, DxScrollViewModule, CommonFilterModule],
  declarations: components,
  exports: components,
})
export class SharedModule {}
