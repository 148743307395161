import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { plainToInstance } from 'class-transformer';

import { MainService } from './main.service';
import { PlatformService } from '@app/auth/platform.service';

import { SplitterType } from '../model/splitterType.model';
import { Cons } from '../cons/cons';

@Injectable({
  providedIn: 'root'
})
export class SplitterTypeService {

  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) { }

  getElementsTypeWithFilter(entity?: any): Observable<any> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    console.log(this.platformService.platformID);
    
    return this.main.getWithFilter(Cons._SPLITTERTYPE, entity).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  getSplitterTypeById(id: number): Observable<SplitterType> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    
    return this.main.getByID(Cons._SPLITTERTYPE, id).pipe(
      map((res) => {
        try {
          res.commonID = res.containerID;
          res.entityName = Cons._SPLITTERTYPE;
          return plainToInstance(SplitterType, res);
        } catch (ex) {
          return (<any>res)._body.toString();
        }
      })
    );
  }
  update(splitterType: SplitterType): any {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    //TODO: evaluar como quitar la variable busqueda
    const busqueda =`${this.platformService.platformID }/${splitterType.splitterTypeID}`;
    return this.main.put(Cons._SPLITTERTYPE, busqueda,splitterType).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  delete(elementTypeID: number): Observable<unknown> {
    this.main.chooseAPI(Cons._SERVICECONFIG);

    return this.main.delete(Cons._SPLITTERTYPE, elementTypeID).pipe(
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }
  create(elementType: SplitterType): Observable<any> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    return this.main.post(Cons._SPLITTERTYPE, undefined, elementType).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
