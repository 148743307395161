<!-- <p>hola mundo</p> -->
 <div fxLayout="column" fxLayoutAlign="space-between none" class="h-80">
    <div fxLayout="row nowrap">
      <div class="me-2" fxLayout="column nowrap" fxLayoutAlign="space-between none" fxFlex="50">
  
        <dx-text-box
          height="45px"
          [value]="container.name"
          [valueChangeEvent]="'keyup'"
          (onValueChanged)="nameChange($event)"
          labelMode="floating"
          [label]="labels.name"
        >
        </dx-text-box>
          
        <dx-select-box
          height="45px"
          labelMode="floating"
          displayExpr="name" 
          valueExpr="elementTypeID"
          [value]="container.elementTypeID"
          [dataSource]="dataModelElementType"
          [deferRendering]="false"
          [placeholder]="labels.container"
          [searchEnabled]="true"
          [searchExpr]="'name'" 
          [searchMode]="'contains'"
          [searchTimeout]="200" 
          (onSelectionChanged)="containerChange($event)"
        ></dx-select-box>
  
        <dx-text-box
          height="45px"
          [value]="container.description"
          [valueChangeEvent]="'keyup'"
          (onValueChanged)="descriptionChange($event)"
          labelMode="floating"
          [label]="labels.description"
        >
        </dx-text-box>
  
       
      </div>
  
      <div fxLayout="column nowrap" fxFlex="50">
        <div fxLayout="column nowrap" fxFlex="50">

          <ng-container *ngIf="container?.locationData.coordinates.geographyType == 'Point'">
            <address-form
              [required]="true"
              [location]="container?.locationData"
              (fullAddressChange)="fullAddressChange($event)"
              (onAddressChanged)="addressChanged($event)"
            >
            </address-form> 
          </ng-container>
          <ng-container *ngIf="container?.locationData.coordinates.geographyType == 'LineString'">
            <ol-map
              id="mapContainer"
              #olMap
              [latitude]="latitude"
              [longitude]="longitude"
              [zoom]="zoom"
              [linestring]="container?.locationData.coordinates.geographyType"
              (onMapReady)="mapReady()"
            ></ol-map>
          </ng-container>
        </div>
      </div>
    </div>
  
    <div class="me-2 mt-4">
      <dx-button
      class="ms-3 main-background-theme"
      [text]="'container.add-edit.EditDevices' | translate"
      >
      </dx-button>
      <dx-tab-panel>
        <dxi-item [title]="'container.add-edit.devices' | translate" *ngIf="dataModelDevice">
          <dx-scroll-view>
            <div fxLayout="column" class="data-container">
              <dx-data-grid
                [dataSource]="dataModelDevice"
                [showBorders]="true"
                [showRowLines]="true"
              >
                <dxi-column dataType="string" [caption]="'container.add-edit.name' | translate" dataField="name"></dxi-column>
                <dxi-column [caption]="'container.add-edit.model' | translate" dataField="elementType.job_name"></dxi-column>
                <dxi-column dataType="string" [caption]="'container.add-edit.description' | translate" dataField="description"></dxi-column>

              </dx-data-grid>
            </div>
          </dx-scroll-view> 
        </dxi-item>
      </dx-tab-panel>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="end center" class="me-2 mt-4">
      <dx-button
        [text]="'general.cancel' | translate"
        (click)="cancel.emit()"
      >
      </dx-button>
      <dx-button
        [disabled]="disableSave "
        class="ms-3 main-background-theme"
        [text]="'general.save' | translate"
        (click)="saveContainer()"
      >
      </dx-button>
    </div>
  </div>