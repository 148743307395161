import { Component, Input, OnInit } from '@angular/core';
import { Device } from '@app/@shared/model/device.model';
import { FeasibilityStudy } from '@app/@shared/model/feasibilityStudy.model';

@Component({
  selector: 'feasibility-allocation',
  templateUrl: './feasibility-allocation.component.html',
  styleUrls: ['./feasibility-allocation.component.scss']
})
export class FeasibilityAllocationComponent implements OnInit {

  _entity : FeasibilityStudy;
  @Input("entity")
  get entity(){
    return this._entity;
  }
  set entity(value){
    this._entity = value;
    this.devicePort = this.findDevicePortNumber(this._entity.device, this._entity.feasibilityStudyAttributes?.devicePortID);
  }

  constructor() { }

  devicePort : number;
  ngOnInit(): void {
  }

  findDevicePortNumber(d : Partial<Device>, devicePortID : number) : number{
    let portsList : any[] = d?.portsDataAttributes;
    if(!portsList || !portsList.length){
      return 0;
    }
    let port = portsList?.find(x=> x.portID == devicePortID)?.number;

    return port;
  }
 
}
