<div class="card-panel" style="width: 50%; max-width: 500px;">
    <h5><i class="fa fa-search"></i> {{ 'general.search-panel' | translate }}</h5>
    <div class="mb-2 mt-2" fxLayout="row">
      <div [fxFlex]="showElementType ? 50 : 100" class="form-field">
        <dx-text-box 
          labelMode="floating" 
          [showClearButton]="true"
          [label]="'general.name' | translate"  
          [(value)]="entity.name" >
        </dx-text-box>
      </div>
      <div fxFlex="50" *ngIf="showElementType" class="form-field">
        <dx-select-box  labelMode="floating"  
          [deferRendering]="false" [label]="'general.models' | translate" [dataSource]="entityTypeModels"
          [placeholder]="'general.models' | translate" [displayExpr]="'name'" [valueExpr]="'elementTypeID'"
          [searchTimeout]="500" [searchMode]="'contains'" [searchExpr]="'name'" [searchEnabled]="true"
          [showDataBeforeSearch]="true" [(value)]="entity.elementTypeID">
        </dx-select-box>
      </div>
    </div>
    <div class="mb-2" *ngIf="showEmail">
        <dx-text-box 
          labelMode="floating" 
          [showClearButton]="true"
          [label]="'users.grid-header.mail' | translate"  
          [(value)]="entity.email" >
        </dx-text-box>
    </div>
    <div class="mb-2 form-field" *ngIf="showDescription">
      <dx-text-box 
        labelMode="floating" 
        [showClearButton]="true"
        [label]="'general.description' | translate"  
        [(value)]="entity.description" >
      </dx-text-box>
  </div>
    <div style="text-align: right;">
        <dx-button
        class="ms-3 main-background-theme"
        [text]="'general.search' | translate"
        (click)="search()"
        ></dx-button>
    </div>
</div>