<app-simple-crud
[gridTitle]="'container.title' | translate"
[modalTitle]="modalTitle"
[entityType]="entityType"
[entityTypeModels]="entityTypeModels"
[addButtonTitle]="'container.add-device' | translate"
[deleteButtonTooltip]="'container.delete-device' | translate"
[deleteWarningMessage]="'container.delete-device-warning' | translate"
[selectedItem]="selectedContainer"
[loadingModal]="loadingModal"
[(showDeletePopup)]="popups.confirmDelete"
[addButtonNewItem]="false"
[showCreateOrEditPopup]="popups.createOrEdit"
(showCreateOrEditPopupChange)="closeModal()"
(addNewItem)="openModalAddContainer()"
(deleteItem)="deleteContainer($event)"
(onSearchPerformed)="search($event)"
>
    <app-container-list grid
    [loading]="loading"
    [containers]="containers"
    (editItem)="openModalEditContainer($event)"
    (deleteItem)="openPromptDeleteContainer($event)"
>
    </app-container-list>
    <div create-edit-modal>
        <app-create-edit-container-modal *ngIf="selectedContainer"
          [selectedContainer]="selectedContainer"
          (cancel)="closeModal()"
          (save)="saveContainer($event)"
          (deleteItem)="deleteContainer($event)"
        ></app-create-edit-container-modal>
    </div>
</app-simple-crud>