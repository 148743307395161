import { Injectable } from '@angular/core';
import { GalleryImage } from '@app/@shared/model/gallery.model';

const images: GalleryImage[] = [];

@Injectable({
  providedIn: 'root', 
})
export class GalleryService {
  getImages(): GalleryImage [] {
    return images;
  }
}
