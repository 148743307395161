import { ICommonEntity } from "../../interface/iCommonEntity";
import { IOlMapFeature } from "../../interface/iOlMapFeature";

export class olMapPoint extends IOlMapFeature {
  icon: string;
  scale:number;
  clusterFontColor: string;
  clusterFontBackground: string;
  selectable: boolean = true;


  toMapFeature(m: ICommonEntity, type: string, color?: string, background?: string, filePath? : string, selectable? : boolean, scale? : number): olMapPoint {
    let feature = new olMapPoint();
    feature.entity = m;
    feature.id = m.entityName + '_' + m.commonID;
    feature.name = m.name;
    feature.type = type;
    feature.locationData = m.locationData;
    feature.clusterFontColor = color;
    feature.clusterFontBackground = background;
    if(scale){
      feature.scale = scale;
    }
    if (filePath) {
      feature.icon = filePath;
    }

    // Only false if m.selectable is false. If undefined or true -> true
    feature.selectable = selectable !== false;

    return feature;
  }
}
