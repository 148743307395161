import { Type } from 'class-transformer';
import { Cons } from '../cons/cons';
import { LocationData } from './aux-models/locationData';
import { Device } from './device.model';
import { ElementType } from './elementType.model';
import { ICommonEntity } from './interface/iCommonEntity';

export class Container implements ICommonEntity {
  containerID: number;
  platformID: number;
  elementTypeID: number;
  @Type(()=> LocationData)
  locationData: LocationData;
  name: string;
  description: string;
  containerAttributes: any;

  @Type(()=> ElementType)
  elementType: ElementType;
  modelType: ElementType;
  device: Device[];

  get commonID() {
    return this.containerID;
  }

  get entityName() {
    return Cons._CONTAINER;
  }
}
