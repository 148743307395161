import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IRedirectSettings } from '@app/@shared/model/interface/iRedirect-settings';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { TranslateService } from '@ngx-translate/core';
import { InfrastructureODFComponent } from './infrastructure-odf/infrastructure-odf.component';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { Equipment } from '@app/@shared/model/equipment.model';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { Workbook, Worksheet } from 'exceljs';
import { Device } from 'devextreme/core/devices';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as saveAs from 'file-saver';

@Component({
  selector: 'infrastructure-detail',
  templateUrl: './infrastructure-detail.component.html',
  styleUrls: ['./infrastructure-detail.component.scss'],
})
export class InfrastructureDetailComponent {

  @ViewChild(InfrastructureODFComponent, { static: false }) infrastructureODFComponent: InfrastructureODFComponent;

  disableDownloadAndInspectData: boolean = false;
  ds_accordion_element = [];
  accordion_element_selected = [];

  infrastructureToDownload: Infrastructure = undefined;
  downloadingInfrastructureODF: boolean = false;

  ds_accordion_equipment = [];
  ds_accordion_equipment_selected = [];
  selectedEquipment: Equipment;

  _entity: any;
  projectDataDetail: any;
  projectDataDetailDescr2: any;
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;

    this.projectDataDetail = value.infrastructureAttributes;
    this.projectDataDetailDescr2 = value.infrastructureAttributes.descr2;


    this.disableDownloadAndInspectData = !this._entity?.equipment?.length;
    this.loadEntity();
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private trans: TranslateService,
    private windowService: WindowService,
    private excelJSHelperService: ExcelJSHelperService
  ) { }

  loadEntity() {
    //Close search panel
    this.ds_accordion_element = [];

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.loadEquipmentAccordion();
  }

  loadEquipmentAccordion = () => {
    const equipments = this.entity.equipment;
    this.ds_accordion_equipment = [
      {
        title: this.trans.instant('comp.infrastructure-detail.equipment'),
        data: equipments,
      },
    ];
  };

  equipmentSelected = (event) => {
    const equipment: Equipment = event.data;
    this.selectedEquipment = equipment;
  };



  odfView() {
    const redirectSettings: IRedirectSettings = {
      path: `${this.router.url}/infrastructure/${this.entity.infrastructureID}`,
      newTab: true,
      external: false,
    };
    this.windowService.redirectTo(redirectSettings)
  }

  preparingDownload(infrastructure: Infrastructure) {
    this.infrastructureToDownload = infrastructure;
    this.downloadingInfrastructureODF = true;
  }

  async downloadInfrastructureODF() {
    if (!this.infrastructureODFComponent) {
      return;
    }

    this.changeDetector.detectChanges();

    try {
      await this.infrastructureODFComponent.downloadGraph();
    } catch (ex) {
      console.log('ex', ex);
    } finally {
      this.downloadingInfrastructureODF = false;
      this.infrastructureToDownload = undefined;
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      this.initializeExcelHeaders(worksheet);
      this.writeExcelData(worksheet);
      this.excelJSHelperService.adjustColumnsWidth(worksheet);
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          this.entity.name + '_INFRASTRUCTURE.xlsx'
        );
      });
    });
    e.cancel = true;
  }

  initializeExcelHeaders = (worksheet: Worksheet) => {
    const nameLabel = this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase();
    const descriptionLabel = this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase();

    const headers = [
      nameLabel,
      descriptionLabel
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers);
    this.centerTextExcel(worksheet, 1, [1, 2]);

  };

  writeExcelData = (worksheet: Worksheet) => {
    const equipment: Equipment[] = this.ds_accordion_equipment[0].data;

    const excelData: string[][] = equipment.map((x) => {
      const name = x.name;
      const description = x.description;

      return [name, description];
    });

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
    this.centerTextExcel(worksheet, 2, [1, 2], equipment.length);

  }

  centerTextExcel(worksheet: Worksheet, startRow: number, cells: number[], rowCount: number = 1) {
    for (let i = 0; i < rowCount; i++) {
      const row = worksheet.getRow(startRow + i);
      cells.forEach((cell) => {
        row.getCell(cell).alignment = { horizontal: 'center' };
      });
    }
  }

}
