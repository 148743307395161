import { Cons } from "../cons/cons";
import { Container } from "./container.model";
import { Device } from "./device.model";
import { Infrastructure } from "./infrastructure.model";
import { Trace } from "./trace.model";

export interface ServiceAttributes {
  container: Container[];
  device: Device[];
  trace: Trace[];
  infrastructure: Infrastructure[];
}

export class Service {
  get commonID(): any {
    return this.serviceID;
  }
  get entityName(): any {
    return Cons._SERVICE
  }

  serviceID: number;
  name: string;
  description: string;

  serviceAttributes: Partial<ServiceAttributes>;
}
