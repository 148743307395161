import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { TreeLayout } from 'd3';
import { D3HorizontalTreeData, LeafData } from '@app/@shared/model/d3/horizontal-tree.model';
import { D3GraphHelperService } from '@app/@shared/services/d3/download-d3-graph.service';
import { DataTransferService } from '@app/@shared/services/d3/data-transfer.service';
import * as saveAs from 'file-saver';
import notify from 'devextreme/ui/notify';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ServiceService } from '@app/@shared/services/map-elements-services/service.service';
import { Service } from '@app/@shared/model/service.model';
import { TraceService } from '@app/@shared/services/map-elements-services/trace.service';
import { PlatformService } from '@app/auth/platform.service';
import { ITraceabilityDiagram } from '../../../../model/interface/iTraceability-diagram';

@Component({
  selector: 'app-trace-diagram',
  templateUrl: './trace-diagram.component.html',
  styleUrls: ['./trace-diagram.component.scss']
})
export class TraceDiagramComponent implements AfterViewInit, OnInit {

  @ViewChild('svgContainer', { read: ElementRef, static: true }) svgContainerRef!: ElementRef<SVGElement>;
  @ViewChild('graphSelect', { static: true }) graphSelect: ElementRef;

  isRendered = false;

  color = {
    'AM': 'yellow',
    'AZ': 'blue',
    'BL': '#d3d3d3',
    'CE': 'lightblue',
    'GR': 'grey',
    'MA': 'brown',
    'NA': 'orange',
    'NE': 'black',
    'RJ': 'red',
    'RS': 'pink',
    'VE': 'green',
    'VI': 'purple',
  };

  svgIcon: Map<string, string> = new Map([
    ['icon', '../../../../../../../assets/icons/circle.svg']
  ]);

  searchText = '';
  traceData: any;
  height: number;
  containerName: string;
  displayTraceabilityDiagramModal: boolean = false;
  loading: boolean = false;
  traceabilityDiagramData: ITraceabilityDiagram;
  infrastructureID: any;
  platformID: number;
  svgPositions: { top: number; height: number; }[] = [];
  graphs: any[] = [];
  selectedGraphName: string;
  svg: any;
  serviceName: any;
  fiberNameSelected: string;
  traceName: any[] = [];

  searchTerm: string;
  match: HTMLElement[] = [];
  currentMatchIndex: number = -1;

  constructor(private d3GraphHelperService: D3GraphHelperService, private dataTransferService: DataTransferService, private translateService: TranslateService, private serviceService: ServiceService, private traceService: TraceService, private platformService: PlatformService) { }

  ngOnInit(): void {

    this.containerName = this.dataTransferService.getName();
    this.platformID = this.dataTransferService.getPlatformID();


  }


  ngAfterViewInit() {
    this.traceData = this.dataTransferService.getTraceData();
    this.traceData.forEach((trace, index) => {
      if (!this.isValidTraceData(trace)) {
        console.error(`traceData no es válido en el índice ${index}`, trace);
        return;
      }

      this.traceName.push(this.traceData[index].name);
      const transformedData = this.transformToD3Data(trace);
      this.createHorizontalTree(transformedData);
    });

    this.isRendered = true;

    this.graphs.forEach((graph, index) => {
      const svgElement = document.querySelector(`#svg-${index}`);
      if (svgElement) {
        const rect = svgElement.getBoundingClientRect();
        this.svgPositions[index] = {
          top: rect.top,
          height: rect.height
        };
      }
    });
  }

  private isValidTraceData(traceData: any): boolean {
    return traceData && Array.isArray(traceData.fiber);
  }

  //Parent = Trace
  private transformToD3Data(traceData: any): D3HorizontalTreeData {
    return {
      descr: 'Diagrama de Ejemplo',
      icon: 'icon',
      name: traceData.name,
      type: 'tipoRoot',
      color: 'black',
      children: traceData.fiber.map((fiber: any) => this.transformFiberToNode(fiber, traceData.infrastructureID)) //Pass fiber to child
    };
  }

  // Child = Fiber
  private transformFiberToNode(fiber: any, infrastructureID: number) {
    const fiberName = this.extractTextBetweenBrackets(fiber.name);
    const bufferColor = this.color[fiber.fiberAttributes.buffer] || 'black';
    const fiberColor = this.color[fiber.fiberAttributes.color] || 'black';
    const fiberOrder = fiber.fiberOrder;
    const traceInfrastructureID = infrastructureID;

    return {
      descr: fiberOrder + fiberName,
      icon: 'icon',
      name: fiberOrder + fiberName,
      type: 'fiberType',
      color: bufferColor,
      leaf: '',
      parentElement: null,
      children: this.transformServiceTraces(fiber.fiberID, fiber.serviceTrace, traceInfrastructureID, fiberColor, fiber.name)
    };
  }

  // Grandchild = ServiceTrace
  private transformServiceTraces(fiberID: number, serviceTraces: any[], traceInfrastructureID: number, fiberColor: string, fiberName: string): any[] {
    // Si serviceTraces está vacío, crea un nodo vacío
    if (!serviceTraces || serviceTraces.length === 0) {
      return [{
        descr: ' ',
        icon: 'icon',
        name: ' ',
        type: ' ',
        color: fiberColor,
        leaf: '',
        parentElement: null,
        fiberName: fiberName
      }];
    }

    return serviceTraces.map((serviceTrace: any) => ({
      descr: serviceTrace?.service?.name || ' ',
      icon: 'icon',
      name: serviceTrace?.service?.name || ' ',
      type: 'serviceType',
      color: fiberColor,
      leaf: '',
      infrastructureID: traceInfrastructureID,
      fiberID: fiberID,
      serviceID: serviceTrace?.serviceID,
      fiberName: fiberName,
      parentElement: serviceTraces[0]?.fiber?.name || null
    }));
  }
  extractTextBetweenBrackets(text: string): string {
    const regex = / ?\[(.*?)\]/;
    const match = text.match(regex);
    return match ? match[0] : '';
  }

  createHorizontalTree(horizontalTreeData: D3HorizontalTreeData) {

    // set the dimensions and margins of the diagram
    const margin = { top: 80, right: 90, bottom: 80, left: 200 };
    const width = window.innerWidth;
    this.height = 800;

    const increasedHeight = horizontalTreeData.children?.length > 38;

    if (increasedHeight && horizontalTreeData.children?.length <= 50) {
      this.height = this.height * 1.5;
    } else if (increasedHeight && horizontalTreeData.children?.length <= 96) {
      this.height = this.height * 3;
    } else if (horizontalTreeData.children?.length > 96) {
      this.height = this.height * 4;
    }

    //NOTE: A new SVG graphic is created every time this function is called 

    // Create the SVG for the chart
    this.svg = d3.select(this.svgContainerRef.nativeElement)
      .append('svg')
      .attr('width', width)
      .attr('height', this.height + margin.top + margin.bottom);

    const g = this.svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Create the tree hierarchy
    const root = d3.hierarchy(horizontalTreeData, (d) => d.children);

    const treemap: TreeLayout<D3HorizontalTreeData> = d3.tree<D3HorizontalTreeData>().size([this.height, 800]);
    const nodes = treemap(root);

    this.graphs.push(horizontalTreeData);

    //Push de 'y' position of each svg
    const position = this.svg.node().getBoundingClientRect();
    this.svgPositions.push(position);

    // adds the links between the nodes
    let pNode = undefined;
    g.selectAll('.link')
      .data(nodes.descendants().slice(1))
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('class', 'link')
      .style('stroke', (d) => d.data.color)
      .attr('d', function (d) {
        // Fix factor (some lines are closer and distance must be forced)
        if (pNode && (d.x - pNode.x) < 14 && d.y == pNode.y) {
          d.x += (14 - (d.x - pNode.x));
        }

        // Fix factor for children: If a fix factor was applied to the parent (depth == 1), child value must be fixed as well
        if (d.depth == 2 && d.parent.x != d.x) {
          d.x = d.parent.x;
        }

        pNode = { x: d.x, y: d.y };
        return 'M' + d.y + ',' + d.x
          + 'C' + (d.y + d.parent.y) / 2 + ',' + d.x
          + ' ' + (d.y + d.parent.y) / 2 + ',' + d.parent.x
          + ' ' + d.parent.y + ',' + d.parent.x;
      });

    // adds each node as a group
    const node = g.selectAll('.node')
      .data(nodes.descendants())
      .enter().append('g')
      .attr('class', (d) => {
        let className = d.children ? ' node--internal' : ' node--leaf';
        return 'node' + className;
      })
      .attr('transform', function (d) {
        return 'translate(' + d.y + ',' + d.x + ')';
      });

    // adds images as nodes
    node.append('image')
      .attr('href', (d) => {
        if (!d.data.icon) {
          return '';
        }
        const iconNameWithExtension = d.data.icon;
        const splittedName = iconNameWithExtension.split('.');
        const iconNameWithoutExtension: string = splittedName.length === 1 ?
          splittedName[0] :
          splittedName.slice(0, -1).join('.');
        return this.svgIcon.get(iconNameWithoutExtension);
      })
      .attr('x', '-12px')
      .attr('y', '-12px')
      .attr('width', '24px')
      .attr('height', '24px');



    const xOffsets = {
      0: -190, // Parent
      1: 10, // Child
      2: 10  // Grandchild
    };

    const yOffsets = {
      0: 10, // Parent
      1: 0, // Child
      2: 0  // Grandchild
    };

    // Añadir texto al nodo y manejar el click
    node.append('text')
      .attr('class', 'descr-text')
      .attr('dy', (d) => {
        if (d.data.descr) {
          return '-0.5em';
        } else {
          return d.parent == null ? '2.5em' : '.35em';
        }
      })
      // .attr('font-size', (d) => d.depth == 1 ? '12px' : '14px')
      .attr('font-size', '12px')
      .style('fill', 'black')
      .style('font-weight', 'bold')
      .style('cursor', (d) => d.depth === 2 ? 'pointer' : 'default')
      .attr('y', (d) => yOffsets[d.depth] || 0)
      .attr('x', (d) => xOffsets[d.depth] || 25)
      .attr('text-anchor', 'start')
      .text((d) => d.data.leaf || d.data.name || d.data.descr)
      .on('click', (event, d) => {
        if (d.depth === 2) {

          const leafData = {
            fiberID: d.data.fiberID,
            serviceID: d.data.serviceID,
            infrastructureID: d.data.infrastructureID,
            fiberName: d.data.fiberName
          };

          this.onServiceSelected(leafData);
        }
      });

  };

  async downloadD3Graph() {
    const svgs = this.svgContainerRef.nativeElement.querySelectorAll('svg');

    for (let index = 0; index < svgs.length; index++) {
      await this.downloadSVG(svgs[index], index + 1);
    }

  }

  async downloadSVG(svg: SVGSVGElement, index: number) {
    return new Promise<void>((resolve) => {

      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svg);
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const width = +svg.getAttribute('width');
      const height = +svg.getAttribute('height');
      canvas.width = width;
      canvas.height = height;
      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.onload = () => {

        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);
        context.drawImage(img, 0, 0);
        const pngDataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngDataUrl;

        link.download = `${this.traceName[index - 1]}.png`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
        resolve();
      };

      img.src = url;
    });
  }

  async onServiceSelected(leafData: Partial<LeafData>) {
    this.loading = true;

    const fiberID = leafData?.fiberID;
    const serviceID = leafData?.serviceID;
    this.fiberNameSelected = leafData?.fiberName;
    const result = await this.getFiberIDInfraestructureIDfromServiceID(serviceID);

    if (!result)
      return;

    if (!fiberID) {
      return;
    }

    if (!serviceID || !result?.infrastructureID) {
      notify(this.translateService.instant('infrastructure-odf.traceability-diagram.error.fiber-has-no-service'), 'error', 4500);
      return;
    }

    try {
      this.traceabilityDiagramData = await firstValueFrom(this.serviceService.getTraceabilityDiagramData(result.fiberID, serviceID, result.infrastructureID));
      this.displayTraceabilityDiagramModal = true;
    } catch (ex) {
      return;
    } finally {
      this.loading = false;
    }
  }

  async getFiberIDInfraestructureIDfromServiceID(serviceID: number): Promise<any> {
    try {
      const result = await firstValueFrom(this.traceService.getInfrastructureIDByServiceID(serviceID));
      return result;
    } catch (error) {
      this.loading = false;
      notify(this.translateService.instant('No se encontró un InfraestructureID para esta Fibra'), 'error', 4500);
      return null;
    }
  }

  onGraphSelect(selectedValue: string) {
    this.selectedGraphName = selectedValue;

    const graphIndex = this.graphs.findIndex(graph => graph.name === selectedValue);
    if (graphIndex !== -1) {
      this.scrollToSvg(graphIndex);
    }
  }

  scrollToSvg(index: number) {
    const position = this.svgPositions[index];
    if (position) {
      const graphContainer = document.querySelector('.graph');
      if (graphContainer) {
        const svgHeight = position.height;
        const offset = 150;
        const scrollToCenter = position.top + svgHeight / 2 - graphContainer.clientHeight / 2 - offset;

        graphContainer.scrollTo({
          top: scrollToCenter,
          behavior: 'smooth'
        });
      }
    }
  }

  onScroll(event: any) {
    const graphContainer = event.target;
    const scrollTop = graphContainer.scrollTop;
    const clientHeight = graphContainer.clientHeight;

    this.graphs.forEach((graph, index) => {
      const svgPosition = this.svgPositions[index];
      const svgTop = svgPosition.top;
      const svgHeight = svgPosition.height;

      if (scrollTop + clientHeight / 2 >= svgTop && scrollTop + clientHeight / 2 < svgTop + svgHeight) {
        this.graphSelect.nativeElement.value = graph.name;
      }
    });
  }

  highlightMatches() {
    const searchTerm = this.searchTerm.trim().toLowerCase();
    this.clearHighlights();

    if (searchTerm === '') {
      const elements = this.svgContainerRef.nativeElement.querySelectorAll('svg *');
      elements.forEach((el: HTMLElement) => {
        el.style.opacity = '1';
        el.style.fontSize = '12px';
      });
      return;
    }
    
    const elements = this.svgContainerRef.nativeElement.querySelectorAll('svg *');
    this.match = [];

    elements.forEach((el: HTMLElement) => {
      if (el.childNodes.length === 1 && el.textContent) {
        const text = el.textContent.toLowerCase();
        if (text.includes(searchTerm)) {
          el.style.opacity = '1';
          el.style.fontSize = '14px';
          this.match.push(el);
        } else {
          el.style.opacity = '0.5';
          el.style.fontSize = '12px';
        }
      }
    });

    this.currentMatchIndex = this.match.length ? 0 : -1;
    this.scrollToCurrentMatch();
  }

  clearHighlights() {
    this.match.forEach((el) => (el.style.fill = 'black'));
    this.match = [];
    this.currentMatchIndex = -1;
  }

  previousMatch() {
    if (this.match.length === 0) return;

    this.currentMatchIndex = (this.currentMatchIndex - 1 + this.match.length) % this.match.length;
    this.scrollToCurrentMatch();
  }

  nextMatch() {
    if (this.match.length === 0) return;

    this.currentMatchIndex = (this.currentMatchIndex + 1) % this.match.length;
    this.scrollToCurrentMatch();
  }

  scrollToCurrentMatch() {
    if (this.currentMatchIndex >= 0 && this.currentMatchIndex < this.match.length) {
      const currentMatch = this.match[this.currentMatchIndex];
      currentMatch.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}