<div
  id="loading-screen-container"
  class="d-flex flex-column justify-content-around align-items-center"
>
  <label class="title main-secondary-text">Smart Fiber Network</label>

  <div class="loading-container">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="mb-4 spinner-border secondary-text" role="status"></div>
    </div>
    <label>{{ 'general.loading' | translate }}...</label>
  </div>
</div>
