<div *ngIf="selectedServiceOrElement=='Elemento'">
  <dx-accordion [dataSource]="[{ name: 'Detalle de la búsqueda', data: data }]" [collapsible]="true" [multiple]="false"
    [animationDuration]="300" id="accordion-element-container" style="background-color: #f8f8f8; margin-bottom: 20px;">
    <div *dxTemplate="let item of 'title'">
      <h1 class="main-secondary-text">
        {{ item.name }}
      </h1>
    </div>
    <div *dxTemplate="let item of 'item'">
      <div class="grid-container">
        <dx-data-grid id="gridContainerDisponibles" style="max-height:50vh;" [dataSource]="item.data"
          [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
          [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true">
          <dxi-column dataField="name" caption="Nombre" dataType="string"></dxi-column>
          <dxi-column dataField="description" caption="Descripción" dataType="string"></dxi-column>

          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

          <dxo-export [enabled]="true"></dxo-export>

        </dx-data-grid>
      </div>
    </div>
  </dx-accordion>
</div>

<br>

<div *ngIf="selectedServiceOrElement=='Servicio'">
  <dx-accordion [dataSource]="[{ name: 'Anillos disponibles', data: dataAvailableServices }]" [collapsible]="true"
    [multiple]="false" [animationDuration]="300" id="accordion-element-container"
    style="background-color: #f8f8f8; margin-bottom: 20px;">
    <div *dxTemplate="let item of 'title'">
      <h1 class="main-secondary-text">
        {{ item.name }}
      </h1>
    </div>
    <div *dxTemplate="let item of 'item'">
      <div class="grid-container">
        <dx-data-grid id="gridContainerDisponibles" style="max-height:50vh;" [dataSource]="item.data"
          [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
          [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true">
          <dxi-column dataField="name" caption="Nombre" dataType="string"></dxi-column>
          <dxi-column dataField="clients" caption="Clientes" dataType="string"></dxi-column>

          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

          <dxo-export [enabled]="true"></dxo-export>

        </dx-data-grid>
      </div>
    </div>
  </dx-accordion>

  <br>

  <dx-accordion [dataSource]="[{ name: 'Anillos no disponibles', data: dataServicesNotAvailable }]" [collapsible]="true"
    [multiple]="false" [animationDuration]="300" id="accordion-element-container"
    style="background-color: #f8f8f8; margin-bottom: 20px;">
    <div *dxTemplate="let item of 'title'">
      <h1 class="main-secondary-text">
        {{ item.name }}
      </h1>
    </div>
    <div *dxTemplate="let item of 'item'">
      <div class="grid-container">
        <dx-data-grid id="gridContainerDisponibles" style="max-height:50vh;" [dataSource]="item.data"
          [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
          [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true">
          <dxi-column dataField="name" caption="Nombre" dataType="string"></dxi-column>
          <dxi-column dataField="clients" caption="Clientes" dataType="string"></dxi-column>

          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

          <dxo-export [enabled]="true"></dxo-export>

        </dx-data-grid>
      </div>
    </div>
  </dx-accordion>
</div>