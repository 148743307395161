import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EquipmentType } from '@app/@shared/model/equipmentType.model';

@Component({
  selector: 'app-equipment-type-list',
  templateUrl: './equipment-type-list.component.html',
  styleUrls: ['./equipment-type-list.component.scss']
})
export class EquipmentTypeListComponent implements OnInit {
  @Input() EquipmentTypes: EquipmentType[] = [];
  @Input() loading: boolean = false;

  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
}
