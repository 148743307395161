import { Component, OnInit } from '@angular/core';
import { UserFile } from '@app/@shared/model/userFile.model';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { OverlappedRingsService } from '@app/@shared/services/overlapped-rings.service';
import { AppRoutingService } from '@app/app-routing.service';
import { CredentialsService } from '@app/auth';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'device-occupation',
  templateUrl: './device-occupation.component.html',
  styleUrls: ['./device-occupation.component.scss']
})
export class DeviceOccupationComponent implements OnInit {
  loading: boolean = false;

  

  constructor(
    private trans: TranslateService,
    private credentialsService: CredentialsService,
    private windowService: WindowService
  ) {
    let date = new Date();
    date.setDate(date.getDate() - 1);

  }

  ngOnInit(): void {
   
  }

  customizeText(arg: any) {
    return `${arg.valueText} %`;
  }

  exploreDeviceUsage(){
    
  }
}

