import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIs } from '@app/@shared/cons/cons';
import { Mapbox } from '@app/@shared/model/aux-models/mapbox';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MapboxService {
  constructor(private http: HttpClient) {}

  private baseURL: string = APIs._MAPBOX;
  private apiKey: string = APIs._MAPBOX_APIKEY;

  getLocationByAddress(address: string): Observable<Mapbox> {
    const url = `${this.baseURL}mapbox.places/${address}.json?types=address&access_token=${this.apiKey}&limit=1`;
    return this.http
      .get<{ features: Mapbox[] }>(url)
      .pipe(map((x) => x.features[0]));
  }

  getLocationListByAddress(address: string, country : string, limit? : number): Observable<Mapbox[]> {
    if(!address || address == null){
      return new Observable<Mapbox[]>();
    }
    if(!limit){
      limit = 10;
    }

    let c = country && country != "" ? `&country=${country}` : "";
    const url = `${this.baseURL}mapbox.places/${address}.json?types=address${country}&access_token=${this.apiKey}&limit=${limit}`;
    
    return this.http
      .get<{ features: Mapbox[] }>(url)
      .pipe(map((x) => x.features));
  }

  
}
