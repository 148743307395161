import { Injectable } from '@angular/core';

import { MainService } from './main.service';
import { Cons } from '../cons/cons';
import { Observable, catchError, map, throwError } from 'rxjs';
import { PlatformService } from '@app/auth/platform.service';
import { Equipment } from '../model/equipment.model';
import { EquipmentType } from '../model/equipmentType.model';

@Injectable({ providedIn: 'root' })
export class EquipmentService {

  constructor(
    private mainService: MainService,
    private platformService: PlatformService,
  ) {}

  getByID(id: number) {
    this.mainService.chooseAPI(Cons._INFRASTRUCTURE);

    const url = `${Cons._EQUIPMENT}/GetByID`
    return this.mainService.getByID(url, id).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getWithFilter(entity: any = {}): Observable<Equipment[]> {
    this.mainService.chooseAPI(Cons._INFRASTRUCTURE);

    const platformID = this.platformService.platformID;
    entity.platformID = platformID;

    return this.mainService.getWithFilter(Cons._EQUIPMENT, entity).pipe(
      map((res) => res.responseData),
      catchError((error) => throwError(() => error))
    );
  }


  getModels(): Observable<EquipmentType[]> {
    this.mainService.chooseAPI(Cons._SERVICECONFIG);

    return this.mainService.getWithFilter('EquipmentType').pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  save(olt: Equipment): Observable<any> {
    this.mainService.chooseAPI(Cons._INFRASTRUCTURE);

    return this.mainService.post(Cons._EQUIPMENT, undefined, olt).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  edit(olt: Equipment): Observable<any> {
    this.mainService.chooseAPI(Cons._INFRASTRUCTURE);

    return this.mainService.put(Cons._EQUIPMENT, undefined, olt).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  delete(id: number): Observable<any> {
    this.mainService.chooseAPI(Cons._INFRASTRUCTURE);

    return this.mainService.delete(Cons._EQUIPMENT, id).pipe(
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }
}
