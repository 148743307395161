import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Container } from '@app/@shared/model/container.model';
import { Device } from '@app/@shared/model/device.model';
import { ElementType } from '@app/@shared/model/elementType.model';
import { Splitter } from '@app/@shared/model/splitter.model';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { MainService } from '@app/@shared/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-edit-device-modal',
  templateUrl: './create-edit-device-modal.component.html',
  styleUrls: ['./create-edit-device-modal.component.scss'],
})

export class CreateEditDeviceModalComponent implements OnInit {
  newImage: string | undefined;


  labels = {
    model: '',
    container: '',
    name: '',
  };

  elementTypeDataSource: DataSource;

  containerDataSource: DataSource;

  splitters: Splitter[] = [];
  splitterTypes: SplitterType[] = [];

  device: Device;
  ports: any[] = [];

  address: string;

  disableSave: boolean = true;

  image: string = "";
  showElement: boolean = false;
  private subscription: Subscription;


  @Input() selectedDevice: Device;
  @Output() save = new EventEmitter<Device>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private allDatasources: DataSourceService,
    private translateService: TranslateService,
    private mainService: MainService
  ) {
    this.subscription = this.mainService.showElement$.subscribe(show => {
      this.showElement = show;
    });
  }

  ngOnInit(): void {
    this.setMandatoryLabels();
    this.getDeviceModels();
    this.getSplitterTypes();

    const containers: Container[] = this.allDatasources.allContainers;
    this.containerDataSource = new DataSource({ store: containers });

    this.device = plainToInstance(Device, this.selectedDevice);
     
    this.ports = this.device.portsDataAttributes || [];
    this.splitters = this.device.splitter || [];
    this.showImage()
  }



  showImage() {
    if (this.selectedDevice?.elementType?.filePath && this.selectedDevice?.elementType?.filePath !== "") {
      this.image = this.selectedDevice?.elementType?.filePath;
    } else {
      this.image = "assets/main/not.jpg";
    }

  }


  setMandatoryLabels() {
    this.labels = {
      model: this.translateService.instant('devices.add-edit.model') + '*',
      container: this.translateService.instant('devices.add-edit.container') + '*',
      name: this.translateService.instant('devices.add-edit.name') + '*',
    };
  }

  fullAddressChange(fullAddress: string) {
    this.device.deviceAttributes.address = fullAddress;
    this.updateDisableSaveButton();
  }

  containerChange(event: { selectedItem: Container }) {
    const selectedContainer = event.selectedItem;
    this.device.containerID = selectedContainer.containerID;
    this.updateDisableSaveButton();
  }

  elementTypeChange(event: { selectedItem: ElementType }) {
    const selectedElementType = event.selectedItem;
    this.device.elementTypeID = selectedElementType.elementTypeID;

    this.updateDisableSaveButton();
  }

  nameChange(event: { value: string }) {
    this.device.name = event.value;
    this.updateDisableSaveButton();
  }

  updateDisableSaveButton() {
    // this.disableSave = !this.device.elementTypeID || !this.device.containerID || !this.device.name || !this.device.locationData;
    this.disableSave = !this.device.elementTypeID || !this.device.containerID || !this.device.name;
  }

  getDeviceModels() {
    //Agregar llamada a ServiceConfig
    const elementTypes = []

    this.elementTypeDataSource = new DataSource({
      store: elementTypes,
      paginate: true
    });
    console.log("element: ", elementTypes)
  }

  getSplitterTypes() {
    this.splitterTypes = [];
    //Agregar llamada a serviceConfig
  }

  handleAddSplitter(splitter: Splitter) {
    this.splitters.push(splitter);
    this.updateSplitterList();
  }

  handleEditSplitter(splitter: Splitter) {
    const index = this.splitters.findIndex(x => splitter.splitterID ? x.splitterID === splitter.splitterID : (<any>x).internalSplitterId === (<any>splitter).internalSplitterId);
    this.splitters[index] = splitter
    this.updateSplitterList();
  }

  handleDeleteSplitter(splitter: Splitter) {
    const index = this.splitters.findIndex(x => splitter.splitterID ? x.splitterID === splitter.splitterID : (<any>x).internalSplitterId === (<any>splitter).internalSplitterId);
    this.splitters[index].__deleted = true;
    this.updateSplitterList();
  }

  updateSplitterList() {
    this.device.splitter = this.splitters;
    this.splitters = [...this.splitters];
  }

  saveDevice() {
    this.preSave();

    // Debugging purposes only
    //  this.device.locationData = <any>{
    //    inputSrID: 4326,
    //    outputSrID: 4326,
    //    format: "GEOJSON",
    //    coordinates: {
    //      geographyType: "Point",
    //      values: [
    //        { x: -58.442638, y: -34.621334 }
    //      ]
    //    }
    //  };

    this.save.emit(this.device);
  }

  preSave() {
    const splitters = this.device.splitter;
    this.device.splitter = splitters?.filter(x => !(x.__new && x.__deleted));
    splitters?.filter(x => x.__new).forEach(x => delete x.__modified);
    splitters?.filter(x => x.__deleted).forEach(x => delete x.__modified);
    splitters?.forEach(x => delete (<any>x).internalSplitterId);
  }

  addressChanged($event) {
    this.device.locationData = $event;
  }

  handleFileInput(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.newImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
