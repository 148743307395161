import { Component, OnInit } from "@angular/core";
import { AppRoutingService } from "@app/app-routing.service";


@Component({
  selector: 'navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {

  fillerNav: any = [];

  constructor(
    private appRoutingService : AppRoutingService
  ) {
  }

  ngOnInit(): void {
    let routes = this.appRoutingService.getActiveRoutes();
    this.plainChildRoutes(routes);
  }

  plainChildRoutes(routes){
    routes.forEach(r=> {
      if(r.children){
        this.plainChildRoutes(r.children);
      }
      else{
        this.fillerNav.push(r);
      }
    });
  }
  
  navigateTo(path: string) {
    this.appRoutingService.navigateTo(path);
  }

  show(item){
    item.selected = true;
  }

  hide(item){
    item.selected = false;
  }
}
