import { Component, Input } from '@angular/core';
import { EquipmentPort } from '@app/@shared/model/equipmentPort.model';

@Component({
  selector: 'app-odf-grid-view',
  templateUrl: './odf-grid-view.component.html',
  styleUrls: ['./odf-grid-view.component.scss'],
})

export class OdfGridViewComponent {
  @Input() equipmentPorts: EquipmentPort[];

  constructor() {}

  rowUpdated(event: any) {
    const equipmentPort: EquipmentPort = event.data;
    equipmentPort.__updated = true;
  }
}
