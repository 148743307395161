<dx-list (onItemClick)="itemClick($event)" id="clientList">
  <dxi-item>
    <div
      class="container-rings"
      [ngStyle]="{
        'justify-content':
          selectedPlatform?.name !== 'CLARO - ANILLOS CRITICOS' ? 'center' : ''
      }"
    >
      <div style="width: 32px; font-size: 17px">
        <img
          class="header-icon"
          src="../../../assets/icons/header.svg
      "
        />
      </div>
      <p class="platform">{{ selectedPlatform?.name }}</p>
    </div>
  </dxi-item>
</dx-list>
<dx-popover
  target="#clientList"
  position="bottom"
  [width]="300"
  [(visible)]="actionSheetVisible"
>
  <div *dxTemplate="let data = model; of: 'content'" id="lang_popover">
    <div *ngFor="let item of platforms" style="margin-bottom: 4px">
      <dx-button
        [icon]="selectedPlatform.platformID == item.platformID ? 'check' : ''"
        [class.platform-selected]="
          selectedPlatform.platformID == item.platformID
        "
        width="100%"
        [text]="item.name"
        (onClick)="setPlatform(item)"
      ></dx-button>
    </div>
  </div>
</dx-popover>
