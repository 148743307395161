import { Component, EventEmitter, Input ,OnChanges, Output, SimpleChanges } from '@angular/core';
import { Splitter } from '@app/@shared/model/splitter.model';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { PlatformService } from '@app/auth/platform.service';
import { plainToClass } from 'class-transformer';

type EditableSplitter = Splitter & { editing?: boolean, newSplitterType?: SplitterType, newName?: string, internalSplitterId?: number };

@Component({
  selector: 'app-device-splitter-list',
  templateUrl: './device-splitter-list.component.html',
  styleUrls: ['./device-splitter-list.component.scss'],
})

export class DeviceSplitterListComponent implements OnChanges {
  @Input() splitterTypes: SplitterType[] = [];
  @Input() splitters: Splitter[] = [];

  @Output() addSplitter = new EventEmitter<Splitter>();
  @Output() editSplitter = new EventEmitter<Splitter>();
  @Output() deleteSplitter = new EventEmitter<Splitter>();

  internalSplitterId: number = 1;

  constructor(
    private platformService: PlatformService,
  ) { }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['splitters']) {
      this.updateDisplayedSplitters();
    }
  }

  disableAddSplitterButton: boolean = false;
  displayedSplitters: EditableSplitter[] = [];

  createSplitterRow() {
    const newSplitter = plainToClass(Splitter, { __new: true, editing: true, internalSplitterId: this.internalSplitterId });
    this.internalSplitterId++;
    
    this.displayedSplitters.push(newSplitter);
    this.disableAddSplitterButton = this.displayedSplitters.some(x => x.editing);
  }

  splitterTypeChange(splitter, event) {
    splitter.newSplitterType = event.selectedItem;
  }

  saveSplitter(splitter: EditableSplitter) {
    if (!splitter.newName || !splitter.newSplitterType) {
      return;
    }

    const platformID = this.platformService.platformID;
    splitter.platformID = platformID;
    splitter.name = splitter.newName;
    splitter.splitterTypeID = splitter.newSplitterType.splitterTypeID;
    splitter.newName = undefined;
    splitter.newSplitterType = undefined;

    splitter.editing = false;

    delete splitter.editing;
    delete splitter.newSplitterType;
    delete splitter.newName;

    if (splitter.__modified) {
      this.editSplitter.emit(splitter)
    } else if (splitter.__new) {
      this.addSplitter.emit(splitter);
    }

    this.displayedSplitters = this.splitters.filter(x => !x.__deleted);
  }

  async cancelSplitterChanges(splitter: any) {
    splitter.newSplitterType = undefined;
    splitter.newName = '';
    splitter.editing = false;
    this.disableAddSplitterButton = this.displayedSplitters.some(x => x.editing);
  }

  editSplitterRow(splitter: EditableSplitter) {
    splitter.splitterID = splitter.splitterID;
    splitter.newSplitterType = splitter.splitterType;
    splitter.newName = splitter.name;
    splitter.__modified = true;
    splitter.editing = true;
  }

  deleteSplitterRow(splitter: EditableSplitter) {
    splitter.splitterID = splitter.splitterID;
    splitter.__deleted = true;
    this.deleteSplitter.emit(splitter);

    this.displayedSplitters = this.splitters.filter(x => !x.__deleted);
  }

  updateDisplayedSplitters() {
    this.displayedSplitters = this.splitters.filter(x => !x.__deleted).map(x => plainToClass(Splitter, x));
    this.disableAddSplitterButton = this.displayedSplitters.some(x => x.editing);
  }

  getSplitterTypeName(splitterTypeID: number) {
    return this.splitterTypes.find(x => splitterTypeID === x.splitterTypeID)?.name;
  }
}
