<!-- <dx-popup [(visible)]="isPopupVisible" [closeOnOutsideClick]="true" (onHiding)="cancel.emit()"> -->
    <label class="main-secondary-text">{{ 'element.grid-action.edit-element' | translate }}</label>
    <div fxLayout="column" fxLayoutAlign="space-between none">
      
        <div class="flex flex-wrap justify-content-between">

            <ng-container *ngFor="let elementEditableField of elementEditableData">
                <ng-container *ngIf="selectedSplitterType; else elseTemplate">
                    <dx-text-box fxFlex="49" class="my-3"
                    [(value)]="selectedSplitterType[elementEditableField.field]"
                    labelMode="floating"
                    [label]="elementEditableField.label | translate"
                    >
                    </dx-text-box>
                </ng-container>
                <ng-template #elseTemplate>
                    <dx-text-box fxFlex="49" class="my-3"
                    [(value)]="elementEditableField.label"
                    labelMode="floating"
                    [label]="elementEditableField.label | translate"
                    >
                    </dx-text-box>
                </ng-template>
                

            </ng-container>

        </div>
      
    </div>
    <label class="main-secondary-text">SpecData</label>
    <!-- 
        <dx-data-grid
    [dataSource]="dataSourceGrid"
    [showBorders]="true"
    [showRowLines]="true">
      <dxi-column [caption]="'element.grid-header.ID' | translate" dataField="ID" ></dxi-column>
      <dxi-column [caption]="'element.grid-header.name' | translate" dataField="Name"></dxi-column>
      <dxi-column [caption]="'element.grid-header.status' | translate" dataField="status"></dxi-column>
      <dxi-column [caption]="'element.grid-header.number' | translate" dataField="Number"></dxi-column>
      <dxi-column [caption]="'element.grid-header.accessId' | translate" dataField="AccessID"></dxi-column>
      <dxi-column [caption]="'element.grid-header.updated' | translate" dataField="Updated"></dxi-column>
    </dx-data-grid> 
    -->
    <spec-tree-view 
    [specAttributes]="selectedSplitterType.specAttributes"
    (onSpecSaved)="specSaved($event)"
    (isJSONValid)="isJSONValid = $event">
    </spec-tree-view>
     
    <div fxLayout="row" fxLayoutAlign="end center" class="my-4 pe-2">
        <dx-button
            [text]="'general.cancel' | translate"
            (click)="cancel.emit()"
        >
        </dx-button>
        <dx-button
            [text]="'general.save' | translate"
            (click)="saveElementType()"
        >
        </dx-button>
    </div>
