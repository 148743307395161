<div class="d-flex flex-column justify-content-between h-100">
  <label>{{message}}</label>

  <div class="d-flex justify-content-end mt-2">
    <dx-button
      [text]="'general.cancel' | translate"
      (click)="cancel.emit()"
    >
    </dx-button>

    <dx-button
      class="ms-2 main-background-theme"
      [text]="confirmButtonMessage"
      (click)="userConfirm.emit()"
    >
    </dx-button>
  </div>
</div>