<div class="homepage-container pt-4 px-5">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <label class="main-secondary-text">{{ 'users.title' | translate }}</label>
  </div>

  <common-filter [showEmail]="true" [showDescription]="false" [showElementType]="false"
    (onSearchPerformed)="search($event)"></common-filter>

  <div class="add-user-button">
    <dx-button class="main-background-theme grid-button" icon="fas fa-user-plus" (onClick)="addUser()"></dx-button>
  </div>

  <dx-data-grid [dataSource]="dataSource" [showBorders]="true" height="65vh" [allowColumnResizing]="true"
    [columnAutoWidth]="true" columnResizingMode="widget">
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column [caption]="'users.grid-header.id' | translate" dataField="userID" [cssClass]="'verticalAlign'"
      width="80"></dxi-column>
    <dxi-column [caption]="'users.grid-header.name' | translate" dataField="name"
      [cssClass]="'verticalAlign'"></dxi-column>
    <dxi-column [caption]="'users.grid-header.mail' | translate" dataField="email"
      [cssClass]="'verticalAlign'"></dxi-column>
    <dxi-column [caption]="'users.grid-header.regional-config' | translate" dataField="cultureID">
      <dxo-lookup [dataSource]="regionalDS" valueExpr="cultureID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="'users.grid-header.active' | translate" dataField="active" witdh="80"></dxi-column>

    <dxi-column width="90" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>
    <div *dxTemplate="let d of 'actionsCellTemplate'">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="100" style="text-align: center;">
          <dx-button class="main-background-theme grid-button" (onClick)="resetPassword(d.data)" icon="fas fa-user-lock"
            [hint]="'users.reset-password' | translate"></dx-button>
          <dx-button class="main-background-theme grid-button" (onClick)="openPopup(d.data)" icon="fas fa-pen"
            [hint]="'users.edit-user' | translate"></dx-button>
        </div>
      </div>
    </div>

  </dx-data-grid>

  <dx-popup class="user-modal-popup" *ngIf="selectedEntity" [(visible)]="isPopupVisible" [closeOnOutsideClick]="true"
    [maxWidth]="'50%'" [height]="'50vh'">
    <users-single-modal class="user-modal" [isNewUser]="isNewUser" [entity]="selectedEntity" (onSaved)="save($event)"
      (onCanceled)="closePopup()" (onCreateUser)="createUser($event)">
    </users-single-modal>
  </dx-popup>

  <dx-toast [(visible)]="isVisible" [type]="type" [message]="message">
  </dx-toast>