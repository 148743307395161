import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit {
  @ViewChild('autocomplete') autocompleteInput!: ElementRef;
  @Input() address: string = '';
  @Output() addressSelected = new EventEmitter<string>();

  private currentAddress: string = '';

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address'] && this.autocompleteInput) {
      this.autocompleteInput.nativeElement.value = this.address;
    }
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.nativeElement, {
        types: ['address']
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.currentAddress = place.formatted_address || '';
        });
      });
    });
  }

  getAddress(): string {
    return this.currentAddress;
  }
}
