import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SplitterService } from '@app/@shared/services/map-elements-services/splitter.service';
import { D3HorizontalTreeData } from '@app/@shared/model/d3/horizontal-tree.model';

@Injectable({ providedIn: 'root' })
export class SplitterBoxResolver implements Resolve<D3HorizontalTreeData[]> {
  constructor(
    private splitterService: SplitterService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<D3HorizontalTreeData[]> {
    const deviceID = Number(route.params['deviceID']);
    return await firstValueFrom(this.splitterService.getSplitterBox(deviceID));
  }
}
