import { Operator } from "./operator.model";

export class InvokerSystem  {
    invokerSystemCode : number;
    operatorID : number;
    name : string;
    serviceTypeCode: string;
    vLanConfig: string;
    vLanBlockAllocation : number;
    vLanReservationDays : number;

    operator: Partial<Operator>;
}