<dx-data-grid
  [dataSource]="fiber"
  [showBorders]="true"
  [showRowLines]="true"
>
  <dxi-column dataType="string" [caption]="'devices.add-edit.port-grid.id' | translate" dataField="name"></dxi-column>
  <dxi-column [caption]="'devices.add-edit.port-grid.status' | translate" dataField="fiberOrder"></dxi-column>
  <dxi-column dataType="string" [caption]="'devices.add-edit.port-grid.number' | translate" dataField="fiberAttributes.buffer"></dxi-column>

  <div *dxTemplate="let d of 'updatedDateTemplate'">
    <div> {{ d?.data?.updated | date: 'dd/MM/yyyy HH:mm' }}</div>
  </div>
</dx-data-grid>