import { NgModule } from '@angular/core';

// Material Form Controls
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
// Material Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

// Material Layout
import { MatCardModule } from '@angular/material/card';
// Material Buttons & Indicators
import { MatIconModule } from '@angular/material/icon';
// Material Popups & Modals
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';


const modules = [
  MatIconModule,
  MatFormFieldModule,
  MatCardModule,
  MatCheckboxModule,
  MatRadioModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatDialogModule,
  MatTooltipModule,
  MatChipsModule,
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'auto' },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class AngularMaterialModule {}
