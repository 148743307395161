<header>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="navbar-nav"></div>
    <div
      [style.backgroundImage]="
        'url(assets/backgrounds/' + this.background + ')'
      "
      class="list-item-content-image"
    ></div>
    <h1 style="font-weight: bold; font-size: 1.5em; margin-left: 0.8em">
      <i [class]="this.icon"></i>&nbsp; {{ title }}
    </h1>

    <div fxLayout="row" style="position: absolute; right: 2em">
      <dx-button
        class="header-button save"
        type="success"
        *ngIf="showSaveButton"
        icon="save"
        (onClick)="save($event)"
      >
      </dx-button>
      <dx-button
        class="header-button add"
        *ngIf="showAddButton"
        icon="plus"
        (onClick)="add($event)"
      >
      </dx-button>
    </div>
  </nav>
</header>
