import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxDataGridModule, DxGalleryModule, DxLoadPanelModule, DxPopupModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationMenuComponent } from './navigation-menu.component';



@NgModule({
  declarations: [
    NavigationMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    TranslateModule,
    DxPopupModule,
    MatCardModule,
    DxLoadPanelModule,
    DxDataGridModule,
    FlexLayoutModule,
  ],
  exports	: [NavigationMenuComponent]
})
export class NavigationMenuModule { }
