export class UserFile {
  
  fileID: number;
  platformID: number;
  userID : number;
  elementID : number;
  entityType: string;
  name: string;
  description: string;
  extension : string;
  size : number;
  filePath: string;
  creationDate : Date;
  userFileAttributes : any;
}
