import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIs } from '@app/@shared/cons/cons';
import { PositionStack } from '@app/@shared/model/aux-models/positionStack';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PositionStackService {
  constructor(private http: HttpClient) {}

  private baseURL: string = APIs._POSITIONSTACK;
  private apiKey: string = APIs._POSITIONSTACK_APIKEY;

  getLocationByAddress(address: string): Observable<PositionStack> {
    const url = `${this.baseURL}forward?access_key=${this.apiKey}&country=AR&query=${address}&limit=1`;
    return this.http
      .get<{ data: PositionStack[] }>(url)
      .pipe(map((x) => x.data[0]));
  }

  getLocationListByAddress(address: string, country : string, limit? : number): Observable<PositionStack[]> {
    if(!address || address == null){
      return new Observable<PositionStack[]>();
    }
    if(!limit){
      limit = 10;
    }
    const url = `${this.baseURL}forward?access_key=${this.apiKey}&country=${country}&query=${address}&limit=${limit}`;
    return this.http
      .get<{ data: PositionStack[] }>(url)
      .pipe(map((x) => x.data));
  }

  getLocationByLatLong(
    latitude: number,
    longitude: number
  ): Observable<PositionStack> {
    const url = `${this.baseURL}reverse?access_key=${this.apiKey}&country=AR&query=${latitude},${longitude}&limit=1`;

    return this.http
      .get<{ data: PositionStack[] }>(url)
      .pipe(map((x) => x.data[0]));
  }
}
