import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Container } from '@app/@shared/model/container.model';


@Component({
  selector: 'app-container-list',
  templateUrl: './container-list.component.html',
  styleUrls: ['./container-list.component.scss'],
})
export class ContainerListComponent {
  // @Input() containers: Container[] = [];
  @Input() loading: boolean = false;
  @Input() containers: any [];

  // @Output() editItem = new EventEmitter<Container>();
  // @Output() deleteItem = new EventEmitter<Container>();
  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  

  constructor() {
  }
  ngOnInit(): void {
  }
}
