import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss'],
})
export class UserOptionsComponent implements OnInit {
  get username(): string | null {
    return this.credentialsService.decodeToken()?.['name'];
  }

  constructor(private credentialsService: CredentialsService) {}

  actionSheetVisible = false;
  actionSheetTarget: any = '';

  ngOnInit() {}

  setClient(item) {}

  itemClick(e) {
    this.actionSheetTarget = e.itemElement;
    this.actionSheetVisible = true;
  }
}
