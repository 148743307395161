import { Type } from 'class-transformer';
import { LocationData } from "@app/@shared/model/aux-models/locationData";
import { Interface } from 'readline';
import { FeasibilityStudy } from './feasibilityStudy.model';

export class Client {
  aud_Action?: string;
  aud_Date?: string;
  aud_User?: number;

  clientID?: number;
  platformID?: number;
  email: string;
  name: string;
  id: string;
  typeID: string;
  
  @Type(()=> FeasibilityStudy)
  feasibilityStudy?: FeasibilityStudy[];
  

  get lastFeasibility(): FeasibilityStudy {
    return (this.feasibilityStudy || []).sort((a, b) => new Date(b.verificationDate).getTime() - new Date(a.verificationDate).getTime())[0];
  }

  get hasValidData(): boolean {
    return !!this.name && !!this.email && !!this.idNumberIsValid && !!this.typeID;
  }

  get idNumberIsValid(): boolean {
    if (!this.id) {
      return false;
    }

    const idNumberAsString: string = String(this.id);
    return idNumberAsString.length === 7 || idNumberAsString.length === 8;
  }


  toDTO(platformID?: number): Partial<Client> {
    return {
      platformID: this.platformID || platformID,
      email: this.email,
      name: this.name,
      typeID: this.typeID,
      id: ''+this.id,
      clientID: this.clientID,
    }
  }
}

