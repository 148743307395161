import { Injectable } from "@angular/core";
import { geojsonToWKT, wktToGeoJSON  } from "@terraformer/wkt"

type Geometry = 'Point' | 'Linestring' | 'Polygon';
interface GeoJsonCoordinates {
    type: Geometry,
    coordinates: number[][]; 
}

@Injectable({ providedIn: 'root' })
export class CoordinatesServices {
  constructor() {}

  coordsToGeoJson(wktCoordinates: string) {
    return wktToGeoJSON(wktCoordinates); 
  }

  coordsToWKT(geoJsonCoordinates: GeoJsonCoordinates): string {
    return geojsonToWKT(geoJsonCoordinates);
  }
}
