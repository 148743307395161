import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { MainService } from '@app/@shared/services/main.service';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { CredentialsService } from './credentials.service';
import { InventoryService } from '@app/@shared/services/inventory.service';
import { PlatformService } from './platform.service';

export interface LoginContext {
  user: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private inventoryService: InventoryService,
    private credentialsService: CredentialsService,
    private platformService: PlatformService,
    private main: MainService
  ) {}

  private _expiredTokenModalSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  expiredTokenModal$: Observable<boolean> = this._expiredTokenModalSource.asObservable();
  
  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    // Replace by proper authentication call
    this.main.chooseAPI(Cons._AUTHENTICATION);
    return this.main
      .post(Cons._AUTHENTICATION, 'GetTokenAuthorization', context)
      .pipe(
        map((res) => {
          try {
            return res?.responseData;
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    const token = this.main.token;
    
    //this.inventoryService.removeToken();
    this.credentialsService.setCredentials();
    this.platformService.deletePlatforms();
    
    return this.disableToken(token).pipe(
      map((expiredToken: string) => !!expiredToken),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  private disableToken(token): Observable<string> {
    const decodedToken = this.credentialsService.decodeToken(token);
    const { email } = decodedToken;

    this.main.chooseAPI(Cons._AUTHENTICATION);

    return this.main.post(Cons._AUTHENTICATION, 'GetTokenLogout?user=' + email).pipe(
      map(res => res?.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  openExpiredTokenModal() {
    this._expiredTokenModalSource.next(true);
  }

  
}
