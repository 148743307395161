<div class="mt-3">
  <div class="body-container d-flex flex-column align-items-center">
    <i class="fas fa-check mb-2"></i>
    <label class="my-2">{{
      'survey.plot-form.plot-saved-successfully-modal.changes-saved-successfully'
        | translate
    }}</label>
  </div>
  <div class="mt-4" fxLayout="row" fxLayoutAlign="space-around center">
    <dx-button
      fxFlex="48"
      class="button quick-access-button cancel-button"
      [text]="'survey.plot-form.plot-saved-successfully-modal.edit' | translate"
      (click)="close.emit()"
    >
    </dx-button>
    <dx-button
      fxFlex="48"
      class="button main-background-theme save-button"
      [text]="'survey.plot-form.plot-saved-successfully-modal.next' | translate"
      (click)="next.emit()"
    >
    </dx-button>
  </div>
</div>
