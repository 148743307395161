import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxColorBoxModule, DxContextMenuModule, DxDataGridModule, DxGalleryModule, DxLoadPanelModule, DxNumberBoxModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule, DxTreeViewModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpecTreeViewComponent } from './spec-tree-view.component';



@NgModule({
  declarations: [
    SpecTreeViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DxGalleryModule,
    DxButtonModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    TranslateModule,
    DxPopupModule,
    MatCardModule,
    DxLoadPanelModule,
    DxDataGridModule,
    FlexLayoutModule,
    DxTreeViewModule,
    DxColorBoxModule,
    DxContextMenuModule,
    DxTextAreaModule,
    DxRadioGroupModule,
    DxScrollViewModule
  ],
  exports: [SpecTreeViewComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, 
      useValue: { floatLabel: 'auto' },
    }
  ]
})
export class SpecTreeViewModule { }
