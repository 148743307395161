import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PlotService } from '@app/@shared/services/map-elements-services/plot.service';
import { firstValueFrom } from 'rxjs';
import { PlotAddress } from '../../models/plot-address';

@Component({
  selector: 'app-edit-address-modal',
  templateUrl: './edit-address-modal.component.html',
  styleUrls: ['./edit-address-modal.component.scss'],
})
export class EditAddressModalComponent implements OnChanges {
  @Input() plotID: number;
  @Input() address: PlotAddress;
  @Output() addressChange = new EventEmitter<PlotAddress>();
  @Output() close = new EventEmitter<void>();

  previousAddress: string;
  currentAddress: string = '';

  constructor(private plotService: PlotService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address']) {
      this.previousAddress = this.address?.Street;
      this.currentAddress = this.address?.Street;
    }
  }

  save = async () => {
    const addressChanged = this.currentAddress !== this.previousAddress;
    if (addressChanged) {
      const newAddr = { ...this.address, Street: this.currentAddress };
      await firstValueFrom(this.plotService.save(this.plotID, newAddr));
      this.addressChange.emit(newAddr);
    }

    this.close.emit();
  };

  cancel = () => {
    this.currentAddress = this.previousAddress;
    this.close.emit();
  }
}
