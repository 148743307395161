import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Device } from '@app/@shared/model/device.model';
import { MainService } from '@app/@shared/services/main.service';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent {
  @Input() devices: Device[] = [];
  @Input() loading: boolean = false;

  @Output() editItem = new EventEmitter<Device>();
  @Output() deleteItem = new EventEmitter<Device>();

  constructor(private mainService: MainService) { }

  handleButtonClick() {
    this.mainService.updateShowElementState(true);
  }
}
