import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ElementType } from '@app/@shared/model/elementType.model';
import { TranslateService } from '@ngx-translate/core';
import { path } from 'd3';
import { DxContextMenuComponent, DxTreeViewComponent } from 'devextreme-angular';
import { find } from 'rxjs';

@Component({
  selector: 'element-type-single',
  templateUrl: './element-type-single.component.html',
  styleUrls: ['./element-type-single.component.scss']
})
export class ElementTypeSingleComponent implements OnInit {

  @Input() entity;

  @Output() onSaved = new EventEmitter<any>();
  @Output() onCanceled = new EventEmitter<void>();
  elementEditableData = [
    { label: 'element.grid-header.name', field: 'name' },
    { label: 'element.grid-header.reference', field: 'reference' },
    { label: 'element.grid-header.groupId', field: 'groupID' },
    { label: 'element.grid-header.filePath', field: 'filePath' },
  ];

  isJSONValid : boolean = true;
  constructor(private trans : TranslateService) { 
    
  }
  

  ngOnInit(): void {
  }

  specSaved(spec){
    this.entity.specAttributes = spec;
  }

  save() {
    if(!this.isJSONValid){
      return;
    }   
    this.onSaved.emit(this.entity);
  }

  cancel(){
    this.onCanceled.emit();
  }

  

}
