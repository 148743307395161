import { Directive, HostListener, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {
  private isDragging = false;
  private offsetX = 0;
  private offsetY = 0;


  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent, data : any, id : string) {
    let element = (<any>this.renderer).getElementById(id);
    if(!element){
        return;
    }

    const x = Number(element.getAttribute("dx-value"));
    const y = Number(element.getAttribute("dy-value"));

    if(!x || !y){
        return;
    }

    this.isDragging = true;
    this.offsetX = event.clientX - x;
    this.offsetY = event.clientY - y;

    // Add class to indicate dragging
    element.classList.add("dragging");
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent, data : any, id : string) {
    if (this.isDragging) {
        const x = event.clientX - this.offsetX;
        const y = event.clientY - this.offsetY;

        let element = (<any>this.renderer).getElementById(id);

        // Get the current transform attribute value
        let currentTransform = element.getAttribute("transform");
        
        // Modify the transform attribute to change the location
        currentTransform = `translate(${x}, ${y})`;
        
        // Set the modified transform attribute back to the element
        element.setAttribute("transform", currentTransform);
    }
  }

  @HostListener('document:mouseup')
  onMouseUp(id : string) {
    if (this.isDragging) {
        // Remove class used for dragging
        let element = (<any>this.renderer).getElementById(id);
        if (element) {
            element.classList.removeClass("dragging");
        }
    }
    this.isDragging = false;
  }
}