<div>
  <ng-container *ngFor="let item of fillerNav">
    <div 
      (mouseenter)="show(item)"
      (mouseleave)="hide(item)"
      (click)="navigateTo(item.path)"
      class="feature-container"
      [ngClass]="{ 'feature-container-selected': item.selected === true }"
      [ngStyle]="{ color: !item.selected ? '#5F1347' : '' }"
    >
     
      <div class="title">
        <i [class]="item.icon"></i>&nbsp;&nbsp;{{ item.name | translate }}
      </div>
      <i class="fas fa-chevron-right" style="padding-right: 1em;" [ngStyle]="{ color: !item.selected ? '#5F1347' : '' }"></i>
      
    </div>
  </ng-container>
</div>