<dx-accordion
  class="mt-4"
  [dataSource]="ds_splitter_review"
  [collapsible]="false"
  [multiple]="false"
  [animationDuration]="300"
  [selectedItems]="[ds_splitter_review[0]]"
  style="background-color: #f8f8f8"
>
  <div *dxTemplate="let item of 'title'" [class.isDetail]="closable">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h1 class="main-secondary-text">
        {{ item.title }}
      </h1>
      <div class="close-panel" (click)="closeDetail()" *ngIf="closable">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </div>

  <div *dxTemplate="let item of 'item'">
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field">
        <dx-text-box
          [(value)]="item.data.name"
          [disabled]="true"
          labelMode="floating"
          [label]="'Name'"
        >
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field">
        <dx-text-box
          [value]="item.data?.splitterType?.name"
          [disabled]="true"
          labelMode="floating"
          [label]="'Splitter Type'"
        >
        </dx-text-box>
      </div>
    </div>
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="100" class="form-field">
        <dx-text-box
          [(value)]="item.data.description"
          [disabled]="true"
          labelMode="floating"
          [label]="'Description'"
        >
        </dx-text-box>
      </div>
    </div>
  </div>
</dx-accordion>

<dx-accordion
  class="mt-4"
  [dataSource]="splitterConnectionsDataSource"
  [collapsible]="true"
  [multiple]="false"
  [animationDuration]="300"
  [noDataText]="''"
  [selectedItems]="[splitterConnectionsDataSource[0]]"
  id="accordion-element-connection"
  style="background-color: #f8f8f8"
>
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-data-grid
      id="gridSplices"
      [dataSource]="item.data"
      [remoteOperations]="false"
      [allowColumnReordering]="true"
      [rowAlternationEnabled]="true"
      [showColumnLines]="false"
      [showBorders]="false"
      [columnAutoWidth]="true"
      [showColumnHeaders]="false"
      [noDataText]="'comp.device-detail.splitters.no-connections' | translate"
      (onExporting)="onExporting($event)"
      style="max-height: 45vh"
    >
      <dxo-search-panel
        [visible]="true"
        [highlightCaseSensitive]="true"
      ></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

      <dxi-column dataField="" caption="" cellTemplate="splitterConnectionTemplate" >
        <div *dxTemplate="let cellInfo of 'splitterConnectionTemplate'" style="text-align: center; font-size: 0.9em" >
          <div style="padding: 5px 0 5px 0">{{ cellInfo.row.data.name }}</div>
          <div fxLayout="row">
            <div fxFlex="50"> {{ cellInfo.row.data.fiber.name }} </div>
            <div fxFlex="10"> {{ cellInfo.row.data.fiber.fiberOrder }} </div>
            <div fxFlex="20" *ngIf="cellInfo.row.data.fiber && cellInfo.row.data.fiberID !=0" [class]="'fiber-connection-chip left bk-' + cellInfo.row.data.fiber?.fiberAttributes?.buffer?.toLowerCase()">
              {{ cellInfo.row.data.fiber.fiberAttributes.buffer }}
            </div>
            <div *ngIf="cellInfo.row.data.fiber && cellInfo.row.data.fiberID !=0"
              fxFlex="20" [class]="'fiber-connection-chip right bk-' + cellInfo.row.data.fiber.fiberAttributes.color.toLowerCase()">
              {{ cellInfo.row.data.fiber.fiberAttributes.color }}
            </div>
          </div>
        </div>
      </dxi-column>

      <dxo-export [enabled]="true"></dxo-export>
    </dx-data-grid>
  </div>
</dx-accordion>
