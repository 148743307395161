<div fxLayout="column">
  <label>
    <strong>{{ 'infrastructure-odf.port-details.port-name' | translate }}:</strong> {{ port?.name }}
  </label>

  <ng-container *ngIf="port?.hasFiber">
    <label *ngIf="!port?.fiber">
      <strong>Fiber:</strong> {{ port?.fiberID }}
    </label>
    <label *ngIf="port?.fiber">
      <strong>Fiber:</strong> {{ port?.fiber?.name }} ({{ port?.fiber?.fiberOrder }})
    </label>
    <label *ngIf="port?.fiber?.serviceTrace">
      <strong>Service:</strong> {{ port?.fiber?.serviceTrace[0]?.service?.name }}
    </label>
  </ng-container>

  <ng-container *ngIf="port?.hasCrossConnection">
    <label>
      <strong>{{ 'infrastructure-odf.port-details.cross-connection' | translate }}:</strong>  {{ ( port?.internalPort?.name) }}
    </label>
  </ng-container>

  <ng-container *ngIf="!port?.hasFiber && !port?.hasCrossConnection">
    <strong>{{ 'infrastructure-odf.port-details.no-connections' | translate }}</strong>
  </ng-container>
</div>
