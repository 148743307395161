import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Utilities } from '@app/@shared/services/main.service';
import { CredentialsService } from '@app/auth';
import { TranslateService } from '@ngx-translate/core';
import { DxFileUploaderComponent } from 'devextreme-angular';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploadComponent implements OnInit {
  // Variable to store shortLink from api response
  shortLink: string = '';
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file

  isDropZoneActive = false;
  fileSource = '';
  textVisible = true;
  progressVisible = false;
  progressValue = 0;
  headers: any = {};
  dyID: string;
  showUploader = true;

  @Input() url = '';
  @Input() label: string = '';
  @Input() height = '200px';
  @Input() allowedTypes = ['.jpg', '.jpeg', '.gif', '.png'];
  @Input() required = false;
  @Input() buttonMode = false;
	@Input() selectButtonText = '';

  @Output() onFileUploaded = new EventEmitter<any>();

  // Inject service
  constructor(private credentialsService : CredentialsService, private trans: TranslateService) {
    this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
    this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
    this.onUploaded = this.onUploaded.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);

    this.dyID = Utilities.getNewGuid();

    this.headers = { 'Authorization': 'Bearer ' + this.credentialsService.credentials.token };
  }

  @ViewChild("fileUploader") fileUploader : DxFileUploaderComponent;

  ngOnInit(): void {
    if(this.selectButtonText == ''){
			this.selectButtonText = this.trans.instant('general.select-file');
		}
  }

  onDropZoneEnter(e) {
    if (e.dropZoneElement.id === 'dropzone-external') {
      this.isDropZoneActive = true;
    }
  }

  onDropZoneLeave(e) {
    if (e.dropZoneElement.id === 'dropzone-external') {
      this.isDropZoneActive = false;
    }
  }

  onUploaded(e) {
    const file = e.file;
    const fileReader = new FileReader();
    fileReader.onload = (res) => {
      this.isDropZoneActive = false;
      this.fileSource = fileReader.result as string;
    };

    fileReader.readAsDataURL(file);
    this.textVisible = false;
    this.progressVisible = false;
    this.progressValue = 0;

    if (e.request && e.request.response && e.request.response != '') {
      let response = JSON.parse(e.request.response);
        try {
          this.onFileUploaded.emit(response.responseData);
        } catch (ex) {
          console.log(response.responseException);
        }
    }
  }

  onProgress(e) {
    this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
  }

  onUploadStarted() {
    this.fileSource = '';
    this.progressVisible = true;
  }

  public getValue(){
		return this.fileUploader.value;
	}

	public resetValue(){
		if(this.fileUploader){
			this.fileUploader.value = [];
		}
	}

  
}
