<dx-drawer #drawer [openedStateMode]="'shrink'" [position]="'left'" [revealMode]="'slide'" template="template"
  [(opened)]="isSearchOpen" [height]="'100%'" [closeOnOutsideClick]="false">
  <div *dxTemplate="let data of 'template'" class="search-panel" style="width: 520px"
    [style.overflow]="showOverflow ? 'auto' : 'hidden'">
    <div style="padding: 20px;">
      <search-panel #searchPanelRef [mapViewer]="this" [erase]="erase" [entity]="selectedEntity"
        (onSearching)="search($event)" (connectionSelected)="onConnectionSelected($event)"
        (textBoxFocusIn)="handleTextBoxFocusIn($event)" (textBoxFocusOut)="handleTextBoxFocusOut($event)"
        (areaExtentCalculated)="handleQuery($event)" (switchChanged)="onSwitchValueChanged($event)"
        [textBoxValue]="textBoxValue" [dataFromMapViewer]="dataToPass">
        ></search-panel>
    </div>
  </div>
  <div class="drawer-collapser" (click)="toggleDrawer()">
    <i *ngIf="!isSearchOpen" class="fas fa-chevron-right"></i>
    <i *ngIf="isSearchOpen" class="fas fa-chevron-left"></i>
  </div>
  <div class="chip-list-container" *ngIf="servicesSearchByUser?.length">
    <app-service-chip-list [services]="servicesSearchByUser" [selectedService]="selectedService"
      (toggleVisibility)="toggleServiceVisibility($event)" (remove)="removeService($event)"
      (center)="centerService($event)"></app-service-chip-list>
  </div>
  <ol-map id="mapContainer" #olMap [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [points]="points"
    [linestring]="lineString" [cssClass]="'position-fixed full-width'" [height]="'100%'" [centerViewOnFeatures]="false"
    [enableDrawing]="searchPolygonInProgress" (onMapClicked)="mapClicked($event)"
    (onElementHovered)="elementHovered($event)" (onMapZoomed)="mapZoomed($event)"
    (selectDataMapDrawing)="selectDataMapDrawing($event)" [xygoMapSelected]="xygoMapSelected">
  </ol-map>
  <div *ngIf="searchAreaInProgress" class="corners c01"></div>
  <div *ngIf="searchAreaInProgress" class="corners c02"></div>
  <div *ngIf="searchAreaInProgress" class="corners c03"></div>

  <div class="top-buttons" fxLayout="row" fxLayoutAlign="center center">
    <dx-button class="top-cancel-button" *ngIf="showCancelSearchButton" (onClick)="handleCancelSearch()">
      {{ 'forms.map-viewer.cancel-search' | translate }}
    </dx-button>
    <dx-button class="top-search-button" *ngIf="dataMapDrawing && dataMapDrawing.length != 0"
      (onClick)="handleQuery(this.dataMapDrawing)">
      {{ 'forms.map-viewer.search-polygon' | translate }}
    </dx-button>
    <dx-button class="top-search-button" *ngIf="searchAreaInProgress" (onClick)="handleQuery(this.mapBoundaries)">{{
      'forms.map-viewer.search-area' | translate }}</dx-button>
  </div>

  <div class="layers-dx-field-value-map">
    <dx-button id="mapLayer" class="map main-background-theme" icon="fa fa-map"
      [disabled]="dowloadImg || searchAreaInProgress || searchPolygonInProgress || layersActivated"
      (onClick)="selectMap()" [class.active]="mapButtonClicked" [ngClass]="{ 'main-background-theme': mapButtonClicked }"
      [ngbTooltip]="'forms.map-viewer.maps' | translate ">
    </dx-button>
    <dx-button id="layer" class="view main-background-theme" icon="fas fa-layer-group"
      [disabled]="dowloadImg || searchAreaInProgress || searchPolygonInProgress || mapButtonClicked" (onClick)="selectLayers()"
      [class.active]="layersActivated" [ngClass]="{ 'main-background-theme': layersActivated }"
      [ngbTooltip]="'forms.map-viewer.layers' | translate ">
    </dx-button>
    <dx-button id="polygon-draw-button" class="fence main-background-theme" icon="fas fa-download"
      [disabled]="layersActivated || searchAreaInProgress || searchPolygonInProgress || mapButtonClicked" (click)="dowloadMap()"
      [class.active]="dowloadImg" [ngClass]="{ 'main-background-theme': dowloadImg }" style="margin-left: 20px"
      [ngbTooltip]="'forms.map-viewer.dowload' | translate ">
    </dx-button>

   

    <dx-popover target="#layer" position="bottom" [width]="200" [(visible)]="layersActivated">
      <div *dxTemplate="let data = model; of: 'content'" id="lang_popover">
        <div *ngFor="let item of ds_layers" style="margin-bottom: 4px">
          <h5 style="font-size:1.2em;">{{item.name}}</h5>
          <!--dx-check-box
          class="mb-2 custom-checkbox"
          width="100%"
          [id]="item.name" 
          [text]="item.name | translate"
          (onValueChanged)="selectCheckbox($event, item.name)"
        ></dx-check-box-->

          <!-- Subcheckboxes -->
          <div>
            <div *ngFor="let subItem of item.subItems; let subIndex = index" style="margin-left: 10px; margin-top: 1px">
              <dx-check-box class="mb-2 custom-checkbox" width="100%" [id]="subItem" [text]="subItem | translate"
                [value]="" (onValueChanged)="selectSubCheckbox($event, subItem)"></dx-check-box>
            </div>
          </div>
        </div>
      </div>
    </dx-popover>


    <dx-popover target="#mapLayer" position="bottom" [width]="200" [(visible)]="mapButtonClicked">
      <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
        <h5 style="font-size:1.2em;">{{'forms.map-viewer.maps' | translate}}</h5>
        <dx-radio-group
          [(value)]="selectedMapOption"  
          (valueChange)="selectMapOption($event, selectedMapOption)" 
          [items]="mapOptions"
          displayExpr="name"  
          valueExpr="name" 
          class="mb-2 custom-radio-group">
        </dx-radio-group>
      </div>
    </dx-popover>
  </div>


  <div class="dx-field-value-map">
    <dx-button id="clear-searches-performed" icon="fas fa-eraser" class="main-background-theme"
      (onClick)="clearSearchesPerformed()" [ngClass]="{ 'main-background-theme': searchAreaInProgress }"
      [ngbTooltip]="'forms.map-viewer.eraser' | translate ">
    </dx-button>

    <div class="container-search-polygon">
      <dx-button id="search-area-button" class="view main-background-theme" icon="fas fa-crop-alt"
        [disabled]="searchPolygonInProgress || dowloadImg || layersActivated" (onClick)="searchByArea()"
        [class.active]="searchAreaInProgress" [ngClass]="{ 'main-background-theme': searchAreaInProgress }"
        [ngbTooltip]="'forms.map-viewer.area' | translate ">
      </dx-button>
      <dx-button id="polygon-draw-button" class="fence main-background-theme" icon="edit"
        [disabled]="searchAreaInProgress || dowloadImg || layersActivated" (onClick)="searchByPolygon()"
        [class.active]="searchPolygonInProgress" [ngClass]="{ 'main-background-theme': searchPolygonInProgress }"
        [ngbTooltip]="'forms.map-viewer.polygon' | translate ">
      </dx-button>

    </div>


  </div>
</dx-drawer>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: 'dx-drawer' }" [(visible)]="loading"
  [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>