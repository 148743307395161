import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { ServiceService } from '@app/@shared/services/map-elements-services/service.service';
import { PlatformService } from '@app/auth/platform.service';
import DataSource from 'devextreme/data/data_source';
import { firstValueFrom, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchPanelDataResolver implements Resolve<DataSource> {
  constructor(
  ) {}

  
  async resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    
  }
  

  
}
