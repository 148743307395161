export class SplitterType {
  splitterTypeID: number;
  platformID: number;
  name: string;
  model: string;
  specAttributes : any|SpecAttributes;
}
export interface SpecAttributes {
  name: string;
  state:  boolean;
  number: number;
  accessID: number;
  updated: boolean;
}