import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { D3CircularHierarchyTreeData } from '@app/@shared/model/d3/circular-hierarchy-tree.model';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { CredentialsService } from '@app/auth';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeviceBoxResolver implements Resolve<any[]> {
    
  constructor(
    private deviceService: DeviceService,
    private credentials: CredentialsService,
  ) {
    
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<D3CircularHierarchyTreeData[][]> {
    const tokenFromActivatedRoute: string = route.queryParamMap.get('token');
    if(tokenFromActivatedRoute){
      //The module is being loaded from an external link (PortalGIS)
      this.credentials.setCredentials(tokenFromActivatedRoute);
      
      if(route.params['containerID']){
        const mslink = route.params['containerID'];
        return this.deviceService.getDeviceBoxByContainerMslink(mslink);
      }

      const mslink = route.params['deviceID'];
      return this.deviceService.getDeviceBoxByMslink(mslink);
    }
    else{
      const deviceID = route.params['deviceID'];
      return this.deviceService.getDeviceBox(deviceID);
    }
  }
}
