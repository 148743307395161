<label class="main-secondary-text">SpecData</label>
<dx-radio-group [items]="radioEditionItems" [(value)]="radioEditionValue" layout="horizontal" style="padding-bottom: 5px;padding-top: 8px;" ></dx-radio-group>
<dx-scroll-view *ngIf="radioEditionValue == 'formatted'"
    #scrollView
    id="scrollview"
    [scrollByContent]="true"
    [scrollByThumb]="true"
    [height]="400"
    [showScrollbar]="'onScroll'"
    reachBottomText="Updating..." >
    <div fxLayout="row">
        <div fxFlex="50">
            <dx-tree-view
                id="simple-treeview"
                [items]="specTreeView"
                [width]="300"
                (onItemClick)="selectItem($event)"
                (onItemCollapsed)="itemCollapsed($event)"
                (onItemExpanded)="itemExpanded($event)"
                (onItemContextMenu)="treeViewItemContextMenu($event)"
            ></dx-tree-view>
        </div>
        <div fxFlex="50">
            <div *ngIf="selectedNode && selectedNode.__new" fxLayout="row" style="margin-bottom: 5px;">
                <div fxFlex="50">
                    <dx-select-box  labelMode="floating" [label]="'general.spec.type' | translate" 
                        [deferRendering]="false" [dataSource]="typeDS" [(value)]="selectedNode.type">
                    </dx-select-box>
                </div>
                <div class="form-field" fxFlex="50">
                    <dx-text-box 
                        labelMode="floating" 
                        [showClearButton]="true"
                        [label]="'general.name' | translate"  
                        [(value)]="selectedNode.name"
                        (onValueChanged)="onNewNodeNameChanged($event)" >
                    </dx-text-box>
                </div>
            </div>
            <div *ngIf="selectedNode && !selectedNode.__new" class="attr-title">{{ selectedNode.name }}</div>
            <div fxLayout="row" *ngIf="selectedNode && selectedNode.type">
                <div fxFlex="70">
                    <dx-text-box  labelMode="floating" *ngIf="selectedNode.type == 'string'"
                        [(value)]="selectedNode.value">
                    </dx-text-box>
                    <dx-number-box  labelMode="floating" *ngIf="selectedNode.type == 'number'"
                        [(value)]="selectedNode.value">
                    </dx-number-box>
                    <dx-select-box  labelMode="floating"  *ngIf="selectedNode.type == 'boolean'"
                        [deferRendering]="false" [dataSource]="booleanDS" [(value)]="selectedNode.value">
                    </dx-select-box>
                    <div *ngIf="selectedNode && selectedNode.type == 'symbol'">
                        <dx-radio-group [items]="radioItems" [(value)]="radioValue" layout="horizontal" style="padding-bottom: 5px;" ></dx-radio-group>
                        <dx-color-box [(value)]="selectedNode.value" *ngIf="radioValue != 'Text'"
                            [inputAttr]="{ 'aria-label': 'Default mode' }"
                        ></dx-color-box>
                        <dx-text-box  labelMode="floating" *ngIf="radioValue == 'Text'"
                            [(value)]="selectedNode.value">
                        </dx-text-box>
                    </div>
                </div>
                <div fxFlex="30" fxLayoutAlign="space-around end" >
                    <dx-button
                        class="grid-button"
                        (onClick)="selectedNode = undefined"
                        icon="fas fa-times"
                    ></dx-button>
                    <dx-button 
                        class="grid-button"
                        (onClick)="saveSpec(selectedNode)"
                        icon="fas fa-check"
                    ></dx-button>
                </div>
            </div>
        </div>
    </div>        
</dx-scroll-view>

<dx-text-area *ngIf="radioEditionValue == 'raw'"
    [class]="!validJSON ? 'textarea-invalid-value' : ''"
    [height]="400"
    [(value)]="specRawView"
    [autoResizeEnabled]="false"
    [inputAttr]="{ 'aria-label': 'Notes' }"
    (onValueChanged)="rawValueChanged($event)"
>
</dx-text-area>
<label *ngIf="!validJSON" class="invalid-json-label">{{ 'general.invalid-json' | translate }}</label>
 
<!--dx-context-menu
    [dataSource]="menuItems"
    target="#simple-treeview .dx-treeview-item"
    (onItemClick)="contextMenuItemClick($event)"
>
</dx-context-menu-->