<dx-accordion [dataSource]="dataSource" [collapsible]="true" [selectedIndex]="-1"   style="background-color: #f8f8f8"
>
    <div *dxTemplate="let item of 'title'">
        <h1 class="main-secondary-text">{{ 'project-detail.header' | translate }}</h1>
    </div>
    <div *dxTemplate="let item of 'item'">
        <p class="sub-title" style="margin-left: 1rem;">{{ 'project-detail.createdData' | translate }}</p>
        <div>
            <div fxLayout="row wrap" class="row-clearance">
                <div fxFlex="50" class="form-field">
                    <dx-text-box [disabled]="true" labelMode="floating" label="{{ 'project-detail.createdBy' | translate }}"
                        [value]="projectData.createdBy"></dx-text-box>
                </div>
                <div fxFlex="50" class="form-field">
                    <dx-text-box [disabled]="true" labelMode="floating" label="{{ 'project-detail.creationDate' | translate }}"
                        [value]="projectData.creationDate"></dx-text-box>
                </div>
            </div>
            <br>
            <p class="sub-title" style="margin-left: 1rem;">{{ 'project-detail.modifiedData' | translate }}</p>
            <div fxLayout="row wrap" class="row-clearance">
                <div fxFlex="50" class="form-field">
                    <dx-text-box [disabled]="true" labelMode="floating" label="{{ 'project-detail.updatedVersion' | translate }}"
                        [value]="projectData.updatedVersion"></dx-text-box>
                </div>
            </div>
            <div fxLayout="row wrap" class="row-clearance">
                <div fxFlex="50" class="form-field">
                    <dx-text-box [disabled]="true" labelMode="floating" label="{{ 'project-detail.editedBy' | translate }}"
                        [value]="projectData.editedBy"></dx-text-box>
                </div>
                <div fxFlex="50" class="form-field">
                    <dx-text-box [disabled]="true" labelMode="floating" label="{{ 'project-detail.editionDate' | translate }}"
                        [value]="projectData.editionDate"></dx-text-box>
                </div>
            </div>
        </div>
    </div>
</dx-accordion>
