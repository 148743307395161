<dx-data-grid
  [dataSource]="equipmentPorts"
  [showBorders]="true"
  [showRowLines]="true"
  [columnHidingEnabled]="true"
  (onRowUpdated)="rowUpdated($event)"
>
  <dxo-editing
    [allowUpdating]="true"
  >
  </dxo-editing>

  <dxo-paging [pageSize]="10"></dxo-paging>
    
  <dxo-pager
    [visible]="true"
    [displayMode]="'full'"
    [showPageSizeSelector]="false"
    [showInfo]="false"
    [showNavigationButtons]="true"
  >
  </dxo-pager>

  <dxi-column [allowEditing]="false"  dataType="string" [caption]="'olt.modal.grid-view.grid-header.port-name' | translate" dataField="name"></dxi-column>
  <dxi-column [caption]="'olt.modal.grid-view.grid-header.description' | translate" dataField="description"></dxi-column>
  <dxi-column [caption]="'olt.modal.grid-view.grid-header.fiber-id' | translate" dataField="fiberID"></dxi-column>

</dx-data-grid>
