import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FileUploadComponent } from '@app/@shared/components/file-uploader/file-uploader.component';
import { Cons } from '@app/@shared/cons/cons';
import { PlatformPlot } from '@app/@shared/model/platform-plot.model';
import { UserFile } from '@app/@shared/model/userFile.model';
import { Plot } from '@app/@shared/model/plot.model';
import { FileService } from '@app/@shared/services/aux-services/file.service';
import { OlMapHelperService } from '@app/@shared/services/aux-services/ol-map-helper.service';
import { PositionStackService } from '@app/@shared/services/aux-services/position-stack.service';
import { MainService, Utilities } from '@app/@shared/services/main.service';
import { PlotService } from '@app/@shared/services/map-elements-services/plot.service';
import { plainToInstance } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { PlotAddress } from '../models/plot-address';
import { CameraService } from '@app/@shared/services/cordova/camera.service';
import { FilesystemService } from '@app/@shared/services/cordova/filesystem.service';
import { PlatformService } from '@app/auth/platform.service';

declare var navigator: any;
declare var Camera: any;

@Component({
  selector: 'app-plot-form',
  templateUrl: './plot-form.component.html',
  styleUrls: ['./plot-form.component.scss'],
})
export class PlotFormComponent implements OnInit, OnChanges {

  @Input('plot') _plot: Plot;
  plot: Plot;

  @Input() centerExtent: {
    coordinates: number[],
    srID: string,
  };

  @Output() cancel = new EventEmitter<void>();
  @Output() nextPlot = new EventEmitter<void>();
  @Output() plotAddressUpdated = new EventEmitter<Plot>();
  @Output() platformPlotChange = new EventEmitter<PlatformPlot>();

  @ViewChild("fileUploader") fileUploader : FileUploadComponent;

  platformID: number;
  files : UserFile[] = [];
  popups = {
    savedPlotData: false,
    editAddress: false,
  };

  disableSaveButton: boolean = false;
  address: PlotAddress;

  model: PlatformPlot = new PlatformPlot();
  platformFiles : UserFile[] = [];
  no_file_triggered = false;
  file_url = '';
  url: string;
  showUploader = true;

  constructor(
    private cameraService: CameraService,
    private filesystemService: FilesystemService,
    public mainService: MainService,
    private plotService: PlotService,
    private positionStackService: PositionStackService,
    private fileService : FileService,
    private olMapHelperService : OlMapHelperService,
    public platformService: PlatformService,
  ) {}

  ngOnInit() {
    const platformID = this.platformService.platformID;
    const file_url = this.mainService.getAPIByName("FILE");

    this.model.platformID = platformID
    this.model.platformPlotAttributes = { shop: false };
    this.url = file_url + 'File/' + platformID;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['_plot'] && !!changes?.['_plot']?.currentValue) {
      this.plot = plainToInstance(Plot, changes?.['_plot']?.currentValue);
      this.setPlot();
    }
  }

  resetFieldsToDefault = () => {
    this.model.platformPlotAttributes = {};
    this.address = undefined;
    this.popups.savedPlotData = false;
    this.popups.editAddress = false;
    this.validateData();
  };

  setPlot = async () => {
    this.showUploader = false;
    const platformPlot = this.plot?.platformPlot;

    if (platformPlot.length > 0) {
      this.model = { ...platformPlot[0] };
      this.showShops = !!this.model.platformPlotAttributes?.shops;
    } else {
      this.resetFieldsToDefault();
    }

    this.model.plotID = this.plot.plotID;
    this.model.plot = plainToInstance(Plot, this.plot);
    //Avoid circular reference
    this.model.plot.platformPlot = [];

    await this.getAddress();
    await this.getFiles();
    this.validateData();

    this.showUploader = true;
  };

  save = async () => {
    if (!this.showShops)  {
      this.model.platformPlotAttributes.shops = undefined;
    }

    await firstValueFrom(this.plotService.savePlatformPlot(this.model));
    this.files.forEach(async x=> { await firstValueFrom(this.fileService.save(x)); });
    this.popups.savedPlotData = true;

    const platformPlot = this.plotService.cleanUpForRequest(this.model);
    this.platformPlotChange.emit(platformPlot);
  };

  getAddress = async () => {
    this.address = this.plot.plotAttributes.address;

    if (!!this.address?.Street) {
      return;
    }

    // transformToLatLon modifies the original extent value.. a copy will be needed
    let extent =  JSON.parse(JSON.stringify(this.centerExtent));
    const [lng, lat] = (this.centerExtent.srID != Cons.SRIDS.S4326 ? Utilities.plainArray(this.olMapHelperService.transformToLonLat(extent).coordinates) : extent.coordinates);
    if(isNaN(lng) || !lng || lng == 0 || isNaN(lat) || !lat || lat == 0){
      return;
    }

    const addressInfo = await firstValueFrom(
      this.positionStackService.getLocationByLatLong(lat, lng)
    );

    const newAddress: PlotAddress = {
      Country: addressInfo.country,
      Number: addressInfo.number,
      PostalCode: addressInfo.postal_code,
      Region: addressInfo.region,
      Street: addressInfo.label,
    };

    await firstValueFrom(this.plotService.save(this.plot.plotID, newAddress)).then(()=>{
      this.model.plot.plotAttributes.address = newAddress;
      this.plotAddressUpdated.emit(this.model.plot);
    });

    this.address = newAddress;
  };

  getFiles = async () => {
    let filter = new UserFile();
    filter.platformID = this.platformService.platformID;
    filter.elementID = this.plot.plotID;
    filter.entityType = Cons._PLATFORM_PLOT;

    /*
    this.fileService.getWithFilter(filter).subscribe((res)=> {
      if(res){
        this.files = res;
      }
    });*/
  };

  validateData = () => {
    const addressIsValid = !!this.address?.Street;
    const hpIsValid = !!this.model.platformPlotAttributes.hp;
    const floorsAreValid = !!this.model.platformPlotAttributes.floors;
    const shopIsValid = this.model.platformPlotAttributes.shop ? !!this.model.platformPlotAttributes.shops : true;

    this.disableSaveButton = [
      addressIsValid,
      hpIsValid,
      floorsAreValid,
    ].some((x) => x === false);
  };

  showShops = false;
  shopChange = (checkValue: boolean) => {
    this.showShops = checkValue;
    this.validateData();
  };

  hpChange = (hp: number) => {
    this.model.platformPlotAttributes.hp = hp;
    this.validateData();
  };

  numberOfShopsChange = (shops: number) => {
    this.model.platformPlotAttributes.shops = shops;
    this.validateData();
  };

  floorsChange = (floors: number) => {
    this.model.platformPlotAttributes.floors = floors;
    this.validateData();
  };

  onNextClicked(){
    this.popups.savedPlotData = false;
    this.nextPlot.emit();
  }

  fileUploaded($event){
    if($event && $event.length != 0){
      $event.forEach((x : UserFile)=> {
        x.entityType = Cons._PLATFORM_PLOT;
        x.elementID = this.plot.plotID;        
        this.files.push(x);
      });

      this.fileUploader.resetValue();
    }
  }

  deleteFile($event){
    let index = this.files.findIndex(x=> x.fileID == $event.fileID);
		if(index > -1){
			this.files.splice(index, 1);
		}
  }

  viewFile($event){
    window.open($event.filePath, "_BLANK");
  }

  async takePhoto() {
    if  (!navigator.camera) {
      return;
    }

    let blobImage: Blob;

    const cameraOptions = { 
      destinationType: Camera.DestinationType.FILE_URI,         
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE
    };

    try {
      const imageUri: string = await this.cameraService.getPictureImgUri(cameraOptions);
      const fileEntry = await this.filesystemService.getFileEntry(imageUri);
      blobImage = await this.filesystemService.transformFileEntryToBlob(fileEntry);
    } catch (ex) {
      console.log('Error!', ex);
      return;
    }

    const formData = new FormData()
    formData.append('files[]', blobImage);
    await firstValueFrom(this.fileService.uploadFile(formData));
    this.fileUploaded({ length: 1 });
  }
}
