import { vOdfBox } from "../aux-models/vOdfBox";

export interface D3HorizontalTreeData extends Partial<LeafData> {
  children: D3HorizontalTreeData[];
  descr: string;
  icon: string;
  color: string;
  name: string;
  type: string;
  leaf?: string;
  parentElement? : any
}

export interface LeafData {
  color: string;
  descr: string;
  fiberID: number;
  serviceID: number;
}


export class D3HorizontalTreeDataMapper{
  static Map(oCol : vOdfBox[]) : D3HorizontalTreeData[]{
    let retval = [];
    oCol.forEach(entity=> {
      let target : D3HorizontalTreeData = {
        children: entity.children,
        descr: '',
        icon: entity.icon,
        name: entity.name,
        type: '',
        color: '',
        parentElement: entity.infrastructureID
      }
      retval.push(target);
    });
    

    return retval;
  }
}