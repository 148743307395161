<div fxLayout="row">
    <div fxFlex="50" style="padding-top:10px; padding-left:30px">
        <h1 class="app-name" style="font-size: 1.5em; font-family: 'Montserrat'; cursor: pointer;">
            SFN
        </h1>
    </div>
    <div fxFlex="50" fxLayoutAlign="end center" style="padding-top:10px; padding-right: 30px; padding-bottom: 10px;">
        <dx-button
            class="btn-outline"
            [text]="'forms.login.login' | translate"
            width="150"
            height="35"
            (click)="redirectToLogin()" >
        </dx-button>
    </div>
</div>
<div class="header-legend">
    Try Smart Fiber Network <a style="color: #FFFFFF;" href="">here</a>
    <!-- div class="close">X</div-->
</div>

<div style="padding: 0 3em">
    <dx-gallery
    [dataSource]="items"
    [height]="420"
    width="100%"
    [loop]="true"
    [showNavButtons]="false"
    [showIndicator]="true">
    <div *dxTemplate="let galleryItem of 'item'">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
            <div  class="item-legend" [innerHTML]="galleryItem.legend"></div>
            <div  fxLayoutAlign="center center" style="height:300px"><img class="galleryImg" src="{{ galleryItem.img }}" /></div>
        </div>
        </div>
    </dx-gallery>
</div>

   



<div style="padding: 0 7em; place-content: center; display: flex; flex-direction: column;">
    <div class="content-services">
        <h3 style="color: #5F1347; text-align: left; margin-left: 10px; font-weight: 600;">Smart Fiber Network</h3>
        <p id="designed"style="color: #777777;text-align: left; margin-left: 10px;font-weight: 400; font-size: 14px; line-height: 16px;">{{'forms.main.paragraph_1' | translate}}</p>
    </div>
    <div  fxLayout="row" fxLayout.xs="column" style="max-width:1365px">
        <div  fxLayout="column" fxFlex="40" class="group p-1 m-1">
            <div *ngFor="let item of items" (click)="show(item)" class="feature-container p-3 my-2"
                [ngClass]="{'feature-container-selected': item.selected === true}"> 
                <img class="feature-img" [src]="item.img" *ngIf = "item.selected === false"    >
                <img class="feature-img" [src]="item.imgSelected" *ngIf = "item.selected === true"    >
                <div style="padding-left: 20px">{{ item.title }}</div>
                <img src="../../assets/main/Vector.png" alt="" style="position: absolute; right: 30px;" *ngIf="item.selected === true">
            </div>
        </div>
        <div  fxFlex="60" class="feature-container p-3 m-3">
            <div fxLayout="row">
                <div fxLayout="row" fxFlex="50">
                    <div fxLayout="column" >
                        
                        <div class="title" >{{ selectedItem?.title  }}</div>
                        <div class="legend" >{{ selectedItem?.legendaDerecha}}</div> 
                    </div>
                </div>
                <div fxLayout="row" fxFlex="50">
                    <img class="slide" [src]="selectedItem?.imgDerecha">
                </div>
            </div>
        </div>
    </div>
</div>
   
    
  