import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'headend-detail',
  templateUrl: './headend-detail.component.html',
  styleUrls: ['./headend-detail.component.scss']
})
export class HeadendDetailComponent implements OnInit {

  headendData = [
    {
      name: 'Headend Central',
      location: 'Av. Principal 123, Ciudad Capital'
    },
    {
      name: 'Headend Norte',
      location: 'Calle Secundaria 456, Ciudad Norte'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
