import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LanguageSelectorComponent } from './language-selector.component';
import {
  DxActionSheetModule,
  DxButtonModule,
  DxListModule,
  DxPopoverModule,
  DxSelectBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxListModule,
    DxActionSheetModule,
    DxButtonModule,
    DxPopoverModule,
  ],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
})
export class I18nModule {}
