import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '@app/auth/authentication.service';
import { PlatformService } from '@app/auth/platform.service';
import { firstValueFrom } from 'rxjs';

import notify from "devextreme/ui/notify"
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuard implements CanActivate {
  constructor(
    private router: Router,
    private platformService: PlatformService,
    private authService: AuthenticationService,
    private translateService: TranslateService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Should be greater than 1, maybe the platformName has-this-character besides the dash separator.
    const urlHasPlatform = state.url.split('/')[1].split('-').length > 1;

    if (!urlHasPlatform) {
      return true;
    }

     //18-12-23: Authentication can come from embeeded token in url
     const tokenFromActivatedRoute: string = route.queryParamMap.get('token');
     if(tokenFromActivatedRoute){
      const activePlatform = this.platformService.getActivePlatform(state.url, tokenFromActivatedRoute);
      if (activePlatform) {
        return true;
      }
     }
     else{
      const activePlatform = this.platformService.getActivePlatform(state.url);
      if (activePlatform) {
        return true;
      }
     }
    
    await firstValueFrom(this.authService.logout());
    await this.router.navigate(['/login'], { replaceUrl: true });
    notify(this.translateService.instant('platform.no-permissions'), 'error', 4500);

    return false;
  }
}
