<dx-data-grid
  [dataSource]="infrastructure"
  [showBorders]="true"
  [showRowLines]="true"
  [columnHidingEnabled]="true"
  
>
  <dxo-paging [pageSize]="10"> </dxo-paging>
    
  <dxo-pager
    [visible]="true"
    [displayMode]="'full'"
    [showPageSizeSelector]="false"
    [showInfo]="false"
    [showNavigationButtons]="true"
  >
  </dxo-pager>

  <dxi-column dataType="string" [caption]="'ID'" dataField="infrastructureID" [hidingPriority]="5"></dxi-column>
  <dxi-column dataType="string" [caption]="'infrastructure.grid-header.name' | translate" dataField="name" [hidingPriority]="2"></dxi-column>
  <dxi-column [caption]="'infrastructure.grid-header.model' | translate" dataField="elementType.name" [hidingPriority]="1"></dxi-column>
  <dxi-column [caption]="'infrastructure.grid-header.description' | translate" dataField="description" [hidingPriority]="3"></dxi-column>
  <dxi-column width="78" [caption]="''" cellTemplate="actionsCellTemplate" [hidingPriority]="4"></dxi-column>

<div *dxTemplate="let d of 'actionsCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <i 
          (click)="editItem.emit(d.data)"
          class="fas fa-pen clickable mx-2"
          placement="bottom" [ngbTooltip]="'infrastructure.edit-device' | translate" container="body"
        ></i>
        <i 
          (click)="deleteItem.emit(d.data)"
          class="fa fa-trash clickable"
          placement="bottom" [ngbTooltip]="'infrastructure.delete-device' | translate" container="body"
        ></i> 
      </div>
    </div>
  </div>
</dx-data-grid>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [visible]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>