import { NgModule } from '@angular/core';
import { AngularMaterialModule } from './angular-material.module';

const modules = [
    AngularMaterialModule,
];

@NgModule({
    imports: modules,
    exports: modules
})
export class ThirdPartyModule {}
