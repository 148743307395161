<dx-accordion #accordion_element [dataSource]="ds_accordion_element" [collapsible]="true" [multiple]="false"
  [animationDuration]="300" [selectedItems]="[ds_accordion_element[0]]" id="accordion-element-container"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="item.data.infrastructureAttributes.mslink"
          [disabled]="true"
          labelMode="floating"
          [label]="'general.bentley.mslink' | translate"
        >
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.elementType.name" [disabled]="true" labelMode="floating"
          [label]="'general.model' | translate">
        </dx-text-box>
      </div>
    </div>
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.name" [disabled]="true" labelMode="floating"
          [label]="'general.name' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.description" [disabled]="true" labelMode="floating"
          [label]="'general.description' | translate">
        </dx-text-box>
      </div>
    </div>
    <!--div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="100" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.infrastructureAttributes.address" [disabled]="true" labelMode="floating"
          [label]="'general.address' | translate">
        </dx-text-box>
      </div>
    </div-->

    <!--BENTLEY-->
    <div fxLayout="row wrap" class="row-clearance">

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="item.data.infrastructureAttributes.xfmID"
          [disabled]="true"
          labelMode="floating"
          [label]="'general.bentley.xfmid' | translate"
        >
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="item.data.infrastructureAttributes.poleno"
          [disabled]="true"
          labelMode="floating"
          [label]="'general.bentley.poleno' | translate"
        >
        </dx-text-box>
      </div>
    </div>
   
      <div fxLayout="row wrap" class="row-clearance">
        <div fxFlex="50" class="form-field custom-disabled-text-box">
          <dx-text-box
            [(value)]="item.data.infrastructureAttributes.owner"
            [disabled]="true"
            labelMode="floating"
            [label]="'general.bentley.owner' | translate"
          >
          </dx-text-box>
        </div>
        <div fxFlex="50" class="form-field custom-disabled-text-box">
          <dx-text-box
            [(value)]="item.data.infrastructureAttributes.jobDesc"
            [disabled]="true"
            labelMode="floating"
            [label]="'general.bentley.jobDesc' | translate"
          >
          </dx-text-box>
        </div>
      </div>

    
    <div fxLayout="row wrap" class="row-clearance">
  

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="item.data.infrastructureAttributes.jobName"
          [disabled]="true"
          labelMode="floating"
          [label]="'general.bentley.jobName' | translate"
        >
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="projectDataDetailDescr2"
          [disabled]="true"
          labelMode="floating"
          [label]="'descr2' | translate"
        >
        </dx-text-box>
      </div>

    </div>
   
    <!---------------------------------->


    <div style="padding-top: 8px; text-align: right; padding-right: 6px;">
      <dx-button class="odf-view me-2" icon="fas fa-file-download" [text]="'general.download' | translate"
        [hint]="'general.download' | translate" (click)="preparingDownload(item.data)"
        [disabled]="disableDownloadAndInspectData">
      </dx-button>
      <dx-button class="odf-view" icon="far fa-eye" [text]="'general.inspect' | translate"
        [hint]="'general.inspect' | translate" (click)="odfView()" [disabled]="disableDownloadAndInspectData">
      </dx-button>
    </div>
  </div>
</dx-accordion>


<dx-accordion
  *ngIf="ds_accordion_equipment && ds_accordion_equipment.length != 0 && entity?.elementType?.specAttributes?.showODF"
  class="mt-4" [dataSource]="ds_accordion_equipment" [collapsible]="true" [multiple]="false" [animationDuration]="300"
  [selectedItems]="ds_accordion_equipment_selected" style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-data-grid id="equipment-list" [dataSource]="item.data" [remoteOperations]="false" [allowColumnReordering]="true"
      [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true"
      [noDataText]="'comp.device-detail.splitters.no-devices' | translate" (onRowClick)="equipmentSelected($event)" (onExporting)="onExporting($event)">
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxi-column dataField="name" dataType="string"></dxi-column>
      <!-- <dxi-column dataField="splitterType.name" dataType="string" caption="Splitter Type"></dxi-column> -->
      <dxi-column dataField="description" dataType="string"></dxi-column>
      <dxo-export [enabled]="true"></dxo-export>
    </dx-data-grid>
  </div>
</dx-accordion>

<div *ngIf="infrastructureToDownload">
  <infrastructure-odf [infrastructure]="infrastructureToDownload"
    (componentReady)="downloadInfrastructureODF()"></infrastructure-odf>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="downloadingInfrastructureODF" [showIndicator]="true"
  [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<div class="projectDetail">
  <project-detail [projectDataDetail]="projectDataDetail"></project-detail>
</div>