import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserSearch } from '@app/@shared/model/aux-models/user-search.model';

@Component({
  selector: 'app-service-chip-list',
  templateUrl: './service-chip-list.component.html',
  styleUrls: ['./service-chip-list.component.scss'],
})
export class ServiceChipListComponent {
  @Input() services: UserSearch[];
  @Input() selectedService: UserSearch;

  @Output() toggleVisibility = new EventEmitter<UserSearch>();
  @Output() remove = new EventEmitter<UserSearch>();
  @Output() center = new EventEmitter<UserSearch>();
}
