import { Component, Input } from '@angular/core';
import { FeasibilityStudy } from '@app/@shared/model/feasibilityStudy.model';

@Component({
  selector: 'feasibility-history-list',
  templateUrl: './feasibility-history-list.component.html',
  styleUrls: ['./feasibility-history-list.component.scss'],
})
export class ClientFeasibilityHistoryListComponent {
  @Input() feasibilityHistory: FeasibilityStudy[] = [];
}
