import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Cons } from '../cons/cons';
import { Observable, catchError, map, throwError } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { ElementType } from '../model/elementType.model';
import { PlatformService } from '@app/auth/platform.service';
import { UserProfile } from '../model/userProfile.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) { }

  getWithFilter(entity: any, limit, offset): Observable<any> {
    this.main.chooseAPI(Cons._AUTHENTICATION);
    
    return this.main.getWithFilter(Cons._USERPROFILE, entity, false, limit, offset).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByID(id : number): Observable<any> {
    this.main.chooseAPI(Cons._AUTHENTICATION);
    return this.main.getByID(Cons._USERPROFILE, id).pipe(
      map((res) => {
        try {
          res.commonID = res.userID;
          res.entityName = Cons._USERPROFILE;
          return plainToInstance(UserProfile, res);
        } catch (ex) {
          return (<any>res)._body.toString();
        }
      })
    );
  }
  
  update(entity: UserProfile): any {
    this.main.chooseAPI(Cons._AUTHENTICATION);
    const path =`${this.platformService.platformID }/${entity.userID}`;
    return this.main.put(Cons._USERPROFILE, path, entity).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  resetPassword(entity: UserProfile): any {
    this.main.chooseAPI(Cons._AUTHENTICATION);
    const path =`ResetPassword/${this.platformService.platformID }/${entity.userID}`;
    return this.main.put(Cons._USERPROFILE, path).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  
  create(entity: UserProfile): Observable<any> {
    this.main.chooseAPI(Cons._AUTHENTICATION);
    return this.main.post(Cons._USERPROFILE, undefined, entity).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

}
