<dx-data-grid
    [dataSource]="SplitterTypes"
    [showBorders]="true"
    [showRowLines]="true">
    <dxo-paging [pageSize]="10"> </dxo-paging>
    
    <dxo-pager
    [visible]="true"
    [displayMode]="'full'"
    [showPageSizeSelector]="false"
    [showInfo]="false"
    [showNavigationButtons]="true"
    >
    </dxo-pager>

    <dxi-column [caption]="'element.grid-header.ID' | translate" dataField="splitterTypeID" ></dxi-column>
    <dxi-column [caption]="'element.grid-header.name' | translate" dataField="name"></dxi-column>
    <dxi-column [caption]="'element.grid-header.reference' | translate" dataField="model"></dxi-column>
    <dxi-column width="75" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>


    <div *dxTemplate="let d of 'actionsCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
        <i 
            class="mx-2 fas fa-sharp fa-solid fa-pen clickable"
            placement="bottom" [ngbTooltip]="'element.grid-action.edit-element' | translate" container="body"
            (click)="editItem.emit(d.data)"
        ></i>
        <i 
            class="mx-2 fas  fa-solid fa-trash clickable"
            placement="bottom" [ngbTooltip]="'element.grid-action.delete-element' | translate" container="body"
            (click)="deleteItem.emit(d.data)"
        ></i>
        </div>
    </div> 
    </div>
</dx-data-grid>