import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { Cons } from '@app/@shared/cons/cons';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { Container } from '@app/@shared/model/container.model';
import { Trace } from '@app/@shared/model/trace.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { OlMapHelperService, OpenLayerGeometryTypes } from '@app/@shared/services/aux-services/ol-map-helper.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-trace-modal',
  templateUrl: './trace-modal.component.html',
  styleUrls: ['./trace-modal.component.scss']
})
export class TraceModalComponent implements OnInit {




  @Input() isPopupVisible: boolean = false;
  @Input() selectedTrace: Trace;
  @Input() dataSourceGrid: any[];
  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('olMap') olMap: OlMapComponent;
  latitude: number = -34.604369;
  longitude: number = -58.3850703;
  zoom: number = 8;
  lineString: olMapLineString[]=[];

  containerDataSource: DataSource<any, any>;
  
  constructor(private olMapHelperService: OlMapHelperService,private allDatasources: DataSourceService,) { 
  }

  ngOnInit(): void {
    const c = Cons.OLSTYLES.$traceDefaultColor;
    this.lineString.push(this.olMapHelperService.OlMapLineString.toMapFeature(this.selectedTrace, this.selectedTrace.traceID.toString(), c));
    const containers: Container[] = this.allDatasources.allContainers;
    this.containerDataSource = new DataSource({ store: containers });
  }
  saveTrace() {
    this.save.emit(this.selectedTrace);
  }
  mapReady() {
    this.olMap._linestring=this.lineString;
    this.olMap.renderLineString(false);
    const coordinates = this.lineString[0].flatCoordinates[0];
    if (typeof(coordinates[0]) === 'number') {
      this.olMap.moveTo(coordinates[1],coordinates[0],14);
    } else {
      this.olMap.moveTo(coordinates[0][1],coordinates[0][0],14);
    }    
  }
}
