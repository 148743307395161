import { Injectable } from '@angular/core';

declare var navigator: any;

@Injectable({ providedIn: 'root' })
export class CameraService {

  constructor() {}

  getPictureImgUri(cameraOptions?: any): Promise<string> {
    if (!navigator) {
        return Promise.reject({ navigator: false });
    }

    return new Promise<any>((resolve, reject) => navigator.camera.getPicture(resolve, reject, cameraOptions));
  }
}
