import { Component, OnInit, Input } from '@angular/core';
import langConfig from './languages-setup.json';
import { I18nService } from './i18n.service';
import { confirm } from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as DevExpress from 'devextreme/ui/dialog';


@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() inNavbar = false;
  @Input() menuClass = '';

  constructor(private i18nService: I18nService, private trans: TranslateService) { }

  get currentLanguage(): any {
    let current = this.i18nService.language;
    let lang = langConfig.find((l) => l.culture == current);

    return lang;
  }

  get languages(): any[] {
    return langConfig;
  }

  actionSheetVisible = false;
  actionSheetTarget: any = '';

  ngOnInit() { }

  itemClick(e) {
    this.actionSheetTarget = e.itemElement;
    this.actionSheetVisible = true;
  }

  setLanguage($event) {
    const message = `<i>${this.trans.instant("general.reload-confirmation.message")}</i>`;
    const title = this.trans.instant("general.confirm-action");

    DevExpress.custom({
      title,
      messageHtml: message,
      buttons: [
        { text: this.trans.instant('general.yes'), onClick: () => this.reloadLanguage($event.culture) },
        { text: this.trans.instant('general.no'), onClick: () => { } }
      ]
    }).show().then((dialogResult) => {
    });
  }

  reloadLanguage(culture) {
    this.i18nService.language = culture;
    this.actionSheetVisible = false;
    window.location.reload();
  }
}
