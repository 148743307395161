<label class="main-secondary-text">{{ 'element.grid-action.edit-element' | translate }}</label>
<div fxLayout="column" fxLayoutAlign="space-between none">
    <div class="flex flex-wrap justify-content-between">

        <ng-container *ngFor="let elementEditableField of elementEditableData">
            <ng-container *ngIf="entity; else elseTemplate">
                <dx-text-box fxFlex="49" class="my-3"
                [(value)]="entity[elementEditableField.field]"
                labelMode="floating"
                [label]="elementEditableField.label | translate"
                >
                </dx-text-box>
            </ng-container>
            <ng-template #elseTemplate>
                <dx-text-box fxFlex="49" class="my-3"
                [(value)]="elementEditableField.label"
                labelMode="floating"
                [label]="elementEditableField.label | translate"
                >
                </dx-text-box>
            </ng-template>
        </ng-container>
    </div>
</div>
<spec-tree-view 
    [specAttributes]="entity.specAttributes"
    (onSpecSaved)="specSaved($event)"
    (isJSONValid)="isJSONValid = $event">
</spec-tree-view>

<div fxLayout="row" fxLayoutAlign="end center" class="my-4 pe-2 popup-button">
    <dx-button style="margin-right: 10px;"
      [text]="'general.cancel' | translate"
      (click)="cancel()" >
    </dx-button>
    <dx-button
      class="main-background-theme"
      [text]="'general.save' | translate"
      (click)="save()"
    >
    </dx-button>
</div>