<div class="plot-form-container px-4 pt-4">
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="main-secondary-text mb-0">{{ address?.Street }}</h1>
    <i
      class="main-secondary-text fas fa-pen clickable"
      (click)="popups.editAddress = true"
    ></i>
  </div>

  <div class="d-flex justify-content-end">
    <div fxFlex="48">
      <dx-check-box
        class="mb-2"
        [value]="showShops"
        (valueChange)="shopChange($event)"
        [text]="'survey.plot-form.shop' | translate"
      ></dx-check-box>
    </div>
  </div>

  <div class="mb-4">
    <div class="plot-body-container">
      <div class="plot-input">
        <label>{{ 'survey.plot-form.HPperPlot' | translate }}</label>
        <dx-number-box
          [value]="model?.platformPlotAttributes?.hp"
          (valueChange)="hpChange($event)"
          [showSpinButtons]="true"
          [showClearButton]="false"
          [min]="0"
        ></dx-number-box>
      </div>

      <div class="plot-input" *ngIf="showShops">
        <label>{{ 'survey.plot-form.numberOfShops' | translate }}</label>
        <dx-number-box
          [value]="model?.platformPlotAttributes?.shops"
          (valueChange)="numberOfShopsChange($event)"
          [showSpinButtons]="true"
          [showClearButton]="false"
          [min]="0"
        ></dx-number-box>
      </div>

      <div class="plot-input">
        <label>{{ 'survey.plot-form.floors' | translate }}</label>
        <dx-number-box
          [value]="model?.platformPlotAttributes?.floors"
          (valueChange)="floorsChange($event)"
          [showSpinButtons]="true"
          [showClearButton]="false"
          [min]="0"
        ></dx-number-box>
      </div>
    </div>
  </div>

  <div class="d-flex mb-4 justify-content-around align-items-center" *ngIf="showUploader">
    <ng-container *ngIf="mainService.isMobile">
      <dx-button icon="photo" (onClick)="takePhoto()"> </dx-button>
    </ng-container>
    <file-uploader *ngIf="false"
      #fileUploader
      [buttonMode]="true"
      [label]="'survey.plot-form.screenshot' | translate"
      [allowedTypes]="['.jpg', '.jpeg', '.gif', '.png']"
      [url]="url"
      (onFileUploaded)="fileUploaded($event)"
      [required]="no_file_triggered"  
    ></file-uploader>
    <div *ngFor="let f of files" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="8" style="text-align: center;">
        <dx-button style="border: none;"
          stylingMode="contained"
          icon="fas fa-trash"
          (click)="deleteFile(f)">
        </dx-button>
      </div>
      <div fxFlex="8" style="text-align: center;">
        <dx-button style="border: none;"
          stylingMode="contained"
          icon="fas fa-eye"
          (click)="viewFile(f)">
        </dx-button>
      </div>
      <div fxFlex="80" style="padding: 6px 10px;">{{f.name}}</div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-2">
    <dx-button
      fxFlex="40"
      class="button quick-access-button cancel-button"
      [text]="'general.cancel' | translate"
      width="200"
      (click)="cancel.emit()"
    >
    </dx-button>
    <dx-button
      fxFlex="40"
      class="button main-background-theme save-button"
      [text]="'general.save' | translate"
      width="200"
      (click)="save()"
      [disabled]="disableSaveButton"
    >
    </dx-button>
  </div>
</div>

<dx-popup
  [(visible)]="popups.editAddress"
  [closeOnOutsideClick]="false"
  [showTitle]="false"
  [height]="175"
>
  <div *dxTemplate="let data of 'content'">
    <app-edit-address-modal
      [plotID]="plot.plotID"
      [(address)]="address"
      (close)="popups.editAddress = false"
    ></app-edit-address-modal>
  </div>
</dx-popup>

<dx-popup
  [width]="(mainService.isMobile ? '80vw': '35vw')"
  [(visible)]="popups.savedPlotData"
  [closeOnOutsideClick]="false"
  [showTitle]="false"
  [height]="225"
>
  <div *dxTemplate="let data of 'content'">
    <app-plot-saved-successfully-modal
      (close)="popups.savedPlotData = false"
      (next)="onNextClicked()"
    ></app-plot-saved-successfully-modal>
  </div>
</dx-popup>
