<dx-accordion #accordion [dataSource]="ds_accordion_search" [collapsible]="true" [multiple]="false"
  [animationDuration]="300" [selectedItems]="accordion_search_selected" id="search_accordion"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-radio-group [items]="searchTypes" [value]="searchModel.searchType" layout="horizontal"
      (onValueChanged)="radioChanged($event)">
    </dx-radio-group>

    <div fxLayout="row" class="row-clearance" *ngIf="showElements">
      <div fxFlex="100">
        <dx-select-box labelMode="floating" [value]="selectedType"
          [label]="'comp.search-panel.choose-element' | translate" [dataSource]="ds_types" fieldTemplate="field"
          [disabled]="searchElementDisabled" [placeholder]="'comp.search-panel.choose-element' | translate"
          (onValueChanged)="onElementTypePicked($event)" (onFocusIn)="onTextBoxFocusIn($event)"
          (onFocusOut)="onTextBoxFocusOut($event)">
          <div *dxTemplate="let data of 'field'">
            <div class="custom-item">
              <dx-text-box
                [value]="data && (data.name + ' ' + data.description)"
                [readOnly]="true"
              ></dx-text-box>
            </div>
          </div>
          <div *dxTemplate="let data of 'item'">
            <div class="custom-item">
              <div>
                {{data.name}} <b>{{ data.description }}</b>
              </div>
            </div>
          </div>
        </dx-select-box>
      </div>
    </div>

    <div fxLayout="row" style="margin-top: 10px" *ngIf="showElements">
      <div fxFlex="100">
        <!-- OS Autosuggest -->
        <dx-select-box #selectBoxOS *ngIf="searchElementDisabled" labelMode="floating"
          [displayExpr]="displayServiceName" [deferRendering]="false" [label]="'comp.search-panel.os' | translate"
          [dataSource]="element_datasource" [placeholder]="'comp.search-panel.os' | translate" [searchTimeout]="500"
          [searchMode]="'contains'" [searchExpr]="'name'" [searchEnabled]="true" [showDataBeforeSearch]="true"
          [(value)]="selectBoxValue" (onFocusIn)="onTextBoxFocusIn($event)" (onFocusOut)="onTextBoxFocusOut($event)">

          <dx-validator #service_validator>
            <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>

        <!-- ELEMENT NAME Autosuggest (Based on selected element) -->
        <dx-select-box *ngIf="!searchElementDisabled" labelMode="floating" fieldTemplate="field"
          [label]="'comp.search-panel.element' | translate" [dataSource]="element_datasource"
          [placeholder]="'comp.search-panel.element' | translate" displayExpr="name" [(value)]="selectBoxValue"
          [searchMode]="'contains'" searchExpr="name" [searchEnabled]="true" [searchTimeout]="500"
          [noDataText]="'comp.search-panel.no-data' | translate">
          <dx-validator #element_validator>
            <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
          </dx-validator>
          <div *dxTemplate="let data of 'field'">
            <dx-text-box #selectTextBox class="product-name" style=" display: inline-block;width:80%"
              [value]="(data && data.multipleSearchField ? data.multipleSearchField : data ? data.name : '')"
              (onFocusIn)="onTextBoxFocusIn($event)" (onFocusOut)="onTextBoxFocusOut($event)">
            </dx-text-box>
          </div>

          <div *dxTemplate="let data of 'item'" class="custom-item" style="color: #333;">
            <span style="margin-right: 7px;"></span>
            {{ data.multipleSearchField ? data.multipleSearchField : data.name }}
          </div>

          <div *dxTemplate="let data of 'dropDownButton'">
            <dx-load-indicator [visible]="allDatasources.loading"></dx-load-indicator>
          </div>
        </dx-select-box>
        <br>

        <p style="font-size: 10px">
          <span class="red-asterisk">*</span>&nbsp;&nbsp;Ingrese al menos 4 caracteres para una busqueda mas exacta
        </p>
      </div>
    </div>


    <!-- Selection of service or element for search by proximity -->
    <div fxLayout="row" class="row-clearance" *ngIf="!showElements && proximitySelected">
      <div fxFlex="100">
        <dx-select-box labelMode="floating" [label]="'comp.search-panel.service-or-element' | translate"
          [dataSource]="service_or_element" [placeholder]="'comp.search-panel.choose-element' | translate"
          (onValueChanged)="onElementPicked($event)"></dx-select-box>
      </div>
    </div>

    <!-- If selected by element -->
    <div fxLayout="row" class="row-clearance" *ngIf="elementSelected && !showElements && proximitySelected">
      <div fxFlex="100">
        <dx-select-box labelMode="floating" [label]="'comp.search-panel.element-type' | translate" fieldTemplate="field"
          [value]="selectedType" [placeholder]="'Seleccionar servicio o elemento' | translate" [dataSource]="ds_types"
          (onFocusIn)="onTextBoxFocusIn($event)" (onFocusOut)="onTextBoxFocusOut($event)" 
          (onValueChanged)="onElementTypePicked($event)">
          <div *dxTemplate="let data of 'field'">
            <div class="custom-item">
              <dx-text-box
                [value]="data && (data.name + ' ' + data.description)"
                [readOnly]="true"
              ></dx-text-box>
            </div>
          </div>
          <div *dxTemplate="let data of 'item'">
            <div class="custom-item">
              <div>
                {{data.name}} <b>{{ data.description }}</b>
              </div>
            </div>
          </div>
        </dx-select-box>
      </div>
    </div>

    <!-- Selection of km distance in proximity -->
    <div fxLayout="row" class="row-clearance" *ngIf="!showElements && proximitySelected">
      <div fxFlex="100">
        <dx-select-box labelMode="floating" [label]="'comp.search-panel.distance' | translate"
          [value]="selectedDistance" [dataSource]="km_selection" (onValueChanged)="distanceChange($event)"
          [placeholder]="'comp.search-panel.choose-element' | translate"></dx-select-box>
      </div>
    </div>

    <!-- Input for address -->
    <div fxLayout="row" class="row-clearance" *ngIf="!showElements && proximitySelected">
      <div fxFlex="100">
        <app-address-autocomplete #addressAutocomplete></app-address-autocomplete>
      </div>
    </div>

    <br />

    <div style="text-align: right; margin-top: 30px">
      <dx-button class="search-button main-background-theme" [text]="'general.search' | translate"
        [hint]="'general.search' | translate" (click)="search()">
      </dx-button>
    </div>
  </div>
</dx-accordion>


<br />

<proximity-detail *ngIf="proximitySelected && proximitySearched"  [selectedServiceOrElement]="selectedServiceOrElement"></proximity-detail>

<headend-detail *ngIf="proximitySelected && proximitySearched "></headend-detail>

<google-map-view [address]="selectedAddress" [searchModel]="searchModel" (areaExtentCalculated)="areaExtentCalculated"
  style="display: none;"></google-map-view>
<container-detail [entity]="entity" (connectionSelected)="connectionSelected.emit($event)"
  *ngIf="entity && entity.entityName == 'CONTAINER'"></container-detail>
<device-detail [entity]="entity" (connectionSelected)="connectionSelected.emit($event)"
  *ngIf="entity && entity.entityName == 'DEVICE'"></device-detail>
<infrastructure-detail [entity]="entity"
  *ngIf="entity && entity.entityName == 'INFRASTRUCTURE'"></infrastructure-detail>
<trace-detail [entity]="entity" *ngIf="entity && entity.entityName == 'TRACE'"></trace-detail>
<service-detail [showDistance]="showDistance" [textBoxValue]="textBoxValue" (switchChanged)="onSwitchChangedInServiceDetail($event)" [entity]="entity"
  *ngIf="entity && entity.entityName == 'SERVICE' && !proximitySelected" [dataFromSearchPanel]="dataFromMapViewer"></service-detail>
