import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  MainService,
  Utilities,
} from '@app/@shared/services/main.service';

import { Feature, Overlay } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { Select, Translate } from 'ol/interaction';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import * as olProj from 'ol/proj';
import ClusterSource from 'ol/source/Cluster';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';

import View from 'ol/View';
import Draw from 'ol/interaction/Draw';
import { OlMapHelperService, OpenLayerGeometryTypes } from '@app/@shared/services/aux-services/ol-map-helper.service';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { olMapPolygon } from '@app/@shared/model/aux-models/ol-map-models/olMapPolygon';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { Cons } from '@app/@shared/cons/cons';
import TileWMS from 'ol/source/TileWMS';
import apiSelector from 'src/environments/api-selector.json';


@Component({
  selector: 'ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss'],
})
export class OlMapComponent implements OnInit, OnChanges {

  @Input() xygoMapSelected: any;
  @ViewChild('tooltip')
  tooltipHtml!: ElementRef;

  DRAWING_TYPES = ['Polygon'];

  _CLUSTERDISTANCE = 25;
  _CLUSTER_MINDISTANCE = 5;

  /// --------------- MARKERS ---------------- ///

  _points: olMapPoint[];
  xygoMap: boolean = false;
  @Input('points')
  set points(value) {
    this._points = [];
    this._points.push(...value);
    this.renderPoints();
  }
  get points() {
    return this._points;
  }

  _animatedMarkers: olMapPoint[];
  @Input('animatedMarkers')
  set animatedMarkers(value) {
    this._animatedMarkers = [];
    this._animatedMarkers.push(...value);
    this.renderAnimatedIcons();
  }
  get animatedMarkers() {
    return this._animatedMarkers;
  }

  /// --------------- LINESTRING ---------------- ///
  _linestring: olMapLineString[];
  @Input('linestring')
  set linestring(value) {
    this._linestring = [];
    this._linestring.push(...value);
    this.renderLineString();
  }
  get linestring() {
    return this._linestring;
  }

  /// --------------- POLYGONS ---------------- ///
  _polygons: olMapPolygon[];
  @Input('polygons')
  set polygons(value) {
    this._polygons = [];
    this._polygons.push(...value);
    this.renderPolygons();
  }
  get polygons() {
    return this._polygons;
  }

  /// --------------- COORDINATES ---------------- ///
  _latitude: number;
  @Input('latitude')
  set latitude(value) {
    if (this._latitude == value) {
      return;
    }
    this._latitude = value;
    this.updateLocation();
  }
  get latitude() {
    return this._latitude;
  }

  _longitude: number;
  @Input('longitude')
  set longitude(value) {
    if (this._longitude == value) {
      return;
    }
    this._longitude = value;
    this.updateLocation();
  }
  get longitude() {
    return this._longitude;
  }

  _zoom: number;
  @Input('zoom')
  set zoom(value) {
    if (this._zoom == value) {
      return;
    }
    this._zoom = value;
  }
  get zoom() {
    return this._zoom;
  }

  /// --------------- CONFIGS ---------------- ///
  @Input() cssClass: string = '';
  @Input() height: string = '400px';
  _enableDragAndDrop;
  @Input('enableDragAndDrop')
  get enableDragAndDrop() {
    return this._enableDragAndDrop;
  }
  set enableDragAndDrop(value) {
    if (this._enableDragAndDrop == value) {
      return;
    }

    this._enableDragAndDrop = value;

    if (this.points.length == 0) {
      //New marker will be added
      this.newMarker = true;
    } else {
      this.newMarker = false;
    }

    if (this._enableDragAndDrop) {
      if (this.map) {
        if (
          !this.map
            .getInteractions()
            .getArray()
            .some((i) => i.constructor.name == 'Translate')
        ) {
          this.bindDragAndDrop();
        }
      }
    } else {
      if (this.map) {
        let interactions = [];
        this.map
          .getInteractions()
          .getArray()
          .forEach((i) => {
            if (
              i?.constructor?.name == 'Translate' ||
              i?.constructor?.name == 'Select'
            ) {
              interactions.push(i);
            }
          });

        interactions.forEach((i) => {
          this.map.removeInteraction(i);
        });
      }
    }
  }

  _enableDrawing;
  @Input('enableDrawing')
  get enableDrawing() {
    return this._enableDrawing;
  }
  set enableDrawing(value) {
    if (this._enableDrawing == value) {
      return;
    }

    this._enableDrawing = value;

    if (this._enableDrawing) {
      if (this.map) {
        if (!this.map.getInteractions().getArray().some((i) => i.constructor.name == 'Draw')) {
          this.bindDrawing();
        }
      }
    } else {
      if (this.map) {
        let interactions = [];
        this.map
          .getInteractions()
          .getArray()
          .forEach((i) => {
            if (i?.constructor?.name == 'Draw') {
              interactions.push(i);
            }
          });

        interactions.forEach((i) => {
          this.map.removeInteraction(i);
        });
      }
    }
  }

  @Input() centerViewOnFeatures = false;
  @Input() custerColorByType: any;

  @Output() onDragAndDropCompleted = new EventEmitter<any>();
  @Output() onMapClicked = new EventEmitter<any>();
  @Output() onMapZoomed = new EventEmitter<any>();
  @Output() onElementHovered = new EventEmitter<any>();
  @Output() selectDataMapDrawing = new EventEmitter<any>();
  @Output() onMapReady = new EventEmitter<void>();

  /// --------------- LOCAL PROPERTIES ---------------- ///
  map: Map;
  mapID: string = 'square_map';
  hideOverlay = false;
  newMarker = false;
  markerExtent = [];
  draw: Draw;
  features;
  currentLayer: TileLayer<any>;
  tooltipOverlay: Overlay;

  constructor(private mainService: MainService, private olHelperService: OlMapHelperService) {
    this.mapID = this.mapID + "_" + Math.floor(Math.random() * 99999);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['xygoMapSelected'] && !changes['xygoMapSelected'].firstChange) {
      this.changeMapLayer(this.xygoMapSelected);
    }
  }

  ngOnInit() {
    this.renderMap();
  }




  renderMap() {
    setTimeout(() => {
      let drawingLayer = this.olHelperService.createLayerWithIDAndSource(
        OlMapHelperService._ID_DRAWINGLAYER
      );

      this.tooltipOverlay = new Overlay({
        element: this.tooltipHtml.nativeElement,
        offset: [9, 9]
      });

      this.map = new Map({
        target: this.mapID,
        layers: [
          //new TileLayer({ source: new OSM({ crossOrigin: null }) }),
          new TileLayer({
            source: new OSM({
              crossOrigin: null,
              url: apiSelector.map_config.google.url,
            }),
          }),
          drawingLayer,
        ],
        overlays: [this.tooltipOverlay],
        view: new View({
          center: olProj.fromLonLat([this.longitude, this.latitude]),
          zoom: this.zoom,
        }),
        controls: defaultControls({
          attribution: true,
          zoom: true,
          rotate: false,
        }).extend([]),
      });

      this.currentLayer = this.map.getLayers().item(0);


      //bind Click on map ----->
      this.bindClick();

      //bind Hover on vector layer ----->
      this.bindHover();

      //avoids icons to be resized when zoom out ------>
      this.zoomAdjustment();

      // change mouse cursor when over marker
      this.map.on('pointermove', function (e) {
        if (e.dragging) {
          return;
        }
        var pixel = this.getEventPixel(e.originalEvent);
        var hit = this.hasFeatureAtPixel(pixel);
        this.getTargetElement().style.cursor = hit ? 'pointer' : '';
      });

      this.onMapReady.emit();
    }, 1000);
  }

  changeMapLayer(xygoMapSelected: boolean) {
    if (!this.map) {
      console.error("The map has not been initialized yet.");
      return;
    }
  
    const areLinesVisible = this.linestring?.some((line) => line.visible) || false;
    const arePolygonsVisible = this.polygons?.some((polygon) => polygon.visible) || false;
    const arePointsVisible = this.points?.some((point) => point.visible) || false;
    const areAnimatedMarkersVisible = this.animatedMarkers?.some((marker) => marker.visible) || false;
  
    if (this.currentLayer) {
      this.map.removeLayer(this.currentLayer);
    }
  
    if (!xygoMapSelected) {
      const osmLayer = new TileLayer({
        source: new OSM({
          crossOrigin: null,
          url: apiSelector.map_config.google.url,  
        }),
      });
      this.map.addLayer(osmLayer);
      this.currentLayer = osmLayer;
    } else {
      const wmsLayer = new TileLayer({
        source: new TileWMS({
          url: apiSelector.map_config.xygo.url, 
          params: apiSelector.map_config.xygo.parameters, 
        }),
        opacity: 1,
        maxZoom: 25,
        minZoom: 3,
      });
      this.map.addLayer(wmsLayer);
      this.currentLayer = wmsLayer;
    }
  
    // Re-renders all elements because they are removed when changing the map.
    if (areLinesVisible) {
      this.renderLineString(true);
    }
  
    if (arePolygonsVisible) {
      this.renderPolygons(true);
    }
  
    if (arePointsVisible) {
      this.renderPoints(true);
    }
  
    if (areAnimatedMarkersVisible) {
      this.renderAnimatedIcons();
    }
  }

  updateLocation() {
    if (this.map) {
      this.map.getView().setCenter(olProj.fromLonLat([this.longitude, this.latitude]));
      this.map.getView().setZoom(this.zoom);
    }
  }

  public renderPoints(recenter?: boolean) {
    //Render icons if exist in collection
    if (!this.points || !this.map) {
      return;
    }

    if (recenter == undefined) {
      recenter = true;
    }

    //Groups by type
    let processedMarkers = [];
    this.points.forEach((x: olMapPoint) => {
      if (x.visible) {
        if (processedMarkers.some((m) => m.type == x.type)) {
          processedMarkers.find((m) => m.type == x.type).data.push(x);
        } else {
          processedMarkers.push({
            type: x.type,
            data: [x],
          });
        }
      }
    });

    let groupedLayers = [];
    //Creates independent sources by type
    processedMarkers.forEach((m) => {
      let vectorSource = new VectorSource({ features: [] });
      let clusterSource = new ClusterSource({
        distance: this._CLUSTERDISTANCE,
        minDistance: this._CLUSTER_MINDISTANCE,
        source: vectorSource,
      });

      m.data.forEach((x: olMapPoint) => {
        let feature = this.olHelperService.createFeatureFromPoint(x);
        vectorSource.addFeature(feature);

        this.calculateExtent(x.flatCoordinates);
      });

      let vectorLayer = this.olHelperService.createLayerWithIDAndSource("VECTOR_" + m.id, clusterSource);
      this.setClusteredStyleForVectorLayer(vectorLayer);

      groupedLayers.push(vectorLayer);
    });

    //REMOVE ALL LAYERGROUP LAYERS WITH EXCEPTION OF THE DRAWING LAYER
    let oldLayers = this.map
      .getLayers()
      .getArray()
      .filter(
        (x) =>
          x instanceof LayerGroup &&
          x.get('typeID') &&
          x.get('typeID') == OpenLayerGeometryTypes.POINT
      );
    if (oldLayers && oldLayers.length > 0) {
      oldLayers.forEach((ol) => {
        this.map.removeLayer(ol);
      });
    }

    if (groupedLayers.length != 0) {
      let mapLayers = new LayerGroup({ layers: groupedLayers });
      mapLayers.set('typeID', OpenLayerGeometryTypes.POINT);

      this.map.addLayer(mapLayers);

      //---- DRAG & DROP ---- //
      if (this.enableDragAndDrop) {
        this.bindDragAndDrop();
      }

      //If a marker is already selected, call the function to re-style the icons
      if (this.points.some((x) => x.selected)) {
        this.setDefaultFeatureStyle(this);
      }

      if (this.centerViewOnFeatures && recenter) {
        setTimeout(() => {
          this.centerOnFeatures();
        }, 1000);
      }
    }

    //---- ZOOM ---- //
    this.zoomAdjustment();
  }

  public renderPolygons(recenter?: boolean) {
    //Render icons if exist in collection
    if (!this.polygons || !this.map) {
      return;
    }

    if (recenter == undefined) {
      recenter = true;
    }

    let features = [];

    this.polygons.forEach((x: olMapPolygon) => {
      if (x.visible) {
        let feature = this.olHelperService.createFeatureFromPolygon(x);

        this.olHelperService.setStyleForLineStringFeature(feature, false, x.color, x.width, x.background);
        features.push(feature);
      }
    });

    let vectorSource = new VectorSource({ features: features });
    let vectorLayer = this.olHelperService.createImageLayerWithIDAndSource("VECTOR_" + Utilities.getNewGuid(), vectorSource);


    //REMOVE ALL VECTOR LAYERS WITH EXCEPTION OF THE DRAWING LAYER
    let oldLayers = this.map
      .getLayers()
      .getArray()
      .filter(
        (x) =>
          x instanceof LayerGroup &&
          x.get('typeID') &&
          x.get('typeID') == 'Polygon'
      );
    if (oldLayers && oldLayers.length > 0) {
      oldLayers.forEach((ol) => {
        this.map.removeLayer(ol);
      });
    }

    if (vectorLayer) {
      let mapLayers = new LayerGroup({ layers: [vectorLayer] });
      this.map.addLayer(mapLayers);
      mapLayers.set('typeID', 'Polygon');

      //---- DRAG & DROP ---- //
      /*if (this.enableDragAndDrop) {
        this.bindDragAndDrop();
      }*/

      //If a polygon is already selected, call the function to re-style the icons
      if (this.polygons.some((x) => x.selected)) {
        //this.setDefaultFeatureStyle(this);
      }

      if (this.centerViewOnFeatures && recenter) {
        setTimeout(() => {
          this.centerOnFeatures();
        }, 1000);
      }
    }

    //---- ZOOM ---- //
    this.zoomAdjustment();
  }


  public renderLineString(recenter?: boolean) {
    //Render icons if exist in collection
    if (!this.linestring || !this.map) {
      return;
    }

    if (recenter == undefined) {
      recenter = true;
    }

    let groupedLayers = [];
    let sourceFeatures = [];

    this.linestring.forEach((x: olMapLineString) => {
      if (!x.visible) {
        return;
      }

      if (x.locationData.coordinates.geographyType.toUpperCase() == OpenLayerGeometryTypes.LINESTRING) {
        //Define feature
        let feature = this.olHelperService.createFeatureFromLineString(x);
        //Set style for feature
        this.olHelperService.setStyleForLineStringFeature(feature, false, x.color, x.width);

        sourceFeatures.push(feature);
      } else {
        //Define feature
        let features = this.olHelperService.createFeaturesFromMultiLineString(x);
        //Set style for feature
        features.forEach(f => this.olHelperService.setStyleForLineStringFeature(f, false, x.color, x.width));

        sourceFeatures.push(...features);
      }
    });


    /*
    // Split sourceFeatures into groups of maximum 5000 elements
    for (let i = 0; i < sourceFeatures.length; i += Cons.PREFERENCES.MAX_ELEMENTS_PER_LAYER) {
      const featuresSubset = sourceFeatures.slice(i, i + Cons.PREFERENCES.MAX_ELEMENTS_PER_LAYER);
      groupedLayers.push(this.createVectorLayer(featuresSubset, groupedLayers.length));
    }
  */
    groupedLayers.push(this.createVectorLayer(sourceFeatures, groupedLayers.length, true));


    //REMOVE ALL VECTOR LAYERS WITH EXCEPTION OF THE DRAWING LAYER
    let oldLayers = this.map
      .getLayers()
      .getArray()
      .filter(
        (x) =>
          x instanceof LayerGroup &&
          x.get('typeID') &&
          x.get('typeID') == 'Polygon'
      );
    if (oldLayers && oldLayers.length > 0) {
      oldLayers.forEach((ol) => {
        this.map.removeLayer(ol);
      });
    }

    if (groupedLayers.length != 0) {
      let mapLayers = new LayerGroup({ layers: groupedLayers });
      this.map.addLayer(mapLayers);
      mapLayers.set('typeID', 'Polygon');

      //---- DRAG & DROP ---- //
      /*if (this.enableDragAndDrop) {
        this.bindDragAndDrop();
      }*/

      //If a polygon is already selected, call the function to re-style the icons
      if (this.linestring.some((x) => x.selected)) {
        //this.setDefaultFeatureStyle(this);
      }

      if (this.centerViewOnFeatures && recenter) {
        setTimeout(() => {
          this.centerOnFeatures();
        }, 1000);
      }
    }

    //---- ZOOM ---- //
    this.zoomAdjustment();
  }

  createVectorLayer(features: any[], index: number, declutter?: boolean) {
    const vectorSource = new VectorSource({ features });
    return this.olHelperService.createImageLayerWithIDAndSource("VECTOR_" + index, vectorSource, declutter);
  }


  renderAnimatedIcons() {
    if (!this.animatedMarkers) {
      return;
    }

    setTimeout(() => {
      this.animatedMarkers.forEach((x) => {
        let pos = this.olHelperService.transformFromLonLat(x.flatCoordinates);
        let marker = new Overlay({
          position: pos,
          positioning: 'center-center',
          element: document.getElementById('a_' + x.id),
          stopEvent: false,
        });
        this.map.addOverlay(marker);
      });
    }, 800);
  }

  zoomAdjustment() {
    var that = this;
    this.map.on('movestart', function (e) { });
    this.map.on('moveend', function (e) {
      let zm = that.map.getView().getZoom();
      that.zoom = zm;
      let extentView = that.olHelperService.transformToLonLat(
        that.map.getView().calculateExtent()
      );
      e.stopPropagation();

      that.onMapZoomed.emit({ zoom: zm, extentView: Utilities.plainArray(extentView) });
    });
  }

  public getExtent() {
    let flatCoordinates = this.olHelperService.transformToLonLat(this.map.getView().calculateExtent());
    return Utilities.plainArray(flatCoordinates);
  }

  calculateExtent(flatCoordinates) {
    if (flatCoordinates == undefined) {
      return [];
    }

    let latLonPoint = this.olHelperService.transformToLonLat(flatCoordinates);
    let longitude = latLonPoint[0];
    let latitude = latLonPoint[1];

    let point = Utilities.plainArray(flatCoordinates);

    if (this.markerExtent.length < 4) {
      this.markerExtent.push(...point);

      //Because the extent needs 4 points to work, in case there is only one icon it's added twice
      this.markerExtent.push(...this.markerExtent);
    } else {
      let lowerLeft = this.olHelperService.transformToLonLat([
        this.markerExtent[0],
        this.markerExtent[1],
      ]);
      let upperRight = this.olHelperService.transformToLonLat([
        this.markerExtent[2],
        this.markerExtent[3],
      ]);

      if (longitude > upperRight[0]) {
        this.markerExtent[2] = point[0];
      }

      if (latitude > upperRight[1]) {
        this.markerExtent[3] = point[1];
      }

      if (longitude < lowerLeft[0]) {
        this.markerExtent[0] = point[0];
      }

      if (latitude < lowerLeft[1]) {
        this.markerExtent[1] = point[1];
      }
    }

    return this.markerExtent;
  }

  centerOnFeatures() {
    let vectorLayers = this.olHelperService.getMapVectorLayers(this);

    if (vectorLayers == undefined) {
      return;
    }

    vectorLayers.forEach((vl) => {
      let vectorSource = vl?.getSource();
      if (vectorSource == undefined || vectorSource == null) {
        return;
      }

      let clusteredFeatures = vectorSource.getFeatures();
      if (clusteredFeatures.length != 0) {
        this.map.getView().fit(this.markerExtent, {
          padding: [50, 100, 50, 100],
          minResolution: 50,
        });
      }
    });
  }

  public mapReady(): boolean {
    return this.map && this.map.getView();
  }

  // ************************* HOVER FUNCTION ************************* //
  bindHover() {
    var selected = null;
    var that = this;
    this.map.on('pointermove', function (e) {
      that.tooltipHtml.nativeElement.innerHTML = '';
      that.tooltipHtml.nativeElement.hidden = true;

      if (selected !== null) {
        selected.setStyle(undefined);
        selected = null;
      }

      if (that.enableDragAndDrop || that.enableDrawing) {
        return;
      }
      var hoverSent = false;
      //Assign hover style to hovered element
      this.forEachFeatureAtPixel(e.pixel, function (cf: any) {
        let entities = [];
        if (cf.getGeometry().getType().toUpperCase() == OpenLayerGeometryTypes.POINT) {
          let fts = cf.get('features');

          if (!fts) {
            return false;
          }

          cf.get('features').forEach((x) => {
            const entity = x.getProperties()['entity'];
            entities.push(entity);

            that.tooltipOverlay.setPosition(e.coordinate);
            that.tooltipHtml.nativeElement.innerHTML = entity?.name;
            that.tooltipHtml.nativeElement.hidden = false;
          });
        }
        else {
          const entity = cf.getProperties()['entity'];
          entities.push(entity);

          that.tooltipOverlay.setPosition(e.coordinate);
          that.tooltipHtml.nativeElement.innerHTML = entity?.name;
          that.tooltipHtml.nativeElement.hidden = false;
        }

        that.onElementHovered.emit({
          coordinates: that.olHelperService.transformToLonLat(e.coordinate),
          entities: entities,
          screenPos: e.pixel,
        });
        hoverSent = true;
        return true;
      });

      if (!hoverSent) {
        that.onElementHovered.emit({
          coordinates: that.olHelperService.transformToLonLat(e.coordinate),
        });
      }
    });
  }

  // ************************* CLICK FUNCTION ************************* //
  bindClick() {
    var that = this;
    this.map.on('click', (evt) => {
      if (this.enableDragAndDrop && this.points.length > 0) {
        return;
      }

      that.setDefaultSelection(that);

      var retval = this.map.forEachFeatureAtPixel(evt.pixel, (cf: any) => {
        const type: string = cf.getGeometry().getType();
        let fts: any;
        //Line selection

        switch (type.toUpperCase()) {
          case OpenLayerGeometryTypes.LINESTRING: {
            fts = cf;
            if (!fts || !fts.getProperties()['entity']) {
              return false;
            }

            cf.set('selected', true);

            that.olHelperService.setStyleForLineStringFeature(cf, true, undefined, undefined, cf.getStyle()?.getFill()?.getColor());
            return cf;
          }
          case OpenLayerGeometryTypes.POINT: {
            fts = cf.get('features');

            if (!fts || (fts instanceof Array && fts.length == 0) || (fts instanceof Array && fts.every(x => x.getProperties().selectable === false))) {
              return false;
            }

            fts.filter(x => x.getProperties().selectable !== false).forEach((f) => {
              f.set('selected', true);
            });

            return cf.get('features');
          }
          case OpenLayerGeometryTypes.POLYGON: {
            fts = cf;
            if (!fts || !fts.getProperties()['entity']) {
              return false;
            }

            that.olHelperService.setStyleForLineStringFeature(cf, true, cf.getStyle()?.getStroke()?.getColor(), undefined, cf.getStyle()?.getFill()?.getColor());
            return cf;
          }
        }
      });

      let data: any = {};
      data.coordinates = this.olHelperService.transformToLonLat(evt.coordinate);

      if (retval) {
        //Clicked on existing feature
        data.data = retval;
      }

      this.onMapClicked.emit(data);
    });
  }

  // ************************* DRAG AND DROP FUNCTION ************************* //
  bindDragAndDrop() {
    let select = new Select();

    let translate = new Translate({
      features: select.getFeatures(),
    });

    translate.on('translateend', (evt) => {
      let features = evt.features.item(0).get('features');
      let entities = [];
      features.forEach((f) => {
        entities.push(f.getProperties()['entity']);
      });
      let data = {
        entities: entities,
        rawCoordinates: evt.coordinate,
        lonlat: this.olHelperService.transformToLonLat(evt.coordinate),
      };
      this.onDragAndDropCompleted.emit(data);
    });

    this.map.addInteraction(select);
    this.map.addInteraction(translate);

    /*
    let vectorLayers = this.map.getLayers().item(1)?.getLayers()?.getArray();
    if(vectorLayers == undefined){ return; }

    let clusteredFeatures = []
    vectorLayers.forEach(vl => {
      let vectorSource = vl?.getSource();
      if(vectorSource == undefined || vectorSource == null){
        return;
      }
  
      clusteredFeatures.push(...vectorSource.getFeatures());
    });


    clusteredFeatures.forEach(cf=> {
      let features = cf.get("features");

      let translate1 = new Translate({
        features: new Collection([features]),
      });
  
      this.map.addInteraction(translate1);
      translate1.on('translateend', (evt) => {
        let data = {
          marker: evt.features.item(0).getProperties("entity"),
          rawCoordinates: evt.coordinate,
          latlon: OpenLayerUtils.transformToLonLat(evt.coordinate)
        };
        this.onDragAndDropCompleted.emit(data);
      });
      
    });
    */
  }

  // ************************* DRAW ************************* //
  bindDrawing() {
    let layers = this.map.getLayers().getArray();

    if (layers) {
      let index = layers.findIndex((x) => x.getProperties()['id'] == OlMapHelperService._ID_DRAWINGLAYER);

      let drawingLayer;
      if (index < 0) {
        //Drawing layer was removed... add it again
        drawingLayer = this.olHelperService.createLayerWithIDAndSource(OlMapHelperService._ID_DRAWINGLAYER);
        this.map.addLayer(drawingLayer);
      } else {
        drawingLayer = layers[index];
      }

      this.draw = new Draw({
        source: drawingLayer.getSource(),
        type: this.DRAWING_TYPES[0],
      });

      this.draw.on('drawend', ({ feature }) => {
        let coordinates = [];
        let getCoordinates = feature.getGeometry().getCoordinates()[0];
        for (const c of getCoordinates) {
          coordinates = [...coordinates, this.olHelperService.transformToLonLat(c)];
        }
        this.selectDataMapDrawing.emit(coordinates);
      });

      this.map.addInteraction(this.draw);
    }
  }

  deleteDraw() {
    if (this.draw) {
      let features = this.draw.source_.getFeatures()[0];

      if (features) {
        this.draw.source_.removeFeature(features);
      }
    }
  }

  // ************************* ANIMATED MOVEMENTS ************************* //

  public moveTo(lat, lon, zoom, duration?, done?: (complete: boolean) => void) {
    if (!lat || !lon) {
      return;
    }

    let parts = 2;
    let called = false;
    let view = this.map.getView();
    let location = this.olHelperService.transformFromLonLat([lon, lat]);
    let that = this;
    if (duration == undefined) {
      duration = 2000;
    }

    function callback(complete: boolean) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;

        setTimeout(() => {
          that.zoom = zoom;
          if (done) {
            done(complete);
          }
        }, duration);
      }
    }
    view.animate(
      {
        center: location,
        duration: duration,
      },
      callback
    );
    view.animate(
      {
        zoom: zoom - 1,
        duration: duration / 2,
      },
      {
        zoom: zoom,
        duration: duration / 2,
      },
      callback
    );
  }

  public makeZoom(zoom /*, done*/) {
    let duration = 2000;
    let parts = 1;
    let called = false;
    let view = this.map.getView();
    let location = this.olHelperService.transformFromLonLat([
      this.longitude,
      this.latitude,
    ]);
    let that = this;
    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        setTimeout(() => {
          that.zoom = zoom;
        }, duration);
        //done(complete);
      }
    }
    view.animate(
      {
        center: location,
        duration: duration,
      },
      callback
    );
    view.animate(
      {
        zoom: zoom,
        duration: duration / 2,
      },
      callback
    );
  }

  public setDefaultFeatureStyle(context) {
    if (context == undefined) {
      context = this;
    }

    let vectorLayers = this.olHelperService.getMapVectorLayers(context);

    if (vectorLayers == undefined) {
      return;
    }

    vectorLayers.forEach((vl) => {
      let vectorSource = vl?.getSource();
      if (vectorSource == undefined || vectorSource == null) {
        return;
      }

      let clusteredFeatures = vectorSource.getFeatures();
      let anySelection;
      clusteredFeatures.forEach((cf) => {
        if (cf instanceof Feature) {
          anySelection = {
            feature: cf,
            selected: cf.getProperties()['selected'],
            color: cf.getProperties()['color'],
            width: cf.getProperties()['width'],
            background: cf.getProperties()['background'],
          };

          if (anySelection.selected) {
            return;
          }

          const type = anySelection.feature
            .getGeometry()
            .getType()
            .toUpperCase();
          if (
            anySelection &&
            anySelection.feature &&
            (type === OpenLayerGeometryTypes.LINESTRING ||
              type === OpenLayerGeometryTypes.POLYGON)
          ) {
            this.olHelperService.setStyleForLineStringFeature(anySelection.feature, anySelection.selected, anySelection.color, anySelection.width, anySelection.background);
          } else if (anySelection) {
            this.setClusteredStyleForVectorLayer(vl, anySelection.selected);
          }
        }
      });
    });
  }

  public setDefaultSelection(context) {
    if (context == undefined) {
      context = this;
    }

    let vectorLayers = this.olHelperService.getMapVectorLayers(context);

    if (vectorLayers == undefined) {
      return;
    }

    vectorLayers.forEach((vl) => {
      let vectorSource = vl?.getSource();
      if (vectorSource == undefined || vectorSource == null) {
        return;
      }

      let clusteredFeatures = vectorSource.getFeatures();
      clusteredFeatures.forEach((cf) => {
        const type = cf.getGeometry().getType().toUpperCase();
        if (cf instanceof Feature) {
          if (type === OpenLayerGeometryTypes.LINESTRING || type === OpenLayerGeometryTypes.POLYGON) {
            this.olHelperService.setStyleForLineStringFeature(cf, false, cf.getProperties()['color'], cf.getProperties()['width'], cf.getProperties()['background']);
          }

          if (cf.getProperties()['features']) {
            cf.getProperties()['features'].forEach((f) =>
              f.set('selected', false)
            );
          } else if (cf.getProperties()['selected']) {
            cf.set('selected', false);
          }
        }
      });
    });
  }


  public getMapFeatureByID(context, entityId) {
    if (context == undefined) {
      context = this;
    }

    return this.olHelperService.getMapFeatureByID(context, entityId);
  }

  public selectMapFeature(context, entityId) {
    let feature = this.getMapFeatureByID(context, entityId);

    if (feature && !(feature instanceof Array)) {
      this.olHelperService.setStyleForLineStringFeature(feature, true, feature.getStyle()?.getStroke()?.getColor(), undefined, feature.getStyle()?.getFill()?.getColor());
    } else if (feature instanceof Array) {
      for (let i = 0; i < feature.length; i++) {
        this.olHelperService.setStyleForLineStringFeature(feature[i], true, feature[i].getStyle()?.getStroke()?.getColor(), undefined, feature[i].getStyle()?.getFill()?.getColor()
        );
      }
    }
  }

  public changeMapPolygonStyle(context, featureId, color?: string, background?: string, width?: number) {
    let feature = this.getMapFeatureByID(context, featureId);

    if (feature && !(feature instanceof Array)) {
      if (!color) {
        color = feature.getStyle()?.getStroke()?.getColor();
      }
      if (!background) {
        background = feature.getStyle()?.getFill()?.getColor();
      }
      if (!width) {
        width = feature.getStyle()?.getStroke()?.getWidth();
      }

      feature.set("background", background);
      feature.set("color", color);
      feature.set("width", width);

      this.olHelperService.setStyleForLineStringFeature(feature, false, color, width, background);
    } else if (feature instanceof Array) {
      for (let i = 0; i < feature.length; i++) {
        if (!color) {
          color = feature[i].getStyle()?.getStroke()?.getColor();
        }
        if (!background) {
          background = feature[i].getStyle()?.getFill()?.getColor();
        }
        if (!width) {
          width = feature[i].getStyle()?.getStroke()?.getWidth();
        }

        feature[i].set("background", background);
        feature[i].set("color", color);
        feature[i].set("width", width);

        this.olHelperService.setStyleForLineStringFeature(feature[i], false, color, width, background);
      }
    }
  }

  public changeMapLineStringStyle(context, entityId, color) {
    let feature = this.getMapFeatureByID(context, entityId);

    if (feature && !(feature instanceof Array)) {
      this.olHelperService.setStyleForLineStringFeature(feature, true, color, undefined);
    } else if (feature instanceof Array) {
      for (let i = 0; i < feature.length; i++) {
        this.olHelperService.setStyleForLineStringFeature(feature[i], true, color, undefined);
      }
    }
  }

  // ************************* STYLING FUNCTIONS ************************* //

  private setClusteredStyleForVectorLayer(vectorLayer: VectorLayer, anySelection?) {
    var styleCache = {};
    vectorLayer.setStyle((feature) => {
      return this.buildStyleForClusteredFeature(
        feature,
        styleCache,
        anySelection
      );
    });
  }

  private buildStyleForClusteredFeature(feature: Feature, styleCache?, anySelection?: boolean) {
    let size = feature.get('features').length;

    if (size == 0) {
      return;
    }

    let selected = feature.get('features').some((x) => x.getProperties()['selected']);
    let icon = '../../../../assets/icons/' + feature.get('features')[0].getProperties()['icon'];
    let hovered = feature.get('features').some((x) => x.getProperties()['hovered']);
    let scale = (feature.get('features')[0].getProperties()['scale'] ? feature.get('features')[0].getProperties()['scale'] : 0.24)

    let fontColor = feature.get('features')[0]?.getProperties()['clusterFontColor'];
    let fontBackground = feature.get('features')[0]?.getProperties()['clusterFontBackground'];

    let style = styleCache[size];
    if (anySelection && !selected && !hovered) {
      //there is a selected marker in another group
      style = this.olHelperService.newStyleInstance(0.75, scale, icon);
    } else {
      //There is selected marker in the group... (Current marker might or might not be selected)
      if (selected) {
        if (icon != undefined) {
          let i = icon.substring(
            icon.lastIndexOf('/') + 1,
            icon.lastIndexOf('.')
          );
          if (i) {
            icon = icon.replace(i, '_' + i);
          }
        }
      }
      style = this.olHelperService.createStyleInstanceWithIcon(
        hovered || selected,
        size,
        icon,
        fontColor,
        fontBackground,
        scale
      );
    }
    styleCache[size] = style;

    return style;
  }




}
