<dx-list (onItemClick)="itemClick($event)" id="langList"[ngbTooltip]= "'general.language' | translate ">
  <dxi-item class="d-flex justify-content-center align-items-center">
    <div style="margin-bottom: 2px; font-size: 22px">
      <img [src]="'../../assets/icons/lang/' + currentLanguage.icon" />
    </div>
  </dxi-item>
</dx-list>
<dx-popover
  target="#langList"
  position="bottom"
  [width]="200"
  [(visible)]="actionSheetVisible"
>
  <div *dxTemplate="let data = model; of: 'content'" id="lang_popover">
    <div *ngFor="let item of languages" style="margin-bottom: 4px">
      <dx-button
        width="100%"
        [icon]="'../../assets/icons/lang/' + item.icon"
        [text]="item.name"
        (onClick)="setLanguage(item)"
      ></dx-button>
    </div>
  </div>
</dx-popover>
