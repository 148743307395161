import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'proximity-detail',
  templateUrl: './proximity-detail.component.html',
  styleUrls: ['./proximity-detail.component.scss']
})
export class ProximityDetailComponent implements OnInit, OnChanges{
  public data = [{}];
  public dataAvailableServices = [{}];
  public dataServicesNotAvailable = [{}];

  @Input() selectedServiceOrElement: any;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {

  }

  pepe: "pepe";

  ngOnInit(): void {
  
  }



}
