import { Component, OnInit } from '@angular/core';
import { Platform } from '@app/@shared/model/platform.model';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { PlatformService } from '@app/auth/platform.service';

@Component({
  selector: 'app-platform-selector',
  templateUrl: './platform-selector.component.html',
  styleUrls: ['./platform-selector.component.scss'],
})
export class ClientSelectorComponent implements OnInit {

  constructor(
    private platformService: PlatformService,
    private windowService: WindowService,
  ) {}

  actionSheetVisible = false;
  actionSheetTarget: any = '';
  selectedPlatform: Platform;

  platforms: Platform[] = [];

  ngOnInit() {
    this.platforms = this.platformService.getPlatforms() || [];
    this.selectedPlatform = this.platformService.getActivePlatform();
  }

  setPlatform(platform: Platform) {
    const platformURL = this.platformService.createPlatformUrl(platform);
    // this.platformService.setActivePlatform(platform);
    this.windowService.redirectTo({ path: `${platformURL}/home`, newTab: true, external: false });
    this.actionSheetVisible = false;
  }

  itemClick(e) {
    this.actionSheetTarget = e.itemElement;
    this.actionSheetVisible = true;
  }
}
