import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { InventoryService } from '../services/inventory.service';
import { AuthenticationService } from '@app/auth/authentication.service';

@Injectable({ providedIn: 'root' })
export class TokenInventoryInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private inventoryService: InventoryService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.url.toLowerCase().startsWith('http://inventory.koppengis.com')) {
      return next.handle(req);
    }

    const whitelistedEndpoints = [
      'authenticate'
    ];

    const isWhitelisted = whitelistedEndpoints.some((whitelistedUrl: string) => {
      const splittedUrl = req.url.split('/');
      const lastElement = splittedUrl[splittedUrl.length - 1].split('?')[0];

      return lastElement.toLowerCase() === whitelistedUrl.toLowerCase();
    });

    if (isWhitelisted) {
      return next.handle(req);
    }

    const token = this.inventoryService.userInventory;

    if (!token) {
      this.authenticationService.openExpiredTokenModal();
      return EMPTY;
    }

    if (!!token && !req.headers.get('Authorization')) {
      const headers = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
  
      return next.handle(headers);
    }
      
    return next.handle(req);
  }
}