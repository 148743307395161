<mat-toolbar
  class="header-container"
  [ngClass]="{ 'p-0': mobileQuery.matches }"
  style="min-height: 55px; background-color: white"
>
  <button mat-icon-button (click)="snav.toggle()">
    <i class="fas fa-bars"></i>
  </button> 

  <h1
    class="app-name"
    style="
      font-size: 1em;
      font-family: 'Montserrat';
      cursor: pointer;
      margin-left: 9px;
    "
    (click)="navigateTo('/home')"
    [ngbTooltip]="'general.sfn' | translate"
  >
  SFN {{ environment }}
  </h1>

  <div>
    <div style="justify-content: end">
      <div
        fxFlex="20"
        style="text-align: center; padding-top: 5px"
        *ngIf="!mobileQuery.matches"
      >
        <app-platform-selector></app-platform-selector>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        [fxFlex]="!mobileQuery.matches ? 25 : 100"
      >
        <app-language-selector></app-language-selector>

        <dx-list
          (onItemClick)="checkForHelp()"
          id="help-button"
          [ngbTooltip]="'general.help' | translate"
        >
          <dxi-item fxLayoutAlign="center center">
            <div style="font-size: 22px">
              <img
                class="other-icons questions"
                src="../../../assets/icons/question.svg"
              />
            </div>
          </dxi-item>
        </dx-list>

        <app-user-options></app-user-options>

        <dx-list
          (onItemClick)="logout()"
          id="logout-button"
          [ngbTooltip]="'general.logout' | translate"
        >
          <dxi-item fxLayoutAlign="center center">
            <div style="font-size: 22px">
              <img class="other-icons" src="../../../assets/icons/logout.svg" />
            </div>
          </dxi-item>
        </dx-list>
      </div>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container
  class="example-sidenav-container"
  [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
>
  <mat-sidenav
    #snav
    [mode]="'push'"
    [fixedInViewport]="mobileQuery.matches"
    fixedTopGap="56"
  >
    <mat-nav-list>
      <div (click)="navigateTo('/home')" routerLinkActive="active-list-item"
        class="nav_list_router_item" >
        <i class="fas fa-home"></i>&nbsp;&nbsp; Home
      </div>

      <div (click)="navigateTo(nav.path)" routerLinkActive="active-list-item"
        *ngFor="let nav of fillerNav" >
        <div *ngIf="!nav.children || nav.children.length == 0"
          class="nav_list_router_item">
          <i [class]="nav.icon"></i>&nbsp;&nbsp; {{ nav.name }}
        </div>

        <div *ngIf="nav.children && nav.children.length != 0">
          <dx-accordion class="nav_accordion" [dataSource]="[nav]" [collapsible]="true" [multiple]="false" [selectedItems]="[]"
            [animationDuration]="300">
            <div *dxTemplate="let route of 'title'" class="accordion-template">
              <i [class]="route.icon"></i>&nbsp;&nbsp; {{ route.name }}
            </div>
            <div *dxTemplate="let route of 'item'" class="accordion-template accordion-children">
              <div *ngFor="let child of route.children" (click)="navigateTo(child.path, child.openNewTab)">
                <i [class]="child.icon"></i>&nbsp;&nbsp; {{ child.name | translate }}
              </div>
            </div>
          </dx-accordion>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <div *ngFor="let help of userHelpModules">
      <ng-container *ngIf="help.visible">
        <app-user-help
          [formPath]="help.url"
          (onHelpClosed)="help.visible = $event"
        ></app-user-help>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


