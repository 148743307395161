import { Component, EventEmitter, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/auth';

@Component({
  selector: 'app-expired-token-modal',
  templateUrl: './expired-token-modal.component.html',
  styleUrls: ['./expired-token-modal.component.scss'],
})
export class ExpiredTokenModalComponent {

  @Output() onLogout = new EventEmitter<void>();
  loggingOut: boolean = false;
   
  constructor(
    private authenticationService : AuthenticationService, 
    private router: Router,
    private activatedRoute: ActivatedRoute 
  ) {}

  logout() {
    this.loggingOut = true;

    this.authenticationService.logout().subscribe(() => {
      this.loggingOut = false;
      this.onLogout.emit();

      const redirect = this.getRedirectionPath();

      this.router.navigate(['/login'], { replaceUrl: true });
    });
  }

  getRedirectionPath() : string{
    // Get the current URL path
    const currentPath = this.router.url;
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/login'], {queryParams: { redirectTo: currentPath }})
    );

    return url;
  }
}
