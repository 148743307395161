import { Type } from 'class-transformer';
import { Fiber } from './fiber.model';
import { Equipment } from './equipment.model';
import { IEditableModel } from './interface/iEditable-model.model';

export class EquipmentPort implements Partial<IEditableModel> {
  equipmentPortID: Equipment;
  equipmentID: Equipment;
  internalPortID: number;
  slotNumber : number;
  fiberID: number;
  name: string;
  state: string;
  number : number;
  equipmentPortAttributes: any;

  __new: boolean;
  __updated: boolean;
  __deleted: boolean;

  @Type(()=> Fiber)
  fiber: Fiber;

  @Type(()=> EquipmentPort)
  internalPort: EquipmentPort;

  @Type(()=> Equipment)
  equipment : Equipment;

  constructor() {
    this.equipmentPortAttributes = {};
  }
  
  get hasFiber(): boolean {
    return !!this.fiberID && this.fiberID !== 0;
  }

  get hasCrossConnection(): boolean {
    return !!this.internalPortID && this.internalPortID !== 0;
  }

  
}


