import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Equipment } from '@app/@shared/model/equipment.model';
import { EquipmentService } from '@app/@shared/services/equipment.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EquipmentResolver implements Resolve<Equipment[]> {
  constructor(
    private oltService: EquipmentService,
  ) {}

  async resolve(): Promise<Equipment[]> {
    return firstValueFrom(this.oltService.getWithFilter());
  }
}
