import { Injectable } from '@angular/core';
import { ITraceabilityDiagram } from '@app/@shared/model/interface/iTraceability-diagram';
import { SearchPanelModel } from '@app/model/search-panel.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private areaExtentSource = new Subject<{ areaExtent: { x: number, y: number }[], searchModel: SearchPanelModel }>();
  areaExtent$ = this.areaExtentSource.asObservable();

  private locationSource = new Subject<google.maps.LatLng>();
  location$ = this.locationSource.asObservable();

  emitAreaExtent(areaExtent: { x: number, y: number }[], searchModel: SearchPanelModel): void {
    this.areaExtentSource.next({ areaExtent, searchModel });
  }

  emitLocation(location: google.maps.LatLng): void {
    this.locationSource.next(location);
  }

  private functionCallSource = new Subject<ITraceabilityDiagram>();
  functionCall$ = this.functionCallSource.asObservable();

  callFunctionWithParam(param: ITraceabilityDiagram) {
    this.functionCallSource.next(param);
  }
}
