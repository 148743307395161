<dx-popover
  *ngFor="let step of steps; let stepIndex = index"
  [style.display]="prerender ? 'none' : 'block'"
  #popover
  [closeOnOutsideClick]="false"
  [showTitle]="true"
  [showCloseButton]="false"
  [title]="step.title"
  [width]="width"
  [(visible)]="step.visible"
  [shading]="true"
  shadingColor="rgba(0, 0, 0, 0.5)"
>
  <dxo-position [my]="step.my" [at]="step.at" [of]="step.of"> </dxo-position>
  <dxo-animation>
    <dxo-show type="pop" [from]="{ scale: 0 }" [to]="{ scale: 1 }"></dxo-show>
    <dxo-hide type="fade" [from]="1" [to]="0"></dxo-hide>
  </dxo-animation>
  <div *dxTemplate="let data = model; of: 'content'" class="message" [class.scroll]="mainService.isMobile">
    <dx-scroll-view [showScrollbar]="mainService.isMobile ? 'always' : ''">
      <p>{{ step.message_1 }}</p>
      <p *ngIf="step.message_2">{{ step.message_2 }}</p>
  
      <div style="margin-top: 10px" *ngIf="stepIndex == 0">
        <dx-check-box style="color: #5f1347" [(value)]="hideMessageOnLoad"
          [text]="'user-help.do-not-show-again' | translate"></dx-check-box>
      </div>
  
      <div style="text-align: center" *ngIf="step.image">
        <img [style]="'width:' + step.width" [src]="'../../../assets/user-help/' + step.image"
          (load)="imgLoadComplete($event)" />
      </div>
      
      <div style="text-align: right; margin-top: 30px">
        <dx-button *ngIf="steps.length > stepIndex + 1" class="skip-button" [text]="'general.skip' | translate"
          [hint]="'general.skip' | translate" (click)="skip()">
        </dx-button>
        <dx-button *ngIf="steps.length > stepIndex + 1 && hideMessageOnLoad == false" class="continue-button main-background-theme"
          [text]="'general.continue' | translate" [hint]="'general.continue' | translate" (click)="continue(stepIndex)">
        </dx-button>
    
        <dx-button *ngIf="steps.length == stepIndex + 1" class="skip-button" [text]="'general.start-over' | translate"
          [hint]="'general.start-over' | translate" (click)="continue(stepIndex)">
        </dx-button>
    
        <dx-button *ngIf="steps.length == stepIndex + 1" class="continue-button main-background-theme"
          [text]="'general.finish' | translate" [hint]="'general.finish' | translate" (click)="continue(null)">
        </dx-button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popover>
