import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';

import { plainToInstance } from 'class-transformer';
import { EquipmentPort } from '@app/@shared/model/equipmentPort.model';
import { Equipment } from '@app/@shared/model/equipment.model';
import { EquipmentType } from '@app/@shared/model/equipmentType.model';

@Component({
  selector: 'app-create-edit-equipment-modal',
  templateUrl: './create-edit-equipment-modal.component.html',
  styleUrls: ['./create-edit-equipment-modal.component.scss'],
})
export class CreateEditEquipmentModalComponent implements OnInit {

  @Input() selected: Equipment;
  @Input() equipmentTypes: EquipmentType[] = [];

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<Equipment>();

  olt: Equipment;
  rows: number;
  ports: number;
  displayEquipmentGraph: boolean = false;

  disableSave: boolean = true;

  mockedRacks: any[] = [
    { rackId: 1, name: 'Rack 1'},
    { rackId: 2, name: 'Rack 2'},
    { rackId: 3, name: 'Rack 3'},
    { rackId: 4, name: 'Rack 4'},
    { rackId: 5, name: 'Rack 5'},
  ];

  mockedFrames: any[] = [
    { frameId: 1, name: 'Frame 1'},
    { frameId: 2, name: 'Frame 2'},
    { frameId: 3, name: 'Frame 3'},
    { frameId: 4, name: 'Frame 4'},
    { frameId: 5, name: 'Frame 5'},
  ];

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.olt = plainToInstance(Equipment, this.selected);

    if (this.selected.equipmentID) {
      this.rows = this.selected.equipmentAttributes.slot.length;
      this.ports = this.selected.equipmentPort.length;
      this.generatePorts();
    }

    this.displayEquipmentGraph = true;
  }

  saveOLT() {
    this.save.emit(this.olt);
  }

  generatePorts() {
    const isValidNumber = (n: number) => !!n && n > 0;
    const createEmptyArray = (size: number) => Array(size).fill(1);
    
    if (!isValidNumber(this.rows) || !isValidNumber(this.ports)) {
      notify(this.translateService.instant('olt.modal.generate-ports.error.invalid-numbers'), 'error', 4500);
      return;
    }

    if (this.ports % this.rows !== 0) {
      notify(this.translateService.instant('olt.modal.generate-ports.error.not-divisible'), 'error', 4500);
      return;
    }

    const equipmentPort: EquipmentPort[] = [
      ...createEmptyArray(this.ports).map((_, i) => this.createPort(true, i+1, i+1)),
      ...createEmptyArray(this.ports).map((_, i) => this.createPort(false, i+1, i + 1 + this.ports))
    ];
    
    this.olt.equipmentAttributes.slot = createEmptyArray(this.rows);
    this.olt.equipmentPort = equipmentPort;
  }

  modelSelectionChange(event: { selectedItem: EquipmentType }) {
    const { equipmentTypeID, model } = event?.selectedItem;
    this.olt.equipmentAttributes.model = model;
    this.olt.equipmentTypeID = equipmentTypeID;
  }

  headerSelectionChange(event: { selectedItem: any }) {
    // this.olt.equipmentAttributes.header = event?.selectedItem;
    const { elementTypeID, name } = event?.selectedItem;
    this.olt.equipmentAttributes.header.elementTypeID = elementTypeID;
    this.olt.equipmentAttributes.header.name = name;
  }

  rackSelectionChange(event: { selectedItem: any }) {
    const selectedRack: any = event.selectedItem;
    this.olt.equipmentAttributes.cabinetRack = selectedRack;
  }

  framesSelectionChange(event: { selectedItem: any }) {
    const selectedFrame: any = event.selectedItem;
    this.olt.equipmentAttributes.frame = selectedFrame;
  }

  createPort(back: boolean, portNumber: number, equipmentPortID: number): EquipmentPort {
    const placement = back ? 'B' : 'F';

    return plainToInstance(EquipmentPort, {
      equipmentID: this.olt.equipmentID,
      equipmentPortID, 
      name: `${placement}${portNumber}`,
      description: '',
      __new: true,
      __updated: false,
      __deleted: false,
      equipmentPortAttributes: { portNumber }, 
    });
  }
}
