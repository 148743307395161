import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Device } from '@app/@shared/model/device.model';
import { ElementType } from '@app/@shared/model/elementType.model';
import { ElementTypeService } from '@app/@shared/services/element-type.service';
import { MainService } from '@app/@shared/services/main.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-device-home',
  templateUrl: './device-home.component.html',
  styleUrls: ['./device-home.component.scss'],
})
export class DeviceHomeComponent implements OnInit {
  loading: boolean = false;
  loadingModal: boolean = false;
  devices: Device[] = [];
  selectedDevice: Device;
  modalTitle: string;
  entityType = "DEVICE";
  entityTypeModels = [];

  popups = {
    confirmDelete: false,
    createOrEdit: false,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private elementTypeService : ElementTypeService,
    private mainService: MainService
  ) {}
    

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ devices }) => this.devices = devices);

    let filter = new ElementType();
    filter.groupID = this.entityType;
    filter.platformID = this.platformService.activePlatform.platformID;
    this.elementTypeService.getElementsTypeWithFilter(filter).subscribe({
      next: (res)=>{
        if(res){
          this.entityTypeModels = res;
        }
      }
    });
  }

  openModalAddDevice() {
    const platformID: number = this.platformService.platformID;
    this.modalTitle = this.translateService.instant('devices.add-device');
    this.selectedDevice = plainToClass(Device, { platformID });
    this.popups.createOrEdit = true;
  }

  async openModalEditDevice(device: Device) {
    this.modalTitle = this.translateService.instant('devices.edit-device');
    this.loadingModal = true;
    this.selectedDevice = await firstValueFrom(this.deviceService.getByID(device.deviceID));
    this.loadingModal = false;
    this.popups.createOrEdit = true;
  }

  openPromptDeleteDevice(device: Device) {
    this.selectedDevice = device;
    this.popups.confirmDelete = true;
  }

  async deleteDevice(device: Device) {
    // this.popups.confirmDelete = false;

    // await this.getDevices();
    // this.selectedDevice = undefined;
  }

  closeModal() {
    this.popups.createOrEdit = false;
    this.selectedDevice = undefined;
    this.handleButtonClick()
  }

  handleButtonClick() {
    this.mainService.updateShowElementState(false);
  }

  async saveDevice(device: Device) {
    this.loadingModal = true;

    try {
      if (this.selectedDevice?.deviceID) {
        const updatedDevice = await firstValueFrom(this.deviceService.update(device));
        const index = this.devices.findIndex(x => x.deviceID === updatedDevice.deviceID);
        this.devices[index] = updatedDevice;
      } else {
        const newDevice = await firstValueFrom(this.deviceService.create(device));
        this.devices = [newDevice, ...this.devices];
      }
    } catch (ex) {
      return;
    } finally {
      this.loadingModal = false;
      this.popups.createOrEdit = false;
      this.selectedDevice = undefined;
    }
  }
}
