import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trace-modal-maps',
  templateUrl: './trace-modal-maps.component.html',
  styleUrls: ['./trace-modal-maps.component.scss']
})
export class TraceModalMapsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
