import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IBreakpointMediaQuery } from '../model/interface/iBreakpoint-media-query';

@Injectable({ providedIn: 'root' })
export class MediaQueryService {
  private mediaQueryChange = new ReplaySubject<IBreakpointMediaQuery>(1);
  mediaQueryChange$ = this.mediaQueryChange.asObservable();

  private displayNameMap = new Map([
    [Breakpoints.XSmall, { mobileLayout: true, name: 'XSmall' }],
    [Breakpoints.Small, { mobileLayout: true, name: 'Small' }],
    [Breakpoints.Medium, { mobileLayout: false, name: 'Medium' }],
    [Breakpoints.Large, { mobileLayout: false, name: 'Large' }],
    [Breakpoints.XLarge, { mobileLayout: false, name: 'XLarge' }],
  ]);

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.mediaQueryChange.next(this.displayNameMap.get(query));
          }
        }
      });
  }
}
