import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Cons } from '../cons/cons';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { ElementType } from '../model/elementType.model';
import { PlatformService } from '@app/auth/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ElementTypeService {



  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) { }

  getElementsTypeWithFilter(entity?: any, limit? : number, offset? : number): Observable<any> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    
    return this.main.getWithFilter(Cons._ELEMENTTYPE, entity, false, limit, offset).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  getElementsTypeById(id: number): Observable<ElementType> {
    this.main.chooseAPI(Cons._SERVICECONFIG);

    return this.main.getByID(Cons._ELEMENTTYPE, id).pipe(
      map((res) => {
        try {
          res.commonID = res.containerID;
          res.entityName = Cons._ELEMENTTYPE;
          return plainToInstance(ElementType, res);
        } catch (ex) {
          return (<any>res)._body.toString();
        }
      })
    );
  }
  update(elementType: ElementType): any {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    //TODO: evaluar como quitar la variable busqueda
    const busqueda =`${this.platformService.platformID }/${elementType.elementTypeID}`;
    return this.main.put(Cons._ELEMENTTYPE, busqueda,elementType).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  delete(elementTypeID: number): Observable<unknown> {
    this.main.chooseAPI(Cons._SERVICECONFIG);

    return this.main.delete(Cons._ELEMENTTYPE, elementTypeID).pipe(
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }
  create(elementType: ElementType): Observable<any> {
    this.main.chooseAPI(Cons._SERVICECONFIG);
    return this.main.post(Cons._ELEMENTTYPE, undefined, elementType).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

}
