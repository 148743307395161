<div class="h-100" style="position: relative;" id="modal_detail">
  <div fxLayout="row" class="mb-2">
    <div fxFlex="100" class="form-field">
      <dx-text-box
          [disabled]="!allowEditing"
          [(value)]="entity.client.name"
          labelMode="floating"
          [label]="'feasibility.grid.name' | translate"
        >
        <dx-validator #name_validator>
          <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
        </dx-validator>
        </dx-text-box>
    </div>
  </div>
  <div fxLayout="row" class="mb-2">
    <div fxFlex="40" class="form-field">
      <dx-text-box
          [disabled]="!allowEditing"
          [(value)]="entity.client.email"
          labelMode="floating"
          [label]="'feasibility.grid.email' | translate"
        >
        <dx-validator #email_validator>
          <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div fxFlex="20" class="form-field">
      <dx-select-box 
          [disabled]="!allowEditing"
          [(value)]="entity.client.typeID"
          labelMode="floating"
          [label]="'feasibility.client.type-id' | translate"
          [items]="items"
          required
          (onValueChanged)="onDocumentTypeChanged($event.value)"
        >
        <dx-validator #typeID_validator>
          <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
    <div fxFlex="40" class="form-field">
      <dx-number-box
        [disabled]="!allowEditing"
        valueChangeEvent="keyup"
        [(value)]="parsedID"
        (onValueChanged)="validateIDNumber()"
        labelMode="floating"
        [label]="'feasibility.client.id' | translate"
        required
      >
      <dx-validator #id_validator>
        <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
      </dx-validator>
      </dx-number-box>
      <div style="font-size: .85em; color: #d9534f; height: 16px; text-align: right;">{{ idNumberValidationMessage }}</div>
    </div>
  </div>

  <address-form 
    [allowEditing]="allowEditing"
    [location]="entity?.locationData"
    [locationAddress]="entity?.placeName"
    [embeddedAddress]="true"
    (onAddressChanged)="addressChanged($event)"
    [height]="200" >
  </address-form>

  <div class="me-2 mt-4" fxLayout="row">
    <div fxFlex="50" class="form-field">
      <dx-select-box 
        [disabled]="!allowEditing  || (entity.clientID && entity.clientID != 0)"
        [(value)]="entity.operatorID"
        labelMode="floating"
        [label]="'feasibility.grid.operator' | translate"
        valueExpr="operatorID"
        displayExpr="name"
        [dataSource]="operatorDS"
        required
      >
      <dx-validator #operator_validator>
        <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
      </dx-validator>
    </dx-select-box>
    </div>
    <div fxFlex="50" class="form-field">
      <dx-select-box 
        [disabled]="!allowEditing || (entity.clientID && entity.clientID != 0)"
        [(value)]="entity.invokerSystemCode"
        labelMode="floating"
        [label]="'feasibility.grid.invokerSystem' | translate"
        valueExpr="invokerSystemCode"
        displayExpr="name"
        [dataSource]="invokerSystemDS"
        required
      >
      <dx-validator #invoker_validator>
        <dxi-validation-rule type="required" [message]="'general.required' | translate"></dxi-validation-rule>
      </dx-validator>
    </dx-select-box>
    </div>
  </div>

  <div class="me-2 mt-4" *ngIf="entity?.stateID && entity?.stateID != 0">
    <dx-scroll-view>
      <div fxLayout="column" class="data-container" style="max-height: 40vh;">
        <feasibility-allocation
        [entity]="entity"
        ></feasibility-allocation>
      </div>
    </dx-scroll-view>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="footer">
    <dx-button
      [text]="'general.cancel' | translate"
      (click)="cancel.emit()"
    >
    </dx-button>
    <dx-button 
      *ngIf="entity.feasibilityStudyID != 0"
      [disabled]="!requiredFieldsOk"
      [text]="'general.save-and-close' | translate"
      class="ms-3"
      (click)="save()"
    >
    </dx-button>
    <dx-button
      *ngIf="entity.feasibilityStudyID == 0 || entity.stateID == _stateCodes._NOT_FEASIBLE"
      [disabled]="!requiredFieldsOk"
      class="ms-3 main-background-theme"
      [text]="'feasibility.client-details.check-feasibility' | translate"
      (click)="checkFeasibilityAndSaveClient()"
    >
    </dx-button>
    <dx-button
      *ngIf="entity.feasibilityStudyID != 0 && entity.stateID == _stateCodes._FEASIBLE"
      [disabled]="!requiredFieldsOk"
      class="ms-3 main-background-theme"
      [text]="'feasibility.client-details.allocate' | translate"
      (click)="allocateFeasibility()"
    >
    </dx-button>
  </div>
</div>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#modal_detail' }"
  [visible]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>