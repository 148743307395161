import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { Observable, firstValueFrom, forkJoin } from 'rxjs';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { Feature } from 'ol';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { MainService, Utilities } from '@app/@shared/services/main.service';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { Cons } from '@app/@shared/cons/cons';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { TraceService } from '@app/@shared/services/map-elements-services/trace.service';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { OlMapHelperService, OpenLayerGeometryTypes } from '@app/@shared/services/aux-services/ol-map-helper.service';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { SearchPanelModel } from '@app/model/search-panel.model';
import { EntityService } from '@app/@shared/model/aux-models/entity-service.type';
import { UserSearch } from '@app/@shared/model/aux-models/user-search.model';
import { ICommonEntity } from '@app/@shared/model/interface/iCommonEntity';
import { PlatformService } from '@app/auth/platform.service';
import { Connection, ConnectionRel } from '@app/@shared/model/connection.model';
import html2canvas from 'html2canvas';
import { StaticReportContainer, StaticReportContainerSpec } from '@app/@shared/model/aux-models/staticReportContainer.model';
import { DxDrawerComponent } from 'devextreme-angular/ui/drawer';
import { SearchPanelComponent } from 'src/app/@shared/components/search-panel/search-panel.component';
import { GoogleMapView } from '@app/@shared/components/search-panel/google-map-view/google-map-view.component';
import { CommunicationService } from '@app/@shared/services/map-elements-services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { ITraceabilityDiagram, SingleNode } from '@app/@shared/model/interface/iTraceability-diagram';

export interface OLDrawedPoints {
  preDrawnPoints: olMapPoint[];
  drawnPoints: olMapPoint[];
}

export interface OLDrawedLineStrings {
  preDrawnLineStrings: olMapLineString[];
  drawnLineStrings: olMapLineString[];
}

@Component({
  selector: 'app-map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss'],
})

export class MapViewerComponent implements OnInit {

  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('drawer', { static: false }) drawer: DxDrawerComponent;
  @ViewChild('searchPanelRef', { static: false }) searchPanelRef: ElementRef<SearchPanelComponent>;
  @ViewChild(SearchPanelComponent) searchPanelComponent!: SearchPanelComponent;

  ds_layers = [
    { name: 'Container', subItems: ['LH', 'URB'] },
  ];

  selectedMainCheckbox: string | null = null;
  selectedSubCheckboxes: { mainItem: string, subItem: string }[] = [];
  searchByAreaLocal: boolean;

  // ------ OL MAP SETTINGS ------ //
  latitude: number;
  longitude: number;
  zoom: number;
  searchOneTrace = false;
  mapBoundaries: any = [];
  servicesSearchByUser: UserSearch[] = [];
  selectedService: UserSearch;
  selectedConnections: ConnectionRel[] = [];
  _points: olMapPoint[] = [];
  erase: boolean = true
  showOverflow: boolean = true;
  get points() {
    return this._points;
  }
  set points(value) {
    this._points = value;
    this.processDatasource();
  }

  groupedDatasource = [];
  // ------ OL MAP SETTINGS ------ //
  isSearchOpen = true;
  searchAreaInProgress = false;
  layersActivated = false;
  dowloadImg = false;
  lineString: olMapLineString[] = [];
  searchPolygonInProgress = false;
  dataMapDrawing = [];
  dispatchCancel: any;
  isTextBoxFocused: boolean = false;

  selectedElementsMap: any[] = [];
  textBoxValue: string = '';

  get showCancelSearchButton() {
    return (
      this.searchAreaInProgress ||
      this.searchPolygonInProgress ||
      (this.dataMapDrawing && this.dataMapDrawing.length != 0)
    );
  }

  selectedEntity;
  loading = false;
  coordinatesMap = [];
  dataProximityDetail: { dataAvailableServices: [{}], dataServicesNotAvailable: [{}] };

  switchValue: boolean = false;
  dataToPass: ITraceabilityDiagram = { serviceName: '', traceability: [] };
  dataTraceabilityDiagram: ITraceabilityDiagram = null;

  constructor(
    private platformService: PlatformService,
    private containerService: ContainerService,
    private deviceService: DeviceService,
    private infraService: InfrastructureService,
    private traceService: TraceService,
    private olMapHelperService: OlMapHelperService,
    private datasources: DataSourceService,
    private mainService: MainService,
    private renderer: Renderer2,
    private communicationService: CommunicationService,
    private trans: TranslateService
  ) {
    this.dataProximityDetail =
    {
      dataAvailableServices: [{}],
      dataServicesNotAvailable: [{}]
    };
  }

  @ViewChild('olMap') olMap: OlMapComponent;

  ngOnInit() {
    let initialPos = this.platformService.getPlatformPreference("initialPosition");

    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    else {
      this.getUserPosition().then(({ lon, lat, zoom }) => {
        this.latitude = lat;
        this.longitude = lon;
        this.zoom = zoom;
      },
        ((err) => {
          alert(err);
          this.latitude = -34;
          this.longitude = -58;
          this.zoom = 6;
        }));


      if (this.searchPanelRef) {
        const eventData = {};
        this.searchPanelRef.nativeElement.onTextBoxFocusIn(eventData);
      }
    }

    if (this.searchPanelRef && this.searchPanelRef.nativeElement) {
      const searchPanel = this.searchPanelRef.nativeElement;
      const focusState = true;
      if (focusState) {
        this.renderer.addClass(searchPanel, 'no-scroll');
      } else {
        this.renderer.removeClass(searchPanel, 'no-scroll');
      }
    }

    this.communicationService.areaExtent$.subscribe(areaExtent => {
      this.onAreaExtentCalculated(areaExtent);
    });

    this.communicationService.location$.subscribe((location: google.maps.LatLng) => {
      this.goToLastPointPosition(location);
    });

    this.communicationService.functionCall$.subscribe((param: ITraceabilityDiagram) => {
      this.dataTraceabilityDiagram = param;
      this.validateDataTraceabilityDiagram(param);
    });
  }

  getUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp =>
        resolve({ lon: resp.coords.longitude, lat: resp.coords.latitude, zoom: 8 }),
        err => {
          //Geolocation didn't work. Set some harcoded position
          return resolve({ lon: -58.3850703, lat: -34.604369, zoom: 4 })
        }
      );
    });
  }

  async getLayer(layerName: string) {
    this.loading = true;
    let serviceObservables: Observable<StaticReportContainer>[] = [];

    if (layerName === 'Container') {
      serviceObservables = [
        this.containerService.getFixedDuct(this.platformService.platformID, "LH"),
        this.containerService.getFixedDuct(this.platformService.platformID, "URB")
      ];
    } else if (layerName === 'LH') {
      serviceObservables = [
        this.containerService.getFixedDuct(this.platformService.platformID, "LH")
      ];
    } else if (layerName === 'URB') {
      serviceObservables = [
        this.containerService.getFixedDuct(this.platformService.platformID, "URB")
      ];
    }

    forkJoin(serviceObservables).subscribe({
      next: (res) => {
        this.loading = false;
        this.drawFixedDucts(res[0].reportAttributes, layerName);
      },
      error: (err) => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });

  }

  drawFixedDucts(res: StaticReportContainerSpec[], layerName: string) {
    let lineDS: olMapLineString[] = [];

    res.forEach(x => {
      if (!lineDS.some((t1) => t1.id == x.containerID)) {
        let c = x?.specAttributes?.color;
        if (!c) {
          c = Cons.OLSTYLES.$traceDefaultColor;
        }
        lineDS.push(this.olMapHelperService.OlMapLineString.toMapFeature(x, layerName, c));
      }
    });

    this.lineString = [...this.lineString, ...lineDS];
  }

  toggleDrawer() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  processDatasource() {
    this.groupedDatasource = [];
  }

  mapClicked($event) {
    let entity = this.handleClickOnElement($event);
    if (!this.switchValue) {
      if (!$event.data) {
        return;
      }

      if (!entity) {
        return;
      }

      let service = this.datasources.getServiceByEntityName(entity.entityName);
      if (!service) {
        return;
      }
      service.getByID(entity.commonID).subscribe((res) => {
        if (res) {
          this.selectedEntity = res;
          this.isSearchOpen = true;
        }
      });
    } else {
      console.log("test", entity);
      if (entity.elementType.groupID == 'DEVICE') {
        this.selectedElementsMap.push(entity);
        this.selectedElementsMap = this.removeDuplicates(this.selectedElementsMap, "deviceID");
        if (this.selectedElementsMap.length >= 2 && this.dataTraceabilityDiagram != null) {
          this.loading = true;
          console.log("selectedElementsMap-->", this.selectedElementsMap);
          this.validateDataTraceabilityDiagram(this.dataTraceabilityDiagram);
        }
      }
    }
  }

  handleClickOnElement($event) {
    if ($event.data.length > 1) {
      let newZoom = 14;
      if (this.olMap.zoom >= 18) {
        this.processDatasource();
      } else {
        if (this.olMap.zoom >= newZoom) {
          newZoom = this.olMap.zoom;
          newZoom++;
        }

        let coord;
        if ($event.data instanceof Array) {
          coord = $event.data[0].getProperties()['geometry'].flatCoordinates;
        } else {
          coord = $event.data.getProperties()['geometry'].flatCoordinates;
        }
        let lonlat = this.olMapHelperService.transformToLonLat(coord);

        this.olMap.moveTo(lonlat[1], lonlat[0], newZoom);
        this.olMap.setDefaultSelection(this.olMap);
      }
      return;
    }

    let entity;
    if ($event.data instanceof Array) {
      entity = $event.data[0].getProperties()['entity'];
    } else {
      entity = $event.data.getProperties()['entity'];
    }

    return entity;
  }

  elementHovered($event) { }

  mapZoomed($event) {
    if ($event.zoom && $event.zoom < 18) {
      if (this.points.some((x) => x.visible == false) && !this.selectedSubCheckboxes) {
        this.points.forEach((x) => (x.visible = true));
        this.processDatasource();
      }
    }


    if ($event.extentView) {
      //Coordinates should be grouped as x: long, y: lat
      this.mapBoundaries = [
        { x: $event.extentView[0], y: $event.extentView[1] }, //coord 1
        { x: $event.extentView[2], y: $event.extentView[1] }, //coord 2
        { x: $event.extentView[2], y: $event.extentView[3] }, //coord 3
        { x: $event.extentView[0], y: $event.extentView[3] }, //coord 4
      ];

      //Repeat first coord to close the polygon
      this.mapBoundaries.push(this.mapBoundaries[0]);
    }
  }

  selectLayers() {
    this.layersActivated = !this.layersActivated;
  }

  dowloadMap() {
    const mapElement = document.getElementById('mapContainer');
    const originalBackgroundColor = mapElement.style.backgroundColor;
    mapElement.style.backgroundColor = 'lightgray';

    html2canvas(mapElement, {
      useCORS: true
    }).then((canvas) => {
      mapElement.style.backgroundColor = originalBackgroundColor;
      const imageDataURL = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = imageDataURL;
      downloadLink.download = 'map.png';
      downloadLink.textContent = 'Map';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  searchByArea() {
    this.searchAreaInProgress = !this.searchAreaInProgress;

    if (this.searchAreaInProgress) {
      let h: any = document.getElementsByClassName('ol-zoom')[0];
      h.style.display = 'none';
    } else {
      let h: any = document.getElementsByClassName('ol-zoom')[0];
      h.style.display = 'inline';
    }
  }

  searchByPolygon() {
    this.handleCancelSearch();
    this.searchAreaInProgress = false;
    this.searchPolygonInProgress = !this.searchPolygonInProgress;
  }

  clearSearchesPerformed() {
    this.points = []
    this.lineString = []
    this.mainService.triggerClearSearchesPerformed()
  }

  async search(filter: SearchPanelModel) {
    this.loading = true;
    let service: EntityService = this.datasources.getServiceByEntityName(filter.controller);

    try {
      // Handle proximity search 
      if (filter.searchType === this.trans.instant('forms.map-viewer.search-by-proximity')) {
        try {
          this.points = []
          this.lineString = []
          this.servicesSearchByUser = [];
          this.searchPanelComponent.callGoogleMapFunction();
          this.goToLastPointPosition();
        } catch (error) {
          console.error('Error handling proximity search:', error);
        }
      } else {
        if (filter.controller.toUpperCase() === Cons._SERVICE) {
          try {
            await this.handleServiceSearch(service, filter, true);
          } catch (error) {
            console.error('Error handling service search:', error);
          }
        } else if (filter.controller.toUpperCase() === Cons._TRACE) {
          try {
            await this.handleTraceSearch(service, filter);
          } catch (error) {
            console.error('Error handling trace search:', error);
          }
        } else {
          try {
            await this.handleSingleSearch(service, filter);
          } catch (error) {
            console.error('Error handling single search:', error);
          }
        }
      }
    } finally {
      this.loading = false;
    }
  }

  private async handleServiceSearch(service: EntityService, filter: SearchPanelModel, isVisible: boolean, showPosition: boolean = true) {
    this.loading = true;
    const response = await this.searchServiceElements(service, filter);
    this.selectedEntity = response;
    const clients = this.countClient(response.serviceAttributes.infrastructure);
    response.clients = clients;

    if (clients > 10) {
      this.dataProximityDetail.dataServicesNotAvailable.push(response); 
    } else {
      this.dataProximityDetail.dataAvailableServices.push(response);
    }

    const { drawnPoints, drawnLineStrings } = this.drawServiceElements(response);
    if (showPosition)
      this.goToLastPointPosition();

    let coord = this.coordinatesMap;
    this.selectedEntity.serviceAttributes.infrastructure = response.serviceAttributes.infrastructure.filter(f => f.locationData.coordinates.values.includes(`${coord[0]}`));
    const userSearch = this.addUserSearch(response, drawnPoints, drawnLineStrings);
    if (!isVisible)
      this.toggleServiceVisibility(userSearch);
  }

  private async handleTraceSearch(service: EntityService, filter: SearchPanelModel) {
    const responses = await this.searchSingleElement(service, filter);

    if (this.searchOneTrace) {
      await this.handleSingleSearch(service, filter);
    } else {
      responses.forEach(response => {
        this.drawSingleElement(response);
      });
      this.selectedEntity = responses[0];
      this.isSearchOpen = true;
    }
  }


  private async handleSingleSearch(service: EntityService, filter: SearchPanelModel) {
    const response = await this.searchSingleElement(service, filter);
    this.drawSingleElement(response);
    this.selectedEntity = response;
    this.isSearchOpen = true;
    this.loading = false;
  }

  async searchServiceElements(service: EntityService, filter: SearchPanelModel) {
    return await firstValueFrom(service.getByID(filter.id));
  }

  drawServiceElements(res): { drawnPoints: olMapPoint[], drawnLineStrings: olMapLineString[] } {
    const { container, device, infrastructure, trace } = res.serviceAttributes;
    const elements: ICommonEntity[][] = [container, device, infrastructure, trace];

    const { olDrawedLineStrings, olDrawedPoints } = this.drawLinesAndPoints(elements);

    const drawnPoints = olDrawedPoints.drawnPoints;
    const drawnLineStrings = olDrawedLineStrings.drawnLineStrings;

    return { drawnPoints, drawnLineStrings };
  }

  addUserSearch(response: any, drawnPoints: olMapPoint[], drawnLineStrings: olMapLineString[]): UserSearch {
    const serviceName: string = response?.name;
    const alreadyExists = this.servicesSearchByUser.some(x => x.name === serviceName);

    if (alreadyExists) {
      return null;
    }

    const userSearch: UserSearch = {
      name: serviceName,
      visible: true,
      mapFeatures: {
        lineStrings: drawnLineStrings,
        points: drawnPoints,
      }
    };

    this.servicesSearchByUser.push(userSearch);
    this.selectedService = userSearch;

    return userSearch;
  }

  async searchSingleElement(service: EntityService, filter: SearchPanelModel) {
    if (filter.id && filter.controller.toUpperCase() != Cons._TRACE) {
      return await firstValueFrom(service.getByID(filter.id));
    } else {

      let entity: any = { platformID: this.platformService.platformID, name: filter.name };

      const response = await firstValueFrom(service.getWithFilter(entity, undefined, undefined, "OR"));

      if (Array.isArray(response) && response.length === 1) {
        this.searchOneTrace = true;
        return await firstValueFrom(service.getByID(filter.id));
      } else {
        this.searchOneTrace = false;
        return response;
      }
    }
  }

  drawSingleElement(element: ICommonEntity) {
    const { olDrawedLineStrings, olDrawedPoints } = this.drawLinesAndPoints([[element]]);

    const centerToElement = olDrawedLineStrings.drawnLineStrings.length === 1 ? 'lastLineString' : (olDrawedPoints.drawnPoints.length === 1 ? 'lastPoint' : undefined);
    this.centerMap(centerToElement);
  }

  centerMap(centerTo: 'lastPoint' | 'lastLineString' | undefined) {
    if (centerTo === 'lastPoint') {
      this.goToLastPointPosition();
      return;
    }

    if (centerTo === 'lastLineString') {
      this.centerLineStringInMap();
      return;
    }
  }

  centerLineStringInMap() {
    const lastLineString = this.lineString[this.lineString.length - 1];
    let coordinates = Utilities.plainArray(lastLineString.flatCoordinates);
    this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500);
  }

  handleQuery(areaExtent) {
    this.loading = true;
    this.queryView(areaExtent);

    this.handleCancelSearch();
  }

  handleCancelSearch() {
    this.searchAreaInProgress = false;
    this.searchPolygonInProgress = false;
    this.dataMapDrawing = [];
    this.olMap.deleteDraw();

    let h: any = document.getElementsByClassName('ol-zoom')[0];
    h.style.display = 'inline';
  }

  queryView(extent) {

    const body = { platformID: this.platformService.platformID };
    let serviceObservables: Observable<any>[] = [];
    if (extent.searchModel != undefined) {
      this.searchByAreaLocal = false;

      if (this.searchPanelComponent.proximitySelected && this.searchPanelComponent.selectedServiceOrElement == 'Servicio') {
        serviceObservables.push(this.traceService.queryView(body, extent.areaExtent, 'CONTAINS', true));
      } else {
        switch (extent.searchModel.controller) {
          case 'Container':
            serviceObservables.push(this.containerService.queryView(body, extent.areaExtent, 'CONTAINS'));
            break;
          case 'Device':
            serviceObservables.push(this.deviceService.queryView(body, extent.areaExtent, 'CONTAINS'));
            break
          case 'Infrastructure':
            serviceObservables.push(this.infraService.queryView(body, extent.areaExtent, 'CONTAINS'));
            break
          case 'Trace':
            serviceObservables.push(this.traceService.queryView(body, extent.areaExtent, 'CONTAINS'));
            break
          default:
            break;
        }
      }
    } else {
      this.searchByAreaLocal = true;
      serviceObservables = [
        this.containerService.queryView(body, extent, 'CONTAINS'),
        this.deviceService.queryView(body, extent, 'CONTAINS'),
        this.infraService.queryView(body, extent, 'CONTAINS'),
        this.traceService.queryView(body, extent, 'CONTAINS'),
      ];
    }

    if (this.searchByAreaLocal == true) {

      forkJoin(serviceObservables).subscribe({
        next: (res) => {
          this.loading = false;
          this.drawLinesAndPoints(res);
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });

    } else { //If you search by proximity.

      forkJoin(serviceObservables).subscribe({
        next: (res) => {
          this.loading = false;

          const shouldFilter = res.some(innerArray =>
            innerArray.some(element => element.entityName === 'INFRASTRUCTURE')
          );

          const filteredRes = shouldFilter
            ? res.map(innerArray => innerArray.filter(element => element.elementType.elementTypeID === 220 || element.elementType.elementTypeID === 857))
            : res;

          if (this.searchPanelComponent.selectedServiceOrElement == 'Servicio'
            && filteredRes.length > 0) {
            let service: EntityService = this.datasources.getServiceByEntityName('Service');

            filteredRes[0].forEach(async (element, index) => {
              if (index > 8)
                return;

              const filter = { controller: 'Service', id: element.serviceID, name: '', searchType: 'Servicio' };
              await this.handleServiceSearch(service, filter, true, false);
            });

            this.updateProximityDataAvailableServices(this.dataProximityDetail.dataAvailableServices);
            this.updateProximityDataServicesNotAvailable(this.dataProximityDetail.dataServicesNotAvailable);
          } else {
            this.updateProximityDetailInSearchPanel(filteredRes[0]);

            this.drawLinesAndPoints(filteredRes);
          }
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });

    }
  }

  drawLinesAndPoints(res: ICommonEntity[][], hideAllPoints?: boolean): { olDrawedLineStrings: OLDrawedLineStrings, olDrawedPoints: OLDrawedPoints } {
    const getGeometryType = (entity: ICommonEntity) => entity.locationData?.coordinates?.geographyType.toUpperCase();
    const isLine = (entity: ICommonEntity) => [OpenLayerGeometryTypes.LINESTRING, OpenLayerGeometryTypes.MULTILINESTRING].some(x => x === getGeometryType(entity));
    const isPoint = (entity: ICommonEntity) => getGeometryType(entity) === OpenLayerGeometryTypes.POINT;

    const elements = res.flatMap(x => x);
    const lines = elements.filter(p => isLine(p));
    const points = elements.filter(p => isPoint(p));

    const olDrawedLineStrings: OLDrawedLineStrings = this.lineDataLoad([lines]);
    const olDrawedPoints: OLDrawedPoints = this.pointDataLoad([points], hideAllPoints);

    return { olDrawedLineStrings, olDrawedPoints };
  }

  lineDataLoad(res: ICommonEntity[][]): OLDrawedLineStrings {
    let lineDS: olMapLineString[] = [];

    res.forEach(x => {
      x.forEach((t: ICommonEntity) => {
        if (!lineDS.some((t1) => t1.id == t.commonID)) {
          //27-03-23: This happen cause old services do not have the elementType property populated right... New imported services do.
          let c = t.modelType?.specAttributes?.color;
          if (!c) {
            c = Cons.OLSTYLES.$traceDefaultColor;
          }
          lineDS.push(this.olMapHelperService.OlMapLineString.toMapFeature(t, t.elementTypeID.toString(), c));
        }
      });
    });

    this.lineString = [...this.lineString, ...lineDS];

    return {
      preDrawnLineStrings: this.lineString,
      drawnLineStrings: lineDS
    };
  }


  pointDataLoad(res: ICommonEntity[] | ICommonEntity[][], hideAllPoints?: boolean): OLDrawedPoints {
    let markerDS: olMapPoint[] = [];

    res.forEach((x) => {
      if (x instanceof Array) {
        x.forEach((c: ICommonEntity) => {
          if (!markerDS.some((m1) => m1.id == c.entityName + '_' + c.commonID)) {
            markerDS.push(this.olMapHelperService.OlMapPoint.toMapFeature(c, c.elementTypeID.toString(), "", "", "markers/" + (<any>c.elementType).filePath, undefined, c.elementType?.specAttributes?.scale));
          }
        });
      }
      else {
        if (!markerDS.some((m1) => m1.id == x.entityName + '_' + x.commonID)) {
          markerDS.push(this.olMapHelperService.OlMapPoint.toMapFeature(x, x.elementTypeID.toString(), "", "", "markers/" + (<any>x.elementType).filePath, undefined, x.elementType?.specAttributes?.scale));
        }
      }
    });

    if (hideAllPoints) {
      markerDS.forEach(element => element.visible = false)
    }

    this.points = [...this.points, ...markerDS];

    return {
      preDrawnPoints: this.points,
      drawnPoints: markerDS
    }
  }

  selectDataMapDrawing(coordinates) {
    this.dataMapDrawing = coordinates.map((i) => ({ x: i[0], y: i[1] }));
    this.searchPolygonInProgress = !this.searchPolygonInProgress;
  }

  goToPointPosition(points: olMapPoint[] = this.points) {
    const coordinates = points.flatMap(x => x.flatCoordinates).pop()
    this.olMap.moveTo(coordinates[1], coordinates[0], 14, 2500);
  }

  goToLastPointPosition(location?: google.maps.LatLng) {
    if (location) {
      const coordinates = [location.lng(), location.lat()]; // Orden: [lng, lat]
      const locationData = new LocationData('WKT', `POINT(${coordinates[0]} ${coordinates[1]})`, 0); // Ajusta inputSrID según corresponda
      this.displayPointOnMap(locationData, '', coordinates);
    } else {
      let coordinates = Utilities.plainArray(this.points[this.points.length - 1].flatCoordinates);
      this.coordinatesMap = coordinates;
      this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500);
    }
  }

  displayPointOnMap(locationData: LocationData, name: string, coordinates?: any) {
    if (!locationData) {
      return;
    }
    const pointHelper: olMapPoint = new olMapPoint();

    const commonEntity: ICommonEntity = {
      name: name,
      locationData: locationData,
      commonID: null,
      entityName: null,
      elementType: null,
      elementTypeID: null,
      modelType: null
    };
    const point = pointHelper.toMapFeature(commonEntity, null, null, null, 'markers/pin.png', false, 0.8);
    this.points = [point];
    console.log(point);

    if (point.coordinateArray != null && point.coordinateArray.length > 1) {
      coordinates = point.coordinateArray;
    }


    // No es necesario ajustar coordinates aquí
    if (coordinates) {
      this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500);
    }
  }

  removeService(userSearch: UserSearch) {
    const serviceName = userSearch?.name;
    const { lineStrings, points } = userSearch.mapFeatures;

    this.servicesSearchByUser = this.servicesSearchByUser.filter(x => x.name !== serviceName);
    this.lineString = this.lineString.filter(x => !lineStrings.map(t => t.id).includes(x.id));
    this.points = this.points.filter(x => !points.map(p => p.id).includes(x.id));
    this.selectedService = undefined;
  }

  toggleServiceVisibility(userSearch: UserSearch) {
    userSearch.visible = !userSearch.visible;

    const toggleVisibleState = (x, data) => {
      if (data.map(y => y.id).includes(x.id)) {
        x.visible = userSearch.visible;
      }

      return x;
    }

    this.points = this.points.map(x => toggleVisibleState(x, userSearch.mapFeatures.points));
    this.lineString = this.lineString.map(x => toggleVisibleState(x, userSearch.mapFeatures.lineStrings));

    this.selectedService = !!userSearch.visible ? userSearch : undefined;
  }

  centerService(userSearch: UserSearch) {
    if (!userSearch.visible) {
      this.toggleServiceVisibility(userSearch);
    }

    this.goToPointPosition(userSearch.mapFeatures.points);
    this.selectedService = userSearch;
  }

  onConnectionSelected(connection: Connection) {
    const getTraceID = (traceID: number) => `TRACE_${traceID}`;

    const toggleConnections = (select: boolean) => {
      this.selectedConnections
        .map((connectionRel: ConnectionRel) => getTraceID(connectionRel.traceID))
        .map((traceID: string) => this.olMapHelperService.getMapFeatureByID(this.olMap, traceID))
        .forEach((feature: Feature) => this.olMapHelperService.setStyleForLineStringFeature(feature, select));
    }

    if (connection) {
      this.selectedConnections = [connection.elementID1_Rel, connection.elementID2_Rel];
      toggleConnections(true);
    } else {
      if (!this.selectedConnections.length) {
        return;
      }

      toggleConnections(false);
      this.selectedConnections = [];
    }
  }

  async selectCheckbox(event: any, layerName: string) {
    const selected: boolean = event.value;
    this.selectedMainCheckbox = selected ? layerName : null;

    if (selected) {
      this.getLayer(layerName);
    } else {
      this.removePoints(['LH', 'URB']);
      this.removeLineStrings(['LH', 'URB']);
    }
  }

  selectSubCheckbox(event: any, elementTypeName: string) {
    const selected: boolean = event.value;
    this.drawLinesAndPoints([]);

    if (selected) {
      this.getLayer(elementTypeName);
    } else {
      this.removePoints(elementTypeName);
      this.removeLineStrings(elementTypeName);
    }
  }

  removePoints(elementTypesName: string | string[]) {
    const elementTypesNameArray = Array.isArray(elementTypesName) ? elementTypesName : [elementTypesName];
    this.points = this.points.filter(element => !elementTypesNameArray.includes(element.type));
  }

  removeLineStrings(elementTypesName: string | string[]) {
    const elementTypesNameArray = Array.isArray(elementTypesName) ? elementTypesName : [elementTypesName];
    this.lineString = this.lineString.filter(element => !elementTypesNameArray.includes(element.type));
  }

  handleTextBoxFocusIn(eventData: boolean): void {
    this.showOverflow = false;
  }

  // Método para manejar el evento de pérdida de foco del cuadro de texto en SearchPanelComponent
  handleTextBoxFocusOut(eventData: boolean): void {
    this.showOverflow = true;
  }

  onAreaExtentCalculated(areaExtent: {}): void {
    this.handleQuery(areaExtent);
  }

  countClient(infra: [{}]): number {
    const count = infra.filter((item: any) => item.elementType.name === 'Edificio_Lindo').length;
    return count;
  }

  updateProximityDetailInSearchPanel(newValue: [{}]) {
    this.searchPanelComponent.updateDataTable(newValue);
  }

  updateProximityDataAvailableServices(value: [{}]) {
    this.searchPanelComponent.updateDataAvailableServices(value);
  }

  updateProximityDataServicesNotAvailable(value: [{}]) {
    this.searchPanelComponent.updateDataServicesNotAvailable(value);
  }

  onSwitchValueChanged(newValue: boolean) {
    console.log('Switch value changed in map-viewer:', newValue);
    this.switchValue = newValue;
    this.selectedElementsMap = [];

  }

  removeDuplicates(data: any[], property: string): any[] {
    const seen = new Set<string>();

    return data.filter(d => {
      if (property == '') {
        if (seen.has(d)) {
          return false;
        } else {
          seen.add(d);
          return true;
        }
      } else {
        if (seen.has(d[property])) {
          return false;
        } else {
          seen.add(d[property]);
          return true;
        }
      }
    });
  }

  getDevicesBetween(diagram: ITraceabilityDiagram, elementID1: number, elementID2: number): any[] {
    const elements: { elementID: number, name: string }[] = [];

    for (const nodeArray of diagram.traceability) {
      // Encontrar los índices de los connectionID
      const index1 = nodeArray.findIndex(node => node.elementID === elementID1);
      const index2 = nodeArray.findIndex(node => node.elementID === elementID2);

      // Si ambos índices son válidos, se procesan
      if (index1 !== -1 && index2 !== -1) {
        // Ordenar los índices para garantizar un rango ascendente
        const [startIndex, endIndex] = [index1, index2].sort((a, b) => a - b);

        // Extraer los elementID en el rango
        for (let i = startIndex; i <= endIndex; i++) {
          if (nodeArray[i].elementID !== undefined && nodeArray[i].name !== undefined) {
            elements.push({ elementID: nodeArray[i].elementID!, name: nodeArray[i].name! });
          }
        }
      }
    }

    return elements;
  }

  validateDataTraceabilityDiagram(data: ITraceabilityDiagram) {
    if (this.selectedElementsMap.length >= 2) {
      this.loading = true;
      console.log("selectedElementsMap-->", this.selectedElementsMap);
      // let result = [];
      let result = this.getDevicesBetween(data, this.selectedElementsMap[this.selectedElementsMap.length - 2].deviceID, this.selectedElementsMap[this.selectedElementsMap.length - 1].deviceID);
      // result.push(this.selectedElementsMap[0].deviceID);
      // result.push(this.selectedElementsMap[1].deviceID);
      // this.selectedElementsMap.forEach(e=> {
      //   result.push(e.deviceID);
      // });
      result = this.removeDuplicates(result, 'elementID');
      console.log("result-->", result);
      let deviceObservables: Observable<any>[] = [];
      const elementIDs = result.map(element => element.elementID);
      deviceObservables.push(this.traceService.getTracesByDeviceIds(elementIDs, 'DEVICE'));
      forkJoin(deviceObservables).subscribe({
        next: (res) => {
          let total = 0;
          res[0].forEach(e => {
            total += parseFloat(e.length);
          });

          this.textBoxValue = total.toString();
          console.log("selectedElementsMap-->", this.selectedElementsMap);
          let data: ITraceabilityDiagram = { serviceName: '', traceability: [] };
          let tracePrev = [];
          result.forEach((element, index) => {
            let traceability: SingleNode = {
              connectionID: index + 1,
              description: res[0][index]?.name,
              name: element.name,
              type: ''
            }
            tracePrev.push(traceability);
          });
          data.traceability.push(tracePrev);
          console.log("res-->", res);
          this.dataToPass = data;
          // this.traceabilityDiagramEmitter.emit(res);

          // this.selectedElementsMap = [];
          // this.selectedElementsMap.push(entity);
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        }
      });
    } else
      this.loading = false;
  }
}
