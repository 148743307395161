import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import {
  DxButtonModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxGalleryModule
} from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainComponent } from './main/main.component';
import { DxListModule } from 'devextreme-angular';




@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxButtonModule,
    DxGalleryModule,
    DxListModule,
  ],
  declarations: [LoginComponent, MainComponent],
})
export class AuthModule {}
