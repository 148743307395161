import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

interface Node {
  key?: string;
  side: string;
  id: string;
  buffer: string;
  x?: number;
  y?: number;
  mainAttr: {
    buffer?: any;
    name?: string;
    traceID?: string;
    fiberID?: string;
    color?: string;
    service?: string;
  };
  importAttr?: {
    traceID?: string;
    color?: string;
  };
}

interface NodeGroup {
  [side: string]: Node[];
}


@Component({
  selector: 'app-fiber-diagram',
  templateUrl: './fiber-diagram.component.html',
  styleUrls: ['./fiber-diagram.component.scss']
})
export class FiberDiagramComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('svgcontainer', { static: false }) svgContainerRef: ElementRef;

  @Input() id: string;
  @Input() data: any[];
  @Input() selectedData: any = {};
  @Input() hideUnselectedData: boolean;
  @Input() serviceName: string = '';
  @Output() fiberNameOutput = new EventEmitter<string>();
  @Output() selectedDataChange = new EventEmitter<any>();
  svg: any;

  nodeCounter: number;

  pngWidth: number;

  fiberName: string[] = [];

  links: any[];

  originalLinks: any[] = [];

  globalYPosition: number;
  globalXPosition: number;
  globalMaxBufferYLeft: number;
  globalMaxBufferYRight: number;


  color = {
    'AM': 'yellow',
    'AZ': 'blue',
    'BL': '#d3d3d3',
    'CE': 'lightblue',
    'GR': 'grey',
    'MA': 'brown',
    'NA': 'orange',
    'NE': 'black',
    'RJ': 'red',
    'RS': 'pink',
    'VE': 'green',
    'VI': 'purple',
  };
  groupNodeByFibername: boolean;
  isSingleSideGlobal: string;
  removedNodes: any;



  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedData']) {
      this.selectedFiber(changes['selectedData'].currentValue);
    }

    if (changes['hideUnselectedData']) {
      this.applyLinkVisibility();
    }

    if (changes['serviceName']) {
      this.searchService();
    }

  }
  ngOnInit(): void {


    console.log(this.data);


  }

  ngAfterViewInit(): void {


    this.createGraph();
    this.drawLinks();

  }

  // /$$$$$$$  /$$$$$$$   /$$$$$$  /$$      /$$                                           
  // | $$__  $$| $$__  $$ /$$__  $$| $$  /$ | $$                                           
  // | $$  \ $$| $$  \ $$| $$  \ $$| $$ /$$$| $$                                           
  // | $$  | $$| $$$$$$$/| $$$$$$$$| $$/$$ $$ $$                                           
  // | $$  | $$| $$__  $$| $$__  $$| $$$$_  $$$$                                           
  // | $$  | $$| $$  \ $$| $$  | $$| $$$/ \  $$$                                           
  // | $$$$$$$/| $$  | $$| $$  | $$| $$/   \  $$                                           
  // |_______/ |__/  |__/|__/  |__/|__/     \__/                                           
                                                                                        
                                                                                        
                                                                                        
  //  /$$$$$$$  /$$$$$$$  /$$$$$$ /$$   /$$  /$$$$$$  /$$$$$$ /$$$$$$$   /$$$$$$  /$$      
  // | $$__  $$| $$__  $$|_  $$_/| $$$ | $$ /$$__  $$|_  $$_/| $$__  $$ /$$__  $$| $$      
  // | $$  \ $$| $$  \ $$  | $$  | $$$$| $$| $$  \__/  | $$  | $$  \ $$| $$  \ $$| $$      
  // | $$$$$$$/| $$$$$$$/  | $$  | $$ $$ $$| $$        | $$  | $$$$$$$/| $$$$$$$$| $$      
  // | $$____/ | $$__  $$  | $$  | $$  $$$$| $$        | $$  | $$____/ | $$__  $$| $$      
  // | $$      | $$  \ $$  | $$  | $$\  $$$| $$    $$  | $$  | $$      | $$  | $$| $$      
  // | $$      | $$  | $$ /$$$$$$| $$ \  $$|  $$$$$$/ /$$$$$$| $$      | $$  | $$| $$$$$$$$
  // |__/      |__/  |__/|______/|__/  \__/ \______/ |______/|__/      |__/  |__/|________/
                                                                                        
                                                                                        
                                                                                        
  //   /$$$$$$  /$$$$$$$   /$$$$$$  /$$$$$$$  /$$   /$$                                    
  //  /$$__  $$| $$__  $$ /$$__  $$| $$__  $$| $$  | $$                                    
  // | $$  \__/| $$  \ $$| $$  \ $$| $$  \ $$| $$  | $$                                    
  // | $$ /$$$$| $$$$$$$/| $$$$$$$$| $$$$$$$/| $$$$$$$$                                    
  // | $$|_  $$| $$__  $$| $$__  $$| $$____/ | $$__  $$                                    
  // | $$  \ $$| $$  \ $$| $$  | $$| $$      | $$  | $$                                    
  // |  $$$$$$/| $$  | $$| $$  | $$| $$      | $$  | $$                                    
  //  \______/ |__/  |__/|__/  |__/|__/      |__/  |__/       


  private createGraph(): void {




    const margin = { top: 45, right: 100, bottom: 70, left: 130 };


    const svgContainer = d3.select(this.svgContainerRef.nativeElement);
    this.svg = svgContainer.append("svg")
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);



    const groupedNodes = this.groupNodesBySide(this.data);

    const uniqueSides = Object.keys(groupedNodes);

    uniqueSides.forEach((side, sideIndex) => {
      let labelName = "";
      for (const buffer of Object.keys(groupedNodes[side])) {
        const nodesInBuffer = groupedNodes[side][buffer];
        for (const node of nodesInBuffer) {
          if (node.mainAttr.name) {
            labelName = node.mainAttr.name;
            break;
          }
        }
        if (labelName) break;
      }

      this.fiberName.push(labelName);
      this.fiberNameOutput.emit(labelName);
    });



    this.globalMaxBufferYLeft = -Infinity;
    this.globalMaxBufferYRight = -Infinity;


    let globalMinX = Infinity, globalMaxX = -Infinity, globalMinY = Infinity, globalMaxY = -Infinity;



    uniqueSides.forEach((side, sideIndex) => {
      const buffers = groupedNodes[side];

      let nodeCounterLocal = 1;

      this.nodeCounter = nodeCounterLocal;

      Object.keys(buffers).forEach((buffer, bufferIndex) => {
        const nodesInBuffer = buffers[buffer];
        let bufferMinX = Infinity, bufferMaxX = -Infinity, bufferMinY = Infinity, bufferMaxY = -Infinity;



        nodesInBuffer.forEach((node, index) => {
          const pos = this.getNodePosition(index, sideIndex, bufferIndex, Object.keys(buffers).length, nodesInBuffer.length);
          const color = this.color[node.mainAttr.color.slice(0, 2)] || '#000';
          const serviceName = node.mainAttr.service;

          node.x = pos.x;
          node.y = pos.y;

          bufferMinX = Math.min(bufferMinX, pos.x);
          bufferMaxX = Math.max(bufferMaxX, pos.x);
          bufferMinY = Math.min(bufferMinY, pos.y);
          bufferMaxY = Math.max(bufferMaxY, pos.y);

          this.svg.append("circle")
            .attr("cx", pos.x)
            .attr("cy", pos.y)
            .attr("r", 5)
            .style("fill", color);

          if (sideIndex == 0) {

            this.svg.append("text")
              .attr("x", pos.x - 28)
              .attr("y", pos.y + 3)
              .text(nodeCounterLocal)
              .style("font-size", "12px")
              .style("font-weight", "bold")
              .style("fill", "#000");

            this.svg.append("text")
              .datum(node)
              .attr("x", pos.x + 10)
              .attr("y", pos.y - 5)
              .text(serviceName)
              .style("font-size", "12px")
              .style("font-weight", "bold")
              .style("fill", "#000")
              .attr("class", "service-name-text");

          } else {

            this.svg.append("text")
              .attr("x", pos.x + 6)
              .attr("y", pos.y + 5)
              .text(nodeCounterLocal)
              .style("font-size", "12px")
              .style("font-weight", "bold")

              .style("fill", "#000");

            this.svg.append("text")
              .datum(node)
              .attr("x", pos.x - 10)
              .attr("y", pos.y - 5)
              .text(serviceName)
              .style("font-size", "12px")
              .style("font-weight", "bold")
              .style("fill", "#000")
              .attr("text-anchor", "end")
              .attr("class", "service-name-text");

          }





          nodeCounterLocal++;

        });


        const rectColor = this.color[buffer] || 'null';


        const rectAroundX = sideIndex === 0 ? bufferMinX - 30 : bufferMinX + 10;

        if (sideIndex == 0) {
          this.svg.append("rect")
            .attr("x", rectAroundX - 20)
            .attr("y", bufferMinY - 10)
            .attr("width", bufferMaxX - bufferMinX + 20)
            .attr("height", bufferMaxY - bufferMinY + 20)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", rectColor)
            .style("stroke", 'black')
            .style("stroke-width", 1);

          this.globalMaxBufferYLeft = Math.max(this.globalMaxBufferYLeft, bufferMaxY + 10);


        } else {
          this.svg.append("rect")
            .attr("x", rectAroundX + 20)
            .attr("y", bufferMinY - 10)
            .attr("width", bufferMaxX - bufferMinX + 20)
            .attr("height", bufferMaxY - bufferMinY + 20)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", rectColor)
            .style("stroke", 'black')
            .style("stroke-width", 1);

          this.globalMaxBufferYRight = Math.max(this.globalMaxBufferYRight, bufferMaxY + 10);


        }




        globalMinX = Math.min(globalMinX, bufferMinX - 30);
        globalMaxX = Math.max(globalMaxX, bufferMaxX + 30);
        globalMinY = Math.min(globalMinY, bufferMinY - 10);
        globalMaxY = Math.max(globalMaxY, bufferMaxY + 10);
      });
    });





    const sideHeight = globalMaxY - globalMinY;
    const sideWidth = 20;
    const sidePadding = 15;

    const columns = 2;





    uniqueSides.forEach((side, sideIndex) => {
      const isTopRow = Math.floor(sideIndex / columns) === 0;
      const isLeftColumn = sideIndex % columns === 0;
      const isRightColumn = !isLeftColumn;

      let xPosition = isLeftColumn ? globalMinX - sidePadding - sideWidth : globalMaxX + sidePadding;
      let yPosition = isTopRow ? globalMinY : globalMinY + sideHeight + sidePadding;

      this.globalXPosition = xPosition;
      this.globalYPosition = yPosition;

      xPosition += isRightColumn ? +10 : -10;


      //Draw the rectangles with Fiber name
      if (sideIndex == 0) {

        this.drawLateralRectangle(xPosition, yPosition, sideWidth, this.globalMaxBufferYLeft + 10, 'black', 'black', this.svg);


      } else {

        this.drawLateralRectangle(xPosition, yPosition, sideWidth, this.globalMaxBufferYRight + 10, 'black', 'black', this.svg);


      }
      this.drawLateralText(xPosition, yPosition, isRightColumn, this.fiberName[sideIndex], this.svg);


      //Draw the rectangles with Side name
      const secondXPosition = isLeftColumn ? xPosition - sideWidth - sidePadding : xPosition + sideWidth + sidePadding;
      this.drawLateralRectangle(secondXPosition, yPosition, sideWidth, sideHeight, 'grey', 'grey', this.svg);
      if (this.groupNodeByFibername == false) {
        this.drawLateralText(secondXPosition, yPosition, isRightColumn, side, this.svg);
      } else {
        this.drawLateralText(secondXPosition, yPosition, isRightColumn, this.isSingleSideGlobal, this.svg);
      }






    });

    const svgWidth = globalMaxX - globalMinX + margin.left + margin.right;
    const svgHeight = globalMaxY - globalMinY + margin.top + margin.bottom;

    this.pngWidth = svgWidth;


    if (this.data.length < 384) {
      svgContainer
        .style("display", "flex")
        .style("justify-content", "center")
        .style("align-items", "center")
        .style("overflow-x", "hidden");

      svgContainer.select("svg")
        .attr("width", svgWidth)
        .attr("height", svgHeight);
    } else {
      svgContainer
        .style("display", "block")
        .style("justify-content", null)
        .style("align-items", null)
        .style("overflow-x", "auto");

      svgContainer.select("svg")
        .attr("width", svgWidth)
        .attr("height", svgHeight)
        .style("min-width", `${svgWidth}px`);
    }

    this.drawRemovedNodes(svgWidth, svgHeight);


  }




  drawLateralRectangle(xPosition: number, yPosition: number, sideWidth: number, sideHeight: number, fillColor: string, strokeColor: string, svg: any) {
    svg.append("rect")
      .attr("x", xPosition)
      .attr("y", yPosition)
      .attr("width", sideWidth)
      .attr("height", sideHeight)
      .attr("rx", 5)
      .attr("ry", 5)
      .style("fill", fillColor)
      .style("stroke", strokeColor)
      .style("stroke-width", 2);

  }

  drawLateralText(
    xPosition: number,
    yPosition: number,
    isRightColumn: boolean,
    text: string,
    svg: any,
    avoidRotation: boolean = false  
) {
    let transform = '';
    if (!avoidRotation) {
        transform = isRightColumn ? `rotate(-90, ${xPosition}, ${yPosition})` : `rotate(90, ${xPosition}, ${yPosition})`;
    }

    const textColor = avoidRotation ? "black" : "white";

    svg.append("text")
        .attr("x", isRightColumn ? xPosition - 10 : xPosition + 10)
        .attr("y", isRightColumn ? yPosition + 10 : yPosition - 10)
        .attr("text-anchor", isRightColumn ? "end" : "start")
        .attr("dominant-baseline", "middle")
        .style("fill", textColor)  
        .style("font-size", "12px")
        .attr("transform", transform)
        .text(text);
}


  drawConnectionText(text: string, svg: any) {
    svg.selectAll(".connection-text").remove();

    svg.append("text")
      .attr("class", "connection-text")
      .attr("x", this.globalXPosition)
      .attr("y", this.globalYPosition - 20)
      .text(text)
      .style("text-anchor", "end")
      .style("font-size", "15px")
      .style("fill", "black")
      .style("font-weight", "bold");
  }

  getNodePosition(index: number, sideIndex: number, keyIndex: number, numKeys: number, numNodesInKey: number): { x: number, y: number } {
    const width = window.innerWidth;


    let x;


    if (this.data.length >= 384) {

      x = sideIndex * 2000;

    } else {

      x = sideIndex * width / 2;

    }

    const heightBetweenNodes = 18;
    const keyMargin = 18;
    const y = heightBetweenNodes * index + (keyIndex * (heightBetweenNodes + keyMargin * numNodesInKey));

    return { x, y };
  }

//   /$$$$$$  /$$$$$$$  /$$$$$$$$  /$$$$$$  /$$$$$$$$ /$$$$$$$$                                     
//  /$$__  $$| $$__  $$| $$_____/ /$$__  $$|__  $$__/| $$_____/                                     
// | $$  \__/| $$  \ $$| $$      | $$  \ $$   | $$   | $$                                           
// | $$      | $$$$$$$/| $$$$$   | $$$$$$$$   | $$   | $$$$$                                        
// | $$      | $$__  $$| $$__/   | $$__  $$   | $$   | $$__/                                        
// | $$    $$| $$  \ $$| $$      | $$  | $$   | $$   | $$                                           
// |  $$$$$$/| $$  | $$| $$$$$$$$| $$  | $$   | $$   | $$$$$$$$                                     
//  \______/ |__/  |__/|________/|__/  |__/   |__/   |________/                                     
                                                                                                 
                                                                                                 
                                                                                                 
//   /$$$$$$  /$$$$$$$  /$$$$$$ /$$$$$$$         /$$$$$$  /$$   /$$ /$$$$$$$                        
//  /$$__  $$| $$__  $$|_  $$_/| $$__  $$       /$$__  $$| $$$ | $$| $$__  $$                       
// | $$  \__/| $$  \ $$  | $$  | $$  \ $$      | $$  \ $$| $$$$| $$| $$  \ $$                       
// | $$ /$$$$| $$$$$$$/  | $$  | $$  | $$      | $$$$$$$$| $$ $$ $$| $$  | $$                       
// | $$|_  $$| $$__  $$  | $$  | $$  | $$      | $$__  $$| $$  $$$$| $$  | $$                       
// | $$  \ $$| $$  \ $$  | $$  | $$  | $$      | $$  | $$| $$\  $$$| $$  | $$                       
// |  $$$$$$/| $$  | $$ /$$$$$$| $$$$$$$/      | $$  | $$| $$ \  $$| $$$$$$$/                       
//  \______/ |__/  |__/|______/|_______/       |__/  |__/|__/  \__/|_______/                        
                                                                                                 
                                                                                                 
                                                                                                 
//  /$$$$$$$  /$$$$$$$   /$$$$$$  /$$      /$$       /$$       /$$$$$$ /$$   /$$ /$$   /$$  /$$$$$$ 
// | $$__  $$| $$__  $$ /$$__  $$| $$  /$ | $$      | $$      |_  $$_/| $$$ | $$| $$  /$$/ /$$__  $$
// | $$  \ $$| $$  \ $$| $$  \ $$| $$ /$$$| $$      | $$        | $$  | $$$$| $$| $$ /$$/ | $$  \__/
// | $$  | $$| $$$$$$$/| $$$$$$$$| $$/$$ $$ $$      | $$        | $$  | $$ $$ $$| $$$$$/  |  $$$$$$ 
// | $$  | $$| $$__  $$| $$__  $$| $$$$_  $$$$      | $$        | $$  | $$  $$$$| $$  $$   \____  $$
// | $$  | $$| $$  \ $$| $$  | $$| $$$/ \  $$$      | $$        | $$  | $$\  $$$| $$\  $$  /$$  \ $$
// | $$$$$$$/| $$  | $$| $$  | $$| $$/   \  $$      | $$$$$$$$ /$$$$$$| $$ \  $$| $$ \  $$|  $$$$$$/
// |_______/ |__/  |__/|__/  |__/|__/     \__/      |________/|______/|__/  \__/|__/  \__/ \______/ 


  createNodeList(): Node[] {
    const nodeList: Node[] = [];

    this.data.forEach(node => {
      if (node.mainAttr && node.mainAttr.fiberID) {
        nodeList.push(node);
      } else {
        console.warn('Node missing traceID:', node);
      }
    });

    // console.log('NodeList: ', nodeList);

    return nodeList;
  }

  private usedVerticalPaths: Map<string, number> = new Map();


  drawLinks(): void {
    try {
      const nodeList = this.createNodeList();
      const nodeMap = new Map(nodeList.map(node => [node.mainAttr.fiberID, node]));

      const linkMap = new Map<string, string[]>();
      const allLinks = new Map<string, d3.Selection<SVGPathElement, unknown, null, undefined>>();

      this.links = this.data
        .map(d => {
          const sourceNode = nodeMap.get(d.mainAttr.fiberID);
          const targetNode = nodeMap.get(d.importAttr.fiberID);
          const colorCode1 = (sourceNode?.mainAttr.color || 'grey').slice(0, 2);
          const colorCode2 = (sourceNode?.importAttr.color || 'grey').slice(0, 2);
          const color1 = this.color[colorCode1] || 'grey';
          const color2 = this.color[colorCode2] || 'grey';
          return {
            source: sourceNode,
            target: targetNode,
            color1: color1,
            color2: color2
          };
        })
        .filter(d => d.source && d.target);

      console.log("Enlaces creados:", this.links);

      this.links.forEach(link => {
        try {
          if (link.source && link.target) {
            const [startX, startY] = this.getGridCoordinates(link.source.x, link.source.y);
            const [endX, endY] = this.getGridCoordinates(link.target.x, link.target.y);

            let d1: string;
            let d2: string;

            const midX = (startX + endX) / 2;
            const midY = (startY + endY) / 2;

            if (startX === endX) {
              d1 = `M${startX},${startY} V${endY}`;
              d2 = '';
            } else if (startY === endY) {
              d1 = `M${startX},${startY} H${endX}`;
              d2 = '';
            } else {
              if (startY !== midY) {
                const midPointX = this.getNextVertical(midX, endX) + 6;
                d1 = `M${startX},${startY} H${midPointX} V${midY} H${midPointX}`;
              } else {
                d1 = `M${startX},${startY} H${midX}`;
              }

              if (midY !== endY) {
                const midPointX = this.getNextVertical(midX, endX);
                d2 = `M${midPointX},${midY} H${midPointX} V${endY} H${endX}`;
              } else {
                d2 = `M${midX},${midY} H${endX}`;
              }
            }

            if (d1) {
              try {
                const line1 = this.svg.append("path")
                  .datum({ source: link.source, target: link.target })
                  .attr("class", "link")
                  .attr("d", d1)
                  .style("stroke", link.color1)
                  .style("stroke-width", 3)
                  .style("fill", "none");

                const line1Id = `${link.source.mainAttr.fiberID}-${link.target.mainAttr.fiberID}`;
                if (!linkMap.has(line1Id)) {
                  linkMap.set(line1Id, []);
                }
                linkMap.get(line1Id)?.push(d1);
                allLinks.set(d1, line1);

                line1.on("click", () => {
                  this.highlightConnectedLinks(line1Id, linkMap, allLinks);
                });
              } catch (error) {
                console.error(`Error al crear la primera línea para el enlace ${link.source.mainAttr.fiberID}-${link.target.mainAttr.fiberID}:`, error);
              }
            }

            if (d2) {
              try {
                const line2 = this.svg.append("path")
                  .datum({ source: link.source, target: link.target })
                  .attr("class", "link")
                  .attr("d", d2)
                  .style("stroke", link.color2)
                  .style("stroke-width", 3)
                  .style("fill", "none");

                const line2Id = `${link.source.mainAttr.fiberID}-${link.target.mainAttr.fiberID}`;
                if (!linkMap.has(line2Id)) {
                  linkMap.set(line2Id, []);
                }
                linkMap.get(line2Id)?.push(d2);
                allLinks.set(d2, line2);

                line2.on("click", () => {
                  this.highlightConnectedLinks(line2Id, linkMap, allLinks);
                });
              } catch (error) {
                console.error(`Error al crear la segunda línea para el enlace ${link.source.mainAttr.fiberID}-${link.target.mainAttr.fiberID}:`, error);
              }
            }
          }
        } catch (error) {
          console.error(`Error al procesar el enlace ${link.source?.mainAttr.fiberID} a ${link.target?.mainAttr.fiberID}:`, error);
        }
      });
    } catch (error) {
      console.error('Error al crear los enlaces de fibra:', error);
    }
  }

  applyLinkVisibility(): void {
    const selectedDataArray = this.data
      .filter(d =>
        this.selectedData.mainAttrFiberID.includes(d.mainAttr.fiberID) ||
        this.selectedData.importAttrFiberID.includes(d.importAttr.fiberID)
      )
      .map(d => ({
        mainAttr: { fiberID: d.mainAttr.fiberID },
        importAttr: { fiberID: d.importAttr.fiberID }
      }));

    const selectedFiberIDs = new Set(selectedDataArray.flatMap(d => [
      d.mainAttr.fiberID,
      d.importAttr.fiberID
    ]));

    this.svg.selectAll(".link")
      .each(function (d) {
        if (d && d.source && d.target) {
          const isVisible = selectedFiberIDs.has(d.source.mainAttr.fiberID) || selectedFiberIDs.has(d.target.mainAttr.fiberID);

          if (!isVisible) {
            d3.select(this).remove();
          }
        } else {
          console.warn('Link data is not correctly defined:', d);
        }
      });

    if (this.hideUnselectedData == false) {
      this.svg.selectAll(".link").remove();
      this.drawLinks();
    }



  }

  applyLinkVisibilityBySelectedData() {

    if (this.hideUnselectedData == true) {

      this.svg.selectAll(".link").remove();
      this.drawLinks();

      const selectedDataArray = this.data
        .filter(d =>
          this.selectedData.mainAttrFiberID.includes(d.mainAttr.fiberID) ||
          this.selectedData.importAttrFiberID.includes(d.importAttr.fiberID)
        )
        .map(d => ({
          mainAttr: { fiberID: d.mainAttr.fiberID },
          importAttr: { fiberID: d.importAttr.fiberID }
        }));

      const selectedFiberIDs = new Set(selectedDataArray.flatMap(d => [
        d.mainAttr.fiberID,
        d.importAttr.fiberID
      ]));

      this.svg.selectAll(".link")
        .each(function (d) {
          if (d && d.source && d.target) {
            const isVisible = selectedFiberIDs.has(d.source.mainAttr.fiberID) || selectedFiberIDs.has(d.target.mainAttr.fiberID);

            if (!isVisible) {
              d3.select(this).remove();
            }
          } else {
            console.warn('Link data is not correctly defined:', d);
          }
        });
    }
  }

  highlightConnectedLinks(selectedLinkId: string, linkMap: Map<string, string[]>, allLinks: Map<string, d3.Selection<SVGPathElement, unknown, null, undefined>>): void {
    this.svg.selectAll(".link")
      .style("stroke-width", 3)
      .style("stroke-opacity", 0.7);

    const pathsToHighlight = linkMap.get(selectedLinkId) || [];
    pathsToHighlight.forEach(pathData => {
      const link = allLinks.get(pathData);
      if (link) {
        link.style("stroke-width", 8)
          .style("stroke-opacity", 0.6);
      }
    });
  }


  getGridCoordinates(x: number, y: number): [number, number] {
    const gridSize = 1;
    const gridX = Math.round(x / gridSize) * gridSize;
    const gridY = Math.round(y / gridSize) * gridSize;
    return [gridX, gridY];
  }







  getNextVertical(startX: number, endX: number): number {
    let midX = (startX + endX) / 10;
    while (this.usedVerticalPaths.has(midX.toString())) {
      midX += 6;
    }
    this.usedVerticalPaths.set(midX.toString(), 1);
    return midX;
  }


  // /$$$$$$$   /$$$$$$  /$$      /$$ /$$   /$$ /$$        /$$$$$$   /$$$$$$  /$$$$$$$ 
  // | $$__  $$ /$$__  $$| $$  /$ | $$| $$$ | $$| $$       /$$__  $$ /$$__  $$| $$__  $$
  // | $$  \ $$| $$  \ $$| $$ /$$$| $$| $$$$| $$| $$      | $$  \ $$| $$  \ $$| $$  \ $$
  // | $$  | $$| $$  | $$| $$/$$ $$ $$| $$ $$ $$| $$      | $$  | $$| $$$$$$$$| $$  | $$
  // | $$  | $$| $$  | $$| $$$$_  $$$$| $$  $$$$| $$      | $$  | $$| $$__  $$| $$  | $$
  // | $$  | $$| $$  | $$| $$$/ \  $$$| $$\  $$$| $$      | $$  | $$| $$  | $$| $$  | $$
  // | $$$$$$$/|  $$$$$$/| $$/   \  $$| $$ \  $$| $$$$$$$$|  $$$$$$/| $$  | $$| $$$$$$$/
  // |_______/  \______/ |__/     \__/|__/  \__/|________/ \______/ |__/  |__/|_______/ 

  downloadD3Graph(): Promise<void> {




    if (!this.svgContainerRef?.nativeElement) {
      return Promise.reject(new Error('svgContainerRef not found'));
    }

    const fiberNameLeft = this.fiberName[0] || 'default';
    const fiberNameRight = this.fiberName[1] || 'default';

    this.drawConnectionText(`Conexión de fibra ${fiberNameLeft} a ${fiberNameRight}`, this.svg);


    return new Promise<void>((resolve, reject) => {
      const svgElement = this.svgContainerRef.nativeElement;

      const svgHeight = svgElement.clientHeight;
      const originalWidth = svgElement.style.width;
      svgElement.style.width = svgElement.scrollWidth + 'px';
      html2canvas(svgElement, {
        scale: 2,
        width: svgElement.scrollWidth,
        height: svgHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = imgData;
        link.download = `conexion_de_fibra_${fiberNameLeft}_a_${fiberNameRight}.png`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        svgElement.style.width = originalWidth;


        this.drawConnectionText("", this.svg);

        resolve();

      }).catch(error => {
        reject(error);
      });

    });


  }

  selectedFiber(newSelectedData: any) {
    const selectedData = {
      mainAttrFiberID: newSelectedData.map(data => data.mainAttr.fiberID),
      importAttrFiberID: newSelectedData.map(data => data.importAttr.fiberID)
    };

    this.selectedData = selectedData;

    this.applyLinkVisibilityBySelectedData();


  }

//   /$$$$$$  /$$$$$$$$  /$$$$$$  /$$$$$$$   /$$$$$$  /$$   /$$         
//  /$$__  $$| $$_____/ /$$__  $$| $$__  $$ /$$__  $$| $$  | $$         
// | $$  \__/| $$      | $$  \ $$| $$  \ $$| $$  \__/| $$  | $$         
// |  $$$$$$ | $$$$$   | $$$$$$$$| $$$$$$$/| $$      | $$$$$$$$         
//  \____  $$| $$__/   | $$__  $$| $$__  $$| $$      | $$__  $$         
//  /$$  \ $$| $$      | $$  | $$| $$  \ $$| $$    $$| $$  | $$         
// |  $$$$$$/| $$$$$$$$| $$  | $$| $$  | $$|  $$$$$$/| $$  | $$         
//  \______/ |________/|__/  |__/|__/  |__/ \______/ |__/  |__/         
                                                                     
                                                                     
                                                                     
//   /$$$$$$  /$$$$$$$$ /$$$$$$$  /$$    /$$ /$$$$$$  /$$$$$$  /$$$$$$$$
//  /$$__  $$| $$_____/| $$__  $$| $$   | $$|_  $$_/ /$$__  $$| $$_____/
// | $$  \__/| $$      | $$  \ $$| $$   | $$  | $$  | $$  \__/| $$      
// |  $$$$$$ | $$$$$   | $$$$$$$/|  $$ / $$/  | $$  | $$      | $$$$$   
//  \____  $$| $$__/   | $$__  $$ \  $$ $$/   | $$  | $$      | $$__/   
//  /$$  \ $$| $$      | $$  \ $$  \  $$$/    | $$  | $$    $$| $$      
// |  $$$$$$/| $$$$$$$$| $$  | $$   \  $/    /$$$$$$|  $$$$$$/| $$$$$$$$
//  \______/ |________/|__/  |__/    \_/    |______/ \______/ |________/


  searchService() {
    const searchTerm = this.serviceName.toLowerCase();

    if (searchTerm === '') {
      this.svg.selectAll("text.service-name-text")
        .style("fill", "#000")
        .style("font-size", "12px")
        .style("opacity", "1");
    } else {
      this.svg.selectAll("text.service-name-text")
        .style("fill", function (d) {
          if (!d || !d.mainAttr || !d.mainAttr.service) {
            return '#000';
          }

          const serviceName = d.mainAttr.service.toLowerCase();
          return serviceName.includes(searchTerm) ? "#000" : "#000";
        })
        .style("font-size", function (d) {
          if (!d || !d.mainAttr || !d.mainAttr.service) {
            return "12px";
          }

          const serviceName = d.mainAttr.service.toLowerCase();
          return serviceName.includes(searchTerm) ? "14px" : "12px";
        })
        .style("opacity", function (d) {
          if (!d || !d.mainAttr || !d.mainAttr.service) {
            return "1";
          }

          const serviceName = d.mainAttr.service.toLowerCase();
          return serviceName.includes(searchTerm) ? "1" : "0.2";
        });
    }
  }

//   /$$$$$$  /$$$$$$$   /$$$$$$  /$$   /$$ /$$$$$$$ 
//   /$$__  $$| $$__  $$ /$$__  $$| $$  | $$| $$__  $$
//  | $$  \__/| $$  \ $$| $$  \ $$| $$  | $$| $$  \ $$
//  | $$ /$$$$| $$$$$$$/| $$  | $$| $$  | $$| $$$$$$$/
//  | $$|_  $$| $$__  $$| $$  | $$| $$  | $$| $$____/ 
//  | $$  \ $$| $$  \ $$| $$  | $$| $$  | $$| $$      
//  |  $$$$$$/| $$  | $$|  $$$$$$/|  $$$$$$/| $$      
//   \______/ |__/  |__/ \______/  \______/ |__/      
                                                   
                                                   
                                                   
//   /$$   /$$  /$$$$$$  /$$$$$$$  /$$$$$$$$  /$$$$$$ 
//  | $$$ | $$ /$$__  $$| $$__  $$| $$_____/ /$$__  $$
//  | $$$$| $$| $$  \ $$| $$  \ $$| $$      | $$  \__/
//  | $$ $$ $$| $$  | $$| $$  | $$| $$$$$   |  $$$$$$ 
//  | $$  $$$$| $$  | $$| $$  | $$| $$__/    \____  $$
//  | $$\  $$$| $$  | $$| $$  | $$| $$       /$$  \ $$
//  | $$ \  $$|  $$$$$$/| $$$$$$$/| $$$$$$$$|  $$$$$$/
//  |__/  \__/ \______/ |_______/ |________/ \______/ 

  private groupNodesBySide(data: Node[]): { [side: string]: { [buffer: string]: Node[] } } {
    const groupedNodes: { [side: string]: { [buffer: string]: Node[] } } = {};

    const uniqueSides = Array.from(new Set(data.map(d => d.side)));

    if (uniqueSides.length === 1) {
      const singleSide = uniqueSides[0];

      if (singleSide !== 'Sin Información') {
        this.isSingleSideGlobal = singleSide;
        return this.groupNodesByFiberName(data);
      } else {
        uniqueSides.push(`${singleSide}_2`);
      }
    }

    uniqueSides.forEach(side => {
      groupedNodes[side] = {};
      const nodesToGroup = this.getNodesToGroup(data, side, uniqueSides);
      this.groupNodesByBuffer(groupedNodes, side, nodesToGroup);
    });

    if (uniqueSides.length === 2 && uniqueSides[1].includes('_')) {
      this.transferDataBetweenSides(groupedNodes, uniqueSides);
    }

    console.log(groupedNodes);
    return groupedNodes;
  }

  private getNodesToGroup(data: Node[], side: string, uniqueSides: string[]): Node[] {
    return uniqueSides.length === 2 && side.includes('_')
      ? data.filter(d => d.side === uniqueSides[0])
      : data.filter(d => d.side === side);
  }

  private groupNodesByFiberName(data: Node[]): { [fiberName: string]: { [buffer: string]: Node[] } } {

    this.groupNodeByFibername = true;
    this.removedNodes = {};
    const groupedNodes: { [fiberName: string]: { [buffer: string]: Node[] } } = {};


    data.forEach(node => {
      const fiberName = node.mainAttr.name;
      const buffer = node.mainAttr.buffer;

      if (!groupedNodes[fiberName]) {
        groupedNodes[fiberName] = {};
      }

      if (!groupedNodes[fiberName][buffer]) {
        groupedNodes[fiberName][buffer] = [];
      }

      groupedNodes[fiberName][buffer].push(node);
    });

    Object.keys(groupedNodes).forEach(fiberName => {
      Object.keys(groupedNodes[fiberName]).forEach(buffer => {
        const nodes = groupedNodes[fiberName][buffer];

        const nameCountMap = nodes.reduce((acc, node) => {
          acc[node.mainAttr.name] = (acc[node.mainAttr.name] || 0) + 1;
          return acc;
        }, {} as { [name: string]: number });

        const maxCountName = Object.keys(nameCountMap).reduce((a, b) =>
          nameCountMap[a] > nameCountMap[b] ? a : b
        );

        this.removedNodes[fiberName] = this.removedNodes[fiberName] || {};
        this.removedNodes[fiberName][buffer] = nodes.filter(node => node.mainAttr.name !== maxCountName);

        groupedNodes[fiberName][buffer] = nodes.filter(node => node.mainAttr.name === maxCountName);
      });
    });

    console.log("Removed nodes: ", this.removedNodes);
    return groupedNodes;
  }

  private groupNodesByBuffer(
    groupedNodes: { [side: string]: { [buffer: string]: Node[] } },
    side: string,
    nodesToGroup: Node[]
  ): void {
    if (!this.removedNodes) {
      this.removedNodes = {};
    }

    if (!this.removedNodes[side]) {
      this.removedNodes[side] = {};
    }

    nodesToGroup.forEach(node => {
      const buffer = node.mainAttr.buffer;
      if (!groupedNodes[side]) {
        groupedNodes[side] = {};
      }
      if (!groupedNodes[side][buffer]) {
        groupedNodes[side][buffer] = [];
      }
      groupedNodes[side][buffer].push(node);
    });

    this.groupNodeByFibername = false;

    Object.keys(groupedNodes[side]).forEach(buffer => {
      const nodes = groupedNodes[side][buffer];

      const nameCountMap = nodes.reduce((acc, node) => {
        acc[node.mainAttr.name] = (acc[node.mainAttr.name] || 0) + 1;
        return acc;
      }, {} as { [name: string]: number });

      const maxCountName = Object.keys(nameCountMap).reduce((a, b) =>
        nameCountMap[a] > nameCountMap[b] ? a : b
      );

      this.removedNodes[side][buffer] = nodes.filter(node => node.mainAttr.name !== maxCountName);
      groupedNodes[side][buffer] = nodes.filter(node => node.mainAttr.name === maxCountName);
    });

    // console.log("Removed nodes: ", this.removedNodes);
  }

  //-----------------------------------------------------------If the cable is split in two----------------------------------------//
  private transferDataBetweenSides(
    groupedNodes: { [side: string]: { [buffer: string]: Node[] } },
    uniqueSides: string[]
  ): void {
    const [side1, side2] = uniqueSides;

    groupedNodes[side1] = {};

    Object.keys(groupedNodes[side2]).forEach(buffer => {
      const nodesInSide2 = groupedNodes[side2][buffer];
      const halfSize = Math.floor(nodesInSide2.length / 2);

      const halfNodes = nodesInSide2.slice(0, halfSize);
      const remainingNodes = nodesInSide2.slice(halfSize);

      if (!groupedNodes[side1][buffer]) {
        groupedNodes[side1][buffer] = [];
      }
      groupedNodes[side1][buffer].push(...halfNodes);

      groupedNodes[side2][buffer] = remainingNodes;
    });
  }

    //--------------------------------------------------END---------If the cable is split in two----------------------------------------//



  // /$$   /$$  /$$$$$$  /$$$$$$$  /$$$$$$$$  /$$$$$$                                                 
  // | $$$ | $$ /$$__  $$| $$__  $$| $$_____/ /$$__  $$                                                
  // | $$$$| $$| $$  \ $$| $$  \ $$| $$      | $$  \__/                                                
  // | $$ $$ $$| $$  | $$| $$  | $$| $$$$$   |  $$$$$$                                                 
  // | $$  $$$$| $$  | $$| $$  | $$| $$__/    \____  $$                                                
  // | $$\  $$$| $$  | $$| $$  | $$| $$       /$$  \ $$                                                
  // | $$ \  $$|  $$$$$$/| $$$$$$$/| $$$$$$$$|  $$$$$$/                                                
  // |__/  \__/ \______/ |_______/ |________/ \______/                                                 
                                                                                                    
                                                                                                    
                                                                                                    
  //  /$$      /$$ /$$$$$$ /$$$$$$$$ /$$   /$$       /$$   /$$  /$$$$$$                                
  // | $$  /$ | $$|_  $$_/|__  $$__/| $$  | $$      | $$$ | $$ /$$__  $$                               
  // | $$ /$$$| $$  | $$     | $$   | $$  | $$      | $$$$| $$| $$  \ $$                               
  // | $$/$$ $$ $$  | $$     | $$   | $$$$$$$$      | $$ $$ $$| $$  | $$                               
  // | $$$$_  $$$$  | $$     | $$   | $$__  $$      | $$  $$$$| $$  | $$                               
  // | $$$/ \  $$$  | $$     | $$   | $$  | $$      | $$\  $$$| $$  | $$                               
  // | $$/   \  $$ /$$$$$$   | $$   | $$  | $$      | $$ \  $$|  $$$$$$/                               
  // |__/     \__/|______/   |__/   |__/  |__/      |__/  \__/ \______/                                
                                                                                                    
                                                                                                    
                                                                                                    
  //   /$$$$$$   /$$$$$$  /$$   /$$ /$$   /$$ /$$$$$$$$  /$$$$$$  /$$$$$$$$ /$$$$$$  /$$$$$$  /$$   /$$
  //  /$$__  $$ /$$__  $$| $$$ | $$| $$$ | $$| $$_____/ /$$__  $$|__  $$__/|_  $$_/ /$$__  $$| $$$ | $$
  // | $$  \__/| $$  \ $$| $$$$| $$| $$$$| $$| $$      | $$  \__/   | $$     | $$  | $$  \ $$| $$$$| $$
  // | $$      | $$  | $$| $$ $$ $$| $$ $$ $$| $$$$$   | $$         | $$     | $$  | $$  | $$| $$ $$ $$
  // | $$      | $$  | $$| $$  $$$$| $$  $$$$| $$__/   | $$         | $$     | $$  | $$  | $$| $$  $$$$
  // | $$    $$| $$  | $$| $$\  $$$| $$\  $$$| $$      | $$    $$   | $$     | $$  | $$  | $$| $$\  $$$
  // |  $$$$$$/|  $$$$$$/| $$ \  $$| $$ \  $$| $$$$$$$$|  $$$$$$/   | $$    /$$$$$$|  $$$$$$/| $$ \  $$
  //  \______/  \______/ |__/  \__/|__/  \__/|________/ \______/    |__/   |______/ \______/ |__/  \__/



  private drawRemovedNodes(svgWidth: number, svgHeight: number): void {
    const removedNodes = this.getFlattenedRemovedNodes();
    const removedNodesYOffset = svgHeight + 100;
    const x = 0;
    let maxY = 0;
    const bufferVerticalSpacing = 50;  

    const nodesGroupedByFiberAndBuffer = this.groupRemovedNodesByFiberAndBuffer(removedNodes);

    const nodeCounters: { [name: string]: number } = {};
    const lateralRectangles: { [name: string]: { minX: number; maxX: number; minY: number; maxY: number } } = {};

    const nodePositions: { [name: string]: number } = {};
    const bufferPositions: { [buffer: string]: number } = {};

    let currentY = removedNodesYOffset;

    Object.keys(nodesGroupedByFiberAndBuffer).forEach((fiberName) => {
      const buffers = nodesGroupedByFiberAndBuffer[fiberName];

      Object.keys(buffers).forEach((buffer, bufferIndex) => {
        const nodes = buffers[buffer];

        let bufferMinX = Infinity;
        let bufferMaxX = -Infinity;
        let bufferMinY = Infinity;
        let bufferMaxY = -Infinity;

        nodes.forEach((node, index) => {
          const y = currentY + index * 25;  
          const color = this.color[node.mainAttr.color.slice(0, 2)] || '#000';

          bufferMinX = Math.min(bufferMinX, x - 20);
          bufferMaxX = Math.max(bufferMaxX, x + 20);
          bufferMinY = Math.min(bufferMinY, y - 20);
          bufferMaxY = Math.max(bufferMaxY, y + 20);

          maxY = Math.max(maxY, y + 50);

          const name = node.mainAttr.name;
          if (!nodeCounters[name]) {
            nodeCounters[name] = 1;
          } else {
            nodeCounters[name]++;
          }

          this.svg.append("circle")
            .attr("cx", x)
            .attr("cy", y)
            .attr("r", 5)
            .style("fill", color);

          this.svg.append("text")
            .attr("x", x + 10)
            .attr("y", y + 5)
            .text(nodeCounters[name].toString())
            .style("font-size", "12px")
            .style("font-weight", "bold")
            .style("fill", "#000");

        });

        bufferPositions[buffer] = currentY;

        currentY += nodes.length * 25 + bufferVerticalSpacing;

        this.drawBufferForRemovedNodes(bufferMinX, bufferMaxX, bufferMinY, nodes.length, buffer, bufferIndex);

        const name = nodes[0].mainAttr.name;  
        if (!lateralRectangles[name]) {
          lateralRectangles[name] = {
            minX: bufferMinX - 40,  
            maxX: bufferMinX - 40 + 20,  
            minY: bufferMinY,  
            maxY: bufferMinY + nodes.length * 25 + 5  
          };
        } else {
          lateralRectangles[name].minY = Math.min(lateralRectangles[name].minY, bufferMinY);
          lateralRectangles[name].maxY = Math.max(lateralRectangles[name].maxY, bufferMinY + nodes.length * 25 + 5);
        }
      });
    });

    let currentLateralY = removedNodesYOffset - 15;  

    Object.keys(lateralRectangles).forEach((name) => {
      const rect = lateralRectangles[name];
      this.drawLateralRectangle(
        rect.minX,
        currentLateralY,
        rect.maxX - rect.minX,
        rect.maxY - rect.minY,
        'black',
        'black',
        this.svg
      );

      const isRightColumn = false;  
      this.drawLateralText(
        rect.minX,
        currentLateralY,
        isRightColumn,
        name,
        this.svg,
        true  
      );
      currentLateralY += rect.maxY - rect.minY + bufferVerticalSpacing - 5;  
    });

    const newSvgHeight = Math.max(svgHeight, maxY, currentY);  

    d3.select(this.svgContainerRef.nativeElement).select("svg")
      .attr("width", svgWidth)
      .attr("height", newSvgHeight);
  }




  groupRemovedNodesByFiberAndBuffer(removedNodes: Node[]): { [fiberName: string]: { [buffer: string]: Node[] } } {
    const groupedNodes: { [fiberName: string]: { [buffer: string]: Node[] } } = {};

    removedNodes.forEach(node => {
      const fiberName = node.mainAttr.name;
      const buffer = node.mainAttr.buffer;

      if (!groupedNodes[fiberName]) {
        groupedNodes[fiberName] = {};
      }

      if (!groupedNodes[fiberName][buffer]) {
        groupedNodes[fiberName][buffer] = [];
      }

      groupedNodes[fiberName][buffer].push(node);
    });

    return groupedNodes;
  }


  drawBufferForRemovedNodes(bufferMinX: number, bufferMaxX: number, bufferMinY: number, nodeCount: number, buffer: string, sideIndex: number): void {
    const rectColor = this.color[buffer] || 'none';
    const nodeSpacing = 25;

    const bufferHeight = nodeCount * nodeSpacing;

    this.svg.append("rect")
      .attr("x", bufferMinX - 10) 
      .attr("y", bufferMinY + 5) 
      .attr("width", 20) 
      .attr("height", bufferHeight + 5)  
      .attr("rx", 5)
      .attr("ry", 5)
      .style("fill", rectColor)  
      .style("stroke", 'black')
      .style("stroke-width", 1);
  }

  private calculateMaxY(initialHeight: number, bufferMaxY: number): number {
    return Math.max(initialHeight, bufferMaxY + 100);  
  }

  private getFlattenedRemovedNodes(): Node[] {
    const removedNodes = this.getRemovedNodes();
    const flattenedNodes: Node[] = [];

    Object.keys(removedNodes).forEach(side => {
      Object.keys(removedNodes[side]).forEach(buffer => {
        flattenedNodes.push(...removedNodes[side][buffer]);
      });
    });

    return flattenedNodes;
  }

  private getRemovedNodes(): { [side: string]: { [buffer: string]: Node[] } } {
    const nodes = this.removedNodes;
    const uniqueNodes: { [side: string]: { [buffer: string]: Node[] } } = {};

    Object.keys(nodes).forEach((side) => {
      uniqueNodes[side] = {};

      Object.keys(nodes[side]).forEach((buffer) => {
        const nodesArray = nodes[side][buffer];

        if (!side.endsWith('_2')) {
          uniqueNodes[side][buffer] = nodesArray;
        }
      });
    });

    return uniqueNodes;
  }


}








