<div class="principal-div">
  <div class="t-comments-box">
    <div class="comments-and-input">
      <div class="comments-section">
        <div class="add-comment">
          <div class="search-input">
            <input class="form-control form-control-sm" type="text" placeholder="{{ 'infrastructure-odf.search.comment' | translate }}" [(ngModel)]="searchTerm"
              (ngModelChange)="filterComments()">
            <div class="search-send"><i class="fas fa-search"></i></div>
          </div>
        </div>
        <div class="comment comment-level-1" *ngFor="let comment of filteredComments; let i = index">
          <div class="comment-header">
            <div class="avatar">{{ comments[i]?.initials }}</div>
            <div class="comment-remove" (click)="removeComment(comments[i]?.eventID)">
              <i class="dx-icon-trash"></i>
            </div>
          </div>
          <div>
            <br>
            <div class="comment-label">
              <span class="comment-name">{{ comments[i]?.name }}</span>
              <span class="comment-time"> │ {{ formatTime(comments[i]?.creationDate) }}</span>
            </div>
            <br>
            <div class="comment-content" [innerHTML]="convertLineBreaksToHtml(filteredComments[i]?.content)"></div>
            <br>
            <div class="comment-action">
              <a *ngIf="comment?.fileLink" [href]="comment.fileLink" download="{{ comment.fileName }}" target="_blank">
                {{ 'general.download' | translate }} {{ comment.fileName }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-input-section">
    <textarea class="form-control form-control-sm" placeholder="{{ 'infrastructure-odf.add.comment' | translate }}" [(ngModel)]="comment"></textarea>
    <div class="buttons-container">
      <dx-button class="button main-background-theme" (onClick)="saveComment()" [disabled]="isSaving">{{ 'infrastructure-odf.save.comment' | translate }}</dx-button>
      <div class="fileuploader-container">
        <dx-file-uploader #fileUploader [showFileList]="true" [multiple]="false" [maxFileSize]="5000000"
        selectButtonText="{{ 'infrastructure-odf.select.file' | translate }}"  labelText="{{ 'infrastructure-odf.drop.file' | translate }}" accept="" uploadMode="useForm"
          [inputAttr]="{ 'aria-label': 'Select Photo' }" (onValueChanged)="onFileSelected($event)">
        </dx-file-uploader>
      </div>
    </div>
  </div>
</div>