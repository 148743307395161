<div class="splitter-container py-4 px-5">
  
  <div fxLayout="column" class="header-container py-3 mt-3 mb-5">
    <strong class="title-header">{{ 'splitter.box.device-splitters' | translate: { deviceName: device?.name } }}</strong>

    <div class="my-3">
      <dx-button
        icon="fas fa-file-download"
        class="button main-background-theme"
        [text]="'general.download' | translate"
        (click)="downloadGraph()"
      >
      </dx-button>
    </div>
  </div>


  <app-d3-horizontal-tree *ngFor="let splitterBox of splitterBoxes"
    [data]="splitterBox.graph"
    [fileName]="splitterBox.fileName"
    [svgIcons]="svgIcons"
    (componentReady)="graphReady(splitterBox)"
  >
  </app-d3-horizontal-tree>
</div>
