<div fxLayout="column" fxLayoutAlign="space-between none" class="h-100">
    <div fxLayout="row nowrap">
      <div class="me-2" fxLayout="column nowrap" fxLayoutAlign="space-between none" fxFlex="50">
  
        <dx-number-box
          height="45px"
          [(value)]="selectedTrace.traceID"
          [valueChangeEvent]="'keyup'"
          labelMode="floating"
          [label]="'trace.edit.traceID' | translate"
        >
        </dx-number-box>
        <dx-text-box 
        height="45px"
        [(value)]="selectedTrace.name"
        labelMode="floating"
        [label]="'trace.edit.name' | translate"
        >
        </dx-text-box>
        <dx-text-box 
        height="45px"
        [(value)]="selectedTrace.description"
        labelMode="floating"
        [label]="'trace.edit.description' | translate"
        >
        </dx-text-box>
        <!-- <dx-number-box
        height="45px"
        [(value)]="selectedTrace.elementTypeID"
        labelMode="floating"
        [label]="'trace.edit.elementTypeID' | translate"
        >
        </dx-number-box> -->
        <dx-select-box
        height="45px"
        labelMode="floating"
        displayExpr="name" 
        valueExpr="containerID"
        [value]="selectedTrace.containerID"
        [dataSource]="containerDataSource"
        [deferRendering]="false"
        [placeholder]="'trace.edit.container' | translate"
        [searchEnabled]="true"
        [searchExpr]="'name'" 
        [searchMode]="'contains'"
        [searchTimeout]="200" 
      ></dx-select-box>
        <dx-number-box
        height="45px"
        [(value)]="selectedTrace.length"
        labelMode="floating"
        [label]="'trace.edit.length' | translate"
        >
        </dx-number-box>

      </div>
  
      <div fxLayout="column nowrap" fxFlex="50">
        <ol-map
            id="mapContainer"
            #olMap
            [latitude]="latitude"
            [longitude]="longitude"
            [zoom]="zoom"
            [linestring]="lineString"
            (onMapReady)="mapReady()"
        ></ol-map>
      </div>
    </div>
  
    <div class="me-2 mt-4">
      <dx-tab-panel>
        <dxi-item [title]="'devices.add-edit.ports' | translate" *ngIf="selectedTrace.fiber.length">
          <dx-scroll-view>
            <div fxLayout="column" class="data-container">
              <app-trace-modal-fiber-list
                [fiber]="selectedTrace.fiber"
              >
              </app-trace-modal-fiber-list>
            </div>
          </dx-scroll-view>
        </dxi-item>
  
        <!-- <dxi-item [title]="'devices.add-edit.splitters' | translate">
          <dx-scroll-view>
            <div class="data-container">
              <app-device-splitter-list
                [splitters]="splitters"
                [splitterTypes]="splitterTypes"
                (addSplitter)="handleAddSplitter($event)"
                (editSplitter)="handleEditSplitter($event)"
                (deleteSplitter)="handleDeleteSplitter($event)"
              >
              </app-device-splitter-list>
            </div>
          </dx-scroll-view>
        </dxi-item> -->
      </dx-tab-panel>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="end center" class="me-2 mt-4">
      <dx-button
        [text]="'general.cancel' | translate"
        (click)="cancel.emit()"
      >
      </dx-button>
      <dx-button
        class="ms-3 main-background-theme"
        [text]="'general.save' | translate"
        (click)="saveTrace()"
      >
      </dx-button>
    </div>
  </div>
  