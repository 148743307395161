import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Device } from '@app/@shared/model/device.model';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { PlatformService } from '@app/auth/platform.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonDevicesResolver implements Resolve<any[]> {
  constructor(
    private deviceService: DeviceService,
    private platformService: PlatformService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Device[]> {
    const tokenFromActivatedRoute: string = route.queryParamMap.get('token');
    if(tokenFromActivatedRoute){
      return Promise.resolve([ new Device() ]);
    }
    const deviceID = Number(route.params['deviceID']);
    const device: Device = await firstValueFrom(this.deviceService.getByID(deviceID));
    return Promise.resolve([device]);
  }
}
