import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { Trace } from '@app/@shared/model/trace.model';
import { map, Observable, of } from 'rxjs';
import { MainService } from '../main.service';
import { IQueryViewConfiguration } from '@app/@shared/model/interface/iQuery-view-configuration.model';
import { PlatformService } from '@app/auth/platform.service';

@Injectable()
export class TraceService {

  constructor(private main: MainService,
    private platformService: PlatformService) { }

  getWithFilter(entity?: any, limit?: number, offset?: number): Observable<any> {
    this.main.chooseAPI(Cons._TRACE);
    // let body = this.cleanUpForRequest(entity);
    let body;
    return this.main.getWithFilter(Cons._TRACETYPE, entity, true, limit, offset).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.traceID;
            x.entityName = Cons._TRACE;
            return <Trace>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  queryView(entity: any, xyCoordinates?: any[], locationCriteria?, isWithServiceID: boolean = false): Observable<any> {
    this.main.chooseAPI(Cons._TRACE);

    const queryViewConfiguration: IQueryViewConfiguration = {
      xyCoordinates,
      locationCriteria,
    };

    return this.main
      .queryView(Cons._TRACE, entity, queryViewConfiguration, isWithServiceID)
      .pipe(
        map((res) => {
          try {
            return res.responseData.map((x) => {
              x.commonID = x.traceID;
              x.entityName = Cons._TRACE;
              return <Trace>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  getTracesByDeviceIds(ids: number[], connectionType: string): Observable<any> {
    this.main.chooseAPI(Cons._TRACE);
    const body = { Ids: ids, platformID: this.platformService.platformID, connectionType }

    return this.main
      .post(Cons._TRACE, "ByDeviceIds", body)
      .pipe(
        map((res) => {
          try {
            return res.responseData.map((x) => {
              x.commonID = x.traceID;
              x.entityName = Cons._TRACE;
              return <Trace>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._TRACE);
    return this.main.getByID(Cons._TRACE, id).pipe(
      map((res) => {
        try {
          res.commonID = res.traceID;
          res.entityName = Cons._TRACE;
          return <Trace>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getInfrastructureIDByServiceID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._TRACE);
    return this.main.getByID(Cons._TRACE + "/Infrastructure/Service", id).pipe(
      map((res) => {
        try {
          return <any>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  update(trace: Trace) {
    // throw new Error("Method not implemented.");
    this.main.chooseAPI(Cons._SERVICECONFIG);
    //TODO: evaluar como quitar la variable busqueda
    const busqueda = `${this.platformService.platformID}/${trace.traceID}`;
    return this.main.put(Cons._SPLITTERTYPE, busqueda, trace).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
  // cleanUpForRequest(entity : PlatformPlot) : PlatformPlot{
  //   let body = plainToInstance(PlatformPlot, entity);
  //   body.plot = undefined;

  //   return body;
  // }
}
