import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';


@Component({
  selector: 'app-infrastructure-list',
  templateUrl: './infrastructure-list.component.html',
  styleUrls: ['./infrastructure-list.component.scss'],
})
export class InfrastructureListComponent {
  @Input() loading: boolean = false;
  @Input() infrastructure: any [];

  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  

  constructor() {
  }
  ngOnInit(): void {
  }
}
