<dx-popup
  [visible]="true"
  [closeOnOutsideClick]="false"
  [showTitle]="false"
  [height]="125"
  [width]="300"
>
  <div *dxTemplate="let data of 'content'">
    <div class="d-flex flex-column justify-content-between align-items-center h-100">

      <label class="mb-3">
        {{ loggingOut ? ('expired-token.logging-out' | translate) : ('expired-token.please-login-again' | translate) }}
      </label>

      <dx-button *ngIf="!loggingOut"
        class="button main-background-theme"
        [text]="'general.ok' | translate"
        (click)="logout()"
      >
      </dx-button>

      <div *ngIf="loggingOut" fxLayout="row" fxLayoutAlign="center center">
        <div class="mb-4 spinner-border" role="status"></div>
      </div>
    </div>
  </div>
</dx-popup>