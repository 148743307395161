import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { PlatformService } from '@app/auth/platform.service';
import { plainToInstance } from 'class-transformer';
import { Client } from '../../../@shared/model/client.model';
import { TranslateService } from '@ngx-translate/core';
import { FeasibilityStudy } from '@app/@shared/model/feasibilityStudy.model';
import { FeasibilityService } from '@app/@shared/services/feasibility.service';
import { error } from 'console';
import { parseNumber } from 'devextreme/localization';
import { check } from 'prettier';
import { Cons } from '@app/@shared/cons/cons';

@Component({
  selector: 'feasibility-single-modal',
  templateUrl: './feasibility-single-modal.component.html',
  styleUrls: ['./feasibility-single-modal.component.scss'],
})
export class FeasibilitySingleComponent implements OnInit {
  @Input() entity: FeasibilityStudy;

  @Output() cancel = new EventEmitter<void>();
  @Output() onFeasibilityChecked = new EventEmitter<any>();
  @Output() onFeasibilityAllocated = new EventEmitter<any>();
  @Output() onClientSaved= new EventEmitter<any>();

  get requiredFieldsOk(){
    return this.validateAllFields();
  }

  _stateCodes = Cons.STATE_CODES;
  
  idNumberValidationMessage: string = '';
  emailValidationMessage: string;
  loadingVisible : boolean = false;
  
  disableReserveTab: boolean= false;

  items: string[] = ['DNI'];
  invokerSystemDS = [];
  operatorDS = [];
  allowEditing = true;

  @ViewChild('id_validator') id_validator;
  @ViewChild('typeID_validator') typeID_validator;
  @ViewChild('email_validator') email_validator;
  @ViewChild('name_validator') name_validator;


  get parsedID() : number{
    let id = parseInt(this.entity.client.id);
    if(!id){
      id = 0;
    }

    return id;
  }
  set parsedID (value){
    if(value){
      this.entity.client.id = value.toString();
    }
    
  }

  constructor( private platformService: PlatformService, private translateService: TranslateService, private feasibilityService : FeasibilityService) {}

  ngOnInit() {
    this.loadInvokerSystemList();
    if(this.entity){
      this.allowEditing = this.entity.state?.orderBy <= 1;
    }
  }

  loadInvokerSystemList(){
    this.feasibilityService.getInvokerSystemWithFilter({ platformID: this.platformService.platformID })
    .subscribe((res)=> {
      this.operatorDS = Object.values(res.reduce((acc, obj) => ({ ...acc, [obj.operatorID]: obj.operator }), {}));
      this.invokerSystemDS = res;
    });
  }

  async checkFeasibilityAndSaveClient() {
    if(!this.validateAllFields(true)){
      return;
    }

    this.loadingVisible = true;
    this.onFeasibilityChecked.emit(this.entity);
  }

  allocateFeasibility(){
    this.loadingVisible = true;
    this.onFeasibilityAllocated.emit(this.entity);
  }

  save(){
   if(this.validateAllFields(true)){
    this.loadingVisible = true;
    this.onClientSaved.emit(this.entity.client);
   }
  }

  isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  validateAllFields(checkValidators?) : boolean {
    if(checkValidators){
      this.checkValidators();
    }
    
    if(!this.entity.client.name || this.entity.client.name.trim() == "" || !this.entity.client.typeID || this.entity.client.typeID.trim() == '' || !this.entity.client.id || 
      this.entity.client.id.trim() == '' || !this.entity.client.email || this.entity.client.email.trim() == ''){
      return false;
    }

    return true;
  }

  resetValidators() {
    if (this.name_validator) {
      this.name_validator.instance.reset();
    }
    if (this.email_validator) {
      this.email_validator.instance.reset();
    }
    if (this.typeID_validator) {
      this.typeID_validator.instance.reset();
    }
    if (this.id_validator) {
      this.id_validator.instance.reset();
    }
  }

  checkValidators() {
    if (this.name_validator) {
      this.name_validator.instance.validate();
    }
    if (this.email_validator) {
      this.email_validator.instance.validate();
    }
    if (this.typeID_validator) {
      this.typeID_validator.instance.validate();
    }
    if (this.id_validator) {
      this.id_validator.instance.validate();
    }
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    if (email && !email.match(emailRegex)) {
      this.emailValidationMessage = this.translateService.instant('feasibility.client-details.email');
      return false;
    }

    return true; 
  }

  onDocumentTypeChanged(selectedValue: string) {
    if(this.validateIDNumber()){
      this.entity.client.typeID = selectedValue;
    }
  }

  validateIDNumber() : boolean{
    this.idNumberValidationMessage = '';

    if (this.parsedID <= 1000000 || this.parsedID >= 99999999) {
      this.idNumberValidationMessage = '*' +  this.translateService.instant('feasibility.client-details.id-number.error.length');
      
      return false;
    }
    return true;
  }


  addressChanged($event){
    this.entity.locationData = $event.location;
    this.entity.placeName = $event.placeName;
  }

 
}
