import { Type } from 'class-transformer';
import { Plot } from './plot.model';

export class PlatformPlot {
  platformID: number;
  plotID: number;
  platformPlotAttributes : any;

  @Type(()=> Plot)
  plot?: Plot;
}

