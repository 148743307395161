<div class="card-container">
  <div class="primary-title" [innerHTML]="'overlapped-rings.home.title' | translate"></div>

  <dx-pie-chart  palette="Violet" paletteExtensionMode="Blend" [dataSource]="pieRatio">
    <dxi-series argumentField="metric" valueField="value">
      <dxo-label [visible]="true">
        <dxo-connector [visible]="true" [width]="1"></dxo-connector>
      </dxo-label>
    </dxi-series>
    <dxo-legend verticalAlignment="center" horizontalAlignment="center" itemTextPosition="right" [rowCount]="1">
    </dxo-legend>
    <dxo-size  [height]="200"></dxo-size>
  </dx-pie-chart>

  <br/>
  <div fxLayout="row" fxLayoutAlign="center center">
      <dx-button 
        style="margin-right: 10px;"
        class="main-background-theme"
        icon="fas fa-sync"
        (click)="showAlertPopupGenerateReport = true">
      </dx-button>
      <dx-button 
        [disabled]="!overlappedRingsFile"
        (click)="downloadReport()" 
        icon="fas fa-download">
      </dx-button>
  </div>

  <div class="secondary-title">{{ formattedDate }}</div>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false">
  </dx-load-panel>

  <dx-popup [(visible)]="showAlertPopupGenerateReport" [closeOnOutsideClick]="true" [showTitle]="true"
    [title]="'overlapped-rings.home.btn.generate-new-report' | translate" [dragEnabled]="false" [height]="'200px'"
    [maxWidth]="'50%'">
    <div *dxTemplate="let data of 'content'">
      <span>
        {{
        'overlapped-rings.home.btn.generate-new-report.modal.message'
        | translate
        }}</span>
  
      <div class="d-flex justify-content-end mt-5">
        <dx-button [text]="'general.cancel' | translate" (click)="showAlertPopupGenerateReport = false">
        </dx-button>
  
        <dx-button class="ms-2 main-background-theme" [text]="'general.continue' | translate"
          (click)="generateNewReport()">
        </dx-button>
      </div>
    </div>
  </dx-popup>