import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Container } from '@app/@shared/model/container.model';
import { ElementType } from '@app/@shared/model/elementType.model';
import { ElementTypeService } from '@app/@shared/services/element-type.service';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';


@Component({
    selector: 'app-container-home',
    templateUrl: './container-home.component.html',
    styleUrls: ['./container-home.component.scss'],
  })

  export class ContainerHomeComponent implements OnInit{
    loading: boolean = false;
    loadingModal: boolean = false;
    selectedContainer: Container;
    containers: Container[] = [];
    modalTitle: string;
    containerEditableData: { label, field }[] = [];
    entityType = "CONTAINER";
    entityTypeModels = [];
  
    popups = {
      confirmDelete: false,
      createOrEdit: false,
    };
  
    constructor(
      private activatedRoute: ActivatedRoute,
      private platformService: PlatformService,
      private translateService: TranslateService,
      private containerService: ContainerService,
      private elementTypeService : ElementTypeService
    ) {}


    
    ngOnInit(): void {
        this.containerEditableData = [
          { label: 'container.grid-header.id', field: 'ID'},
          { label: 'container.grid-header.name', field: 'Name'},
          { label: 'container.grid-header.description', field: 'Description'},
        ]
        this.search();

        let filter = new ElementType();
        filter.groupID = this.entityType;
        filter.platformID = this.platformService.activePlatform.platformID;
        this.elementTypeService.getElementsTypeWithFilter(filter).subscribe({
          next: (res)=>{
            if(res){
              this.entityTypeModels = res;
            }
          }
        })
      }


      async search(filter? : any) {
        if(!filter){
          filter = {};
        }

        this.loadingModal=true;
        this.containerService.getWithFilter(filter, 1000).subscribe(
          (valor) => {
            this.containers = valor;
            this.loadingModal=false;
          }
        );
      }
      closeModal() {
        this.popups.createOrEdit = false;
        this.selectedContainer = undefined;
      }
      openModalAddContainer() {
        const platformID: number = this.platformService.platformID;
        this.modalTitle = this.translateService.instant('devices.add-device');
        this.selectedContainer = plainToClass(Container, { platformID });
        this.popups.createOrEdit = true;
      }
      async deleteContainer(container: any) {
        throw new Error('Endpoint not implemented.');
        // this.popups.confirmDelete = false;
    
        // await this.getDevices();
        // this.selectedDevice = undefined;
      }
      async openModalEditContainer(container: Container) {
        this.modalTitle = this.translateService.instant('container.edit-device');
        this.loadingModal = true;
        this.selectedContainer = await firstValueFrom(this.containerService.getByID(container.containerID));
        this.loadingModal = false;
        this.popups.createOrEdit = true;
      }
      openPromptDeleteContainer(container: Container) {
        this.selectedContainer = container;
        this.popups.confirmDelete = true;
      }
      async saveContainer(container: Container) {
        this.loadingModal = true;
    
        try {
          if (this.selectedContainer?.containerID) {
            const updatedDevice = await firstValueFrom(this.containerService.update(container));
            const index = this.containers.findIndex(x => x.containerID === updatedDevice.containerID);
            this.containers[index] = updatedDevice;
          } else {
            const newContainer = await firstValueFrom(this.containerService.create(container));
            this.containers = [newContainer, ...this.containers];
          }
        } catch(ex) {
          // throw 
          throw new Error('Endpoint not implemented.');
        } finally {
          this.loadingModal = false;
          this.popups.createOrEdit = false;
          this.selectedContainer = undefined;
        }
      }
      
  }