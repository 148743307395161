import { Component, OnInit } from '@angular/core';
import { ElementType } from '@app/@shared/model/elementType.model';
import { FeasibilityStudy } from '@app/@shared/model/feasibilityStudy.model';
import { ElementTypeService } from '@app/@shared/services/element-type.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { DxPopupModule, DxTemplateModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { firstValueFrom } from 'rxjs';




@Component({
  selector: 'app-element-type',
  templateUrl: './element-type-home.component.html',
  styleUrls: ['./element-type-home.component.scss']
})
export class ElementTypeHomeComponent implements OnInit {

  loading: boolean = false;
  dataSource: ElementType[] ;

  isPopupVisible: boolean = false;
  loadingModal: boolean;

  selectedEntity: ElementType;

  isVisible = false;
  type = 'success';
  message = '';

  filter = { name: '', groupID: '', platformID: 0 };
  groups = ['', 'CONTAINER', 'DEVICE', 'TRACE', 'INFRASTRUCTURE'];

  constructor(private elementTypeService: ElementTypeService,
    private trans: TranslateService, private platformService: PlatformService) { 
      this.filter.platformID = platformService.activePlatform.platformID;
      this.message = this.trans.instant('general.saved-confirmation');
   }

  ngOnInit(): void {
    this.search();
  }

  search(){
    this.loadingModal=true;
    this.elementTypeService.getElementsTypeWithFilter(this.filter, 1000).subscribe(
      (valor) => {
        this.dataSource = valor;
        this.loadingModal=false;
      }
    );
  }


  openPopup(e: any) {
    this.elementTypeService.getElementsTypeById(e.elementTypeID).subscribe(
      (valor) => {
        this.selectedEntity = valor;
        this.loadingModal = false;
        this.isPopupVisible = true;
      }
    )
  }

  closePopup() {
    this.isPopupVisible = false;
    this.selectedEntity = undefined;
  }
  
  save(e) {
    this.elementTypeService.update(e).subscribe(
    (res)=>{
      if(res){
        let index = this.dataSource.findIndex(x=> x.elementTypeID == res.elementTypeID);
        if(index > -1){
          this.dataSource[index] = res;
        }
        this.closePopup();
        this.type = 'success';
        this.message = this.trans.instant('general.saved-confirmation');
        this.isVisible = true;
      }
    },
    (error)=> {
      this.type = 'error';
      this.message = error;
      this.isVisible = true;
    });
    
  }


  

}
