import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { Cons } from '@app/@shared/cons/cons';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { Container } from '@app/@shared/model/container.model';
import { Device } from '@app/@shared/model/device.model';
import { ElementType } from '@app/@shared/model/elementType.model';
import { Splitter } from '@app/@shared/model/splitter.model';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { OlMapHelperService } from '@app/@shared/services/aux-services/ol-map-helper.service';
import { ElementTypeService } from '@app/@shared/services/element-type.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import DataSource from 'devextreme/data/data_source';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-create-edit-container-modal',
  templateUrl: './create-edit-container-modal.component.html',
  styleUrls: ['./create-edit-container-modal.component.scss'],
})

export class CreateEditContainerModalComponent implements OnInit {

  labels = {
    description: '',
    container: '',
    name: '',
  };

  

  containerDataSource: DataSource;
  elementTypeDataSource: DataSource;
  // splitters: Splitter[] = [];
  splitterTypes: SplitterType[] = [];
  container: Container;
  containerAttributes: any[] = [];

  address: string;

  disableSave: boolean = true;
  @Input() containers: any [];
  @Input() selectedContainer: Container;
  @Output() save = new EventEmitter<Container>();
  @Output() cancel = new EventEmitter<void>();
  lineString: olMapLineString[]=[];
  latitude: number = -34.604369;
  longitude: number = -58.3850703;
  zoom: number = 8;
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('olMap') olMap: OlMapComponent;
  filter = { name: '', groupID: '', platformID: 0 };
  dataModelElementType: ElementType[]=[];
  dataModelDevice: any=[];
  loadingModal=true;

  constructor(
    private allDatasources: DataSourceService,
    private translateService: TranslateService,
    private olMapHelperService: OlMapHelperService,
    private elementTypeService: ElementTypeService,
    private platformService: PlatformService,
    private deviceService : DeviceService,
    private activatedRoute: ActivatedRoute,
  ) {
    
  }

  ngOnInit(): void {
    this.setMandatoryLabels();
    let filter = new ElementType();
    filter.groupID = "CONTAINER";
    filter.platformID = this.platformService.activePlatform.platformID;
    const containers: Container[] = this.allDatasources.allContainers;
    this.containerDataSource = new DataSource({ store: containers });
    this.container = plainToInstance(Container, this.selectedContainer);    
    const c = Cons.OLSTYLES.$traceDefaultColor;
    this.lineString.push(this.olMapHelperService.OlMapLineString.toMapFeature(this.selectedContainer, this.selectedContainer.containerID.toString(), c));
    this.containerAttributes = this.container.containerAttributes || [];
  const observables = [
    this.elementTypeService.getElementsTypeWithFilter(filter, 1000),
    this.deviceService.getWithFilter({
      platformID: this.platformService.platformID,
      containerID: this.container.containerID
    }, 1000)
  ];

  forkJoin(observables).subscribe(
    ([elementTypes, devices]) => {

      this.dataModelElementType = elementTypes;
      this.dataModelDevice = devices;

    },
    error => {
      console.error('Error:', error);
    }
  );

}
  mapReady() {
    this.olMap._linestring=this.lineString;
    this.olMap.renderLineString(false);
    const coordinates = this.lineString[0].flatCoordinates[0];
    if (typeof(coordinates[0]) === 'number') {
      this.olMap.moveTo(coordinates[1],coordinates[0],14);
    } else {
      this.olMap.moveTo(coordinates[0][1],coordinates[0][0],14);
    }    
  }

  setMandatoryLabels() {
    this.labels = {
      description: this.translateService.instant('container.add-edit.description') + '*',
      container: this.translateService.instant('container.add-edit.container') + '*',
      name: this.translateService.instant('container.add-edit.name') + '*',
    };
  }


  containerChange(event: { selectedItem: Container }) {    
    const selectedContainer = event.selectedItem;
    this.container.elementTypeID = selectedContainer.elementTypeID;
    this.updateDisableSaveButton();
  }

  elementTypeChange(event: { selectedItem: ElementType }) {
    const selectedElementType = event.selectedItem;
    this.container.elementTypeID = selectedElementType.elementTypeID;

    this.updateDisableSaveButton();
  }

  nameChange(event: { value: string }) {
    this.container.name = event.value;
    this.updateDisableSaveButton();
  }
  descriptionChange(event: { value: string }) {
    this.container.description = event.value;
    this.updateDisableSaveButton();
  }

  updateDisableSaveButton() {
    // this.disableSave = !this.device.elementTypeID || !this.device.containerID || !this.device.name || !this.device.locationData;
    this.disableSave = (this.container.elementTypeID === undefined || this.container.elementTypeID === null &&
    this.container.description === '' || this.container.description === undefined || this.container.description === null &&
    this.container.name === '' || this.container.name === undefined || this.container.name === null) ;
  }

 

  getSplitterTypes() {
   this.splitterTypes = [];
   //Agregar llamada a serviceConfig
  }

  // handleAddSplitter(splitter: Splitter) {
  //   this.splitters.push(splitter);
  //   this.updateSplitterList();
  // }

  // handleEditSplitter(splitter: Splitter) {
  //   const index = this.splitters.findIndex(x => splitter.splitterID ? x.splitterID === splitter.splitterID : (<any>x).internalSplitterId === (<any>splitter).internalSplitterId);
  //   this.splitters[index] = splitter
  //   this.updateSplitterList();
  // }

  // handleDeleteSplitter(splitter: Splitter) {
  //   const index = this.splitters.findIndex(x => splitter.splitterID ? x.splitterID === splitter.splitterID : (<any>x).internalSplitterId === (<any>splitter).internalSplitterId);
  //   this.splitters[index].__deleted = true;
  //   this.updateSplitterList();
  // }

  // updateSplitterList() {
  //   this.device.splitter = this.splitters;
  //   this.splitters = [...this.splitters];
  // }

  
  preSave() {
    // const devices = this.container.device;
    // this.container.device = devices?.filter(x => !(x.__new && x.__deleted));
    // splitters?.filter(x => x.__new).forEach(x => delete x.__modified);
    // splitters?.filter(x => x.__deleted).forEach(x => delete x.__modified);
    // splitters?.forEach(x => delete (<any>x).internalSplitterId);
  }
  addressChanged(fullAddress: any){
console.log(fullAddress);

  }
  fullAddressChange(fullAddress: any) {
    console.log(fullAddress);
    
    // this.device.deviceAttributes.address = fullAddress;
    // this.updateDisableSaveButton();
  }
  
  saveContainer() {
    
    this.preSave();

    // Debugging purposes only
   //  this.device.locationData = <any>{
   //    inputSrID: 4326,
   //    outputSrID: 4326,
   //    format: "GEOJSON",
   //    coordinates: {
   //      geographyType: "Point",
   //      values: [
   //        { x: -58.442638, y: -34.621334 }
   //      ]
   //    }
   //  };
   
    this.save.emit(this.container);
  }
}
