<div>
  <div>
    <h1 class="main-secondary-text">{{ 'survey.map.address' | translate }}</h1>
    <dx-text-box [(value)]="currentAddress"> </dx-text-box>
  </div>
  <div class="mt-4" fxLayout="row" fxLayoutAlign="space-around center">
    <dx-button
      fxFlex="40"
      class="button quick-access-button cancel-button"
      [text]="'general.cancel' | translate"
      width="200"
      (click)="cancel()"
    >
    </dx-button>
    <dx-button
      fxFlex="40"
      class="button main-background-theme save-button"
      [text]="'survey.plot-form.edit-address.modify' | translate"
      width="200"
      (click)="save()"
    >
    </dx-button>
  </div>
</div>
