import { Type } from 'class-transformer';
import { LocationData } from "@app/@shared/model/aux-models/locationData";
import { Interface } from 'readline';
import { Client } from './client.model';
import { Device } from './device.model';
import { State } from './state.model';
import { InvokerSystem } from './invokerSystem.model';
import { PortResource } from './portResource';


export class FeasibilityStudy  {
  feasibilityStudyID : number;
  platformID : number;
  clientID: number;
  stateID: number;
  deviceID: number;
  invokerSystemCode: number;
  operatorID: number;
  portResourceID : number;
  locationData: LocationData;
  placeName: string;
  floor?: number;
  apartment?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  verificationDate: Date;
  verificationCode: string;
  verificationResult: string;
  allocationDate : Date;
  feasibilityStudyAttributes: any;

  client: Partial<Client>;
  state: Partial<State>;
  device: Partial<Device>;
  invokerSystem: Partial<InvokerSystem>;
  portResource : Partial<PortResource>;


  get feasibilityDate(): Date {
    return new Date(this.verificationDate);
  }
 
  static createInstance() : FeasibilityStudy{
    let entity = new FeasibilityStudy();
    entity.feasibilityStudyID = 0;
    entity.client = new Client();
    entity.invokerSystem = new InvokerSystem();
    entity.state = new State();
    entity.state.orderBy = 0;
    
    return entity;
  }
};
