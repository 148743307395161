import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-device-port-list',
  templateUrl: './device-port-list.component.html',
  styleUrls: ['./device-port-list.component.scss'],
})

export class DevicePortListComponent {
    @Input() ports: any[];
}
