import { Injectable } from '@angular/core';
import { Preference } from '@app/@shared/model/preference.model';
import { JwtHelperService } from '@auth0/angular-jwt';

export interface Credentials {
  token: string;
}

const credentialsKey = 'credentials';
const preferenceKey = 'preferences';
/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private _preferences : Preference[] | null = null;

  constructor() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    const savedPreferences = sessionStorage.getItem(preferenceKey) ||  localStorage.getItem(preferenceKey);

    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }

    if (savedPreferences) {
      this._preferences = JSON.parse(savedPreferences);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  get preferences() : Preference[] | null{
    return this._preferences;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   */
  setCredentials(token?: string, remember?: boolean) {
    this._credentials = { token } || null;

    if (token) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(this._credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }


  setAuxCredentials(key: string, token?: string, remember?: boolean) {
    let auxCredentials : Credentials = { token } || null;

    if (token) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(key, JSON.stringify(auxCredentials));
    } else {
      sessionStorage.removeItem(key);
      localStorage.removeItem(key);
    }
  }

  getAuxCredentials(key: string, remember?: boolean){
    const storage = remember ? localStorage : sessionStorage;
    return JSON.parse(storage.getItem(key));
  }

  setPreferences(preferences?: Preference[], remember?: boolean ) {
    this._preferences = preferences || null;

    if (preferences) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(preferenceKey, JSON.stringify(this._preferences));
    } else {
      sessionStorage.removeItem(preferenceKey);
      localStorage.removeItem(preferenceKey);
    }
  }

  setUserInventory(suffix: string, value: string) {
    const key = `${suffix}`;
    localStorage.setItem(key, value);
  }

  getUserInventory(suffix: string): string {
    const key = `${suffix}`;
    return localStorage.getItem(key);
  }

  removeUserInventory(suffix: string) {
    const key = `${suffix}`;
    localStorage.removeItem(key);
  }

  decodeToken(token?: string): Record<string, any> {
    const t = token || this.credentials?.token;

    if (!t) {
      return undefined;
    }

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(t);

    if (decodedToken.platforms) {
      decodedToken.platforms = <any[]>JSON.parse(decodedToken.platforms).filter(x => !!x);
    }

    if (decodedToken.roles) {
      decodedToken.roles = <any[]>JSON.parse(decodedToken.roles).filter(x => !!x);
    }

    if (decodedToken.resources) {
      decodedToken.resources = <any[]>JSON.parse(decodedToken.resources).filter(x => !!x);
    }

    return decodedToken;
  }

  hasToken(): boolean {
    return !!this.credentials?.token;
  }

  tokenIsExpired(token?: string): boolean {
    if (!token && !this.credentials?.token) {
      return false;
    }

    const helper = new JwtHelperService();
    return helper.isTokenExpired(token || this.credentials?.token);
  }
}
