<div style="height: 100%; position: relative">
  <div class="mb-2" fxLayout="row">
    <div *ngIf="!isNewUser" class="form-field" fxFlex="20">
      <dx-number-box labelMode="floating" [disabled]="true" [label]="'users.grid-header.id' | translate"
        [(value)]="entity.userID">
      </dx-number-box>
    </div>
  </div>
  <div class="mb-2" fxLayout="row">
    <div class="form-field" fxFlex="100">
      <dx-text-box labelMode="floating" [showClearButton]="true" [label]="'users.grid-header.name' | translate"
        [(value)]="entity.name">
      </dx-text-box>
    </div>
  </div>
  <div class="mb-2" fxLayout="row">
    <div class="form-field" fxFlex="50">
      <dx-text-box labelMode="floating" displayExpr="name" [showClearButton]="true"
        [label]="'users.grid-header.mail' | translate" [(value)]="entity.email">
      </dx-text-box>
    </div>
    <div class="form-field" fxFlex="50">
      <dx-select-box labelMode="floating" valueExpr="cultureID" labelMode="floating" fieldTemplate="field"
        [dataSource]="regionalDS" [label]="'users.grid-header.regional-config' | translate"
        [(value)]="entity.cultureID">
        <div *dxTemplate="let data of 'field'">
          <div class="culture-item">
            <div fxFlex="20" style="text-align: center; padding-top: 6px;">
              <img alt="Culture" src="{{ data && data.icon }}" *ngIf="data && data.icon" />
            </div>
            <div fxFlex="80">
              <dx-text-box [value]="data && data.name" [readOnly]="true"></dx-text-box>
            </div>
          </div>
        </div>
        <div *dxTemplate="let data of 'item'">
          <div class="culture-item" fxLayout="row">
            <div fxFlex="20" style="text-align: center;">
              <img alt="Culture" src="{{ data.icon }}" />
            </div>
            <div fxFlex="80">{{ data.name }}</div>
          </div>
        </div>
      </dx-select-box>
    </div>
  </div>

  <div *ngIf="isNewUser">
    <div class="mb-2" fxLayout="row">
      <div class="form-field" fxFlex="50">
        <dx-text-box labelMode="floating" [showClearButton]="true" [label]="'users.grid-header.password' | translate"
          [(value)]="entity.password" [readOnly]="true">
        </dx-text-box>
      </div>
    </div>
  </div>

  <!-- <dx-tab-panel *ngIf="!isNewUser">
    <dxi-item [title]="'users.add-edit.roles' | translate">
      <dx-scroll-view style="max-height: 300px;">
        <div fxLayout="column" class="data-container">
          <dx-data-grid #rolesGrid [dataSource]="rolesDS" [showBorders]="true" [showRowLines]="true"
            (onRowPrepared)="onRowPrepared($event)" (onRowInserted)="rowInserted($event)"
            (onRowUpdated)="rowUpdated($event)" (onRowRemoving)="onRowRemoving($event)"
            (onEditorPreparing)="onEditorPreparing($event)">
            <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"
              [texts]="{confirmDeleteMessage: ''}"></dxo-editing>
            <dxi-column [caption]="'users.grid-header.rol' | translate" dataField="name"
              [cellTemplate]="roleCellTemplate">
              <dxo-lookup [dataSource]="rolesDataSource" displayExpr="name" valueExpr="id">
              </dxo-lookup>
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column [caption]="'users.grid-header.start-date' | translate" dataField="startDate" dataType="date"
              format="dd/MM/yyyy" [cellTemplate]="startDate"></dxi-column>
            <dxi-column [caption]="'users.grid-header.end-date' | translate" dataField="endDate" dataType="date"
              format="dd/MM/yyyy" [cellTemplate]="endDate"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-scroll-view>
    </dxi-item>

    <dxi-item [title]="'users.add-edit.platforms' | translate">
      <dx-scroll-view>
        <div class="data-container">
          <dx-data-grid #platformsGrid [dataSource]="platformDS" [showBorders]="true" [showRowLines]="true"
            (onRowPrepared)="onRowPrepared($event)" (onRowInserted)="rowInsertedPlatform($event)"
            (onRowUpdated)="rowUpdated($event)" (onRowRemoving)="onRowRemoving($event)"
            (onEditorPreparing)="onEditorPreparing($event)">
            <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"
              [texts]="{confirmDeleteMessage: ''}"></dxo-editing>
            <dxi-column [caption]="'users.grid-header.platformID' | translate" dataField="id" [allowEditing]="false"
              readonly [cellTemplate]="idCellTemplate"></dxi-column>
            <dxi-column [caption]="'general.name' | translate" dataField="name" [cellTemplate]="platformCellTemplate">
              <dxo-lookup [dataSource]="platformName" displayExpr="name" valueExpr="id">
              </dxo-lookup>
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column [caption]="'users.grid-header.active' | translate" dataField="active"
              [cellTemplate]="activeCellTemplate"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-scroll-view>
    </dxi-item>
  </dx-tab-panel> -->

  <div fxLayout="row" fxLayoutAlign="end center" class="my-4 pe-2 popup-button">
    <dx-button style="margin-right: 10px;" [text]="'general.cancel' | translate" (click)="cancel()">
    </dx-button>
    <dx-button class="main-background-theme" [text]="'general.save' | translate" (click)="save()">
    </dx-button>
  </div>
</div>