import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlappingRingsComponent } from './overlapping-rings.component';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxDataGridModule, DxGalleryModule, DxLoadPanelModule, DxPieChartModule, DxPopupModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationMenuComponent } from '../navigation-menu/navigation-menu.component';
import { NavigationMenuModule } from '../navigation-menu/navigation-menu.module';



@NgModule({
  declarations: [
    OverlappingRingsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DxGalleryModule,
    DxButtonModule,
    TranslateModule,
    DxPopupModule,
    MatCardModule,
    DxLoadPanelModule,
    DxDataGridModule,
    FlexLayoutModule,
    NavigationMenuModule,
    DxPieChartModule
  ],
  exports: [OverlappingRingsComponent]
})
export class OverlappingRingsModule { }
