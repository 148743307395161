
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ElementType } from '@app/@shared/model/elementType.model';
import { ElementTypeService } from '../../services/element-type.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { DxContextMenuComponent, DxTreeViewComponent } from 'devextreme-angular';

@Component({
  selector: 'spec-tree-view',
  templateUrl: './spec-tree-view.component.html',
  styleUrls: ['./spec-tree-view.component.scss'],
})

export class SpecTreeViewComponent {

  _specAttributes;
  @Input("specAttributes")
  get specAttributes(){
    return this._specAttributes;
  }
  set specAttributes(value){
    this._specAttributes = value;
    this.createSpecTreeView(this._specAttributes, this.specTreeView, "");
    try{
      this.specRawView = JSON.stringify(this._specAttributes);
    }
    catch{

    }
  }

  @Output() isJSONValid = new EventEmitter<boolean>();
  @Output() onSpecSaved = new EventEmitter<any>();

  specRawView = '';
  specTreeView = [];
  expandedNodes = [];

  radioItems = [ 'Color Picker', 'Text' ];
  radioValue = 'Color Picker';
  radioEditionItems = ['formatted', 'raw' ];
  radioEditionValue = 'formatted';

  selectedNode : { id: string, name: string, type: string, value: any, pathToNode : string, __new : boolean };
  booleanDS = [true, false];
  typeDS = [ 'string', 'number', 'boolean', 'symbol', 'object' ];
  menuItems = [];

  constructor(private trans : TranslateService) { 
    this.menuItems = [
      { id: 'add', text: this.trans.instant('general.add'), visible: true, disabled: false },
      { id: 'add-inside', text: this.trans.instant('general.spec.add-inside'), visible: true, disabled: false },
      { id: 'remove', text: this.trans.instant('general.delete'), visible: true, disabled: false }
    ];
  }

  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;
  @ViewChild(DxContextMenuComponent, { static: false }) contextMenu: DxContextMenuComponent;

  /* ******** TREE CREATION ******** */
  createSpecTreeView(spec : any, nodeList : any[], id? : string, path? : string){
    let index = 0;
    if(Array.isArray(spec)){
      for(let i = 0; i < spec.length; i++){
        let node : any = { id: `${id}_${i}`, pathToNode: `${path}_*_${i}`};
        node.expanded = this.isNodeExpanded(`${id}_${i}`);
        node.text = i.toString();
        node.items = [];
        this.createSpecTreeView(spec[i], node.items, `${id}_${i}`, `${path}_*_${i}`);
        nodeList.push(node);
      }
      return;
    }

    for (let propertyName in spec) {
      if (spec.hasOwnProperty(propertyName)) {
        let propertyValue = spec[propertyName];
        let propertyType = typeof propertyValue;

        if(propertyType == 'string' && (propertyName == 'color' || (<string>propertyValue).startsWith("#"))){
          propertyType = 'symbol';
        }

        let pathToNode = (!path || path == "" ? propertyName : `${path}_*_${propertyName}`);
        let idNode = (!id || id == "" ? index.toString() : `${id}_${index}`);
        let node : any = { id: idNode, pathToNode: pathToNode};

        if(Array.isArray(propertyValue)){
          node.expanded = this.isNodeExpanded(idNode);
          node.text = propertyName;
          node.items = [];
          this.createSpecTreeView(propertyValue, node.items, idNode, pathToNode)
        }
        else{          
          node.type = propertyType;
          node.name = propertyName;
          node.text = `${propertyName}: ${propertyValue.toString()}`;
          node.value = propertyValue;
        }
        index++;
        //console.log(node);
        nodeList.push(node);
      }
    }
  }
  redrawTree(){
    this.specTreeView = [];
    this.createSpecTreeView(this.specAttributes, this.specTreeView, "");

    try{
      this.specRawView = JSON.stringify(this.specAttributes);
    }
    catch{

    }
  }

  /* ******** TREE EVENT HANDLER ******** */
  selectItem(e){
    this.selectedNode = e.itemData;
  }

  itemCollapsed(e){
    this.expandedNodes = this.expandedNodes.filter(x=> x.id != e.itemData.id);
  }

  itemExpanded(e){
    this.expandedNodes.push(e.itemData);
  }

  isNodeExpanded(id : string) : boolean{
    let index = this.expandedNodes.findIndex(x=> x.id == id);
    if(index < 0){
      return false;
    }

    return this.expandedNodes[index].expanded;
  }

  validJSON = true;
  rawValueChanged(e){
    try{
      let parsedJson = JSON.parse(e.value);
      this.specAttributes = parsedJson;
      this.validJSON = true;
    }
    catch{
      this.validJSON = false;
    }

    this.isJSONValid.emit(this.validJSON);
  }

  /* ******** TREE CONTEXT MENU ******** */
  clickedNode;
  treeViewItemContextMenu(e) {
    this.clickedNode = e.itemData;
    const contextMenu = this.contextMenu.instance;

    if(e.itemData){
      if(!e.itemData.items){
        contextMenu.option('items[1].disabled', true)
        contextMenu.option('items[1].text', `${this.trans.instant('general.spec.add-inside')}`);
      }
      else{
        contextMenu.option('items[1].disabled', false)
        contextMenu.option('items[1].text', `${this.trans.instant('general.spec.add-inside')} "${e.itemData.text}"`);
      }
    }

  }

  contextMenuItemClick(e) {
    /*
    let logEntry = '';
    const treeView = this.treeView.instance;
    switch (e.itemData.id) {
      case 'add': {
        this.selectedNode = { id: this.specTreeView.length.toString(), name: "", pathToNode: "", type: "", value: "", __new: true };
        break;
      }
      case 'add-inside': {
        let id = this.clickedNode.id + "_" + this.clickedNode.items.length;
        let pathToNode = this.clickedNode.pathToNode + "_*_" + this.clickedNode.items.length;
        let typeofNode = "";
        if(this.clickedNode.items.length != 0){
          typeofNode = typeof this.clickedNode.items[0];
        }

        this.selectedNode = { id: id, name: "", pathToNode: pathToNode, type: typeofNode, value: "", __new: true };
        this.clickedNode.items.splice(0, 0, this.selectedNode);
        break;
      }
      case 'remove': {
        let pathToProperty = this.clickedNode.pathToNode.split('_*_');
        this.findAndSetRecursiveProperty(pathToProperty, this.specAttributes, 0, pathToProperty.length, undefined, true);
        break;
      }
    }

    this.redrawTree();*/
  }

  onNewNodeNameChanged($event){
    if(this.selectedNode.pathToNode == ""){
      this.selectedNode.pathToNode = $event.value;
    }
  }

  /* ******** SAVE TREE NODES ******** */
  saveSpec(updatedNode){
    let pathToProperty = updatedNode.name.split('_*_');
    if(pathToProperty.length > 1){
      //Find recursively
      this.findAndSetRecursiveProperty(pathToProperty, this.specAttributes, 0, pathToProperty.length, updatedNode.value);
      
    }
    else{
      this.specAttributes[updatedNode.name] = updatedNode.value;
    }
    
    this.selectedNode = undefined;
    this.redrawTree();

    if(!this.validJSON){
      return;
    }
    this.onSpecSaved.emit(this.specAttributes);
  }

  findAndSetRecursiveProperty(arrayPath, spec, index, length, value, remove?) : any{
    let name = arrayPath[index];
    if(index == (length - 1)){
      spec[name] = value;
      if(remove){
        delete spec[name];
      }
      return;
    }
    let prop = spec[name];
    index++;
    return this.findAndSetRecursiveProperty(arrayPath, prop, index, length, value);
  }


  ngOnDestroy(){
    this.specTreeView = [];
  }


}