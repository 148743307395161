import { Component, OnInit } from '@angular/core';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { EquipmentType } from '@app/@shared/model/equipmentType.model';
import { EquipamentTypeService } from '@app/@shared/services/equipament-type.service';
import notify from 'devextreme/ui/notify';





@Component({
  selector: 'app-equipment-type-home',
  templateUrl: './equipment-type-home.component.html',
  styleUrls: ['./equipment-type-home.component.scss'],
})

export class EquipmentTypeHomeComponent implements OnInit {
  
  loading: boolean = false;
  dataSource: any[] ;
  dataSourceGrid: any [];
  elementEditableData: { label, field }[] = [];
  isPopupVisible: boolean = false;
  modalTitle: string;
  loadingModal: boolean;

  popups = {
    confirmDelete: false,
    createOrEdit: false,
  };
  selectedEquipmentType: EquipmentType;

  constructor(
    private equipmentTypeService: EquipamentTypeService,
    private translateService: TranslateService, 
    private platformService: PlatformService
    ){ 

    this.dataSourceGrid = [
    ]
   }
   ngOnInit(): void {
    
    this.reload();
  }
  async reload() {
    this.loadingModal=true;
    this.equipmentTypeService.getEquipmentsTypeWithFilter().subscribe(
      (valor) => {
        this.dataSource = valor;
        this.loadingModal=false;
      }
    );
  }

  closeModal() {
    this.popups.createOrEdit = false;
    this.selectedEquipmentType = null;
  }
  openModalAddEquipmentType() {
    const platformID: number = this.platformService.platformID;
    this.modalTitle = this.translateService.instant('element.add-element');
    this.selectedEquipmentType = plainToClass(EquipmentType, { platformID });
    this.popups.createOrEdit = true;
  }
  async deleteEquipmentType(equipmentType: EquipmentType) {
    console.log("borrar ");
    this.popups.confirmDelete = false;

    try {
      await firstValueFrom(this.equipmentTypeService.delete(equipmentType.equipmentTypeID));
      await this.reload();
    } catch {
      const errorMessage = this.translateService.instant('element-type.delete-error');
      notify(errorMessage, 'error', 4500);
    } finally {
      this.selectedEquipmentType = undefined;
    }
    
  }
  openModalEdit(equipmentType: EquipmentType) {
    this.modalTitle = this.translateService.instant('equipment-type.add-equipment');
    this.modalTitle += ` - #${equipmentType.equipmentTypeID}`;
    this.loadingModal = true;
    this.equipmentTypeService.getEquipmentTypeById(equipmentType.equipmentTypeID).subscribe(
      (valor) => {
        this.selectedEquipmentType = valor;
        this.loadingModal = false;
        this.popups.createOrEdit = true;
      }
    )
  }
  saveEquipmentType(equipmentType:EquipmentType){
    this.loadingModal = true;
    try {
      if (this.selectedEquipmentType?.equipmentTypeID) {
        this.equipmentTypeService.update(equipmentType).subscribe(
          (equipmentType) => {
            this.modifyObjectById(equipmentType);
            this.closeModal();
            this.loadingModal = false;
          }
        )
      } else {
        this.equipmentTypeService.create(equipmentType).subscribe(
          (elementType)=>{
            this.dataSource = [elementType, ...this.dataSource];
            this.loadingModal = false;
          }
        )
      }
    } catch(ex) {
      const errorMessage = this.translateService.instant('element-type.update-error');
      notify(errorMessage, 'error', 4500);
    } finally {
      this.loadingModal = false;
      this.popups.createOrEdit = false;
      this.selectedEquipmentType = undefined;
    }

  }
  modifyObjectById(newInformation: Partial<EquipmentType>) {
    const index = this.dataSource.findIndex((obj) => {return obj.equipmentTypeID === newInformation.equipmentTypeID});
    if (index !== -1) {
      const updatedObject = { ...this.dataSource[index], ...newInformation };
      this.dataSource[index] = updatedObject;
      // console.log('Objeto actualizado:', updatedObject);
    } else {
      // console.log('No se encontró un objeto con el ID especificado');
    }
  }
}