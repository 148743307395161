
<dx-data-grid
  [dataSource]="equipments"
  [showBorders]="true"
  [showRowLines]="true"
  [columnHidingEnabled]="true"
  keyExpr="equipmentID"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
    
  <dxo-pager
    [visible]="true"
    [displayMode]="'full'"
    [showPageSizeSelector]="false"
    [showInfo]="false"
    [showNavigationButtons]="true"
  >
  </dxo-pager>

  <dxi-column dataType="string" [caption]="'olt.attr.id' | translate" dataField="equipmentID"></dxi-column>
  <dxi-column [caption]="'olt.attr.name' | translate" dataField="name"></dxi-column>
  <dxi-column [caption]="'olt.attr.IP' | translate" dataField="equipmentAttributes.ip"></dxi-column>
  <dxi-column dataType="string" [caption]="'olt.attr.rack' | translate" dataField="equipmentAttributes.cabinetRack.name"></dxi-column>
  <dxi-column dataType="string" [caption]="'olt.attr.frame' | translate" dataField="equipmentAttributes.frame.name"></dxi-column>
  <dxi-column [caption]="'olt.attr.header' | translate" dataField="equipmentAttributes.header.name"></dxi-column>
  <dxi-column [caption]="'olt.attr.model' | translate" dataField="equipmentAttributes.model"></dxi-column>
  <dxi-column width="75" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>

  <div *dxTemplate="let d of 'actionsCellTemplate'">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <i 
          (click)="editItem.emit(d.data)"
          class="fas fa-pen clickable mx-2"
          placement="bottom" [ngbTooltip]="'olt.edit' | translate" container="body"
        ></i>
        <i 
          (click)="deleteItem.emit(d.data)"
          class="fa fa-trash clickable"
          placement="bottom" [ngbTooltip]="'olt.delete' | translate" container="body"
        ></i>
      </div>
    </div>
  </div>
</dx-data-grid>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [visible]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>
