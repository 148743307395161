import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { PlatformPlot } from '@app/@shared/model/platform-plot.model';
import { Plot } from '@app/@shared/model/plot.model';
import { PlotAddress } from '@app/general/survey/models/plot-address';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';
import { plainToInstance } from 'class-transformer';
import { IQueryViewConfiguration } from '@app/@shared/model/interface/iQuery-view-configuration.model';


@Injectable({ providedIn: 'root' })
export class PlotService {
  constructor(private main: MainService) {}

  getPlotByPlatformId(
    plotID: number,
    platformID: number
  ): Observable<PlatformPlot> {
    this.main.chooseAPI(Cons._PLOT);

    const action = `${plotID}/${platformID}`;
    return this.main.get(Cons._PLATFORM_PLOT, action).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  save(plotID: number, body: PlotAddress): Observable<void> {
    this.main.chooseAPI(Cons._PLOT);

    const action = `${plotID}`;
    return this.main.put(Cons._PLOT, action, { plotAttributes: { address: body }}).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }

  savePlatformPlot(entity : PlatformPlot): Observable<void> {
    this.main.chooseAPI(Cons._PLOT);

    const action = `${entity.plotID}/${entity.platformID}`;
    let body = this.cleanUpForRequest(entity);
    
    return this.main
      .put(Cons._PLATFORM_PLOT, action, body)
      .pipe(
        map((x) => x.responseData),
        catchError((ex) => throwError(() => new Error(ex?.message)))
      );
  }

  getWithFilter(entity?: any): Observable<any> {
    this.main.chooseAPI(Cons._PLOT);
    let body = this.cleanUpForRequest(entity);
    return this.main.getWithFilter(Cons._PLOT, body, true).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.deviceID;
            x.name = x.locationCode;
            x.entityName = Cons._PLOT;
            return <Plot>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  queryView(entity: any, xyCoordinates?: any[], locationCriteria?): Observable<any> {
    this.main.chooseAPI(Cons._PLOT);
    
    const queryViewConfiguration: IQueryViewConfiguration = {
      xyCoordinates,
      locationCriteria,
    };

    return this.main.queryView(String.prototype.upperFirstLetter(Cons._PLOT), entity, queryViewConfiguration)
      .pipe(
        map((res) => {
          try {
            return res.responseData.map((x) => {
              x.commonID = x.plotID;
              x.name = x.locationCode;
              x.entityName = Cons._PLOT;
              return <Plot>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._PLOT);
    return this.main.getByID(Cons._PLOT, id).pipe(
      map((res) => {
        try {
          res.commonID = res.plotID;
          res.name = res.locationCode;
          res.entityName = Cons._PLOT;
          return <Plot>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  cleanUpForRequest(entity : PlatformPlot) : PlatformPlot{
    let body = plainToInstance(PlatformPlot, entity);
    body.plot = undefined;

    return body;
  }
}
