import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Cons } from '@app/@shared/cons/cons';
import { MainService } from '@app/@shared/services/main.service';
import { AppRoutingService } from '@app/app-routing.service';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShellComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  fillerNav: any = [];
  userHelpModules: any = [];
  titleHeader: string;

  _environment : string = '';
  get environment(){
    return this._environment.toUpperCase();
  }
  subscriptions: Subscription = new Subscription();

  constructor(
    private trans: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private mainService: MainService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private credentialService: CredentialsService,
    private appRoutingService : AppRoutingService) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    let sub = this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.checkForHelp(event.url, true);
        }, 1000);
      }
    });
    this.subscriptions.add(sub);
  }

  ngOnInit() {
    this.fillerNav = this.appRoutingService.getActiveRoutes();
    this.userHelpModules = this.appRoutingService.getUserHelpModules();

    if(document.location.hostname == "localhost"){
      this._environment = document.location.hostname + " " + this.mainService.getAPIEnvironment()?.toUpperCase();
    }
    else{
      let host = document.location.hostname?.split(".");
      if(host.length > 2){
        this._environment = host[0];
      }
    }
  }

  logout() {
    this.authenticationService
      .logout()
      .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  platformSelected($event) { }

  @ViewChild('snav') snav: MatSidenav;
  navigateTo(path: string, openNewTab?: boolean) {
    let retval = this.appRoutingService.navigateTo(path, openNewTab);

    if (retval && this.snav && this.snav.opened) {
      this.snav.toggle();
    }
  }

  checkForHelp(newUrl?: string, showOnInit?: boolean) {
    if (!newUrl) {
      newUrl = this.router.url;
    }

    let index = -1;

    if (showOnInit == true) {
      let pref = this.mainService.getPreference(
        Cons.PREFERENCES._HIDE_HELP_ON_INIT,
        false
      )?.value;
      if (pref == true) {
        return;
      }

      index = this.userHelpModules.findIndex(
        (x) => newUrl.includes(x.url) && x.showOnInit == true
      );
    } else {
      index = this.userHelpModules.findIndex((x) => newUrl.includes(x.url));
    }

    this.showHelp(index);
  }


  showHelp(index: number) {
    const tokenExpired = this.credentialService.tokenIsExpired();
    if (index === -1 || tokenExpired) {
      return;
    }

    this.userHelpModules[index].visible = true;
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  notShow(item) {
    item.selected = false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}


