import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Device } from '@app/@shared/model/device.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';

@Injectable({ providedIn: 'root' })
export class DevicesAndContainersResolver implements Resolve<any[]> {
  constructor(
    private allDatasources: DataSourceService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Device[]> {
    await Promise.all([
      this.allDatasources.preloadDevices(),
      this.allDatasources.preloadContainers(),
    ]);

    return this.allDatasources.allDevices;
  }
}
