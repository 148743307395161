import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { D3HorizontalTreeData } from '@app/@shared/model/d3/horizontal-tree.model';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';
import { PlatformService } from '@app/auth/platform.service';
import { plainToClass } from 'class-transformer';
import { EquipmentType } from '@app/@shared/model/equipmentType.model';
import { IQueryViewConfiguration } from '@app/@shared/model/interface/iQuery-view-configuration.model';

@Injectable()
export class EquipmentTypeService {
    constructor(
        private main: MainService,
        private platformService: PlatformService,
      ) {}

      getWithFilter(entity?: any, limit? : number, offset?: number, clause? : string): Observable<any> {
        this.main.chooseAPI(Cons._EQUIPMENT);
        console.log('dalelleeeee')
        return this.main.getWithFilter('EquipmentType', entity, true, limit, offset, clause).pipe(
          map((res) => {
            try {
              return res.responseData.map((x) => {
                x.commonID = x.equipmentTypeID;
                x.entityName = Cons._EQUIPMENT;
                return <EquipmentType>x;
              });
            } catch (ex) {
              return <string>(<any>res)._body.toString();
            }
          })
        );
      }
      
}