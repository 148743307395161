<dx-accordion [collapsible]="true" style="margin-bottom: 1rem;">
    <div *dxTemplate="let item of 'title'">
        <div style="display: inline-flex; align-items: baseline;">
            <h1 class="main-secondary-text">
                {{ item.title }}
            </h1>
            <div style="font-size: 22px; padding-left: 1rem;"
                title="La medición es solo posible entre botellas del anillo seleccionado.">
                <img class="other-icons questions" style="height: 22px;"
                    src="../../../../../assets/icons/information.png" />
            </div>
        </div>
    </div>
    <dxi-item title="Distancia" style="height: 300px;">
        <div *dxTemplate="let data of 'content'">
            <app-traceability-diagram (switchChanged)="onSwitchChangedInDiagram($event)" [textBoxValue]="textBoxValue"
                [traceabilityDiagram]="dataFromSearchPanel" [showDistance]="showDistance"></app-traceability-diagram>
        </div>
    </dxi-item>
</dx-accordion>