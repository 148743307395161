import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxDataGridModule, DxGalleryModule, DxLoadPanelModule, DxPopupModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainScreenComponent } from './main-screen.component';
import { OverlappingRingsModule } from '@app/general/home/overlapping-rings/overlapping-rings.module';
import { DeviceOccupationModule } from '../device-occupation/device-occupation.module';



@NgModule({
  declarations: [
    MainScreenComponent
  ],
  imports: [
    CommonModule,
    DxGalleryModule,
    RouterModule,
    DxButtonModule,
    TranslateModule,
    DxPopupModule,
    MatCardModule,
    DxLoadPanelModule,
    DxDataGridModule,
    FlexLayoutModule,
    OverlappingRingsModule,
    DeviceOccupationModule
  ],
  exports	: [MainScreenComponent]
})
export class MainScreenModule { }
