import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';




@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {

  ngOnInit(): void {
    this.show(this.items[0])
  }


  selectedItem;

  constructor( private router: Router){
    
  }
 
  redirectToLogin(){
    this.router.navigate(["/login"]);
  }
  
  show(item){
    this.items.forEach(x => x.selected=false)
    item.selected = true;
    this.selectedItem = item;

  }

  items= [
    {
      selected: false,
      title:"Mapping & design",
      img:"../../assets/main/Group (3).png",
      imgSelected:"../../assets/main/Group.png",
      imgDerecha:"../../assets/main/slide 1.png",
      legendaDerecha: "Adapt and design the network and its infrastructure with agile, dynamic, and intuitive tools. Integrate legacy network documentation systems with our inventory Portal and maintenance solution. Use the dashboards to check on the progress status of information loads."
     
    },
    {
      selected: false,
      title:"Network inventory",
      img:"../../assets/main/Group (1).png",
      imgSelected:"../../assets/main/Group (4).png",
      imgDerecha:"../../assets/main/slide 2.png",
      legendaDerecha: "End-to-end management of physical and logical network resources. You can engage SFS with third-party systems for as-built confirmation and service provisioning or you can just take advantage of our full solution."
    },
    {
      selected: false,
      title:"Network maintenance",
      img:"../../assets/main/Group (2).png",
      imgSelected:"../../assets/main/Group (5).png",
      imgDerecha:"../../assets/main/slide 3.png",
      legendaDerecha: "Visualize both network general information and infrastructure detail from the back-office portal. Achieve preventive and corrective maintenance through the real-time visualization of ongoing tasks or OTDR monitoring, and identify your services all over the network."
    },
  ];
}




