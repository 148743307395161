import { LocationData } from './aux-models/locationData';
import { ElementType } from './elementType.model';
import { ICommonEntity } from './interface/iCommonEntity';
import { Platform } from './platform.model';
import { Cons } from '@app/@shared/cons/cons';
import { Type } from 'class-transformer';
// export class EquipmentType implements ICommonEntity {
export class EquipmentType{
  equipmentTypeID: number;
  platformID: number;
  name: string;
  model: string;
  specAttributes : any;

  _SCALE: number = 0.24;
  infrastructureID: number;
  elementTypeID: number;
  elementType: ElementType;
  locationData: LocationData;
  
  
  
  
  description?: string;
  infrastructureAttributes?: any;

  @Type(()=> EquipmentType)
  equipment?: EquipmentType[];

  
  get commonID() {
    return this.equipmentTypeID;
  }

  get entityName() {
    return Cons._EQUIPMENT;
  }

}

