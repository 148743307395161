import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Trace } from '@app/@shared/model/trace.model';

@Component({
  selector: 'app-trace-list',
  templateUrl: './trace-list.component.html',
  styleUrls: ['./trace-list.component.scss'],
})
export class TraceListComponent {
  @Input() traces: Trace[] = [];
  @Input() loading: boolean = false;

  @Output() editItem = new EventEmitter<Trace>();
  @Output() deleteItem = new EventEmitter<Trace>();

  constructor() {}
}