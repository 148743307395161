import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { UserFile } from '@app/@shared/model/userFile.model';
import { PlatformService } from '@app/auth/platform.service';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { MainService } from '../main.service';
import { CommentModel } from '@app/@shared/model/comment.model';
import { ResponseAPI } from '@app/@shared/model/responseApi.model';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
    
  ) {}

  getWithFilter(entity?: any): Observable<any> {
    this.main.chooseAPI(Cons._FILE);
    return this.main.getWithFilter('File', entity, false).pipe(
      map((res) => <UserFile>res.responseData),
      catchError((ex)=> throwError(() =>{
        return new Error(ex?.message);
      }))
    );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._FILE);
    return this.main.getByID('File', id).pipe(
      map((res) => {
          res.commonID = res.fileID;
          
          return <UserFile>res;
      }),
      catchError((ex)=> throwError(() =>{
        return new Error(ex?.message);
      }))
    );
  }

  getByElementID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._FILE);
    return this.main.getByID('Photo', id).pipe(
      map((res) => {   
          return <UserFile>res;
      }),
      catchError((ex)=> throwError(() =>{
        return new Error(ex?.message);
      }))
    );
  }

  save(entity : UserFile): Observable<void> {
    this.main.chooseAPI(Cons._FILE);

    return this.main.put(Cons._FILE, entity.fileID.toString(), entity).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex?.message)))
    );
  }


  removePhoto(eventID: number) {
    this.main.chooseAPI(Cons._FILE);

    return this.main.delete('Photo', eventID).pipe(
        map((x) => x.responseData),
        catchError((ex) => throwError(() => new Error(ex?.message)))
    );
}

  removeComment(eventID: number) {
    this.main.chooseAPI(Cons._FILE);

    return this.main.delete('Comment', eventID).pipe(
        map((x) => x.responseData),
        catchError((ex) => throwError(() => new Error(ex?.message)))
    );
}

  uploadFile(photo: FormData): Observable<ResponseAPI> {
    this.main.chooseAPI(Cons._FILE);
    const url = `${Cons._FILE}/${this.platformService.platformID}`;      
    return this.main.postFile(null, photo, url);
  }
  
  uploadComment(comment: string, infrastructureID: number, platformID: number, fileID: number, userID: number, entityName: string): Observable<void> {
    this.main.chooseAPI(Cons._FILE);
    const url = `${Cons._FILE}/Comment`;
    const body = {
      EntityID: infrastructureID,
      Description: comment,
      PlatformID: platformID,
      FileID: fileID,
      UserID: userID,
      EntityName: entityName,
    };
  
    return this.main.post('Comment', null, body).pipe(
      map(() => {}), 
      catchError((ex) => {
        console.error('Error uploading comment:', ex);
        return throwError(() => new Error(ex?.message));
      })
    );
  }

  getCommentByEntityID(entityID: number): Observable<CommentModel[]> {
    this.main.chooseAPI(Cons._FILE); 
    return this.main.getByID('Comment', entityID).pipe(
      map((res: any) => {

        const comments = res.map((comment: any) => {
          if (comment.description === undefined || comment.creationDate === undefined) {
            console.warn('Comment missing properties:', comment);
          }
  
          const transformedComment: CommentModel = {
            user: comment.userName,
            description: comment.description || '',
            creationDate: comment.creationDate,
            fileID: comment.fileID,
            userID: comment.userID,
            eventID: comment.eventID,
          };
  
          return transformedComment;
        });
  
        return comments;
      }),
      catchError((ex) => {
        console.error('Error fetching comments:', ex);
        return throwError(() => new Error(ex?.message));
      })
    );
  }


  getCommentByFileID(fileID: number): Observable<CommentModel[]> {
    this.main.chooseAPI(Cons._FILE); 
    return this.main.getByID('CommentODF', fileID).pipe(
      map((res: any) => {

        const comments = res.map((comment: any) => {
          if (comment.description === undefined || comment.creationDate === undefined) {
            console.warn('Comment missing properties:', comment);
          }
  
          const transformedComment: CommentModel = {
            user: comment.userName,
            description: comment.description || '',
            creationDate: comment.creationDate,
            fileID: comment.fileID,
            userID: comment.userID,
            eventID: comment.eventID,
          };
  
          return transformedComment;
        });
  
        return comments;
      }),
      catchError((ex) => {
        return throwError(() => new Error(ex?.message));
      })
    );
  }
  
}


