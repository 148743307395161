import { Component, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit, Input, OnInit } from '@angular/core';
import { GalleryService } from '@app/@shared/services/aux-services/gallery.service';
import { GalleryImage } from '@app/@shared/model/gallery.model';
import * as DevExpress from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@app/@shared/services/aux-services/file.service';
import { firstValueFrom } from 'rxjs';
import DxLoadIndicator from 'devextreme/ui/load_indicator';
import { ResponseAPI } from '@app/@shared/model/responseApi.model';
import { PlatformService } from '@app/auth/platform.service';
import { DxGalleryComponent } from 'devextreme-angular/ui/gallery';
import { CredentialsService } from '@app/auth';
import { CommentModel } from '@app/@shared/model/comment.model';
import { CommentInterface } from '@app/@shared/model/interface/comment-interface';


@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss']
})
export class GalleryViewerComponent implements OnInit {



  @Input() equipmentID!: number;
  isSaving: boolean = false;
  comments: CommentInterface[] = [];

  textComment: string = '';

  file: File | null = null;
  platformID: number | null = null;
  equipmentIDLocal: number;
  currentImageIndex: number;
  intervalId: any;
  currentIndex: number = 0;

  currentUserID: number | null = null;
  selectedImage: string = '';




  @ViewChild('imageCanvas', { static: false }) imageCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChildren('canvasElement') canvasElements!: QueryList<ElementRef<HTMLCanvasElement>>;
  @ViewChild('gallery') gallery: DxGalleryComponent;


  private readonly maxImages = 5;
  predefinedImages: GalleryImage[] = [];
  private imageCache: Map<string, HTMLImageElement> = new Map();
  imageGallery: GalleryImage[] = [];





  loadingImageIndex: number | null = null;

  constructor(
    private galleryService: GalleryService,
    private trans: TranslateService,
    private fileService: FileService,
    private platformService: PlatformService,
    private credentialsService: CredentialsService,

  ) {



  }

  ngOnInit(): void {
    this.getPlatformID();
    this.getCurrentUserID();
    this.loadImages();
   

  }

  getPlatformID(): number {
    this.platformID = this.platformService.platformID;
    return this.platformID;
  }

  async loadImages() {
    try {

      const imageResponse = await this.fileService.getByElementID(this.equipmentID).toPromise();

      if (Array.isArray(imageResponse)) {
        this.imageGallery = imageResponse.map(image => ({
          fileID: image.fileID,
          imageSrc: image.filePath
        }));
      } else {
        console.warn('Expected an array but received:', imageResponse);
        this.imageGallery = [];
      }


    } catch (error) {
      console.error('Error loading images from database:', error);
    }

 

  }
  
  async loadCommentByFileID() {
    const fileID = this.getFileIDFromCurrentIndex();
    
    try {
      const comments = await this.fileService.getCommentByFileID(fileID).toPromise();
      const processedComments = await this.processComments(comments);
      this.updateCommentsState(processedComments);
    } catch (error) {
      console.error('Error al obtener comentarios:', error);
    }
  }

  private async processComments(comments: any[]): Promise<CommentInterface[]> {
    return Promise.all(
      comments.map(async (item: any) => {
        return {
          ...item,
          initials: this.getInitials(item.user as string),
          content: String(item.description),
          name: item.user,
          creationDate: new Date(item.creationDate),
          eventID: item.eventID
        } as CommentInterface;
      })
    );
  }

  private updateCommentsState(commentsWithFileLinks: CommentInterface[]) {
    this.comments = commentsWithFileLinks;
    this.comments.sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    const initials = names.map(n => n.charAt(0).toUpperCase()).join('');
    return initials;
  }

  async onAddImage() {
    if (this.imageGallery.length >= this.maxImages) {
      const message = `<i>${this.trans.instant("general.limit-reached-description")}</i>`;
      const title = this.trans.instant("general.limit-reached");

      try {
        await DevExpress.custom({
          title,
          messageHtml: message,
          buttons: [
            { text: this.trans.instant('general.ok'), onClick: () => { } }
          ]
        }).show();
      } catch (error) {
        console.error('Error showing limit reached message:', error);
      }
      return;
    }

    // Select photo
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (event: any) => {
      const file = event.target.files[0];
      if (file) {
        this.isSaving = true;

        // Upload file
        const formData = new FormData();
        formData.append('file', file);
        formData.append('elementID', this.equipmentID?.toString() || '');
        formData.append('entityType', 'EQUIPMENT');

        try {
          await firstValueFrom(this.fileService.uploadFile(formData));

          await this.loadImages();

          this.isSaving = false;
        } catch (error) {
          console.error('Error uploading file:', error);
          this.isSaving = false;
        }
      }
    };
    input.click();
  }

  saveComment() {
    if (this.equipmentID === null || this.textComment.trim() === '') {
      console.error('No se ha cargado el ID de infraestructura o el comentario está vacío.');
      return;
    } else {

      this.uploadComment();

    }
  }


  private uploadComment() {
    const fileID = this.getFileIDFromCurrentIndex();
    if (fileID !== null) {
      this.isSaving = true;
      this.fileService.uploadComment(this.textComment, this.equipmentID, this.platformID, fileID, this.currentUserID, "EQUIPMENT").subscribe(
        async () => {
          this.isSaving = false;
          await this.loadCommentByFileID(); 
          this.textComment = ''; 
        },
        (error) => {
          this.isSaving = false;
          console.error('Error al subir el comentario:', error);
        }
      );
    } else {
      console.warn('No fileID available for upload.');
    }
  }


  initializeCurrentIndex(): void {
    this.currentIndex = 0;
    this.loadCommentByFileID();
  }

  handleOptionChanged(event: any): void {
    if (event.name === 'selectedIndex') {
      this.currentIndex = event.value;
    

      this.loadCommentByFileID();
    }

  

  }

  deleteImage(): void {
    const fileID = this.getFileIDFromCurrentIndex();
    if (fileID !== null) {
      const title = this.trans.instant("general.confirm-action");
      const message = `<i>${this.trans.instant("infrastructure-odf.delete.image")}</i>`;
  
      try {
        DevExpress.custom({
          title,
          messageHtml: message,
          buttons: [
            { text: this.trans.instant('general.yes'), onClick: () => this.confirmDeleteImage(fileID) },
            { text: this.trans.instant('general.no'), onClick: () => { } }
          ]
        }).show();
      } catch (error) {
        console.error('Error confirming delete:', error);
      }
    }
  }

  confirmDeleteImage(file_ID: number): void {
    if (file_ID !== null) {
      this.fileService.removePhoto(file_ID).subscribe(
        () => {
          this.loadImages();
        },
        (error) => {
          console.error('Error al eliminar la imagen:', error);
        }
      );
    } else {
      console.warn('fileID no está definido.');
    }
  }

  downloadImage(): void {
    const fileID = this.getFileIDFromCurrentIndex();
    if (fileID !== null) {
      try {
        const fileURL = this.getFileURLFromCurrentIndex();
        if (fileURL) {
          this.downloadFile(fileURL);
        } else {
          console.warn('No se pudo obtener la URL o el nombre del archivo.');
        }
      } catch (error) {
        console.error('Error al intentar descargar:', error);
      }
    }
  }

  private downloadFile(fileUrl: string): void {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



  formatTime(time: Date): string {
    const adjustedTime = new Date(time.getTime() - (3 * 60 * 60 * 1000));

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(adjustedTime);
  }


  convertLineBreaksToHtml(content: string): string {
    return content.replace(/\n/g, '<br>');
  }


  async getFileLink(fileID: number): Promise<{ filePath: string, fileName: string }> {
    try {
      const res: any = await this.fileService.getByID(fileID).toPromise();
      return { filePath: res.filePath, fileName: res.name };
    } catch (error) {
      console.error('Error fetching file:', error);
      throw new Error(error?.message);
    }
  }


  getCurrentUserID() {
    const currentUserIDstring = this.credentialsService.decodeToken()?.['userID'];
    this.currentUserID = currentUserIDstring ? parseInt(currentUserIDstring, 10) : undefined;
    return this.currentUserID;
  }
  

  private getFileIDFromCurrentIndex(): number | null {
    if (this.imageGallery.length > 0 && this.currentIndex !== null) {
      const iamge = this.imageGallery[this.currentIndex];
      return iamge.fileID;
    }
    console.warn('No hay imágenes para eliminar o el índice actual es inválido.');
    return null;
  }

  private getFileURLFromCurrentIndex(): string | null {
    if (this.imageGallery.length > 0 && this.currentIndex !== null) {
      const image = this.imageGallery[this.currentIndex];
      return image.imageSrc;
    }
    console.warn('No hay imágenes para mostrar o el índice actual es inválido.');
    return null;
  }
  

  openImageInNewTab(event: any): void {
    let imageSrc = event.itemData.imageSrc;
  
    if (imageSrc.includes('dropbox.com')) {
      imageSrc = imageSrc.replace('?dl=1', '?raw=1').replace('&dl=1', '&raw=1');
    }
  
    const link = document.createElement('a');
    link.href = imageSrc;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
