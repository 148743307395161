import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '@app/@shared/services/main.service';
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;

  @Input() background: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() showSaveButton: boolean = false;
  @Input() showAddButton: boolean = false;

  @Output() onSaved = new EventEmitter<any>();
  @Output() onAddClicked = new EventEmitter<any>();

  constructor(
    public mainService: MainService,
    public router: Router,
    public trans: TranslateService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    /* config({
      floatingActionButtonConfig: {direction: 'down', position: { at: 'right top', offset: { y: 85, x: -35 } }},
    });
    repaintFloatingActionButton();*/
  }

  save($event: any) {
    this.onSaved.emit($event);
  }

  add($event: any) {
    this.onAddClicked.emit($event);
  }
}
