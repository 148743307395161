<dx-drawer
  [openedStateMode]="drawerLayout.open"
  [position]="drawerLayout.position"
  [revealMode]="'slide'"
  template="template"
  [opened]="!!selectedPlot"
  [height]="'100%'"
  [closeOnOutsideClick]="false"
>
  <div
    *dxTemplate="let data of 'template'"
    class="plot-form-template-container"
    [ngClass]="{
      mobile: currentLayout.mobileLayout,
      desktop: !currentLayout.mobileLayout
    }"
  >
    <div
      class="plot-form-container"
      [ngClass]="{
        mobile: currentLayout.mobileLayout,
        desktop: !currentLayout.mobileLayout
      }"
    >
      <app-plot-form
        [plot]="selectedPlot?.entity"
        [centerExtent]="selectedPlot?.centerExtent"
        (cancel)="closePlotForm()"
        (nextPlot)="selectNextPlot()"
        (plotAddressUpdated)="plotAddressUpdated($event)"
        (platformPlotChange)="platformPlotChange($event)"
      ></app-plot-form>
    </div>
  </div>
  <div style="height: 100%;">

    <!--div fxLayout="row" fxLayoutAlign="center center" id="address-input">
      <dx-text-box
        [(value)]="address"
        [placeholder]="'survey.map.address' | translate"
        (keyup.enter)="search()"
        [disabled]="isLoading"
      >
        <dxi-button
          name="currency"
          location="after"
          [options]="searchButton"
        ></dxi-button>
      </dx-text-box>
    </div 10/09/22: Quitado temporalmente... se va a mejorar la búsqueda de calles por otro medio-->

    <ol-map
      #olMap
      [points]="points"
      [latitude]="latitude"
      [longitude]="longitude"
      [zoom]="zoom"
      [polygons]="polygons"
      [cssClass]="'position-fixed full-width'"
      [height]="'100%'"
      (onMapClicked)="mapClicked($event)"
      (onElementHovered)="elementHovered($event)"
      (onMapZoomed)="mapZoomed($event)"
      (onMapReady)="mapReady()"
    >
    </ol-map>

    <div fxLayout="row" fxLayoutAlign="center center" class="main-action-btn-container" >
      <div fxFlex="100" style="text-align: center" *ngIf="loadPlotVisible">
        <dx-button
          class="button main-background-theme"
          [text]="'survey.map.load-plots' | translate"
          width="200"
          height="40"
          (click)="loadPlotsByArea()"
        >
        </dx-button>
      </div>
    </div>
  </div>
</dx-drawer>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: 'dx-drawer' }"
  [(visible)]="loading_panel"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>
