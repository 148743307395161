import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ElementType } from "@app/@shared/model/elementType.model";
import { Trace } from "@app/@shared/model/trace.model";
import { ElementTypeService } from "@app/@shared/services/element-type.service";
import { TraceService } from "@app/@shared/services/map-elements-services/trace.service";
import { PlatformService } from "@app/auth/platform.service";
import { TranslateService } from "@ngx-translate/core";
import { plainToClass } from "class-transformer";
import notify from "devextreme/ui/notify";
import { firstValueFrom } from "rxjs";



@Component({
    selector: 'app-trace-home',
    templateUrl: './trace-home.component.html',
    styleUrls: ['./trace-home.component.scss'],
  })

  export class TraceHomeComponent implements OnInit {

    modalTitle: string;
    selectedTrace: Trace;
    loading: boolean = false;
    loadingModal: boolean = false;
    dataSource: Trace[] = [];
    entityType = "TRACE";
    entityTypeModels = [];

    popups = {
        confirmDelete: false,
        createOrEdit: false,
    };
    selectedDevice: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private traceService: TraceService,
        private platformService: PlatformService,
        private translateService: TranslateService,
        private elementTypeService : ElementTypeService
      ) {}
   
      

    ngOnInit(): void {
        // throw new Error("Method not implemented.");
        this.search();

        let filter = new ElementType();
        filter.groupID = this.entityType;
        filter.platformID = this.platformService.activePlatform.platformID;
        this.elementTypeService.getElementsTypeWithFilter(filter).subscribe({
          next: (res)=>{
            if(res){
              this.entityTypeModels = res.sort((a,b)=> { return (a.name > b.name ? 1 : -1); });
            }
          }
        });
    }
    openModalAddTrace() {
        const platformID: number = this.platformService.platformID;
        this.modalTitle = this.translateService.instant('devices.add-device');
        this.selectedTrace = plainToClass(Trace, { platformID });
        this.popups.createOrEdit = true;
    }
    closeModal() {
        this.popups.createOrEdit = false;
        this.selectedTrace = undefined;
    }
   

    async search(filter? : any) {
        if(!filter){
          filter = {};
        }
        this.loadingModal=true;
        this.traceService.getWithFilter(filter, 100).subscribe(
        (value) => {
            this.dataSource = value;
            this.loadingModal=false;
        }
        );
    }
    async openModalEditTrace(trace: Trace) {
        this.modalTitle = this.translateService.instant('devices.edit-device');
        this.loadingModal = true;
        this.selectedTrace = await firstValueFrom(this.traceService.getByID(trace.traceID));
        this.loadingModal = false;
        this.popups.createOrEdit = true;
    }
    openPromptDeleteTrace(trace: Trace) {
        this.selectedTrace = trace;
        this.popups.confirmDelete = true;
      }

  }