<dx-list
  (onItemClick)="itemClick($event)"
  id="userList"
  [ngbTooltip]="'general.profile' | translate"
>
  <dxi-item fxLayout="row" fxLayoutAlign="center center">
    <div style="font-size: 22px">
      <img class="other-icons user" src="../../../assets/icons/user.svg" />
    </div>
  </dxi-item>
</dx-list>
<dx-popover
  target="#userList"
  position="bottom"
  [width]="200"
  [(visible)]="actionSheetVisible"
>
  <div *dxTemplate="let data = model; of: 'content'" id="lang_popover">
    <span translate>{{ 'general.logged' | translate }}</span>
    <b>{{ username }}</b>
  </div>
</dx-popover>
