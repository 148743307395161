<dx-data-grid
  [dataSource]="ports"
  [showBorders]="true"
  [showRowLines]="true"
>
  <dxi-column dataType="string" [caption]="'devices.add-edit.port-grid.id' | translate" dataField="portID"></dxi-column>
  <dxi-column [caption]="'devices.add-edit.port-grid.status' | translate" dataField="status"></dxi-column>
  <dxi-column dataType="string" [caption]="'devices.add-edit.port-grid.number' | translate" dataField="number"></dxi-column>
  <dxi-column dataType="string" [caption]="'devices.add-edit.port-grid.accessID' | translate" dataField="accessID"></dxi-column>
  <dxi-column [caption]="'devices.add-edit.port-grid.updated' | translate" cellTemplate="updatedDateTemplate"></dxi-column>

  <div *dxTemplate="let d of 'updatedDateTemplate'">
    <div> {{ d?.data?.updated | date: 'dd/MM/yyyy HH:mm' }}</div>
  </div>
</dx-data-grid>

