<label class="main-secondary-text">{{ 'equipment-type.edit-equipment' | translate }}</label>
  <div fxLayout="column" fxLayoutAlign="space-between none">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="flex flex-wrap justify-content-between">
        <!-- <dx-text-box fxFlex="49" class="my-3" *ngFor="let equipmentEditableField of selectedEquipmentType"
          height="45px"
          [(value)]="equipmentEditableField.field"
          labelMode="floating"
          [label]="equipmentEditableField.label | translate"
        >
        </dx-text-box> -->
        <ng-container *ngFor="let elementEditableField of elementEditableData">
            <ng-container *ngIf="selectedEquipmentType; else elseTemplate">
                <dx-text-box fxFlex="49" class="my-3"
                [(value)]="selectedEquipmentType[elementEditableField.field]"
                labelMode="floating"
                [label]="elementEditableField.label | translate"
                >
                </dx-text-box>
            </ng-container>
            <ng-template #elseTemplate>
                <dx-text-box fxFlex="49" class="my-3"
                [(value)]="elementEditableField.label"
                labelMode="floating"
                [label]="elementEditableField.label | translate"
                >
                </dx-text-box>
            </ng-template>           
        </ng-container>
      </div>
    </div>
  </div>
  <label class="main-secondary-text">SpecData</label>
  <!-- <dx-data-grid
  [dataSource]="dataSourceGrid"
  [showBorders]="true"
  [showRowLines]="true">
    <dxi-column [caption]="'equipment-type.grid-header.name' | translate" dataField="NAME"></dxi-column>
    <dxi-column [caption]="'equipment-type.grid-header.number' | translate" dataField="Number"></dxi-column>
    <dxi-column [caption]="'equipment-type.grid-header.description' | translate" dataField="Description"></dxi-column>
  </dx-data-grid> -->
  <spec-tree-view 
  [specAttributes]="selectedEquipmentType.specAttributes"
  (onSpecSaved)="specSaved($event)"
  (isJSONValid)="isJSONValid = $event">
  </spec-tree-view>
    <div fxLayout="row" fxLayoutAlign="end center" class="my-4 pe-2">
        <dx-button
            [text]="'general.cancel' | translate"
            (click)="cancel.emit()"
        >
        </dx-button>
        <dx-button
            [text]="'general.save' | translate"
            (click)="saveElementType()"
        >
        </dx-button>
    </div>  