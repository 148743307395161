import { Injectable } from '@angular/core';
import { PlatformService } from '@app/auth/platform.service';
import { map, Observable } from 'rxjs';
import { Cons } from '../../cons/cons';
import { Connection } from '../../model/connection.model';
import { MainService } from '../main.service';

@Injectable()
export class ConnectionService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) {}

  getWithFilter(entity?: any): Observable<any> {
    this.main.chooseAPI(Cons._CONNECTION);
    return this.main.getWithFilter('Connection', entity).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            return res.responseData.map((x) => {
              x.commonID = x.containerID;
              x.entityName = Cons._CONNECTION;
              return <Connection>x;
            });
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._CONNECTION);
    return this.main.getByID('Connection', id).pipe(
      map((res) => {
        try {
          return res.map((x) => {
            x.commonID = x.containerID;
            x.entityName = Cons._CONNECTION;
            return <Connection>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByDeviceID(id: number, connectionType: string): Observable<any> {
    this.main.chooseAPI(Cons._CONNECTION);
    return this.main
      .getWithFilter('Connection', {
        elementID3: id,
        connectionType: connectionType,
        platformID: this.platformService.platformID,
      })
      .pipe(
        map((res) => {
          try {
            return res.responseData.map((x) => {
              x.commonID = x.containerID;
              x.entityName = Cons._CONNECTION;
              return <Connection>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  getByTraceID(id: number): Observable<any> {
    this.main.chooseAPI(Cons._CONNECTION);
    return this.main
      .getWithFilter('Connection', {
        elementID3: id,
        connectionType: 'TRACE_',
        platformID: this.platformService.platformID,
      })
      .pipe(
        map((res) => {
          try {
            return res.responseData.map((x) => {
              x.commonID = x.containerID;
              x.entityName = Cons._CONNECTION;
              return <Connection>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }
}
