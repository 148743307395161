import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { Device } from '@app/@shared/model/device.model';

@Injectable({ providedIn: 'root' })
export class DeviceResolver implements Resolve<Device> {
  constructor(
    private deviceService: DeviceService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Device> {
    const deviceID = Number(route.params['deviceID']);
    return await firstValueFrom(this.deviceService.getByID(deviceID));
  }
}
